import React, { useState, useEffect, Fragment, useRef } from 'react';
import PropertyEditor from './PropertyEditor';
import Folder from './Folder';
import { propertyAccessor } from '../Utils/PropertiesOperations';
import { InitializePromoCodeEditor } from '../Components/PromoCodeEditor';

export default function PropertiesGroup({
	property,
	context
}) {
	console.log('🚀🐱 😻 --///** ~ file: PropertiesGroup.js:11 ~ property:', property)
	const {
		getCurrentVariation,
		handleGroupPropertyValueChange,
		handleChangeIsActivePromoCode,
		handleIsFoldedChanged,
		currentDevice,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
		features
	} = context;

	const propertyEditorContext = {
		features,
		getCurrentVariation,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
	};

	const { getGroupPropertyValue } = propertyAccessor();

	const activeVariation = getCurrentVariation() || {};
	const getValue = (item) => getGroupPropertyValue(activeVariation.Properties, item, property.Name);
	const isActive = !property.IsActiveProperty || getValue(property.IsActiveProperty) === true;

	const [steps, setSteps] = useState();

	const [hasPromoCode, setHasPromoCode]=  useState(false);
	const isPromoCodeContent = (c) => c.Editor.Type == 'PromoCode';
	const checkHasPromoCode = () => property.Content.some(isPromoCodeContent);

	useEffect(() => {
		if (property.MultiSteps) {
			setSteps(getSteps(property.MultiSteps.Steps));
		}

		const hasCode = checkHasPromoCode();
		setHasPromoCode(hasCode);
		if (hasCode) {			
			handleChangeIsActivePromoCode(isActive);
		}
	}, [property]);

	const getSteps = (objectSteps) => {
		let arraySteps = [];
		for (const stepProperty in objectSteps) {
			arraySteps.push({
				value: stepProperty,
				label: objectSteps[stepProperty]
			});
		}
		return arraySteps;
	};

	const localHandleIsActiveChanged = (checked) => {
		if (hasPromoCode) {
			handleChangeIsActivePromoCode(checked);
		}
		return handleGroupPropertyValueChange(checked, property.Name, property.IsActiveProperty);
	};

	const isCurrentStep = (step) => {
		const value = getValue(property.MultiSteps);
		if (Array.isArray(step)) {
			return step.includes(value);
		}
		return step == value;
	};

	const checkIfShouldSkip = (item) => {
		const shouldSkip = (item.Device && item.Device !== currentDevice) || (item.Step && !isCurrentStep(item.Step));
		if (shouldSkip){
			if (hasPromoCode && isPromoCodeContent(item)){
				return { shouldSkip: true, toReturnOnSkip: <InitializePromoCodeEditor 
					key={'key_init_promocode'} 
					data={getValue(item)}
					getCurrentVariation={getCurrentVariation}
					handleSetPromoCode={handleSetPromoCode}
				>
				</InitializePromoCodeEditor> };
			}	
			return { shouldSkip: true, toReturnOnSkip: null };
		} 

		return { shouldSkip, toReturnOnSkip: null };
	};

	return (
		<Folder
			property={property}
			handleIsActiveChanged={checked => localHandleIsActiveChanged(checked)}
			handleIsFoldedChanged={handleIsFoldedChanged}
			isActive={isActive}
		>
			{property.MultiSteps &&
				<div className="format_param">
					<ul className="format_param_breadcrumb">
						{steps && steps.map((step, i) => {
							return (
								<li key={i}>
									<a
										className={step.value == getValue(property.MultiSteps) ? 'active' : ''}
										onClick={() => handleGroupPropertyValueChange(step.value, property.Name, property.MultiSteps)}
									>{step.label}</a>
								</li>
							);
						})}
					</ul>
				</div>
			}
			{property.Content.map((item, j) => {
				const { shouldSkip, toReturnOnSkip } = checkIfShouldSkip(item);
				if (shouldSkip){
					return toReturnOnSkip;
				}

				const localCallback = (v) => {
					return handleGroupPropertyValueChange(v, property.Name, item);
				};

				return (
					<Fragment key={j}>
						<PropertyEditor
							key={j}
							context={propertyEditorContext}
							data={getValue(item)}
							property={item}
							callBack={localCallback}
							propertyContainer={
								{ 
									name: property.Name, 
									type: property.Editor.Type, 
									siblingPropertiesAccessor: {
										getSiblingPropertyData: subPropertyName => getValue({Name: subPropertyName}),
										setSiblingPropertyData: (subPropertyName, v) => handleGroupPropertyValueChange(v, property.Name, { Name: subPropertyName })
									}
								}
							}
						/>
					</Fragment>
				);
			})}
		</Folder>
	);
}