import React from "react";
import InputCustom from '../../../../Components/InputCustom';
import SelectCustom from '../../../../Components/SelectCustom';
import websiteType from "./config";

export default function WebSiteInfo({ state, setState, error }) {

  const handleURLChange = (url) => {
    url = url.replace('http://', '');
    url = url.replace('https://', '');
    setState({ ...state, authorizedUrls: [url] });
  };

  return (
    <div className="ob_slide">
      <div className="ob_slide_typeContainer ob_slide_form_elem">
        <legend className="">Thanks for register!</legend>
        <legend className="ob_title_2 mb_30">On which website do you want <br /> to run BEYABLE?</legend>
        <InputCustom
          value={state.authorizedUrls[0]}
          name="authorizedUrls"
          type="text"
          label="Website URL"
          onChange={(e) => handleURLChange(e.target.value)}
          className="ob_text_input"
          labelClassName="s_17 mb_8"
          placeholder="www.mywebsite.com"
          fullWidth="true"
          isAnError={error.authorizedUrls}
          size="l"
          autoFocus={true}
          prepend={
            <span className="custom_input_prepend_text">https://</span>
          }
        />
        <div className="mt_30">
          <SelectCustom
            optionsList={websiteType}
            label="Website main objective"
            labelClassName="s_17 mb_8"
            name="mySelect"
            value={state.accountType}
            fullWidth="true"
            onChangeFunction={(e) => setState({ ...state, accountType: e.target.value })}
            defaultValue={websiteType[0]}
            size="l"
            fullWidth={true}
          />
        </div>
      </div>
    </div>
  );
}
