import React, {useState,useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import newInstanceI18next from '../../../../Util/traductionUtils';

import {Panel, PanelHeader, PanelBody, PanelClose} from '../../../../Components/Panel/Panel';
import SectionMessage from '../../../../Components/SectionMessage';
import Btn from '../../../../Components/Btn';
import { CampaignsContext } from '../../context/index';

import ContextFormAndSurveyProvider from './ContextFormAndSurvey';
import Breadcrumb from '../../../../Components/Breadcrumb';
import QuestionsStep from './QuestionsStep';
import ThanksSection from './ThanksSection';
import AppearanceSection from './AppearanceSection';
import styles from './Survey.module.css';

export default function PanelFormSurvey(props) {

	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<PanelFormSurveyInner {...props} />
		</I18nextProvider>
	);
}

function PanelFormSurveyInner() {

	const {
		handleOpenClosePanelSurveyForm,
		panelFormSurveyIsOpen,
		confirmFormDataSurvey,
		stepSurvey,
		setStepSurvey,
		thankSectionValue,
		setThankSectionValue,
		formSurveyStyles
	} = CampaignsContext();

	const [t] = useTranslation(['survey', 'utils']);

	const [formData, setFormData] = useState<string | object>();
	const refElement = useRef<HTMLParagraphElement>(null);
	const [panelSelected, setpanelSelected] = useState<any>();
	const [hasErrorsOnQuestion, sethasErrorsOnQuestion] = useState(false);
	const [state, setState] = useState<any>();

	const [nbQuestions, setNbQuestions] = useState(0);
	const [isLast, setisLast] = useState(false);
	const [deleteQuestion, setdeleteQuestion] = useState(false);
	function deleteAnswers(){
		const oldState = JSON.parse(JSON.stringify(state));
		const sections = oldState.sections.map(section => {
			
			const panels = section.panels.map(panel => {
				
				const newPanel = { ...panel, valueForTest : '' };
				return newPanel;
				
			});
			setpanelSelected(panels[0]);
		
			return {
				...section,
				panels,
				id: section.id
			};
		});
		setState({'sections' : sections});
	}
	function updatePanel(id, _sectionId, stateToUpdate) {
		let newP = null;
		const oldState = JSON.parse(JSON.stringify(state));

		const sections = oldState.sections.map(section => {
			
			const panels = section.panels.map(panel => {
				if (id && panel.id === id) {
					const newPanel = { ...panel, ...stateToUpdate };
					newP= newPanel;
					setpanelSelected(newPanel);
					return newPanel;
				}
				return panel;
			});
			if(stateToUpdate.hasOwnProperty('styles') || stateToUpdate.hasOwnProperty('thanksSection') || stateToUpdate.hasOwnProperty('css')){
				return { ...section, ...stateToUpdate, panels,  id: section.id};
			}else{
				return { ...section, panels,  id: section.id};
			}
		
		});
		setState({'sections' : sections});
		setpanelSelected(newP);
	}
	function checkAllField(formData){
		const sections = formData;
		let isErr = false;
		sections.sections.forEach((section) => {
			section?.panels.map(element => {
				if(element.type === 3 && element.question !== ''){
					if(!element.hasOwnProperty('buttonType')){
						element['buttonType'] = 'submit';
					}
						
					element.name = element.question;					
				}
				else if(element.type === 8 && element.name !== '' && element.question === ''){
					element.question = element.name;
				}
				else if (element.question === ''){
					isErr = true;
				}
								
			});
		}) ;
		if(!isErr){
			confirmFormDataSurvey(JSON.stringify(sections));
		}
		return isErr;
		
	}
	function cleanObj (){
		const oldState = JSON.parse(JSON.stringify(state));
		const sections = oldState.sections.map(section => {
			
			const panels = section.panels.map(panel => {
				const newPanel = { ...panel, valueForTest : '' };
				setpanelSelected(newPanel);
				return newPanel;
			});
			return { ...section, panels,  id: section.id};
		});

		return JSON.stringify({'sections' : sections});
	}

	function handleChangeFormFata(){
		const hasErrors = checkAllField(state);
		if(!hasErrors){
			confirmFormDataSurvey(cleanObj());
			sethasErrorsOnQuestion(false);
		}else{
			if (refElement.current) {
				window.scrollTo(0, 0);
			}
			sethasErrorsOnQuestion(true);
		}
	}

	useEffect(()=>{
		setpanelSelected(null);
		sethasErrorsOnQuestion(false);
	},[panelFormSurveyIsOpen]);

	function handleClickOnStep(indexToGo){
		const oldState = JSON.parse(JSON.stringify(state));
		const panSelect = oldState.sections[0].panels[0];
		if(indexToGo !== 2){
			setisLast(false);
			setpanelSelected(panSelect);
		}
		setStepSurvey(indexToGo);

	}
	function handleChangePanelSelected(){
		const oldState = JSON.parse(JSON.stringify(state));
		const panSelect = oldState.sections[0].panels[0];
		setpanelSelected(panSelect);
		setdeleteQuestion(false);
	}
	function removePanel(id, sectionId) {
		const sections = state.sections.map(section => {
			if (section.id === sectionId) {
				const panels = section.panels.filter(panel => id !== panel.id);
				section.panels = panels;
			}
			return section;
		});
		setdeleteQuestion(true);
		setState({ sections });
	}
	useEffect(()=>{
		let nbQuestions = 0;
		if(state && state.sections){
			state.sections.forEach(s => {
				nbQuestions += s.panels.length;
			});	
		}
		setNbQuestions(nbQuestions);
	},[state]);

	useEffect(()=> {
		if(deleteQuestion){
			handleChangePanelSelected();
		}
	},[deleteQuestion]);


	return (
		<Panel
			width={1040}
			isOpen={panelFormSurveyIsOpen}
			hasClose={false}
		>
			<PanelHeader
				hasClose={false}
				size='s'
			>
				<div className={styles.header}>
					<div className={styles.header_col_left}>
						<PanelClose
							onClick={() => handleOpenClosePanelSurveyForm(false)}
							position='header_left'
						/>
						<span className='h2'>{t('surveyEditor')}</span>
					</div>
					<div className={styles.header_col_center}>
						{nbQuestions > 0 &&
							<Breadcrumb
								itemOfBreadcrumb={ [
									{ index: 1, toGo: 1, title: t('questions') },
									{ index: 2, toGo : 2, title: t('thankYouMessage')},
									{ index: 3, toGo : 3, title: t('appeareance')},
								]}
								onClick={(indexToGo) => handleClickOnStep(indexToGo)}
								stepSelected={stepSurvey}
							/>
						}
					</div>
					<div className={styles.header_col_right}>
						<Btn
							message={t('actions.confirm', {ns: 'utils'})}
							color="primary"
							onClick={()=>handleChangeFormFata()}
						/>
					</div>
				</div>
			</PanelHeader>
			<PanelBody
				color='grey'
				position='static'
				hasHorizontalMargin={false}
				hasVerticalMargin={false}>

				<div className={styles.body}>
					{hasErrorsOnQuestion && 
						<div className={styles.body_messages}>
							<SectionMessage
								type="warning"
								appearance='background'
								noMargin={true}
								text={t('aQuestionCannotBeEmpty')}
								verticalSize="s"
							/>
						</div>
					}
					<div className={styles.body_main}>
						<ContextFormAndSurveyProvider
							setFormData={setFormData}
							formData={formData}
							panelSelected={panelSelected}
							updatePanel={updatePanel}
							setpanelSelected={setpanelSelected}
							stepSurvey={stepSurvey}
							stepSelected={setStepSurvey}
							formSurveyStyles={formSurveyStyles}
							deleteAnswers={deleteAnswers}
							isLast={isLast}
							setisLast={setisLast}
						>
							<QuestionsStep
								removePanel={removePanel}
								state={state}
								setState={setState}
								thankSectionValue={thankSectionValue}
								stepSurvey={stepSurvey}
								nbQuestions={nbQuestions}
							/>
							<ThanksSection
								state={state}
								formData={formData}
								updatePanel={updatePanel}
								inputValue={thankSectionValue}
								setInputValue={setThankSectionValue}
								stepSurvey={stepSurvey}
								panelSelected={panelSelected}
							/>
							<AppearanceSection
								stepSurvey={stepSurvey}
								updatePanel={updatePanel}
								state={state}
								thankSectionValue={thankSectionValue}
								formSurveyStyles={formSurveyStyles}
								panelSelected={panelSelected}
							/>

						</ContextFormAndSurveyProvider>
					</div>
				</div>
			</PanelBody>
		</Panel>
	);
}
