import React from 'react';
import { ReportGeneratorContext } from '../Context';
import { List, ListItem } from '../../../Components/List';
import IconBtn from '../../../Components/IconBtn';

import formatMoment from '../../../Constants/FormatMoment';
export default function CampaignSelector() {

	const {
		setmodalIsOpen,
		dataFiltered,
		handleChangeCpgIdOptions,
		setcampaignSelected
	} = ReportGeneratorContext();

	const { formatDateToLocal } = formatMoment;
	
	return (
		<List size='s'>
			{dataFiltered.map(campaign => {
				const today = moment();
				const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
				const dateEndOfOperation = formatDateToLocal(campaign.endDate);
				const isStoppedBoolOperation = campaign.isStopped;
				const isTestModeOperationBool = false;
				let statusOperation = {};
				//if dateEnd > today
				if (dateEndOfOperation > today) {
					//if begin > today and notStopped
					if (dateBeginOfOperation > today && !isStoppedBoolOperation) {
						if (isTestModeOperationBool) {
							statusOperation = { id: 'Testing', label: 'Testing', cssClass: 'status-planned testing' };
						} else {
							statusOperation = { id: 'Scheduled', label: 'Scheduled', cssClass: 'status-planned' };
						}
					}
					else if (!isStoppedBoolOperation) {
						if (isTestModeOperationBool) {
							statusOperation = { id: 'Testing', label: 'Testing', cssClass: 'status-testing' };
						} else {
							statusOperation = { id: 'Ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
						}
					} else {
						statusOperation = { id: 'Stopped', label: 'Stopped', cssClass: 'status-stopped' };
					}
				} else {
					statusOperation = { id: 'Past', label: 'Past', cssClass: 'status-past' };
				}
				return	<div className='flex' key={campaign.id}>
					<a className={''} style={{margin : '2px 10px'}} >
						<span className={'status_bullet '
                    + statusOperation.cssClass
						}></span>
				
					</a>
					<ListItem
						
						text={<>{campaign.name} {dateBeginOfOperation.format('DD/MM/YYYY')} - {campaign.endDate !== null ?dateEndOfOperation.format('DD/MM/YYYY') : 'No end date'}</>}
						description={campaign.description}
						textIsBold={true}
						actions={<>
							<IconBtn
								icon='fas fa-plus-circle fa-sm'
								tooltip='Add campaign to your report'
								isActive={true}
								onClick={() => {
									setcampaignSelected(campaign);
									handleChangeCpgIdOptions(campaign);
									setmodalIsOpen(true);
								}}
							/>
						</>
						}
					/>
				</div>;}
			)}
		</List>
	);
}
