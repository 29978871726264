import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import { ContextProvider, serverSideCampaignsDashboardContext } from './context';
import RouterWrapper from '../Dashboard/OnSite/RouterWrapper';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import SystemServices from '../../Services/SystemServices';
import CampaignCreatingServices from '../../Services/CampaignCreatingServices';
import ReportingCampaignServices from '../../Services/ReportingCampaignServices';
import ServerSideCampaignsList from './ServerSideCampaignList';
import ServerSideCampaignServices from '../../Services/ServerSideCampaignServices';
import ServerSideCampaignCreationModal from './ServerSideCampaignCreationModal';
import ServerSideCampaignEditionModal from './ServerSideCampaignIntegrationPanel';
import Btn from '../../Components/Btn';

export default function ServerSideDashboardContainer(props) {
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $http = props.$http;
	const $timeout = props.$timeout;
	const authServices = props.AuthServices;

	const campaignServices = new CampaignCreatingServices($http, $routeParams, authServices);
	const systemServices = new SystemServices($rootScope, $timeout);
	const reportingCampaignService = new ReportingCampaignServices($http, $routeParams);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);
	const serverSideCampaignServices = new ServerSideCampaignServices(accountId, $http);

	const isAdmin = impersonatingServices.isAnAdmin();

	const linkToReporting = (campaignId) => {
		if (isAdmin && $routeParams.ka) {
			return `Performance/Reporting?id=${campaignId}&dimension=onSite&ka=${accountId}&ku=${$routeParams.ku}`;
		} else {
			return `Performance/Reporting?id=${campaignId}&dimension=onSite`;
		}
	};

	return (
		<ContextProvider
			getCampaignList={(callbackSuccess, callbackError) => serverSideCampaignServices.getCampaignList(callbackSuccess, callbackError)}
			getCampaignInfos={(campaignId, callbackSuccess, callbackError) => serverSideCampaignServices.getCampaignInfos(campaignId, callbackSuccess, callbackError)}
			createNewCampaign={(campaign, callbackSuccess, callbackError) => campaignServices.createNewCampaign(accountId, campaign, callbackSuccess, callbackError)}
			deleteCampaign={(campaignId, callbackSuccess, callbackError) => reportingCampaignService.deleteACampaign(accountId, campaignId, callbackSuccess, callbackError)}
			systemServices={systemServices}
			linkToReporting={linkToReporting}
			$rootScope={$rootScope}
		>
			<RouterWrapper props={props}>
				<ServerSideDashboardContainerInner props={props} />
			</RouterWrapper>
		</ContextProvider>
	);
}

function ServerSideDashboardContainerInner(props) {

	const {
		toggleAddItem,
		totalCount,
		permissionsAccount
	} = serverSideCampaignsDashboardContext();

	const permCreateCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PERFCAMPAIGN_CAN_CREATE');
	const canCreateCampaign = permCreateCampaign && permCreateCampaign.Value === true;

	return (
		<div className='page_full_inner'>
			<section className='section no_bottom_pad section_primary'>
				<div className='flex'>
					<div className='flex_item_full'>
						<div className='h1'>Performance tracking campaigns</div>
					</div>
					{totalCount > 0 &&
						<div className='flex_item_fix ml_30'>
							<Btn
								message="Documentation"
								icon="far fa-sm fa-question-circle"
								href="https://docs.beyable.com/web-app-sdk/performance-tracking"
								target="_blank"
								rel="noreferrer"
								style="ghost_outline"
								color="secondary"
							/>
							{canCreateCampaign &&
								<Btn
									message="New campaign"
									icon="fas fa-sm fa-plus"
									onClick={() => toggleAddItem()}
								/>
							}
						</div>
					}
				</div>
			</section>
			<section className='section'>
				<ServerSideCampaignsList />
				<ServerSideCampaignCreationModal />
				<ServerSideCampaignEditionModal />
			</section>
		</div>
	);
}


angular
	.module('beyableSaasApp.ServerSideDashboard', ['beyableSaasApp.Services'])
	.component('serversidedashboard', react2angular(ServerSideDashboardContainer, [],
		['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'AuthServices']));