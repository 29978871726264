import { EmailTemplateSaaSFormatParams } from '../../../Services/EmailTemplateServicesTypes';
import { Style } from '../../EmailTemplate/Editor/context/StyleTypes';

export enum DeviceType {
	Desktop = 'desktop',
	Mobile = 'mobile',
}

export enum EditorType {
	Data = 'Data',
	Group = 'Group',
	Collection = 'Collection',
	Image = 'Image',
	ButtonGroup = 'ButtonGroup',
	Range = 'Range',
	Text = 'Text',
	RichText = 'RichText',
	ColorPicker = 'ColorPicker',
	Boolean = 'Boolean',
	CountDown = 'CountDown',
	ProductReferences = 'ProductReferences',
	ProductRecommendations = 'ProductRecommendations',
	DatePicker = 'DatePicker',
	Font = 'Font'
}

type ProductReferencesEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
type ProductRecommendationsEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
type DatePickerEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
type FontEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
export type CollectionEditor = {
	Type: EditorType;
	IsOpen?: boolean;
	CollectionDefaultSize?: number;
	ActiveItemPropertyName?: string;
}
type GroupEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
type ImageEditor = {
	Type: EditorType;
	IsOpen?: boolean;
	CropRatio?: { Height: number; Width: number; Locked: boolean };
}
type ButtonGroupEditor = {
	Type: EditorType;
	IsOpen?: boolean;
	AvailableValues: { Label: string, Value: string }[];
}
type RangeEditor = {
	Type: EditorType;
	IsOpen?: boolean;
	Min: number;
	Max: number;
	Unit: string;
}
type RichTextEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
type TextEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
type BooleanEditor = {
	Type: EditorType;
	IsOpen?: boolean;
}
type ColorPickerEditor = {
	Type: EditorType;
	IsOpen?: boolean;
	Width?: string;
	Float?: boolean;
}
export type CountDownEditor = {
	Type: EditorType;
	IsOpen?: boolean;
	Timezone?: string;
}

export interface TemplatePropertyNameValue {
	Name: string;
	Value?: any;
}

export type TemplateProperty = {
	Key?: string | null;
	Name: string;
	Editor: CollectionEditor
	| GroupEditor
	| ImageEditor
	| ButtonGroupEditor
	| RangeEditor
	| RichTextEditor
	| TextEditor
	| BooleanEditor
	| ColorPickerEditor
	| FontEditor
	| DatePickerEditor
	| ProductReferencesEditor
	| ProductRecommendationsEditor
	| CountDownEditor
	;
	FriendlyName?: string;
	Tooltip?: string;
	Description?: string;
	SubDescription?: string;
	Value?: any;
	Device?: DeviceType;
	IsActiveProperty?: { Name: string, Value: boolean };
	IsCollection?: boolean;
	IsGroup?: boolean;
	MultiSteps?: { Name: string, Value: boolean };
	Content?: TemplateProperty[];
}

export type TemplateEditableContent = {
	ContentTemplateType: string;
	Content: string
}

export type TemplateDescriptor = {
	Css: string;
	EditableCss?: string;
	EditableTemplate?: TemplateEditableContent;
}

export type Wysiwyg = {
	Template: string
}

export type Template = {
	Key: string;
	Name: string;
	Description: string;
	ThumbnailUrl: string;
	TemplateName: string;
	TemplateDescriptorDesktop: TemplateDescriptor;
	TemplateDescriptorMobile: TemplateDescriptor;
	Properties: TemplateProperty[];
	WysiwygDesktop: Wysiwyg;
	WysiwygMobile: Wysiwyg;
	DefaultStyle?: Style;
}


export type EmailTemplateSaaSFormat = {
	template: Template,
	params: EmailTemplateSaaSFormatParams
}