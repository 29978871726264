import React, { useState, useEffect } from 'react'
import { BrowserRouter, NavLink } from 'react-router-dom';
import { react2angular } from 'react2angular';
import ProductTagList from './ProductTagList'
import ProductTagServices from '../../../Services/ProductTagServices';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import ProductTagAddTag from './ProductTagAddTag';
import ProductTagModifyValue from './ProductTagModifyValue';

export default function ProductTagContainer({ $rootScope, $http, accountId }) {

	// const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	// const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	// const accountId = impersonatedAccount || $rootScope.User.Account.Key;

	const [toggleFilter, setToggleFilter] = useState(1)
	const [nameOfTagList, setNameOfTagList] = useState()
	// with props
	const productTagServices = new ProductTagServices($http);
	const systemServices = new SystemServices($rootScope);

	// Fetch Api Need Services
	// const productTagServices = new ProductTagServices(props.$http, props.$routeParams);
	// const systemServices = new SystemServices(props.$rootScope);
	// const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	// const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	// const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	// const campaignId = props.$routeParams && props.$routeParams.key;

	const getProductTag = (accountId, callbackSuccess) => productTagServices.getProductTag(accountId, callbackSuccess, error => systemServices.showError());
	const putProductReferencesByTag = (accountId, tag, productRef) => productTagServices.putProductReferencesByTag(accountId, tag, productRef, success => systemServices.showSuccess("the tag was successfully created"), error => systemServices.showError("an error occurred during the creation of the tag"))
	const getProductReferencesByTag = (accountId, tag, callbackSuccess, callbackError) => productTagServices.getProductReferencesByTag(accountId, tag, callbackSuccess, callbackError)
	const deleteTag = (accountId, tag) => productTagServices.deleteTag(accountId, tag, success => systemServices.showSuccess("the tag has been successfully edited"), error => systemServices.showError())

	const toggleSeeList = () => {
		setToggleFilter(1)
	}
	const toggleAddItem = () => {
		setToggleFilter(2)
	}
	const toggleModifyModal = () => {
		setToggleFilter(3)
	}
	const handleClick = (e) => {
		setToggleFilter(3)
		setNameOfTagList(e.tagName)

	}

	return (
		<div>
			{/* <p className="contentDescriptionTag">Add tags to your products in order to create pools that you can use in your campaigns or in product recommendations.</p> */}

			<>
				{toggleFilter === 1 &&
					< ProductTagList
						systemServices={systemServices && systemServices}
						accountId={accountId}
						getProductTag={getProductTag}
						toggleAddItem={toggleAddItem}
						handleClick={handleClick}

					/>}
				<div className="center">
					{toggleFilter === 2 &&
						< ProductTagAddTag
							toggleAddTagModal={toggleSeeList}
							accountId={accountId}
							putProductReferencesByTag={putProductReferencesByTag}
						/>}
					{toggleFilter === 3 &&
						<ProductTagModifyValue
							nameOfTagList={nameOfTagList}
							getProductReferencesByTag={getProductReferencesByTag}
							putProductReferencesByTag={putProductReferencesByTag}
							deleteTag={deleteTag}
							accountId={accountId}
							toggleList={toggleSeeList}
						/>
					}
				</div>
			</>


		</div>
	)
}

// angular
//     .module('beyableSaasApp.ProductSettings', [])
//     .component('producttag', react2angular(ProductTagContainer, [], ['$http', '$rootScope', '$routeParams']));