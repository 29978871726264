import React, { useState } from 'react';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import IconBtn from '../../../Components/IconBtn';

import LabelsAccountContainer from './LabelsAccounts/LabelsAccountsContainer';
import LabelAcountItem from './LabelsAccounts/LabeAcountItem';
import formatMoment from '../../../Constants/FormatsMoments';
import styles from './Accounts.module.css';

export default function AccountItem({
	account,
	role,
	isFav,
	addAcountToFavorites,
	labelServices,
	accountIdForLabels,
	systemServices
}) {

	const { formatDateToLocal } = formatMoment;
	const [clipBoardContain, setClipBoardContain] = useState();
	const [labelModalIsIopen, setLabelModalIsIopen] = useState(false);
	const [ddIsOpen, setddIsOpen] = useState(false);
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
		setTimeout(() => {
			setClipBoardContain();
		}, 2000);
	};

	const accountUrl = `/Account/Switch/${account.id}/${account.mainUserId}`;
	const settingsUrl = `/Admin/EditAccount/${account.id}?ka=${account.id}&ku=${account.mainUserId}`;
	const queryUrl = `/Admin/Query/${account.id}?ka=${account.id}&ku=${account.mainUserId}`;
	const historyUrl = `/Admin/History?ka=${account.id}&ku=${account.mainUserId}`;

	return (
		<div className={'table_row table_body_row ' + styles.row}>
			<div className='table_col'>
				<div className='flex'>
					<div className='flex_item_fix mr_10'>
						<IconBtn
							hoverColor="yellow"
							isActive={isFav}
							icon={isFav ? 'fas fa-star' : 'far fa-star'}
							tooltip={isFav ? 'Remove from favorites' : 'Add to favorites'}
							onClick={() => addAcountToFavorites(account, !isFav)}
						/>
					</div>
					<div className='flex_item_auto c_black'>
						<div>
							<a href={accountUrl} className='fw_medium'>
								{account.name}
							</a>
						</div>
						<div
							className={clipBoardContain && clipBoardContain === account.id ? 'pointer c_green s_11 mt_3' : 'pointer grey_3 s_11 mt_3'}
							onClick={() => copyToClipBoard(account.id)}
						>
							<span>{account.id}</span>
							<i className="far fa-clipboard"></i>
						</div>
					</div>
				</div>
			</div>
			<div className='table_col'>
				{account.email && account.email !== account.mainUserEmail &&
					<div className='table_col_inner'>
						<a href={accountUrl} className='ellips'>{account.email === null ? '' : account.email}</a>
					</div>
				}
				<div className='table_col_inner'>
					<a href={accountUrl} className='ellips'>{account.mainUserEmail}</a>
				</div>
			</div>
			<div className='table_col'>
				{formatDateToLocal(account.creationDateUtc).format('DD/MM/YYYY')}
			</div>
			<div className='table_col'>
				{account.labels && account.labels.length > 0 && (
					<div
						className="campaign_label_list"
						onClick={() => setLabelModalIsIopen(true)}
					>
						{
							account.labels.map((label, key) => (
								<LabelAcountItem
									key={key}
									label={label}
								/>
							))
						}
					</div>
				)}
			</div>
			{(role === 'Admin' || role === 'Supervisor') &&
				<div className='table_col flex_justify_end'>
					<Dropdown
						isOpen={ddIsOpen}
						toggle={(s) => setddIsOpen(s)}
						button={
							<IconBtn
								icon="fas fa-ellipsis-v"
								tootltip="More actions"
							/>
						}
					>
						<Listbox>
							{role === 'Admin' &&
								<>
									<ListboxItem 
										message='Account settings'
										href={settingsUrl}
										onClick={() => setddIsOpen(false)}
									/>
									<ListboxItem 
										message='SQL Queries'
										href={queryUrl}
										onClick={() => setddIsOpen(false)}
									/>
									<ListboxItem 
										message='Account history'
										href={historyUrl}
										onClick={() => setddIsOpen(false)}
									/>
								</>
							}
							<ListboxItem 
								message='Edit labels'
								onClick={() => {setLabelModalIsIopen(true); setddIsOpen(false);}}
							/>
						</Listbox>
					</Dropdown>
				</div>
			}

			{labelModalIsIopen &&
				<LabelsAccountContainer
					labelService={labelServices}
					accountId={accountIdForLabels}
					systemServices={systemServices}
					modalLabelsIsOpen={labelModalIsIopen}
					setModalLabelsIsOpen={(e) => setLabelModalIsIopen(e)}
					objectType={'account'}
					accountSelected={account}
				/>
			}
		</div>
	);
}
