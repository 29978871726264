import React from 'react';
import Header from '../../UI/Header';
import PannelOptionsList from '../../UI/PannelOptionsList';
import ProductList from '../../UI/ProductList/ProductList';
import { useRecommendationContext, } from '../context/RecommendationsContextProvider';
import Confirm from '../../../../Components/Confirm';
import EmptyState from '../../../../Components/EmptyState';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import MessageErrorNoConfiguration from '../../UI/MessageErrorNoConfiguration';

export default function RecommendationsSettingsContainer() {
	const {
		dataIsEmpty,
		tenantsCatalog,
		mode,
		handleCloseConfirm,
		modalConfirmIsOpen,
		cancelActions,
		returnToDashboard,
		checkIfCanQuitWithoutSave,
		loadingProductList,
		loadingSaving,
		loadingSettings,
		recommendationProduct,
		handleChangeRecommendationName,
		handleChangeContext,
		sortingFactors,
		handeChangeFilterSorting,
		changeCategorySelected,
		categorySelected,
		typeCategories,
		typeCategoriesSecondLevel,
		typeCategoriesSecondLevelSelected,
		changeSecondCategorySelected,
		updapteAttribute,
		removeAttribute,
		onChangeAttributeOfSign,
		productList,
		recoName,
		ListFilter,
		CreateReco,
		PutRecoById,
		onAddFilter,
		onEditFilter,
		onRemoveFilter,
		handleChangeOperatorOfFilters,
		listTenantAccount,
		idInClipBoard,
		copyToClipBoard,
		paginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		handleChangePaginationOptions,
		setNewPaginationNeed,
		deviceType,
		changeDeviceSelected,
		handleChangeTenantPreview,
		previewTenant,
		handleChangeTenant,
		handleChangenemOfProductbasedOn,
		inputProductBasedOn,
		listIdProducts,
		handleSelectidOfProduct,
		valueOfSelectedproduct,
		autocompleteListIsLoading,
		categoryBasedOn,
		listTags,
		valueOfSelectedTag,
		autocompleteTagListIsLoading,
		handleChangenemOfTagbasedOn,
		inputTagBasedOn,
		handleSelectidOfTag,
		setupAccountServices,
		accountId,
		handleChangeRecoDescription,
		descriptionRule,
		errorNoConfiguration,
		handleChangeSortingFactors,
		arrayOfValuetags,
		
	} = useRecommendationContext();

	if (loadingSettings) {
		return <SpinnerWheel wheelSize='l' />;
	}

	return (<>
		<div className="collection_container page_full flex flex_v">
			{loadingSaving && !errorNoConfiguration
				&& <div className="mask">
					<span className="wheel"></span>
				</div>
			}

			<Confirm
				isOpen={modalConfirmIsOpen}
				onClose={(e) => handleCloseConfirm()}
				title="Close rule editor"
				text="Are you sure to close without saving?"
				cancelText="Stay"
				confirmText="Quit without saving"
				confirmCallback={(e) => returnToDashboard()}
			/>

			<Header
				title="Recommendation rule"
				subTitle={recoName}
				returnUrl='/ProductSettings/Reco'
				valid={mode === 'edition' ? PutRecoById : CreateReco}
				checkIfCanQuitWithoutSave={checkIfCanQuitWithoutSave}
				cancelActions={cancelActions}
			/>
			<div className="collection_body flex_item_full flex flex_align_stretch">
				{!errorNoConfiguration && 
				<><PannelOptionsList
					previewTenant={previewTenant}
					loadingProductList={loadingProductList}
					handleChangeSortingFactors={handleChangeSortingFactors}
					handleChangeRankingDescription={handleChangeRecoDescription}
					descriptionRule={descriptionRule}
					contextBasedOn={recommendationProduct.context}
					setupAccountServices={setupAccountServices}
					accountId={accountId}
					categoryBasedOn={categoryBasedOn}
					listTenantAccount={listTenantAccount}
					nameInput={recoName}
					typeDimensions='recommendations'
					data={recommendationProduct}
					setRuleName={handleChangeRecommendationName}
					handleChangeContext={handleChangeContext}
					sortingFactors={sortingFactors}
					handeChangeFilterSorting={handeChangeFilterSorting}
					ListFilter={ListFilter}
					updapteAttribute={updapteAttribute}
					removeAttribute={removeAttribute}
					onChangeAttributeOfSign={onChangeAttributeOfSign}
					onAddFilter={onAddFilter}
					onEditFilter={onEditFilter}
					onRemoveFilter={onRemoveFilter}
					handleChangeOperatorOfFilters={handleChangeOperatorOfFilters}
					handleChangeTenant={handleChangeTenant}
					tenantsCatalog={tenantsCatalog}
					listTags={listTags}
					valueOfSelectedTag={valueOfSelectedTag}
					autocompleteTagListIsLoading={autocompleteTagListIsLoading}
					handleChangenemOfTagbasedOn={handleChangenemOfTagbasedOn}
					handleSelectidOfTag={handleSelectidOfTag}
					inputTagBasedOn={inputTagBasedOn}
					changeCategorySelected={changeCategorySelected}
					categorySelected={categorySelected}
					typeCategories={typeCategories}
					arrayOfValuetags={arrayOfValuetags}
				/>
				<ProductList
					listTags={listTags}
					valueOfSelectedTag={valueOfSelectedTag}
					autocompleteTagListIsLoading={autocompleteTagListIsLoading}
					handleChangenemOfTagbasedOn={handleChangenemOfTagbasedOn}
					inputTagBasedOn={inputTagBasedOn}
					handleSelectidOfTag={handleSelectidOfTag}
					handleSelectidOfProduct={handleSelectidOfProduct}
					autocompleteListIsLoading={autocompleteListIsLoading}
					valueOfSelectedproduct={valueOfSelectedproduct}
					listIdProducts={listIdProducts}
					contextBasedOn={recommendationProduct.context}
					handleChangenemOfProductbasedOn={handleChangenemOfProductbasedOn}
					inputProductBasedOn={inputProductBasedOn}
					isLoading={loadingProductList}
					products={productList}
					changeCategorySelected={changeCategorySelected}
					categorySelected={categorySelected}
					typeCategories={typeCategories}
					typeCategoriesSecondLevel={typeCategoriesSecondLevel}
					typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
					changeSecondCategorySelected={changeSecondCategorySelected}
					idInClipBoard={idInClipBoard}
					copyToClipBoard={copyToClipBoard}
					paginationOptions={paginationOptions}
					currentPageProductList={currentPageProductList}
					setCurrentPageProductList={setCurrentPageProductList}
					handleChangePaginationOptions={handleChangePaginationOptions}
					setNewPaginationNeed={setNewPaginationNeed}
					typeDimensions='recommendations'
					deviceType={deviceType}
					changeDeviceSelected={changeDeviceSelected}
					handleChangeTenantPreview={handleChangeTenantPreview}
					previewTenant={previewTenant}
					listTenantAccount={listTenantAccount}
					needToHidePreviewTenants={(recommendationProduct.tenant !== null && tenantsCatalog.length > 1) ||
								listTenantAccount.length === 1}
					tenantsCatalog={tenantsCatalog}
					AccountWithoutCategories={dataIsEmpty} /></>
				
				}
				{errorNoConfiguration && 
				<MessageErrorNoConfiguration/>

				}


			</div>

		</div>
	</>
	);
}
