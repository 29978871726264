import React, { useState } from 'react';
import SearchBar from '../../../../Components/SearchBar';
import useCardIcon from '../../hooks/useCardIcon';
import styles from './CardModal.module.css';
import { nFormat } from '../../util';

const CardModal = ({
	title = 'Devices',
	dataCard = {
		data: [],
		loading: true,
		isError: false,
		totalCount: 0
	},
	handleClick,
	handleClose,
	handleViewMore,
	handleSearch,
	dimensionKey,
	ComparisonSelected,
	metricTypeSessionsSelected,
	totalsPeriod1,
	totalsPeriode2,
	chips
}) => {

	var typingTimer;
	const [state, setState] = useState('');
	const [search, setSearch] = useState('');

	const data = dataCard.data || [];
	const isLoading = dataCard.loading;
	const isError = dataCard.isError;

	const onClick = (type) => () => {
		handleClick(dimensionKey, type);
		setState(type);
	};

	const onViewMore = () => {
		handleViewMore(dimensionKey);
	};

	const onSearch = (value) => {
		setSearch(value);
		clearTimeout(typingTimer);
		typingTimer = setTimeout(function () {
			handleSearch(dimensionKey, value);
		}, 1000);
	};
	function getPercentAndNameToRender(isVariation, isCompare) {
		if (!isCompare) {
			if (!isVariation) {
				return getNameOfMetric();
			}
		} else {
			if (!isVariation) {
				return getNameOfMetric();
			} else if (isVariation) {
				return getNameOfMetricVariation();
			}
		}
	}
	function getNameOfMetric() {
		switch (metricTypeSessionsSelected) {
			case 'sessionsAndPagesViews':
			case 'NumberOfSessions':
				return 'NumberOfSessions';
			case 'NumberOfPageViews':
				return 'NumberOfPageViews';
			case 'AverageTimeOnPage':
				return 'DurationSeconds';

			case 'BounceRate':
				return 'NumberOfBounces';
			default:
				break;
		}
	}
	function getNameOfMetricVariation() {
		switch (metricTypeSessionsSelected) {
			case 'sessionsAndPagesViews':
			case 'NumberOfSessions':
				return 'NumberOfSessionsPercentVariation';
			case 'NumberOfPageViews':
				return 'NumberOfPageViewsPercentVariation';
			case 'AverageTimeOnPage':
				return 'DurationSecondsPercentVariation';
			case 'BounceRate':
				return 'NumberOfBouncesPercentVariation';
			default:
				break;
		}
	}
	function getValueOfMetricInTotal() {
		switch (metricTypeSessionsSelected) {
			case 'sessionsAndPagesViews':
			case 'NumberOfSessions':
				return 'numberOfSessions';
			case 'NumberOfPageViews':
				return 'numberOfPageViews';
			case 'AverageTimeOnPage':
				return 'durationSeconds';
			case 'BounceRate':
				return 'numberOfBounces';
			default:
				break;
		}
	}
	return (
		<div className={styles.container}>
			<div className={styles.titleContainer}>
				<span className={`${styles.title} flex flex_align_center`}>
					<span className={styles.prev} onClick={handleClose}>
						<span className={styles.text}>All dimensions</span>
						<i className="fas fa-chevron-right" style={{ fontSize: '14px' }}></i>
					</span>
					{title}
				</span>
				<span >
					<SearchBar
						onChange={(value) => onSearch(value)}
						valueInSearchBar={search}
						placeholder="Search"
						name='Search'
					/>
				</span>
			</div>
			<div className={styles.cardContainer}>
				{isLoading && (
					<div className="mask">
						<span className="wheel"></span>
					</div>
				)}
				{!isLoading && !isError && data.length === 0 && (
					<div
						className={styles.screenContainer}
					>
						<p className={styles.screen}>No data</p>
						<p className={styles.view}>-</p>
					</div>
				)}
				{!isLoading && isError && (
					<div
						className={styles.screenContainer}
					>
						<p className={styles.screen}>Cannot fetch data</p>
						<p className={styles.view}></p>
					</div>
				)}

				{!ComparisonSelected && data.map((value, index) => {

					const { Dimensions: {
						HostName,
						Timezone,
						Language,
						LandingPage,
						LandingPageType,
						ExitPage,
						ExitPageType,
						UtmMedium,
						UtmSource,
						UtmCampaign,
						UtmContent,
						Referrer,
						ReferrerSource,
						ReferrerMedium,
						TrafficSource,
						CountryCode,
						OperatingSystem,
						OperatingSystemVersion,
						Browser,
						BrowserVersion,
						ScreenSize,
						Device
					}, NumberOfSessions,
					NumberOfPageViews,
					NumberOfBounces,
					DurationSeconds
					} = value;

					const name =
						HostName ||
						Timezone ||
						Language ||
						LandingPage ||
						LandingPageType ||
						ExitPage ||
						ExitPageType ||
						UtmMedium ||
						UtmSource ||
						UtmCampaign ||
						UtmContent ||
						Referrer ||
						ReferrerSource ||
						ReferrerMedium ||
						TrafficSource ||
						CountryCode ||
						OperatingSystem ||
						OperatingSystemVersion ||
						Browser ||
						BrowserVersion ||
						ScreenSize ||
						Device;
					const metricType = getValueOfMetricInTotal();

					function caltulateRate(total) {
						return valuesOfMetrics[getPercentAndNameToRender(false,
							true)] * 100 / total[metricType] || 0;
					}

					// const isSelected = state === name
					const isSelected = chips.length !== 0 ? chips[0].title === name : false;
					const icon = useCardIcon(dimensionKey, name);
					const valuesOfMetrics = {
						NumberOfSessions: NumberOfSessions,
						NumberOfPageViews: NumberOfPageViews,
						NumberOfBounces: NumberOfBounces,
						DurationSeconds: DurationSeconds
					};
					const nameOfMetricToRender = valuesOfMetrics[getPercentAndNameToRender(false, false)];
					const percentage = caltulateRate(totalsPeriod1);

					return (
						<div
							key={index}
							className={[styles.screenContainer, isSelected ? styles.isSelected : ''].join(' ')}
							onClick={onClick(name)}
						>
							<div className={styles.progress} style={{ width: percentage.toFixed(1) + '%' }}></div>
							<p className={styles.colScreen}>
								<span className={styles.number}>{(index + 1) + '. '}</span>
								{icon &&
									<span className={styles.icon}>
										<i className={icon}></i>
									</span>
								}
								{name}
							</p>
							<p className={styles.colPercent}>
								{percentage.toFixed(1) + '%'}
							</p>
							<p className={styles.colView}>
								{nameOfMetricToRender}
							</p>
						</div>
					);
				})}
				{ComparisonSelected && data.map((value, index) => {
					const { Dimensions: {
						HostName,
						Timezone,
						Language,
						LandingPage,
						LandingPageType,
						ExitPage,
						ExitPageType,
						UtmMedium,
						UtmSource,
						UtmCampaign,
						UtmContent,
						Referrer,
						ReferrerSource,
						ReferrerMedium,
						TrafficSource,
						CountryCode,
						OperatingSystem,
						OperatingSystemVersion,
						Browser,
						BrowserVersion,
						ScreenSize,
						Device
					}, NumberOfSessions,
					NumberOfSessionsPeriod1,
					NumberOfSessionsPeriod2,
					NumberOfPageViewsPeriod1,
					NumberOfPageViewsPeriod2,
					DurationSecondsPeriod1,
					DurationSecondsPeriod2,
					NumberOfBouncesPeriod1,
					NumberOfBouncesPeriod2,
					DurationSecondsPercentVariation,
					NumberOfPageViewsPercentVariation,
					NumberOfBouncesPercentVariation,
					NumberOfSessionsPercentVariation
					} = value;

					const name =
						HostName ||
						Timezone ||
						Language ||
						LandingPage ||
						LandingPageType ||
						ExitPage ||
						ExitPageType ||
						UtmMedium ||
						UtmSource ||
						UtmCampaign ||
						UtmContent ||
						Referrer ||
						ReferrerSource ||
						ReferrerMedium ||
						TrafficSource ||
						CountryCode ||
						OperatingSystem ||
						OperatingSystemVersion ||
						Browser ||
						BrowserVersion ||
						ScreenSize ||
						Device;
					const isBounce = metricTypeSessionsSelected === 'BounceRate';
					// const isSelected = state === name
					const isSelected = chips.length !== 0 ? chips[0].title === name : false;
					const icon = useCardIcon(dimensionKey, name);
					const valuesOfMetrics = {
						NumberOfSessionsPeriod1: NumberOfSessionsPeriod1,
						NumberOfSessionsPeriod2: NumberOfSessionsPeriod2,
						NumberOfPageViewsPeriod1: NumberOfPageViewsPeriod1,
						NumberOfPageViewsPeriod2: NumberOfPageViewsPeriod2,
						DurationSecondsPeriod1: DurationSecondsPeriod1,
						DurationSecondsPeriod2: DurationSecondsPeriod2,
						NumberOfBouncesPeriod1: NumberOfBouncesPeriod1,
						NumberOfBouncesPeriod2: NumberOfBouncesPeriod2,
						DurationSecondsPercentVariation: DurationSecondsPercentVariation,
						NumberOfPageViewsPercentVariation: NumberOfPageViewsPercentVariation,
						NumberOfBouncesPercentVariation: NumberOfBouncesPercentVariation,
						NumberOfSessionsPercentVariation: NumberOfSessionsPercentVariation
					};
					const metricType = getValueOfMetricInTotal();

					function calculateRate(period, total) {
						return valuesOfMetrics[getPercentAndNameToRender(false,
							true) + period] * 100 / total[metricType] || 0;
					}

					const percentagePeriod1 = calculateRate('Period1', totalsPeriod1);

					const percentagePeriod2 = calculateRate('Period2', totalsPeriode2);

					const percentVariation = isBounce 
						? (percentagePeriod1 - percentagePeriod2 / percentagePeriod2) || 0
						: valuesOfMetrics[getPercentAndNameToRender(true, true)] || 0;

					const valueMetricPeriode1 = valuesOfMetrics[getPercentAndNameToRender(false, true) + 'Period1'] || 0;

					const valueMetricPeriode2 = valuesOfMetrics[getPercentAndNameToRender(false, true) + 'Period2'] || 0;

					const tendance = percentVariation;
					let tendanceClass = 'tendance_flat';
					if (tendance > 0) {
						tendanceClass = 'tendance_up';
					} else if (tendance < 0) {
						tendanceClass = 'tendance_down';
					}
					return (
						<div
							key={index}
							className={[styles.screenContainerDouble, isSelected ? styles.isSelected : ''].join(' ')}
							onClick={onClick(name)}
						>
							<div className={styles.progressTop} style={{ width: percentagePeriod1.toFixed(1) + '%' }}></div>
							<div className={styles.progressBottom} style={{ width: percentagePeriod2.toFixed(1) + '%' }}></div>
							<div className={styles.colScreen}>
								<span className={styles.number}>{(index + 1) + '. '}</span>
								{icon &&
									<span className={styles.icon}>
										<i className={icon}></i>
									</span>
								}
								{name}
							</div>
							<div className={styles.colPercent}>
								<div className={styles.colInnerTop}>{percentagePeriod1.toFixed(1) + '%'}</div>
								<div className={styles.colInnerBottom}>{percentagePeriod2.toFixed(1) + '%'}</div>
							</div>
							<div className={styles.colView}>
								<div className={styles.colInnerTop}>{valueMetricPeriode1}</div>
								<div className={styles.colInnerBottom}>{valueMetricPeriode2}</div>
							</div>
							<div className={styles.colDiff}>
								<span className={tendanceClass}>
									{percentVariation.toFixed(1) + '%'}
								</span>
							</div>
						</div>
					);
				})}
			</div>
			{!isLoading && !isError && data.length < dataCard.totalCount && (
				<div onClick={onViewMore} className={styles.viewMoreButton}>
					View more... (loaded {data.length} / {dataCard.totalCount})
				</div>
			)}
		</div>
	);
};

CardModal.displayName = 'CardModal';

export default CardModal;