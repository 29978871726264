import React, { useState } from 'react';
import { serverSideCampaignsDashboardContext } from './context';
import Modal from '../../Components/Modal';
import ModalHeader from '../../Components/ModalHeader';
import ModalBody from '../../Components/ModalBody';
import ModalFooter from '../../Components/ModalFooter';
import InputCustom from '../../Components/InputCustom';
import Btn from '../../Components/Btn';

export default function ServerSideCampaignCreationModal() {

	const {
		creationModalIsOpened,
		setCreationModalIsOpened,
		saveNewCampaign
	} = serverSideCampaignsDashboardContext();

	const [campaignToCreate, setCampaignToCreate] = useState({
		name: '',
		description: ''
	});

	const onChange = (property, value) => {
		setCampaignToCreate(c => ({...c, [property]: value}));
	};

	const reset = () => {
		setCampaignToCreate(c => ({...c, name: '', description: ''}));
	};

	return (
		<Modal
			isOpen={creationModalIsOpened}
			width={450}
			onClose={() => setCreationModalIsOpened(false)}
		>
			<ModalHeader title='New campaign' />
			<ModalBody>
				<InputCustom
					value={campaignToCreate.name}
					onChange={e => onChange('name', e.target.value)}
					fullWidth={true}
					label="Campaign name"
				/>
				<InputCustom
					type='textarea'
					value={campaignToCreate.description}
					onChange={e => onChange('description', e.target.value)}
					fullWidth={true}
					label="Description"
					placeHolder='Optional'
					rows={3}
				/>
			</ModalBody>
			<ModalFooter
				primaryAction={
					<Btn
						onClick={() => {saveNewCampaign(campaignToCreate); setCreationModalIsOpened(false);}}
						message="Save"
						disabled={!campaignToCreate.name}
					/>
				}
				secondaryAction={
					<Btn
						onClick={() => {reset(); setCreationModalIsOpened(false);}}
						message="Cancel"
						color="secondary"
						style="ghost"
					/>
				}
			/>
		</Modal>
	);
}