import React, { createContext, useContext, useEffect, useState } from 'react';
import { initialDates } from '../components/Nav/config';
import { metricsTypes, NumberOfPageViews, NumberOfSessions } from '../components/Sessions/config.js';

const SessionContext = createContext();

function useSessionContext() {
	return useContext(SessionContext);
}

const SessionContextProvider = (props) => {
	//Tab Session
	const [numberOfSessions, setNumberOfSessions] = useState(null);
	const [numberOfSessions2, setNumberOfSessions2] = useState(null);
	const [totalsPeriod1, setTotalsPeriode1] = useState({
		numberOfSessions: 0,
		numberOfPageViews: 0,
		numberOfBounces: 0,
		durationSeconds: 0
	});
	const [totalsPeriode2, setTotalsPeriode2] = useState({
		numberOfSessions: 0,
		numberOfPageViews: 0,
		numberOfBounces: 0,
		durationSeconds: 0
	});

	const [chips, setChips] = useState([]);
	const [selectedTimeGranularity, setSelectedTimeGranularity] =
		useState('Day');
	const [useTimeDisabled, setUseTimeDisabled] = useState(false);
	const FORMAT = 'YYYY/MM/DD';
	const currentDate = new Date;
	const fromDateFirstValue = moment(currentDate).subtract(7, 'day').format(FORMAT);
	const fromDateCompareValue = moment(fromDateFirstValue).subtract(7, 'day').format(FORMAT);

	const [navFilter, setNavFilter] = useState({
		fromDate: moment(fromDateFirstValue).format(FORMAT),
		toDate: moment(currentDate).format(FORMAT),
		fromDateToCompare: fromDateCompareValue,
		toDateToCompare: fromDateFirstValue,
		filters: []
	});

	const [customDateRange, setCustomDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [selectedPeriod, setSelectedPeriod] = useState(initialDates);
	const [isCustomCompareSelected, setisCustomCompareSelected] = useState(false);
	const [customCompareDateRange, setCustomCompareDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [typeChartSelected, setTypeChartSelected] = useState('spline');
	const [ComparisonSelected, setComparisonSelected] = useState(false);
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [chartData, setChartData] = useState({
		charts: [{ datasets: [], labels: [], title: '' }],
		isLoading: true,
		isError: false,
	});

	const [metricTypeSessionsSelected, setmetricTypeSessionsSelected] = useState({
		key: 'sessionsAndPagesViews',
		label: 'Sessions And Page views',
		value: [NumberOfPageViews, NumberOfSessions]
	});

	function changeTypeChartSelected(elemSelect) {
		typeChartSelected !== elemSelect && setTypeChartSelected(elemSelect);
	}
	function onCardClick(type, title) {
		let isInArray = false;
		chips.forEach((element) => {
			if (element.type === type && element.title === title) {
				isInArray = true;
			}
		});
		if (!isInArray) {
			setChips((currentChip) => [...currentChip, { type, title }]);
			let titleBis = '';
			if(title === 'empty'){
				titleBis = '';
			}else{
				titleBis = title;
			}
			setNavFilter((currentNavFilter) => ({
				...currentNavFilter,
				filters: addFilter(currentNavFilter.filters || [], type, titleBis),
			}));
		}
	}
	function addFilter(filters, dimension, value) {
		if (filters.find((c) => c.dimension === dimension)) {
			const addValueIfNotExists = (c) =>
				c.values.includes(value)
					? c
					: { ...c, values: c.values.concat(value) };
			return filters.map((c) =>
				c.dimension === dimension ? addValueIfNotExists(c) : c
			);
		}
		return filters.concat({ dimension, values: [value] });
	}

	function removeFilter(filters, dimension, value) {
		if (filters.find((c) => c.dimension === dimension)) {
			const valueFilter = value !== 'empty' ? value : '';
			const removeValueIfExists = (c) =>
				c.values.includes(valueFilter)
					? { ...c, values: c.values.filter((v) => v !== valueFilter) }
					: c;
			return filters
				.map((c) =>
					c.dimension === dimension ? removeValueIfExists(c) : c
				)
				.filter((c) => c.values.length > 0);
		}
		return filters;
	}

	const onDeleteChip = (chip) => {
		setChips(chips.filter((c) => c != chip));
		setNavFilter((currentNavFilter) => ({
			...currentNavFilter,
			filters: removeFilter(
				currentNavFilter.filters,
				chip.type,
				chip.title
			),
		}));
	};
	function handleSelectComparaisonPeriodHandler(value) {
		setComparisonSelected(value);
	}
	const onSelectMetricType = (val) => {
		if (val.key !== metricTypeSessionsSelected.key) {
			const newTypeFiltered = metricsTypes.find((t) => t.key === val.key);
			newTypeFiltered && setmetricTypeSessionsSelected(val);
		}
	};
	return (
		<SessionContext.Provider
			value={{
				chips,
				setChips,
				selectedTimeGranularity,
				setSelectedTimeGranularity,
				useTimeDisabled,
				setUseTimeDisabled,
				navFilter,
				setNavFilter,
				selectedPeriod,
				setSelectedPeriod,
				onCardClick,
				onDeleteChip,
				numberOfSessions,
				setNumberOfSessions,
				numberOfSessions2,
				setNumberOfSessions2,
				changeTypeChartSelected,
				typeChartSelected,
				ComparisonSelected,
				handleSelectComparaisonPeriodHandler,
				chartData,
				setChartData,
				onSelectMetricType,
				metricTypeSessionsSelected,
				setTotalsPeriode1,
				setTotalsPeriode2,
				totalsPeriod1,
				totalsPeriode2,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				isCustomCompareSelected,
				setisCustomCompareSelected,
				customCompareDateRange,
				setCustomCompareDateRange
			}}
		>
			{props.children}
		</SessionContext.Provider>
	);
};

export default SessionContextProvider;

export { useSessionContext };
