import React, { useState, useEffect } from 'react';
import PagesBoardContainer from '../Board/PageBoardContainer';
import PageFilterContainer from '../Pages/PageFilterContainer';
import PageTotalsContainer from '../Pages/PageTotalsContainer';
import PagesChartContainer from '../ChartContainer/PagesChartContainer';
export default function PageTabContainer({ 
	pageService, 
	trackEvent,
	sessionsServices,
	fetchCsvService,
	customDimensionConfigsForTenant
}) {
	const [selectedTimeGranularity, setSelectedTimeGranularity] =
        useState('Day');

	useEffect(() =>  {
		trackEvent('analytics/pages-viewed');
	}, []);

	return (
		<>
			<PageFilterContainer sessionsServices={sessionsServices} fetchCsvService={fetchCsvService} customDimensionConfigsForTenant={customDimensionConfigsForTenant} />
			<PageTotalsContainer pageService={pageService}/>
			<>
				<PagesChartContainer
					pageService={pageService}
					selectedTimeGranularity={selectedTimeGranularity}
					setSelectedTimeGranularity={setSelectedTimeGranularity}
				/>
				<PagesBoardContainer pageService={pageService} />
			</>
		</>
	);
}
