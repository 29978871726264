import React, { useState, useEffect, useRef, Fragment } from 'react';
import Panel from '../../../../Components/Panel';
import SortingCard from './SortingCard';
import Btn from '../../../../Components/Btn';
import Modal from '../../../../Components/Modal';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import ModalFooter from '../../../../Components/ModalFooter';
import SelectDropdown from '../../../../Components/SelectDropdown';
import SectionMessage from '../../../../Components/SectionMessage';
import InputCustom from '../../../../Components/InputCustom';
import { v4 as uuidv4 } from 'uuid';
import AutocompleteData from './AutocompleteData';
import { Flag } from '../../../../Components/Flag/Flag';

const dimensionTags = [
	{
		value: 'stringValues',
		label: 'Text',
		allowedForSort: false
	},
	{
		value: 'dateUtcValues',
		label: 'Date',
		allowedForSort: true
	},
	{
		value: 'numberValues',
		label: 'Numeric',
		allowedForSort: true
	},
	{
		value: 'boolean',
		label: 'Boolean',
		allowedForSort: false
	},


];
const operators = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Contains',
		label: 'Contains',
	},
	{
		value: 'NotContains',
		label: 'Does not contain',
	},
	{
		value: 'StartsWith',
		label: 'Starts with',
	},
	{
		value: 'NotStartsWith',
		label: 'Does not start with',
	},
	{
		value: 'EndsWith',
		label: 'Ends with',
	},
	{
		value: 'NotEndsWith',
		label: 'Does not ends with',
	},
	{
		value: 'Like',
		label: 'Is like',
	},
	{
		value: 'NotLike',
		label: 'Is not like',
	},
];
const operatorsDateUtc = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Lower',
		label: 'Is lower than',
	},
	{
		value: 'Greater',
		label: 'Is greater than',
	},
	{
		value: 'LowerOrEquals',
		label: 'Is lower or equal to',
	},
	{
		value: 'GreaterOrEquals',
		label: 'Is greater or equal to',
	},
];
const operatorsBoolean = [
	{
		value: true,
		label: 'True',
	},
	{
		value: false,
		label: 'False',
	},
];
const operatorNumbers = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Lower',
		label: 'Is lower than',
	},
	{
		value: 'Greater',
		label: 'Is greater than',
	},
	{
		value: 'LowerOrEquals',
		label: 'Is lower or equal to',
	},
	{
		value: 'GreaterOrEquals',
		label: 'Is greater or equal to',
	},

];

export default function SortingFactors({
	handleChangeSortingFactors,
	ListFilter,
	data,
	handeChangeFilterSorting,
	updapteAttribute,
	removeAttribute,
	onChangeAttributeOfSign,
	selectedTenant,
	accountId,
	setupAccountServices,
	arrayOfValuetags,
	loadingProductList
}) {

	const [modalOpened, setModalOpened] = useState(false);
	const [arrayOfSortingFactors, setarrayOfSortingFactors] = useState([]);
	const [first, setfirst] = useState(true);

	const [configureSortModal, setConfigureSortModal] = useState({ isOpened: false, editItem: null, selectedSort: null });

	const firstUpdate = useRef(true);

	const addFactorRequested = () => {
		setModalOpened(true);
	};

	const addFactor = (elem) => {
		handeChangeFilterSorting(elem);
		setModalOpened(false);
	};
	const addFactorWithParams = (elem) => {
		setModalOpened(false);
		setConfigureSortModal({ isOpened: true, editItem: null, selectedSort: elem });
	};

	useEffect(() => {
		const arrayOfSortingValues = () => {
			return (
				ListFilter &&
				ListFilter.forSorting.reduce(function (accumulator, sorting) {
					const sortObject = sorting.itemList.map(
						(el) => {
							!accumulator.find(
								(element) => element.id === el.id
							);
							return el;
						}
					);

					return [...accumulator, ...sortObject];
				}, [])
			);
		};
		setarrayOfSortingFactors(arrayOfSortingValues());
	}, [data, ListFilter]);
	useEffect(() => {
		setfirst(false);
	}, []);

	const categoryIcon = {
		'Attributes': 'data',
		'Scores': 'score',
		'Product comparison': 'comparison'
	};

	const getCategoryIcon = (name) => {
		if (!(name in categoryIcon)) {
			name = 'Attributes';
		}
		return categoryIcon[name];
	};
	function handleSortConfigured(value) {
		if (!value) return;
		const isAddedNewItem = configureSortModal.editItem === null;
		handleChangeSortingFactors(value, isAddedNewItem);
		setConfigureSortModal({ isOpened: false, editItem: null, selectedSort: null });
	}

	function onEditAttribute(factor, selectedSort) {
		setConfigureSortModal({ isOpened: true, editItem: factor, selectedSort: selectedSort });
	}

	return (
		<>
			<ConfigureSortingFactorModal
				configureSortModal={configureSortModal}
				onCancel={() => { setConfigureSortModal({ isOpened: false, editItem: null, selectedSort: null }); }}
				onValidate={(value) => { handleSortConfigured(value); }}
				selectedTenant={selectedTenant}
				accountId={accountId}
				setupAccountServices={setupAccountServices}
				arrayOfValuetags={arrayOfValuetags}
				data={data}
			/>
			<div className="collection_sidebar_block_title">Sorting rules</div>

			{data.sorting &&
				data.sorting.map((f) =>
					<SortingCard
						loadingProductList={loadingProductList}
						first={first}
						key={uuidv4()}
						factor={f}
						isSingleElement={data.sorting.length === 1}
						warning={
							arrayOfSortingFactors &&
								arrayOfSortingFactors.find(
									(el) => el.id === f.collationId
								)
								? false
								: true
						}
						name={
							(arrayOfSortingFactors &&
								arrayOfSortingFactors.find(
									(el) => el.id === f.collationId
								) &&
								arrayOfSortingFactors.find(
									(el) => el.id === f.collationId
								).name) ||
							'Name not found'
						}
						onChangeAttribute={updapteAttribute}
						onRemoveAttribute={removeAttribute}
						onChangeAttributeOfSign={onChangeAttributeOfSign}
						onEditAttribute={() => onEditAttribute(f, arrayOfSortingFactors.find(
							el => el.id === f.collationId
						))}
					/>
				)
			}

			<Btn
				onClickFunction={() => addFactorRequested()}
				icon="fas fa-plus-circle"
				message="Add sorting rule"
				color="primary"
				style="outline"
				fullWidth={true}
			/>

			<Panel
				isOpen={modalOpened}
				onClose={(e) => setModalOpened(false)}
				overlay={true}
				closeOnOverlay={true}
			>
				<section className="section flex_item_fix">
					<div className="panel_title">Add a sorting rule</div>
				</section>
				<div className="flex_item_full vscroll pos_rel">
					<section className="section no_top_pad">
						<div className="trigger_store">
							{ListFilter &&
								ListFilter['forSorting'].map((x, key) => {
									const icon = getCategoryIcon(x.name);
									return (
										<div className="trigger_store_category" key={key}>
											<div className="trigger_store_category_label">
												{x.name}
											</div>
											<ul className="trigger_store_list">
												{x.itemList.map((value, i) => {
													const isDisabled = data.sorting && data.sorting.find(
														(el) =>
															el.collationId ===
															value.id
													) && value?.type !== 'Tag';

													const hasParams = value.params?.length > 0;
													if (!hasParams) {
														return (
															<li
																className={isDisabled ? 'trigger_store_item flex disabled' : 'trigger_store_item flex'}
																key={i}
																onClick={() => {
																	if (isDisabled) return;
																	addFactor(value);
																}}
															>
																<div className="flex_item_fix">
																	<span className={'trigger_icon icon_' + icon}></span>
																</div>
																<div className="flex_item_full">
																	<div className="trigger_store_item_name flex flex_align_center">
																		<span>{value.name}</span>
																		{value.id === 'b70ce0a2-905c-4d1d-87c7-147bbe489139' &&
																			<Flag
																				icon="ai"
																				text="AI-powered"
																				tooltip="This feature is powered by BEYABLE AI"
																				className="ml_8"
																			/>
																		}
																	</div>
																</div>
															</li>
														);
													} else {
														return (
															<li
																className={'trigger_store_item flex'}
																key={i}
																onClick={() => {
																	addFactorWithParams(
																		value
																	);
																}}
															>
																<div className="flex_item_fix">
																	<span className={'trigger_icon icon_' + icon}></span>
																</div>
																<div className="flex_item_full">
																	<div className="trigger_store_item_name">
																		{value.name}
																	</div>
																</div>
															</li>
														);
													}
												})}
											</ul>
										</div>
									);
								})
							}
						</div>
					</section>
				</div>
			</Panel>
		</>
	);
}

function ConfigureSortingTagValue({
	selectedTenant,
	accountId,
	setupAccountServices,
	currentValue,
	isEditing,
	onChange,
	arrayOfValuetags,
}) {
	const [componentToReturn, setcomponentToReturn] = useState(<></>);
	const [currentValueString, setCurrentValueString] = useState(currentValue ?? '');
	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });

	const handleChangeValues = (textAreaValue) => {
		let error = { isError: false, message: '' };
		if (!textAreaValue) {
			error = { isError: true, message: 'input cannot be empty' };
		}
		else if (!isEditing && arrayOfValuetags.includes(textAreaValue)) {
			error = { isError: true, message: 'tag is already used' };
		}
		setIsAnError(error);
		onChange({ value: textAreaValue, isValid: !error.isError });
	};

	const onChangeAutocomplete = (text) => {
		let txtFormat = text;
		if (txtFormat.length) {
			txtFormat = txtFormat.replace(/(\n)+/g, ';');
			txtFormat = txtFormat.replace(/;+/g, ';');
		}
		setCurrentValueString(txtFormat);
		handleChangeValues(text);
	};


	function returnComponentValue() {
		setcomponentToReturn(<>
			<AutocompleteData
				selectedTenant={selectedTenant}
				setupAccountServices={setupAccountServices}
				currentAccount={accountId}
				referenceDataType={'productTagKey'}
				value={currentValueString}
				onChange={(value) => onChangeAutocomplete(value)}
			/>
			<span>{isAnError.message}</span>
		</>);
	}

	useEffect(() => {
		returnComponentValue();
	}, [
		currentValueString,
		currentValue
	]);

	return componentToReturn;
}

function ConfigureSortingTagType({ value, onChange }) {
	return (<SelectDropdown
		optionsList={dimensionTags.filter(x => x.allowedForSort)}
		value={value ?? 'numberValues'}
		onChange={(v) => onChange(v)}
		label="Format"
		blockClassName="inline mr_10"
	/>);
}


function ConfigureSortingFactorModal({
	configureSortModal,
	onCancel,
	onValidate,
	selectedTenant,
	accountId,
	setupAccountServices,
	arrayOfValuetags
}) {
	const [editingParams, setEditingParams] = useState([]);

	function handleValidate() {
		if (!configureSortModal.selectedSort) return;
		onValidate({
			collationId: configureSortModal.selectedSort.id,
			weight: 0.5,
			params: editingParams.map(p => ({ name: p.name, value: p.finalValue }))
		});
	}


	function initialzeEditingParameter(param) {
		const inStringValues = c => ({ stringValues: c });
		const isEditing = !!configureSortModal.editItem;

		const getValueFromEditItem = () => {
			if (!isEditing || !configureSortModal.editItem.params) return null;
			const strValue = configureSortModal.editItem.params.find(x => x.name === param.name)?.value?.stringValues;
			return strValue && strValue.length > 0 ? strValue[0] : null;
		};

		if (param.name === 'Tag type') {

			const finalValue = (value) => value === 'stringValues' ? 'String' : (value === 'dateUtcValues' ? 'Date' : (value === 'boolean' ? 'Boolean' : value === 'numberValues' ? 'Numeric' : 'Numeric'));
			const fromEditValue = (value) => value === 'String' ? 'stringValues' : (value === 'Date' ? 'dateUtcValues' : (value === 'Boolean' ? 'boolean' : value === 'Numeric' ? 'numberValues' : 'numberValues'));
			const val = fromEditValue(getValueFromEditItem() ?? 'Numeric');
			return ({
				name: param.name, valueType: param.valueType, paramValue: val, finalValue: inStringValues([finalValue(val)]), isValid: true,
				onUpdateValue: (x, v, isValid) => {
					return ({ ...x, isValid, paramValue: v, finalValue: inStringValues([finalValue(v)]) });
				}
			});

		} else if (param.name === 'Tag name') {

			const splitBySeparator = (str) => {
				return str
					.split(/[;,""\n]/)
					.filter((x) => x.length > 0);
			};

			const finalValue = (value) => (typeof value === 'string') ? splitBySeparator(value) : [value];
			const val = getValueFromEditItem() ?? '';
			return ({
				name: param.name, valueType: param.valueType, paramValue: val, finalValue: inStringValues([val]), isValid: isEditing,
				onUpdateValue: (x, v, isValid) => ({ ...x, isValid, paramValue: v, finalValue: inStringValues(finalValue(v)) })
			});

		} else {

			const finalValue = (value) => [value];
			const val = getValueFromEditItem() ?? '';
			return ({
				name: param.name, valueType: param.valueType, paramValue: val, finalValue: inStringValues([val]), isValid: isEditing,
				onUpdateValue: (x, v, isValid) => ({ ...x, isValid, paramValue: v, finalValue: inStringValues(finalValue(v)) })
			});
		}
	}

	useEffect(() => {
		if (configureSortModal.isOpened) {
			setEditingParams(configureSortModal.selectedSort?.params?.map(initialzeEditingParameter) ?? []);
		} else {
			setEditingParams([]);
		}
	}, [configureSortModal]);

	return (<Modal
		isOpen={configureSortModal.isOpened}
		width="480"
		onClose={() => onCancel()}
	>
		<ModalHeader>
			Configure sorting {configureSortModal.selectedSort?.name}
		</ModalHeader>
		<ModalBody>
			{editingParams && editingParams.map(p => {
				if (p.name === 'Tag name') {
					return <ConfigureSortingTagValue
						key={p.name}
						accountId={accountId}
						isEditing={!!configureSortModal.editItem}
						selectedTenant={selectedTenant}
						setupAccountServices={setupAccountServices}
						currentValue={p.paramValue}
						arrayOfValuetags={arrayOfValuetags}
						onChange={({ value, isValid }) => {
							setEditingParams(params => params.map(x => x.name === p.name ? x.onUpdateValue(x, value, isValid) : x));
						}}
					/>;
				} else if (p.name === 'Tag type') {
					return <ConfigureSortingTagType
						key={p.name}
						value={p.paramValue}
						onChange={value => {
							setEditingParams(params => params.map(x => x.name === p.name ? x.onUpdateValue(x, value, true) : x));
						}}
					/>;
				} else if (p.valueType === 'Url') {
					return <Fragment key={p.name}>
						<InputCustom
							onChange={e => {
								e.preventDefault();
								const value = e.target?.value;
								setEditingParams(params => params.map(x => x.name === p.name ? x.onUpdateValue(x, value, !!value) : x));
							}}
							value={p.paramValue != null ? p.paramValue : ''}
							//isAnError={!p.paramValue}
							descriptionBottom={!p.paramValue ? 'input cannot be empty' : null}
							fullWidth={true}
							label="Google Spreadsheet URL"
							placeholder='https://docs.google.com/spreadsheets/d/aBcdEFgHiJKl987654321023SfdGFoFpTeM73ywaH4eU'
						>
						</InputCustom>
						<SectionMessage fontSize='s'>
							<p>You must give read permissions on your spreadsheet to this account:</p>
							<p><em>beyable-external@beyable-prod-global.iam.gserviceaccount.com</em></p>
						</SectionMessage>
					</Fragment>;
				}
			})}
		</ModalBody>
		<ModalFooter
			secondaryAction={
				<Btn
					onClickFunction={() => onCancel()}
					message="Cancel"
					style="ghost"
					color='secondary'
				/>
			}
			primaryAction={
				<Btn
					disabled={editingParams?.some(p => !p.isValid) ?? true}
					onClickFunction={() => handleValidate()}
					message="Validate"
					color="primary"
				/>
			}
		/>
	</Modal>);

}
