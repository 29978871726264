import React, {useEffect, useState} from 'react';
import { CampaignsContext } from '../../context/index.js';
// @ts-ignore
import FormatCampaign from './FormatCampaign';
import Btn from '../../../../Components/Btn';
import SectionMessage from '../../../../Components/SectionMessage';
import EmptyState from '../../../../Components/EmptyState';
import {List, ListItem} from '../../../../Components/List';
import Picto from '../../../../Components/Picto';

import PanelListOfFeatures from './PanelListOfFeatures';
import PanelProductListSku  from './PanelProductListSku';
import PanelPromoCode  from './PanelPromoCode';
// import PanelRecommendations from '../../components/PanelRecommendations';
import {checkFeaturesSelectedOnCurrentVariation, Feature} from './utils';
import useEventBroker from '../../../../Hooks/useEventBroker';
import PanelRedirection from './PanelRedirection';
import PanelForm from './PanelForm';
import {useTranslation} from 'react-i18next';
import renderJsxTag from '../../../../Translation/translation-utils';
import {listCampaignFormat} from './utils';
import PanelFormSurvey from '../FormSurvey/PanelFormSurvey';
import { useWysiwygEditorContext } from '../../../WysiwygEditor/context/WysiwygEditorContext';
import { ProductRecommendationsValue, ProductReferencesValue } from '../../../WysiwygEditor/Utils/TemplateOperations';


export default function CustomCampaignContainer() {
	const { t } = useTranslation(['common']);
	const {
		editorMode,
		variations,
		currentVariationId,
		getCurrentVariation,
		handleOpenClosePanelFeatures,
		handlePropertyValueChange,
		applyUpdateCurrentVariation,		
		setPanelFormIsOpen,
		setPanelFormSurveyIsOpen,
		step,
		isSaving
	} = CampaignsContext();

	const {
		listOfShelvingRules,
		loadAllProductsShelvingRules
	} = useWysiwygEditorContext();

	const { publish } = useEventBroker();
	const [productListSku, setProductListSku] = useState<boolean>(false);
	const [promoCodePanel, setPromoCodePanel] = useState<boolean>(false);
	const [redirectionPanel, setRedirectionPanel] = useState<boolean>(false);

	const [existingRules, setExistingRules] = useState<any>();
	const [valueStrSku, setvalueStrSku] = useState('');
	const [currentVarHasFormat, setcurrentVarHasFormat] = useState(false);

	const [featuresOnCurrentVariation, setfeaturesOnCurrentVariation] = useState<Array <Feature>>([]);

	function getCurrentvariationValuePropertiesSkus(curr){
		function hasProductSku(){
			return curr.Properties.find(el => el.Name  === 'PRODUCTS') ? true : false;
		}
		const hasSku = hasProductSku();
		if(hasSku){
			const objSku = curr.Properties.find(el => el.Name  === 'PRODUCTS');
			setvalueStrSku(objSku.Value);
		}
	}
	useEffect(()=>{
		const currentVariationSaved = getCurrentVariation();
		if(currentVariationSaved){
			const feat = checkFeaturesSelectedOnCurrentVariation(currentVariationSaved,listOfShelvingRules);
			setfeaturesOnCurrentVariation(feat);
			const SlideFormat =  currentVariationSaved.SlideFormat;
			if(SlideFormat === '' || SlideFormat === null){
				setcurrentVarHasFormat(false);
			}else{
				setcurrentVarHasFormat(true);
			}
			const data = currentVariationSaved.Properties.find(el => el.Name === 'ShelvingRules');
			if(data){
				setExistingRules(ProductRecommendationsValue.fromStringInput(data.Value));
			}else{
				setExistingRules(ProductRecommendationsValue.fromStringInput(null));
			}
			getCurrentvariationValuePropertiesSkus(currentVariationSaved);
			
		}
	},[variations,currentVariationId,listOfShelvingRules]);

	useEffect(() => {
		loadAllProductsShelvingRules();
	}, []);
	const shelvingRulesSetCallback = (result) => {
		setExistingRules(result);
		handlePropertyValueChange(ProductRecommendationsValue.fromValue(result), 'ShelvingRules');
	};

	const openRecommendationsPanelRequested = () => {
		const currentVariation = getCurrentVariation();
		function checkIfMustCreateShelvingObject(){
			return currentVariation.Properties.find(el => el.Name  === 'ShelvingRules') ? true : false;
		}
		if(!checkIfMustCreateShelvingObject()){		
			// Check if must to create the property
			currentVariation.Properties = [
				...currentVariation.Properties,
				{IsCollection: 	false,
					IsGroup : false,
					Name: 'ShelvingRules',
					Value : {}}
			];
			applyUpdateCurrentVariation(currentVariation);
		}
		publish('evt/campaign/setShelvingRulesRequested', {
			callback: shelvingRulesSetCallback, value: existingRules , from: 'custom'
		});
	};

	const openSkuPanelRequested = () => {
		const currentVariation = getCurrentVariation();
		function checkIfMustCreateSkuObject(){
			return currentVariation.Properties.find(el => el.Name  === 'PRODUCTS') ? false : true;
		}
		const needToCreateSku = checkIfMustCreateSkuObject();

		if(needToCreateSku){		
			// Check if must to create the property
			currentVariation.Properties = [
				...currentVariation.Properties,
				{
					IsGroup : true,
					Name: 'PRODUCTS',
					Key : '5dad6ce2-af11-4b35-952c-09fd5a3e54e3',
					Value : new ProductReferencesValue('')
				}
			];
			applyUpdateCurrentVariation(currentVariation);
		}
		setProductListSku(true);
		
	};
	const openRedirectionRequested = () => {
		setRedirectionPanel(true);
	};

	function handleOpenPanel(panelName : string){
		handleOpenClosePanelFeatures(false);
		switch (panelName) {
			case 'reco':
				openRecommendationsPanelRequested();
				break;
			case 'redirection':
				openRedirectionRequested();
				break;
			case 'promoCode':
				setPromoCodePanel(true);
				break;
			case 'productList':
				openSkuPanelRequested();
				break;
			case 'form':
				setPanelFormIsOpen(true);
				break;
			case 'formSurvey':
				setPanelFormSurveyIsOpen(true);
				break;
		}
	}

	function handleOpenClosePanelSku(bool : boolean){
		setProductListSku(bool);
	}
	function handleOpenClosePanelPromoCode(bool : boolean){
		setPromoCodePanel(bool);
	}
	function handleOpenClosePanelRedirection(bool : boolean){
		setRedirectionPanel(bool);
	}

	if (editorMode !== 'custom') return <></>;

	return (
		<>
			<PanelListOfFeatures
				handleOpenPanel={handleOpenPanel}
				featuresOnCurrentVariation={featuresOnCurrentVariation}
			/>
			<PanelProductListSku 
				panelRecosIsOpen={productListSku}
				handleOpenClosePanel={handleOpenClosePanelSku}
				inputValue={valueStrSku}
			/>
			<PanelPromoCode 
				panelPromoCodeIsOpen={promoCodePanel}
				handleOpenClosePanel={handleOpenClosePanelPromoCode}
			/>
			<PanelRedirection
				panelRedirectionIsOpen={redirectionPanel}
				handleOpenClosePanel={handleOpenClosePanelRedirection}
			/>
			<PanelForm />
			<PanelFormSurvey />

			{step === 1 &&
				<div className='target_rules_outer'>
					<div className='target_rules_inner'>
						<div className='segment_section_group'>
							<div className='segment_section_label'>{t('customCampaign.campaignFormat')}</div>
							<div className='segment_section'>
								<FormatCampaign listCampaign={listCampaignFormat}/>
								{!currentVarHasFormat &&
									<SectionMessage
										type="information"
										appearance='background'
										noMargin={false}
										text={t('customCampaign.chooseAFormat')}
									/>
								}
							</div>
						</div>
						<div className="segment_section_group">
							<div className="segment_section_label flex">
								<div className='flex_item_full'>{t('customCampaign.campaignFeatures')}</div>
								{featuresOnCurrentVariation.length > 0 &&
									<div className='flex_item_fix ml_20'>
										<Btn
											color="primary"
											style="link"
											onClick={() => handleOpenClosePanelFeatures(true)}
											message={t('customCampaign.editFeatures')}
										/>
									</div>
								}
							</div>
							<div className="segment_section">
								{featuresOnCurrentVariation.length === 0 && 
									<EmptyState
										title={t('customCampaign.noFeatureYet')}
										text={renderJsxTag(t('customCampaign.featuresCanBeActivated'))}
										titleBold={false}
										icon='fas fa-cubes'
										verticalSize={'s'}
										primaryAction={
											<Btn
												color="primary"
												style="reverse"
												onClick={() => handleOpenClosePanelFeatures(true)}
												message={t('customCampaign.addFeature')}
											/>
										}
									/>
								}
								{featuresOnCurrentVariation.length > 0 && 
									<List>
										{featuresOnCurrentVariation.map((feat:Feature) => 
											<ListItem
												key={feat.id}
												onClick={()=>handleOpenPanel(feat.id)}
												text={feat.label}
												value={feat.value || t('customCampaign.clickToEdit')}
												textIsBold={true}
												hasArrow={true}
												picto={
													<Picto
														color={feat.color}
														icon={feat.icon}
													/>
												}
												pictoHasMargin={true}
											/>
										)}
									</List>
								}
							</div>
						</div>
					</div>
				</div>
			}
			{isSaving && (
				<div className="mask">
					<div className="wheel size_32"></div>
				</div>
			)}
		</>
	);
}
