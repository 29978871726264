import React from 'react';
import { CampaignsContext } from '../../context/index.js';
import Panel from '../../../../Components/Panel';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import Btn from '../../../../Components/Btn';
import PanelArticle from '../../../../Components/PanelArticle';
import ProductReferences from '../../../WysiwygEditor/Components/ProductReferencesEditor.js';
export default function ProductListSku({
	panelRecosIsOpen,
	handleOpenClosePanel,
	inputValue
}) {
	const {
		accountId,
		setupAccountServices,
		handlePropertyValueChange,
	} = CampaignsContext();
	function handleChangeInputValue(value){
		handlePropertyValueChange(value, 'PRODUCTS');
	}
	return (
		<Panel
			width="680"
			isOpen={panelRecosIsOpen}
			onClose={() => handleOpenClosePanel(false)}
			noClose={true}
		>
			<ModalHeader>
				<div className='flex'>
					<div className="flex_item_fix">
						<a className="panel_close panel_close_left" onClick={() => handleOpenClosePanel(false)}></a>
					</div>
					<div className='flex_item_full'>
							Product list (SKU)
					</div>
					<div className='flex_item_fix ml_30'>
						<Btn
							message="Confirm"
							color="primary"
							onClick={()=>handleOpenClosePanel(false)}
						/>
					</div>
				</div>
			</ModalHeader>
			<ModalBody color='grey'>
				<PanelArticle size='xl'>
					<div className='mb_10'>Select the products (SKU) to display in the campaign</div>
					<ProductReferences
						inputValue={inputValue}
						max={5}
						accountId={accountId}
						setupAccountServices={setupAccountServices}
						handleChangeInputValue={handleChangeInputValue}
					/>
				</PanelArticle>
			</ModalBody>
		</Panel>
	);
}
