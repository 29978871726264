import React, { createContext, useContext, useEffect, useState } from 'react';
import ProductFeedConfigurationServices from '../../../Services/ProductFeedConfigurationServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import {createNewEmptyField, newProductFeedObj, shallowCopy,isAnUrlValid,keyExistInConfig,BO_API_URL} from '../utils';
import SystemServices from '../../../Services/SystemServices';
import SetupAccountServices from '../../../Services/SetupAccountServices';
const ProductFeedEditionContext = createContext();

function useProductFeedEditionContext() {
	return useContext(ProductFeedEditionContext);
}

const ProductFeedEditionContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const AuthServices = props.AuthServices;
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const services = new ProductFeedConfigurationServices($http);
	const systemServices = new SystemServices($rootScope, $timeout);
	const setupAccountServices = new SetupAccountServices(props.$http, props.$routeParams);
	const [crudLink, setCrudLink] = useState('');
	const [mode, setMode] = useState('creation');
	const [isLoading, setisLoading] = useState(true);
	const [tenant, setTenant] = useState('');
	const [key, setKey] = useState();
	const [modalConfirmIsOpen, setmodalConfirmIsOpen] = useState(false);
	const [originalProductFeedObject, setoriginalProductFeedObject] = useState();
	const [productFeedObject, setproductFeedObject] = useState();
	const [mappingObject, setMappingObject] = useState({});


	const [needBreadCrumb, setneedBreadCrumb] = useState(false);
	const [canValid, setcanValid] = useState(false);
	const [step, setStep] = useState(1);
	const [metadatasSources, setMetadatasSources] = useState([]) ;
	const [columnsValues, setColumnsValues] = useState();
	const [metadatasSourcesIsLoading, setMetadatasSourcesIsLoading] = useState(false);
	const [needToFetchMetaDatas, setNeedToFetchMetaDatas] = useState(false);
	const [rawFeed, setRawFeed] = useState();
	const [openedFieldIndex, setOpenedFieldIndex] = useState(-1);
	const [separator, setSeparator] = useState('');
	const [hasErrorsOnColums, sethasErrorsOnColums] = useState(false);
	const [panelRawFeedIsOpen, setpanelRawFeedIsOpen] = useState(false);

	// Creation
	const [stateOfConnection, setstateOfConnection] = useState(1);
	const [typeOfSourceSelected, settypeOfSourceSelected] = useState();
	const [stateOfBackendDetection, setstateOfBackendDetection] = useState('ResourceIdentifier');
	const [sourceDetectionCrud, setsourceDetectionCrud] = useState('');
	const [isLoadingConnection, setisLoadingConnection] = useState(false);
	const [hasErrorsOnCreation, sethasErrorsOnCreation] = useState();
	const [isEmptyIdentifier, setisEmptyIdentifier] = useState(false);
	const [mappingHasBeging, setmappingHasBeging] = useState(false);
	const [messageInfos, setmessageInfos] = useState('');
	const [transformations, setTransformations] = useState([]);

	// quit or save
	let urlReturn = '/ProductFeed/Config';
	if ($routeParams && $routeParams.ka) {
		urlReturn += '?ka=' + $routeParams.ka;
	}
	function removeEmpty(obj) {
		return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
	}
	function handleChangesTypeFormatField(e){
		const newProductFeedType = {...productFeedObject.format};

		let value = e.target.value;

		newProductFeedType[e.target.name] = value;

		setproductFeedObject(prev => ({
			...prev,
			format : newProductFeedType ,
		}));
	}
	function handleChangesTypeFormatFieldRichText(e){
		const newProductFeedType = {...productFeedObject.format};
		let value = e;
		newProductFeedType['linesSeparator'] = value;
		setproductFeedObject(prev => ({
			...prev,
			format : newProductFeedType ,
		}));
	}
	function handleChangesTypeFormatFieldObj(objName, nameField, newValue){
		const newProductFeedType = {...productFeedObject.format};
		newProductFeedType[objName][nameField] = newValue;
		setproductFeedObject(prev => ({
			...prev,
			format : newProductFeedType ,
		}));
	}
	function handleChangeValueField(nameOfNewProperty, newValue){
		if(mappingObject.hasOwnProperty(nameOfNewProperty)){
			const mappingObjectCopy = {...mappingObject};
			mappingObjectCopy[nameOfNewProperty][0].sourceField = newValue;
			setMappingObject(mappingObjectCopy);
		}else{
			addNewEmptyField(nameOfNewProperty,newValue);
		}
	}
	const handleAddTagCategoryValue = (newElement, createField, existingElement, field, needTransformations) => {

		if (createField) {
			addNewEmptyField(field, newElement.name, newElement.itemName ? newElement.itemName : newElement.name, needTransformations ? newElement.transformations : false);
		} else {
			const mappingObjectCopy = {...mappingObject};
			if (existingElement) {
				mappingObjectCopy[field][existingElement.index] = {
					beyableField: field ,
					sourceField: newElement.name,
					beyableItemName: newElement.itemName ? newElement.itemName : newElement.name,
					transformations: {...newElement.transformations}
				};
			} else {
				mappingObjectCopy[field].push({
					beyableField: field ,
					sourceField: newElement.name,
					beyableItemName: newElement.itemName ? newElement.itemName : newElement.name,
					transformations: {...newElement.transformations}
				});
			}
			setMappingObject(mappingObjectCopy);
		}
		handleChangeTagConfig(newElement, field);
		if (field === 'Categories') {
			const copyOfProductFeed = {...productFeedObject};
			if (!existingElement) {
				if (newElement.hierarchy && copyOfProductFeed.categoryHierarchies){
					const isExistingValues = copyOfProductFeed.categoryHierarchies.hasOwnProperty(newElement.hierarchy);
					if(isExistingValues){
						copyOfProductFeed.categoryHierarchies[newElement.hierarchy].push(newElement.itemName || newElement.name);
					}else{
						copyOfProductFeed.categoryHierarchies[newElement.hierarchy] = [newElement.itemName || newElement.name];
					}
				} else {
					copyOfProductFeed.categoryHierarchies = {cat_categories: [newElement.itemName || newElement.name]};
				}
			} else if (newElement.itemName !== existingElement.category.beyableItemName) {

				const index = shallowCopy(copyOfProductFeed).categoryHierarchies[newElement.hierarchy].findIndex(e => e === existingElement.category.beyableItemName);
				copyOfProductFeed.categoryHierarchies[newElement.hierarchy].splice(index, 1, newElement.itemName);
			}
			setproductFeedObject(copyOfProductFeed);
		}
	};



	const deleteTag = (tagIndex) => {
		if (mappingObject['Tags'].length === 1) {
			deleteValueKeyInMappingObject('Tags');
		} else {
			const mappingObjectCopy = {...mappingObject};
			mappingObjectCopy['Tags'].splice(tagIndex, 1);
			setMappingObject(mappingObjectCopy);
		}
	};
	function addNewEmptyField(nameOfNewProperty, newValue, itemName = null, needTransformations = false){
		const mappingObjectCopy = {...mappingObject};
		mappingObjectCopy[nameOfNewProperty] = createNewEmptyField(nameOfNewProperty, newValue, itemName, needTransformations);
		setMappingObject(mappingObjectCopy);
	}
	function deleteValueKeyInMappingObject(nameOfNewProperty){
		if(nameOfNewProperty === 'Categories'){
			setproductFeedObject(prev =>
				({...prev,
					categoryHierarchies : {},
				}));
		}
		const mappingObjectCopy = {...mappingObject};
		delete mappingObjectCopy[nameOfNewProperty];
		setMappingObject(mappingObjectCopy);
	}
	function deletePrices(){
		const mappingObjectCopy = {...mappingObject};
		delete mappingObjectCopy['Price1'];
		delete mappingObjectCopy['Price2'];
		setMappingObject(mappingObjectCopy);
	}
	function handleDeleteCustomMapping(){
		const newProductObj = {...productFeedObject};
		newProductObj['mapperVersion'] = '';
		newProductObj['mapperName'] = '';
		setproductFeedObject(newProductObj);

	}

	function checkIfCanQuitWithoutSave() {
		let stringifyNewObj = {};
		if(productFeedObject?.type === 'GenericUri' ||productFeedObject.type === 'Custom' || productFeedObject?.type === 'Http'){
			Object.keys(mappingObject).length > 0 ?
				stringifyNewObj ={
					...productFeedObject ,
					fieldMappingInfos : {
						...productFeedObject?.fieldMappingInfos,
						mappings : mappingObject }} :
				stringifyNewObj ={
					...productFeedObject ,
				};
		}else{
			stringifyNewObj = {...productFeedObject};
		}
		const currentStateOfFeed = JSON.stringify(removeEmpty(stringifyNewObj));
		const compareIfIsSave =
		originalProductFeedObject === currentStateOfFeed;

		if (compareIfIsSave) {
			window.location.href = urlReturn;
		} else {
			setmodalConfirmIsOpen(true);
		}
	}
	function handleCreateSourceFeed(){
		const transformSpecialCaracters = {...productFeedObject.format};
		const specialsCaracters = [
			{key:'\\' , value: '\\'},
			{key:'\n', value:'\\n' },
			{key:'\\n', value:'\\\\n' },
			{key:'\n\n', value:'\\n\\n' },
			{key:'\t', value:'\\t' },
			{key:'\r', value:'\\r' },
			{key:'\r\n', value:'\\r\\n' },
			{key:'\f', value:'\\f' },
			{key:'\v', value:'\\v' },
		];
		var transformObjWithSpecialsCaracters = {};
		for (const property in transformSpecialCaracters) {
			if(specialsCaracters.find(el => el.value === transformSpecialCaracters[property])){
				transformObjWithSpecialsCaracters[property] = specialsCaracters.find(el => el.value === transformSpecialCaracters[property]).key;
			}else{
				transformObjWithSpecialsCaracters[property] = transformSpecialCaracters[property] ;
			}
		}
		let stringifyNewObj = { };
		if(productFeedObject.type === 'GenericUri' ||productFeedObject.type === 'Custom' || productFeedObject?.type === 'Http'){

			stringifyNewObj ={
				...productFeedObject ,
				format :  transformObjWithSpecialsCaracters,
				fieldMappingInfos : {
					...productFeedObject['fieldMappingInfos'],
					mappings : mappingObject }};
		}else{
			stringifyNewObj = {...productFeedObject};
		}
		const newObj = removeEmpty(stringifyNewObj);
		setisLoading(false);
		services.call(crudLink.addSource.href,'post',newObj,
			success => {
				setisLoading(false);
				const stringifyNewObjSave =JSON.stringify(newObj);
				setoriginalProductFeedObject(stringifyNewObjSave);
				setTimeout(()=>{
					window.location.href = urlReturn;
				},400);
				systemServices.showSuccess('Create source feed with success');
			},
			error =>{
				console.error(error);
				systemServices.showError();
				setisLoading(false);
			}
		);
	}
	function handleValidChanges(){

		const transformSpecialCaracters = {...productFeedObject.format};
		const specialsCaracters = [
			{key:'\\' , value: '\\'},
			{key:'\n', value:'\\n' },
			{key:'\\n', value:'\\\\n' },
			{key:'\n\n', value:'\\n\\n' },
			{key:'\t', value:'\\t' },
			{key:'\r', value:'\\r' },
			{key:'\r\n', value:'\\r\\n' },
			{key:'\f', value:'\\f' },
			{key:'\v', value:'\\v' },
		];
		var transformObjWithSpecialsCaracters = {};
		for (const property in transformSpecialCaracters) {
			if(specialsCaracters.find(el => el.value === transformSpecialCaracters[property])){
				transformObjWithSpecialsCaracters[property] = specialsCaracters.find(el => el.value === transformSpecialCaracters[property]).key;
			}else{
				transformObjWithSpecialsCaracters[property] = transformSpecialCaracters[property] ;
			}
		}

		let stringifyNewObj = { };

		if(productFeedObject.type === 'GenericUri' ||productFeedObject.type === 'Custom'){
			stringifyNewObj ={
				...productFeedObject ,
				format :  transformObjWithSpecialsCaracters,
				fieldMappingInfos : {
					...productFeedObject['fieldMappingInfos'],
					mappings : mappingObject  }
			};
		}else{
			stringifyNewObj = {...productFeedObject
			};
		}
		const newObj = removeEmpty(stringifyNewObj);
		setisLoading(true);
		services.call(tenantConfig._links.tagsAndCategoriesFlags.href, 'put', tagConfig,
			success => {
				services.call(crudLink.self.href,'put',newObj,
					success => {
						setisLoading(false);
						const stringifyNewObjSave =JSON.stringify(newObj);
						setoriginalProductFeedObject(stringifyNewObjSave);
						setTimeout(()=>{
							window.location.href = urlReturn;
						},400);
						systemServices.showSuccess('Changes has been saved');
					},
					error =>{
						console.error(error);
						systemServices.showError();
						setisLoading(false);
					}
				);
			},
			error => {
				console.error(error);
				systemServices.showError();
				setisLoading(false);
			});

	}


	function returnToDashboard() {
		window.location.href = urlReturn;
	}
	function handleCloseConfirm() {
		setmodalConfirmIsOpen(false);
	}
	function handleChangeStep(stepNb){

		setStep(stepNb);
	}

	/// connexion
	function handleChangeSimpleField(field, newValue){
		setproductFeedObject(prev => ({
			...prev,
			[field] : newValue ,
		}));
	}
	function handleChangeConnexion(field, newValue){
		const newProductFeedValueAuth = {...productFeedObject.auth ?? {}};
		newProductFeedValueAuth[field] = newValue;
		setproductFeedObject(prev => ({
			...prev,
			auth : newProductFeedValueAuth ,
		}));
	}
	function handleChangeUrlFeed(newUrlValue){
		setproductFeedObject(prev => ({
			...prev,
			urlFeed : newUrlValue,
		}));
	}

	function needToPrintBreadCrumb(type){
		switch (type) {
			case 'GenericUri':
				return true;
			case 'PrestaShop':
				return true;
			case 'OnSiteData':
				return false;
			case 'Http':
				return true;
			case 'None':
				return false;
			case 'Custom':
				return true;
		}}
	function handleChangeProductFeedConfig(obj){
		const objWithoutUndefind = removeEmpty(obj);
		setproductFeedObject(objWithoutUndefind);
		if(objWithoutUndefind.type !== 'PrestaShop'){
			const fieldMappings = {...objWithoutUndefind.fieldMappingInfos?.mappings};
			setMappingObject(fieldMappings);
		}
		const stringifyNewObj =JSON.stringify(objWithoutUndefind);
		setoriginalProductFeedObject(stringifyNewObj);
		setneedBreadCrumb(needToPrintBreadCrumb(objWithoutUndefind.type) && objWithoutUndefind?.hasOwnProperty('auth'));
	}
	/// Metadatas
	function getRawFeed ( ) {
		const endPoint = mode === 'edition' ? `${crudLink.metadata.href}?metadataRequests=RawFeed&rawFeedLineCount=100` : `${crudLink.sourceMetadata.href}?metadataRequests=RawFeed&rawFeedLineCount=100`;
		const stringifyNewObj = {
			...productFeedObject ,
			fieldMappingInfos : {
				...productFeedObject['fieldMappingInfos'],
				mappings : mappingObject } };
		services.call(endPoint,'post',stringifyNewObj,
			success => {
				const rawFeed = success.rawFeed;

				setRawFeed(rawFeed);
			},
			error => {
				systemServices.showError('This product feed service cannot be reached, we cannot retrieve the available columns. Please check the feed accessibility');
				console.error(error);
				sethasErrorsOnColums(true);
			}
		);
	}

	function getMetaDatas(){
		if(productFeedObject.type !== 'PrestaShop'){
			const endPoint = mode === 'edition' ? `${crudLink.metadata.href}?metadataRequests=Columns&metadataRequests=ColumnsValues&metadataRequests=RawFeed&rawFeedLineCount=100&columnsValueCount=10` : `${crudLink.sourceMetadata.href}?metadataRequests=Columns&metadataRequests=ColumnsValues&metadataRequests=RawFeed&rawFeedLineCount=100&columnsValueCount=10`;
			const stringifyNewObj = {
				...productFeedObject ,
				fieldMappingInfos : {
					...productFeedObject['fieldMappingInfos'],
					mappings : mappingObject } };
			setMetadatasSourcesIsLoading(true);
			services.call(endPoint,'post',stringifyNewObj,
				success => {
					const rawFeed = success.rawFeed;
					let metadataListFormated = success.allColumns.map(el => {
						let exempleValues;
						if (success.columnsValues && success.columnsValues[el]) {
							exempleValues = success.columnsValues[el];
						}
						return {
							label : el,
							value : el,
							hoverContent: exempleValues
						};
					});
					setRawFeed(rawFeed);
					setColumnsValues(success.columnsValues);
					setNeedToFetchMetaDatas(false);
					setMetadatasSourcesIsLoading(false);
					setMetadatasSources(metadataListFormated);
					sethasErrorsOnColums(false);
				},
				error => {
					systemServices.showError('This product feed service cannot be reached, we cannot retrieve the available columns. Please check the feed accessibility');
					console.error(error);
					setNeedToFetchMetaDatas(false);
					setMetadatasSourcesIsLoading(false);
					sethasErrorsOnColums(true);
				}
			);
		}
	}
	useEffect(()=>{
		if(needToFetchMetaDatas){
			getMetaDatas();
		}
	},[needToFetchMetaDatas]);

	useEffect(()=>{
		if (step == 2){

			// If not onsiteData et Prestashop check id
			if(productFeedObject?.type !== 'PrestaShop' && productFeedObject?.type !== 'OnSiteData' && productFeedObject?.type !== 'Mazeberry' && productFeedObject?.type !== 'Shopify'){
				if(mappingObject){
					const hasKeyIdInObjet = keyExistInConfig('Id',mappingObject,null);
					setcanValid(hasKeyIdInObjet);
				}
			}
			else{
				setcanValid(true);
			}
		} 
	},[step,productFeedObject,mappingObject]);

	function handleCreateNewEmptyProductFeed(){
		setproductFeedObject(newProductFeedObj);
	}

	// Creation
	function handleChooseATypeSource(type){
		settypeOfSourceSelected(type);
		const newFields = {
			type: type
		};

		if (type === 'Http') {
			newFields.httpVerb = 'Post';
			newFields.auth = {};
			newFields.auth.type = 'None';
		}

		setproductFeedObject(prev => ({
			...prev,
			...newFields
		}));
		setstateOfConnection(2);
	}
	function handleChooseATypeSourceConnection(type){
		const objProperty = {...productFeedObject.auth};
		objProperty.type = type;
		setproductFeedObject(prev => ({
			...prev,
			auth : objProperty ,
		}));
	}
	function handleGetMappingSources(){
		function removeEmpty(obj) {
			return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
		}
		const newObj = removeEmpty(productFeedObject);
		if(!newObj.hasOwnProperty('resourceIdentifier') || newObj?.resourceIdentifier.trim() === ''  ){
			setmessageInfos({
				type : 'warning',
				text : 'URL field is mandatory',
			});
			setisEmptyIdentifier(true);
			return ;
		}
		const urlIsValid = isAnUrlValid(newObj?.resourceIdentifier.trim()) ;
		setisEmptyIdentifier(!urlIsValid);
		if(!urlIsValid){
			setmessageInfos({
				type : 'warning',
				title : 'Invalid url',
				text :'Please check the ressource identifier in invalid url, we accept the urls as https://mylink.com , ftp://myftp.com'
			});
			return ;
		}
		setisLoadingConnection(true);
		services.call(sourceDetectionCrud,'post',removeEmpty(newObj),
			success => {
				const resultDataNeeded = success.dataNeeded;
				setproductFeedObject(success.config);
				setstateOfBackendDetection(resultDataNeeded);
				if(success.errorMessage && success.errorMessage.includes('530')){
					sethasErrorsOnCreation({
						key : 'connectionFail',
						message : success.errorMessage});
				}else{
					sethasErrorsOnCreation(success.errorMessage);
				}
				setTimeout(()=>{
					if(resultDataNeeded === 'FinalParameters'){
						// Fake State
						// setstateOfBackendDetection('FeedFormat');
						setStep(2);
						setmappingHasBeging(true);
						setNeedToFetchMetaDatas(true);
						setstateOfBackendDetection('ResourceIdentifier');
					}
					setisLoadingConnection(false);
				}, 500);
			},
			(error, errorStatus) => {
				systemServices.showError();
				console.error(error);
				setisLoadingConnection(false);
			}
		);
	}
	function handleGoingToMappingShopify(type){
		const objPropertyAuth = {...productFeedObject.auth};
				
		if(!productFeedObject.hasOwnProperty('resourceIdentifier') || productFeedObject.resourceIdentifier === null || productFeedObject?.resourceIdentifier.trim() === '' ){
			setisEmptyIdentifier(true);
			setmessageInfos({
				type : 'warning',
				text :'Store URL field is mandatory'
			});
			return ;
		}
		const urlIsValid = isAnUrlValid(productFeedObject?.resourceIdentifier.trim()) ;
		setisEmptyIdentifier(!urlIsValid);
		if(!urlIsValid){
			setmessageInfos({
				type : 'warning',
				title : 'Invalid url',
				text :'Please check the ressource identifier in invalid url, we accept the urls as https://mylink.com'
			});
			return ;
		}
		const newObj = {...productFeedObject};
		newObj.auth = objPropertyAuth;
		newObj.type = type;
		if(!newObj.hasOwnProperty('apiToken')){
			newObj.apiToken = '';
		}
		if(!newObj.hasOwnProperty('productUrlBasePath')){
			newObj.productUrlBasePath = '';
		}
		setproductFeedObject(newObj);
		setStep(2);
		setmappingHasBeging(true);
	}
	function handleGoingToMappingMaz(type){
		const objPropertyAuth = {...productFeedObject.auth};
				
		if(!productFeedObject.hasOwnProperty('resourceIdentifier') || productFeedObject.resourceIdentifier === null || productFeedObject?.resourceIdentifier.trim() === '' ){
			setisEmptyIdentifier(true);
			setmessageInfos({
				type : 'warning',
				text :'URL field is mandatory'
			});
			return ;
		}
		const urlIsValid = isAnUrlValid(productFeedObject?.resourceIdentifier.trim()) ;
		setisEmptyIdentifier(!urlIsValid);
		if(!urlIsValid){
			setmessageInfos({
				type : 'warning',
				title : 'Invalid url',
				text :'Please check the ressource identifier in invalid url, we accept the urls as https://mylink.com , ftp://myftp.com'
			});
			return ;
		}
		const newObj = {...productFeedObject};
		newObj.auth = objPropertyAuth;
		newObj.type = type;
		if(!newObj.hasOwnProperty('productUrlThumbnailBasePath')){
			newObj.productUrlThumbnailBasePath = '';
		}
		if(!newObj.hasOwnProperty('productUrlBasePath')){
			newObj.productUrlBasePath = '';
		}
		setproductFeedObject(newObj);
		setStep(2);
		setmappingHasBeging(true);
	}
	function handleGoingToMapping(type){
		const objPropertyAuth = {...productFeedObject.auth};
		objPropertyAuth.type = 'apiKey';
		
		if(!productFeedObject.hasOwnProperty('resourceIdentifier') || productFeedObject.resourceIdentifier === null || productFeedObject?.resourceIdentifier.trim() === '' ){
			setisEmptyIdentifier(true);
			setmessageInfos({
				type : 'warning',
				text :'URL field is mandatory'
			});
			return ;
		}
		const urlIsValid = isAnUrlValid(productFeedObject?.resourceIdentifier.trim()) ;
		setisEmptyIdentifier(!urlIsValid);
		if(!urlIsValid){
			setmessageInfos({
				type : 'warning',
				title : 'Invalid url',
				text :'Please check the ressource identifier in invalid url, we accept the urls as https://mylink.com , ftp://myftp.com'
			});
			return ;
		}
	
		
		setproductFeedObject(prev =>
			({...prev,
				auth : objPropertyAuth,
				categoryHierarchies : {},
				productUrlPattern : '',
				productUrlThumbnailPattern :'' ,
				type: type ? type : 'PrestaShop',
			}));
		setStep(2);
		setmappingHasBeging(true);
		if(!type){
			setNeedToFetchMetaDatas(true);
		}

	}

	function HandleChangeTypeFormat(type){
		let format = {...productFeedObject.format};
		format.type = type;

		function createTypeByKey(key){
			switch (key) {
				case 'Custom':
					return {
						parserName : '',
						parserVersion : '',
						parserParams : {
							'@LegacyConfig@' : ''
						},
						type: type
					};
				case 'Csv':
					return {
						fieldsSeparator: '',
						// linesSeparator : '',
						type: type
					};
				case 'Xml':
					return {
						productXPath: '',
						namespaces : {
						},
						type: type
					};


			}
		}
		format = createTypeByKey(type);
		setproductFeedObject(prev =>
			({...prev,
				format: format,
			}));
	}
	function handleGoingToNextPartOfMapping(){
		setStep(2);
		setmappingHasBeging(true);
		if(productFeedObject.type === 'GenericUri' ||productFeedObject.type === 'Http' ){
			setNeedToFetchMetaDatas(true);
		}else{
			setMetadatasSourcesIsLoading(false);
		}
	}
	function handleChangeXmlNameSpace(newArray){
		const namespacesObj = {...productFeedObject.format};
		const obj = newArray.reduce((o, key) => ({ ...o, [key.prefix]: key.nameSpace}), {});
		namespacesObj.namespaces = obj;
		setproductFeedObject(prev =>
			({...prev,
				format: namespacesObj,
			}));
	}
	function handleChangeSelectionForSelectedFields(field, val){
		let selectedFields = [] ;

		if(productFeedObject.selectedFields){
			selectedFields = [...productFeedObject.selectedFields];
		}
		if(!val){
			selectedFields = selectedFields.filter(el => el !== field);
		}else{
			selectedFields = [...selectedFields ,field ];
		}
		setproductFeedObject(prev =>
			({...prev,
				selectedFields: selectedFields,
			}));

	}
	function HandleSubmitNewTag (newObject){
		setproductFeedObject(prev =>
			({...prev,
				selectedTags: newObject,
			}));
	}
	function HandleSubmitCategories (newObject){
		setproductFeedObject(prev =>
			({...prev,
				selectedCategories: newObject,
			}));
	}
	function HandleSubmitHierarchie (newObject){
		setproductFeedObject(prev =>
			({...prev,
				categoryHierarchies: newObject,
			}));
	}
	function handleChangeHierarchyNameExist(newObject){
		setproductFeedObject(prev =>
			({...prev,
				categoryHierarchies: newObject,
			}));
	}
	/* BASILE */

	const [tenantConfig, setTenantConfig] = useState();
	const [tagConfig, setTagConfig] = useState({privateTags: [], excludedTags: [], excludedCategories: []});

	const handleChangeTenantConfig = (value) => {
		setTenantConfig(value);
	};

	const handleChangeTagConfig = (newElement, field) => {
		const copyOfTagConfig = {...tagConfig};

		if (field === 'Tags') {
			const isPrivatePrevState = copyOfTagConfig.privateTags.includes(newElement.name);
			const isExcludedPrevState = copyOfTagConfig.excludedTags.includes(newElement.name);
			if (newElement.isPrivate) {
				!isPrivatePrevState && copyOfTagConfig.privateTags.push(newElement.name);
			} else {
				if (isPrivatePrevState) {
					const index = copyOfTagConfig.privateTags.findIndex((e) => e === newElement.name);
					copyOfTagConfig.privateTags.splice(index, 1);
				}
			}
			if (newElement.isExcluded) {
				!isExcludedPrevState && copyOfTagConfig.excludedTags.push(newElement.name);
			} else {
				if (isExcludedPrevState) {
					const index = copyOfTagConfig.excludedTags.findIndex((e) => e === newElement.name);
					copyOfTagConfig.excludedTags.splice(index, 1);
				}
			}
		}
		if (field === 'Categories') {
			const isExcludedCategoryPrevState = copyOfTagConfig.excludedCategories.includes(newElement.name);
			if (newElement.isExcluded) {
				!isExcludedCategoryPrevState && copyOfTagConfig.excludedCategories.push(newElement.name);
			} else if (isExcludedCategoryPrevState) {
				const index = copyOfTagConfig.excludedCategories.findIndex((e) => e === newElement.name);
				copyOfTagConfig.excludedCategories.splice(index, 1);
			}
		}
		setTagConfig(copyOfTagConfig);
	};

	const handleChangeCategoryHierarchies = (hierarchyValue, hierarchyName) => {
		const copyOfProductFeed = {...productFeedObject};

		if (copyOfProductFeed.categoryHierarchies) {
			copyOfProductFeed.categoryHierarchies[hierarchyName] = hierarchyValue;
		} else {
			copyOfProductFeed.categoryHierarchies = {
				[hierarchyName] : hierarchyValue
			};
		}
		setproductFeedObject(copyOfProductFeed);
	};

	const addHierarchy = () => {
		const copyOfProductFeed = {...productFeedObject};
		let nameOfNewHierarchy;

		if (copyOfProductFeed.categoryHierarchies) {
			const length = Object.keys(copyOfProductFeed.categoryHierarchies).length;
			const keyArray = Object.keys(copyOfProductFeed.categoryHierarchies);
			const nameOfLastHierarchy = length > 0 ? keyArray[length - 1].toString() : '0';
			const lastChar = nameOfLastHierarchy.charAt(nameOfLastHierarchy.length - 1);
			const isLastCharNumber = !isNaN(parseInt(lastChar));
			if (isLastCharNumber) {
				const incrementedLastChar = parseInt(lastChar) + 1;
				nameOfNewHierarchy = nameOfLastHierarchy.slice(0, nameOfLastHierarchy.length -1) + incrementedLastChar;
			} else {
				nameOfNewHierarchy =  nameOfLastHierarchy + (length + 1);
				copyOfProductFeed.categoryHierarchies[nameOfNewHierarchy] = [];
				setproductFeedObject(copyOfProductFeed);
			}
		} else {
			nameOfNewHierarchy = 'cat_categories';
			copyOfProductFeed.categoryHierarchies = {cat_categories: []};
			setproductFeedObject(copyOfProductFeed);
		}

		return nameOfNewHierarchy;
	};

	const handleChangeMonoFieldCategories = (newCategoriesArray) => {
		const mappingObjectCopy = {...mappingObject};
		mappingObjectCopy.Categories = newCategoriesArray;
		setMappingObject(mappingObjectCopy);

		const newHierarchy = newCategoriesArray.map(e => e.beyableItemName);
		const singleHierarchyName = () => {
			if (productFeedObject.categoryHierarchies) {
				return Object.keys(productFeedObject.categoryHierarchies)[0];
			} else return 'cat_categories';
		};
		handleChangeCategoryHierarchies(newHierarchy, singleHierarchyName());
	};

	const deleteCategoryMapping = (category, hierarchyName) => {
		const mappingObjectCopy = {...mappingObject};
		const index = mappingObjectCopy.Categories.findIndex(element => element.beyableItemName === category.beyableItemName);
		mappingObjectCopy.Categories.splice(index, 1);
		setMappingObject(mappingObjectCopy);

		const copyOfProductFeed = {...productFeedObject};
		if (copyOfProductFeed.categoryHierarchies[hierarchyName].length === 1) {
			delete copyOfProductFeed.categoryHierarchies[hierarchyName];
		} else {
			const indexHierarchy = copyOfProductFeed.categoryHierarchies[hierarchyName].findIndex(element => element === category.beyableItemName);
			copyOfProductFeed.categoryHierarchies[hierarchyName].splice(indexHierarchy, 1);
		}
		setproductFeedObject(copyOfProductFeed);
	};

	const toggleMapField = (index) => {
		if (typeof index == 'undefined') {
			setOpenedFieldIndex(-1);
			return;
		}
		if (openedFieldIndex === index) {
			setOpenedFieldIndex(-1);
			return;
		}
		setOpenedFieldIndex(index);
	};

	const getFeedTypeName = (type) => {
		if (type === 'OnSiteData') return 'On-site data';
		if (type === 'Http') return 'HTTP API';
		if (type === 'GenericUri') return 'Generic feed';
		if (type === 'Mazeberry') return 'Easyence';
		return type;
	};
	const handleOpenClosePanelRawfeed = (bool) => {
		setpanelRawFeedIsOpen(bool);
	};
	useEffect(()=>{
		services.call(`${BO_API_URL}feature/productfeedconfig/transformations`,'get',null,
			success => {
				setTransformations(success);				
			},
			(error ,err)=>{
				console.error(error, 'err :' ,err);
			}
		);
	},[]);

	return (
		<ProductFeedEditionContext.Provider
			value={{
				// config mode
				AuthServices,
				tenant,
				setTenant,
				setupAccountServices,
				accountId,
				services,
				systemServices,
				crudLink,
				setCrudLink,
				mode,
				setMode,
				setisLoading,
				isLoading,
				modalConfirmIsOpen,
				checkIfCanQuitWithoutSave,
				canValid,
				step,
				setStep,
				handleChangeStep,
				handleChangeProductFeedConfig,
				handleChangeTenantConfig,
				handleCloseConfirm,
				returnToDashboard,
				handleValidChanges,
				handleCreateNewEmptyProductFeed,
				/// connexions
				isEmptyIdentifier,
				handleChangeSimpleField,
				handleChangeUrlFeed,
				productFeedObject,
				needBreadCrumb,
				setneedBreadCrumb,
				handleChangeConnexion,
				/// mapping
				hasErrorsOnColums,
				metadatasSources,
				metadatasSourcesIsLoading,
				mappingObject,
				addNewEmptyField,
				handleChangeValueField,
				deleteValueKeyInMappingObject,
				deletePrices,
				deleteTag,
				setNeedToFetchMetaDatas,
				needToFetchMetaDatas,
				openedFieldIndex,
				setOpenedFieldIndex,
				toggleMapField,
				handleAddTagCategoryValue,
				tagConfig,
				setTagConfig,
				handleChangeCategoryHierarchies,
				handleChangeMonoFieldCategories,
				handleChangeTagConfig,
				addHierarchy,
				deleteCategoryMapping,
				separator,
				setSeparator,
				handleDeleteCustomMapping,
				/// FORMAT
				handleChangesTypeFormatField,
				handleChangesTypeFormatFieldObj,
				handleChangesTypeFormatFieldRichText,
				columnsValues,


				//Creation 
				setisEmptyIdentifier,
				setmessageInfos,
				messageInfos,
				handleCreateSourceFeed,
				stateOfConnection,
				typeOfSourceSelected,
				handleChooseATypeSource,
				setstateOfConnection,
				setstateOfBackendDetection,
				stateOfBackendDetection,
				handleChooseATypeSourceConnection,
				setsourceDetectionCrud,
				handleGetMappingSources,
				isLoadingConnection,
				handleGoingToMapping,
				handleGoingToMappingMaz,
				handleGoingToMappingShopify,
				hasErrorsOnCreation,
				HandleChangeTypeFormat,
				handleGoingToNextPartOfMapping,
				handleChangeXmlNameSpace,
				handleChangeSelectionForSelectedFields,
				HandleSubmitNewTag,
				HandleSubmitCategories,
				HandleSubmitHierarchie,
				handleChangeHierarchyNameExist,
				getFeedTypeName,
				mappingHasBeging,
				setmappingHasBeging,
				rawFeed,
				handleOpenClosePanelRawfeed,
				panelRawFeedIsOpen,
				getRawFeed,
				transformations,
				setMappingObject
			}}
		>
			{props.children}
		</ProductFeedEditionContext.Provider>
	);
};

export default ProductFeedEditionContextProvider;

export { useProductFeedEditionContext };
