import HttpServices from './HttpServices';

export class WebAnalyticsSettingsServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} else {
			this.ANALYTICS_BO_API_URL = `${window.BEYABLE_env.ANALYTICS_BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getIpAddressesBlackList(callbackSuccess, callbackError) {
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/requestsfiltering/deny-ip`,
			callbackSuccess,
			callbackError
		);
	}

	updateIpAddressesBlackList(blacklistedAddresses, callbackSuccess, callbackError) {
		this.HttpServices.put(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/requestsfiltering/deny-ip`,
			blacklistedAddresses,
			callbackSuccess,
			callbackError
		);
	}
}

export default class WebAnalyticsServices {
	constructor($http, accountId, getTenant) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} else {
			this.ANALYTICS_BO_API_URL = `${window.BEYABLE_env.ANALYTICS_BO_API_URL}`;
		}
		this.accountId = accountId;
		this.getTenant = getTenant;
	}

	getSessions(options, callbackSuccess, callbackError) {
		const tenant = this.getTenant() || '';
		this.HttpServices.post(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/session/${tenant}`,
			options,
			callbackSuccess,
			callbackError
		);
	}

	getSessionsCsv(options, callbackSuccess, callbackError) {
		const tenant = this.getTenant() || '';
		this.HttpServices.post(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/session/${tenant}`,
			options,
			callbackSuccess,
			callbackError,
			{Accept: 'text/csv'}
		);
	}

	getSessionsCompare(options, callbackSuccess, callbackError) {
		const tenant = this.getTenant() || '';
		this.HttpServices.post(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/sessioncompare/${tenant}`,
			options,
			callbackSuccess,
			callbackError
		);
	}

	getUniqueVisitorCompare(options, callbackSuccess, callbackError) {
		const tenant = this.getTenant() || '';
		this.HttpServices.post(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/uniquevisitorcompare/${tenant}`,
			options,
			callbackSuccess,
			callbackError
		);
	}

	getUniqueVisitors(options, callbackSuccess, callbackError) {
		const tenant = this.getTenant() || '';
		this.HttpServices.post(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/uniquevisitor/${tenant}`,
			options,
			callbackSuccess,
			callbackError
		);
	}

	getPageViews(
		options = {
			fromDate: '',
			toDate: '',
			offset: 0,
			limit: 20,
			orderBy: [{metric: '', side: 'desc'}],
		},
		callbackSuccess,
		callbackError
	) {
		const tenant = this.getTenant() || '';
		this.HttpServices.post(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/${tenant}`,
			options,
			callbackSuccess,
			callbackError
		);
	}
}

export class WebAnalyticsConfigServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} else {
			this.ANALYTICS_BO_API_URL = `${window.BEYABLE_env.ANALYTICS_BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getAccountConfig(callbackSuccess, callbackError) {
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/configuration/${this.accountId}`,
			callbackSuccess,
			callbackError
		);
	}

	createAccountConfig(accountApiKey, callbackSuccess, callbackError) {
		this.HttpServices.put(
			`${this.ANALYTICS_BO_API_URL}webanalytics/configuration/${this.accountId}`,
			{apiKey: accountApiKey},
			callbackSuccess,
			callbackError
		);
	}
	changeAccountConfig(config, callbackSuccess, callbackError) {
		this.HttpServices.put(
			`${this.ANALYTICS_BO_API_URL}webanalytics/configuration/${this.accountId}`,
			config,
			callbackSuccess,
			callbackError
		);
	}
	saveCustomDimensionConfiguration(config, callbackSuccess, callbackError) {
		this.HttpServices.put(
			`${this.ANALYTICS_BO_API_URL}webanalytics/configuration-custom-dimension/${this.accountId}`,
			config,
			callbackSuccess,
			callbackError
		);
	}
	getCustomDimensionConfiguration(tenant, callbackSuccess, callbackError) {
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/configuration-custom-dimension/${this.accountId}?tenant=${tenant}`,
			callbackSuccess,
			callbackError
		);
	}
}


export class WebAnalyticsDashboardSetupServices {
	constructor($http, accountId, userId) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} else {
			this.ANALYTICS_BO_API_URL = `${window.BEYABLE_env.ANALYTICS_BO_API_URL}`;
		}
		this.accountId = accountId;
		this.userId = userId;
	}

	getStorageSetup(dashboardId) {
		const item = localStorage.getItem(`webanalytics.dashboardsetup.${dashboardId}`);
		const result = item && JSON.parse(item);
		return result;
	}

	updateStorageSetup(
		dashboardId,
		data
	) {
		localStorage.setItem(`webanalytics.dashboardsetup.${dashboardId}`, JSON.stringify(data));
	}

	getOneSetup(
		dashboardId,
		setupId,
		callbackSuccess,
		callbackError
	) {
		return this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/dashboardsetup/${this.accountId}/user/${this.userId}/dashboard/${dashboardId}/setup/${setupId}`,
			callbackSuccess,
			callbackError
		);
	}

	getAllSetup(
		dashboardId,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/dashboardsetup/${this.accountId}/user/${this.userId}/dashboard/${dashboardId}`,
			callbackSuccess,
			callbackError
		);
	}

	deleteOneSetup(
		dashboardId,
		setupId,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.delete(
			`${this.ANALYTICS_BO_API_URL}webanalytics/dashboardsetup/${this.accountId}/user/${this.userId}/dashboard/${dashboardId}/setup/${setupId}`,
			callbackSuccess,
			callbackError
		);
	}

	deleteAllSetup(
		dashboardId,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.delete(
			`${this.ANALYTICS_BO_API_URL}webanalytics/dashboardsetup/${this.accountId}/user/${this.userId}/dashboard/${dashboardId}`,
			callbackSuccess,
			callbackError
		);
	}

	createSetup(
		dashboardId,
		data,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.post(
			`${this.ANALYTICS_BO_API_URL}webanalytics/dashboardsetup/${this.accountId}/user/${this.userId}/dashboard/${dashboardId}`,
			data,
			callbackSuccess,
			callbackError
		);
	}

	editSetup(
		dashboardId,
		setupId,
		data,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.put(
			`${this.ANALYTICS_BO_API_URL}webanalytics/dashboardsetup/${this.accountId}/user/${this.userId}/dashboard/${dashboardId}/setup/${setupId}`,
			data,
			callbackSuccess,
			callbackError
		);
	}
}

function transformUtmFilter(utmDimensionsSelected) {
	function getUtmFilter (array,prevSentence){
		let newSentence = '';
		array && array.map(element => {
			if(element.name !== ''){
				newSentence += prevSentence + element.label;
			}
		});
		return newSentence; 
	}
	let utmSource = getUtmFilter(utmDimensionsSelected.source ,'&utmSource=');
	let utmCampaign = getUtmFilter(utmDimensionsSelected.campaign ,'&utmCampaign=');
	let utmContent = getUtmFilter(utmDimensionsSelected.content ,'&utmContent=');
	let utmMedium = getUtmFilter(utmDimensionsSelected.medium ,'&utmMedium=');
	
	return {
		utmSource,
		utmCampaign,
		utmContent,
		utmMedium
	};
}
function formatToUriComponent(elementToFormat) {

	if(!elementToFormat){
		return; 
	}
	const trimElement = elementToFormat.trim();
	return encodeURIComponent(trimElement);
}
function transformToStrings(elementToFormat){
	if(!elementToFormat){
		return `&trafficSource=${encodeURIComponent('All')}`;
	}
	const stringReturn = elementToFormat.map(element => {
		return `&trafficSource=${encodeURIComponent(element)}`;
	});

	return stringReturn.join('');
}
function transformDevicesToStrings(elementToFormat){
	if(!elementToFormat){
		return `&devices=${encodeURIComponent('All')}`;
	}
	const stringReturn = elementToFormat.map(element => {
		return `&devices=${encodeURIComponent(element)}`;
	});

	return stringReturn.join('');
}
export class WebAnalyticsPageServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} else {
			this.ANALYTICS_BO_API_URL = window.BEYABLE_env.ANALYTICS_BO_API_URL.endsWith('/') ? `${window.BEYABLE_env.ANALYTICS_BO_API_URL}` : `${window.BEYABLE_env.ANALYTICS_BO_API_URL}/`;
		}
		this.accountId = accountId;
	}

	getBoardMetrics(
		devices,
		fromTo,
		tenant,
		aggregate,
		trafficSource,
		offset,
		limit,
		orderBy,
		orderByDesc,
		forTag,
		utmDimensionsSelected,
		excludeFilter,
		callbackSuccess,
		callbackError
	) {
		const {fromDate, toDate} = fromTo;
		const FortagEncoded = forTag ? encodeURI(forTag) : null;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let forTagKeyValue = '';
		if (forTag) {
			forTagKeyValue = `&for=${formatToUriComponent(forTag)}`;
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let filterExclude = '';
		if(excludeFilter && forTag){
			filterExclude = `&forExclude=${excludeFilter}`;
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metrics?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}&aggregate=${aggregate}&offset=${offset}&limit=${limit}&orderBy=${formatToUriComponent(orderBy)}&orderByDesc=${orderByDesc}`
			+ forTagKeyValue + trafficSourceKeyValue + utmSource+  utmCampaign + utmContent + utmMedium + device + filterExclude,
			callbackSuccess,
			callbackError
		);
	}
	getBoardMetricsCustomDimensions(
		devices,
		fromTo,
		tenant,
		aggregate,
		trafficSource,
		offset,
		limit,
		orderBy,
		orderByDesc,
		forTag,
		utmDimensionsSelected,
		excludeFilter,
		for2Selector,
		for2SelectorIsExactMatch ,
		for2SelectorExclude ,
		callbackSuccess,
		callbackError
	) {
		const {fromDate, toDate} = fromTo;
		const FortagEncoded = forTag ? encodeURI(forTag) : null;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let forTagKeyValue = '';
		if (forTag) {
			forTagKeyValue = `&for=${formatToUriComponent(forTag)}`;
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let for2SelectorKey = '';
		if(for2Selector){
			for2SelectorKey = `&for2=${for2Selector}`;
		}
		let for2SelectorIsExactMatchKey = '';
		if(for2Selector){
			for2SelectorIsExactMatchKey = `&for2IsExactMatch=${for2SelectorIsExactMatch}`;
		}
		let for2SelectorExcludeKey = '';
		if(for2Selector){
			for2SelectorExcludeKey = `&for2Exclude=${for2SelectorExclude}`;
		}
		 
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metrics?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}&aggregate=${aggregate}&offset=${offset}&limit=${limit}&orderBy=${formatToUriComponent(orderBy)}&orderByDesc=${orderByDesc}&forIsexactmatch=true`
			+ forTagKeyValue + trafficSourceKeyValue + utmSource+  utmCampaign + utmContent + utmMedium + device  + for2SelectorKey + for2SelectorIsExactMatchKey + for2SelectorExcludeKey ,
			callbackSuccess,
			callbackError
		);
	}

	getPageMetricsOverTimeWithFilterPamaters(
		devices,
		metrics,
		tenant,
		fromTo,
		granularity,
		aggregate,
		forTag,
		isExact,
		trafficSource,
		utmDimensionsSelected,
		excludeFilter,
		callbackSuccess,
		callbackError
	) {
		const {fromDate, toDate} = fromTo;
		const forTagEncoded = formatToUriComponent(forTag);
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let filterExclude = '';
		if(excludeFilter && !isExact && forTag){
			filterExclude = `&forExclude=${excludeFilter}`;
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metricovertime/${metrics}?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&granularity=${granularity}&forIsexactmatch=${isExact}&aggregate=${aggregate}&for=${forTagEncoded}&tenant=${tenant}`
			+ trafficSourceKeyValue + utmSource + utmCampaign
			+ utmContent +  utmMedium + device + filterExclude,
			callbackSuccess,
			callbackError
		);
	}

	getPageMetricsOverTime(
		devices,
		metrics,
		tenant,
		fromTo,
		granularity,
		aggregate,
		trafficSource,
		utmDimensionsSelected,
		excludeFilter,
		callbackSuccess,
		callbackError
	) {
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let filterExclude = '';

		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metricovertime/${metrics}?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&granularity=${granularity}&aggregate=${aggregate}&tenant=${tenant}`
			+ trafficSourceKeyValue + utmSource + utmCampaign + utmContent + utmMedium + device + filterExclude,
			callbackSuccess,
			callbackError
		);
	}
	getPageMetricsOverTimeCustomTag(
		devices,
		metrics,
		tenant,
		fromTo,
		granularity,
		aggregate,
		trafficSource,
		utmDimensionsSelected,
		excludeFilter,
		selectedCustomTag,
		for2Selector,
		for2SelectorIsExactMatch ,
		for2SelectorExclude ,
		callbackSuccess,
		callbackError
	) {
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let for2SelectorKey = '';
		if(for2Selector){
			for2SelectorKey = `&for2=${for2Selector}`;
		}
		let for2SelectorIsExactMatchKey = '';
		if(for2Selector){
			for2SelectorIsExactMatchKey = `&for2IsExactMatch=${for2SelectorIsExactMatch}`;
		}
		let for2SelectorExcludeKey = '';
		if(for2Selector){
			for2SelectorExcludeKey = `&for2Exclude=${for2SelectorExclude}`;
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metricovertime/${metrics}?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&granularity=${granularity}&aggregate=${aggregate}&tenant=${tenant}&forIsexactmatch=true&for=${selectedCustomTag}`
			+ trafficSourceKeyValue + utmSource + utmCampaign + utmContent + utmMedium + device  +for2SelectorKey + for2SelectorIsExactMatchKey + for2SelectorExcludeKey ,
			callbackSuccess,
			callbackError
		);
	}

	getPageMetricsSumsOverTime(
		devices,
		tenant,
		fromTo,
		aggregate,
		searchValue,
		filterUrl,
		trafficSource,
		utmDimensionsSelected,
		excludeFilter,
		callbackSuccess,
		callbackError
	) {
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		if (filterUrl || searchValue.length !== 0) {
			const isExact = filterUrl ? true : false;
			const filters = filterUrl ? filterUrl : searchValue;
			let filterExclude = '';
			if(excludeFilter && !isExact){
				filterExclude = `&forExclude=${excludeFilter}`;
			}
			const forTagEncoded = formatToUriComponent(filters);
			this.HttpServices.get(
				`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metricssumsovertime?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&forIsexactmatch=${isExact}&aggregate=${aggregate}&for=${forTagEncoded}&tenant=${tenant}`
				+ trafficSourceKeyValue + utmSource + utmCampaign +
				utmContent + utmMedium + device +filterExclude,
				callbackSuccess,
				callbackError
			);
		} else {
			this.HttpServices.get(
				`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metricssumsovertime?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&aggregate=${aggregate}&tenant=${tenant}`
				+ trafficSourceKeyValue + utmSource + utmCampaign +
				utmContent + utmMedium + device,
				callbackSuccess,
				callbackError
			);
		}
	}
	getPageMetricsSumsOverTimeCustomTag(
		devices,
		tenant,
		fromTo,
		aggregate,
		searchValue,
		filterUrl,
		trafficSource,
		utmDimensionsSelected,
		excludeFilter,
		selectedCustomTag,
		for2Selector,
		for2SelectorIsExactMatch ,
		for2SelectorExclude ,
		callbackSuccess,
		callbackError
	) {
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let for2SelectorKey = '';
		if(for2Selector){
			for2SelectorKey = `&for2=${for2Selector}`;
		}
		let for2SelectorIsExactMatchKey = '';
		if(for2Selector){
			for2SelectorIsExactMatchKey = `&for2IsExactMatch=${for2SelectorIsExactMatch}`;
		}
		let for2SelectorExcludeKey = '';
		if(for2Selector){
			for2SelectorExcludeKey = `&for2Exclude=${for2SelectorExclude}`;
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metricssumsovertime?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&aggregate=${aggregate}&tenant=${tenant}&forIsexactmatch=true&for=${selectedCustomTag}`
				+ trafficSourceKeyValue + utmSource + utmCampaign +
				utmContent + utmMedium + device +for2SelectorKey + for2SelectorIsExactMatchKey + for2SelectorExcludeKey ,
			callbackSuccess,
			callbackError
		);
	}

	getPagesCsv(
		fromDate,
		toDate,
		tenant,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/page/metricsoutput?from=${moment(fromDate).format('YYYY-MM-DD')}&csv=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}`,
			callbackSuccess,
			callbackError,
			{Accept: 'text/csv'}
		);
	}
}

export class WebAnalyticsInteractionServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} else {			
			this.ANALYTICS_BO_API_URL = window.BEYABLE_env.ANALYTICS_BO_API_URL.endsWith('/') ? `${window.BEYABLE_env.ANALYTICS_BO_API_URL}` : `${window.BEYABLE_env.ANALYTICS_BO_API_URL}/`;
		}
		this.accountId = accountId;
	}
	getHasCategories(tenant, callbackSuccess, callbackError) {
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}/webanalytics/${this.accountId}
			/interaction/hasCategories?tenant=${tenant}`,
			callbackSuccess,
			callbackError
		);
	}

	getCategories(
		devices,
		fromTo,
		tenant,
		trafficSource,
		filter1,
		filter1Value,
		orderBy,
		orderByDesc,
		limit,
		offset,
		utmDimensionsSelected,
		categorySearchValue,
		excludeFilter,
		isExactMatch,
		callbackSuccess,
		callbackError
	) {
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let filter1KeyValue = '';
		if (filter1) {
			filter1KeyValue = `&filter1=${formatToUriComponent(filter1)}`;
		}
		let filter1ValueKeyValue = '';
		if (filter1Value) {
			filter1ValueKeyValue = `&filter1Value=${formatToUriComponent(filter1Value)}`;
		}
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let categorySearchValueKeyValue = '';
		if (categorySearchValue) {
			categorySearchValueKeyValue = `&category=${formatToUriComponent(categorySearchValue)}`;
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let filterExclude = '';
		if(excludeFilter){
			filterExclude = `&categoryExclude=${excludeFilter}`;
		}
		let isExactMatchKeyValue = '&filter1IsExactMatch=false';
		if (isExactMatch) {
			isExactMatchKeyValue = `&filter1IsExactMatch=${isExactMatch}`;
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metrics?from=${moment(fromDate).format(
				'YYYY-MM-DD')}&to=${moment(toDate).format(
				'YYYY-MM-DD')}&tenant=${tenant}&limit=${limit}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&offset=${offset}`
			+ filter1KeyValue +	filter1ValueKeyValue +trafficSourceKeyValue
			+ categorySearchValueKeyValue + utmSource + utmCampaign + utmContent +utmMedium + device + filterExclude+isExactMatchKeyValue,
			callbackSuccess,
			callbackError
		);
	}

	getBoardMetrics(
		devices,
		fromTo,
		tenant,
		filter1,
		filter1Value,
		isExactMatch,
		filter2,
		filter2ExactValue,
		trafficSource,
		orderBy,
		orderByDesc,
		limit,
		offset,
		selectedCategory,
		unCategorized,
		utmDimensionsSelected,
		excludeFilter,
		categoryIsExactMatch,

		callbackSuccess,
		callbackError
	) {

	
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium} = transformUtmFilter(utmDimensionsSelected);
		// const FortagEncoded = forTag ? encodeURI(forTag) : null;
		let filter1KeyValue = '';
		if (filter1) {
			filter1KeyValue = `&filter1=${formatToUriComponent(filter1)}`;
		}
		let filter1ValueKeyValue = '';
		if (filter1Value) {
			filter1ValueKeyValue = `&filter1Value=${formatToUriComponent(filter1Value)}`;
		}
		let filter2KeyValue = '';
		if (filter2) {
			filter2KeyValue = `&filter2=${formatToUriComponent(filter2)}`;
		}
		let filter2ExactValueKeyValue = '';
		if (filter2ExactValue) {
			filter2ExactValueKeyValue = `&filter2Value=${formatToUriComponent(filter2ExactValue)}`;
		}
		let filterExclude = '';
		if(excludeFilter){
			if(filter2ExactValue){
				filterExclude = `&filter2Exclude=${excludeFilter}`;
			}
			if(!filter2ExactValue){
				filterExclude = `&filter1Exclude=${excludeFilter}`;
			}
		}
		let isExactMatchKeyValue = '&filter1IsExactMatch=false';
		if (isExactMatch && filter2 ) {
			isExactMatchKeyValue = `&filter1IsExactMatch=${isExactMatch}`;
		}
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let categoryKeyValue = '';
		if (selectedCategory) {
			categoryKeyValue = `&category=${formatToUriComponent(selectedCategory)}`;
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let categoryIsExactMatchFilter = '';
		if(categoryIsExactMatch){
			categoryIsExactMatchFilter = `&categoryIsExactMatch=${true}`;
		}
		let shouldGroupInteractionValuesFilter =`&shouldGroupInteractionValues=${true}`;
		
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metrics?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}&limit=${limit}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&offset=${offset}${isExactMatchKeyValue}&uncategorized=${unCategorized}` + filter1KeyValue + shouldGroupInteractionValuesFilter + filter1ValueKeyValue  +filter2KeyValue + filter2ExactValueKeyValue + trafficSourceKeyValue +categoryKeyValue
			+ utmSource + utmCampaign + utmContent + utmMedium + device + filterExclude + categoryIsExactMatchFilter,
			callbackSuccess,
			callbackError
		);
	}
	getBoardMetricsHasValues(
		devices,
		fromTo,
		tenant,
		filter1,
		filter1Value,
		isExactMatch,
		filter2,
		filter2ExactValue,
		trafficSource,
		orderBy,
		orderByDesc,
		limit,
		offset,
		selectedCategory,
		unCategorized,
		utmDimensionsSelected,
		excludeFilter,
		categoryIsExactMatch,
		callbackSuccess,
		callbackError
	) {
	
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium} = transformUtmFilter(utmDimensionsSelected);
		let filter1KeyValue = '';
		if (filter1) {
			filter1KeyValue = `&filter1=${formatToUriComponent(filter1)}`;
		}
		let filter1ValueKeyValue = '';

		if (filter1Value) {
			filter1ValueKeyValue = `&filter1Value=${formatToUriComponent(filter1Value)}`;
		}

		let filterExclude = '';
		if(excludeFilter){
			if(filter2ExactValue){
				filterExclude = `&filter2Exclude=${excludeFilter}`;
			}
			if(!filter2ExactValue){
				filterExclude = `&filter1Exclude=${excludeFilter}`;
			}
		}

		let filter2ExactValueKeyValue = '';
		if (filter2ExactValue) {
			filter2ExactValueKeyValue = `&filter2Value=${formatToUriComponent(filter2ExactValue)}`;
		}
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let categoryKeyValue = '';
		if (selectedCategory) {
			categoryKeyValue = `&category=${formatToUriComponent(selectedCategory)}`;
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let categoryIsExactMatchFilter = '';
		if(categoryIsExactMatch){
			categoryIsExactMatchFilter = `&categoryIsExactMatch=${true}`;
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metrics?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}&limit=${limit}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&offset=${offset}&shouldGroupInteractionValues=false&filter1IsExactMatch=true&categoryIsExactMatch=true` + filter1KeyValue + filter1ValueKeyValue + trafficSourceKeyValue +categoryKeyValue + categoryIsExactMatchFilter +
			filter2ExactValueKeyValue
			+ utmSource + utmCampaign + utmContent + utmMedium + device + filterExclude ,
			callbackSuccess,
			callbackError
		);
	}
	getBoardMetricsHasValuesPages(
		devices,
		fromTo,
		tenant,
		filter1,
		filter1Value,
		isExactMatch,
		filter2,
		filter2ExactValue,
		trafficSource,
		orderBy,
		orderByDesc,
		limit,
		offset,
		selectedCategory,
		unCategorized,
		utmDimensionsSelected,
		excludeFilter,
		categoryIsExactMatch,
		callbackSuccess,
		callbackError
	) {
	
		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium} = transformUtmFilter(utmDimensionsSelected);
		let filter1KeyValue = '';
		if (filter1) {
			filter1KeyValue = `&filter1=${formatToUriComponent(filter1)}`;
		}
		let filter1ValueKeyValue = '';

		if (filter1Value) {
			filter1ValueKeyValue = `&filter1Value=${formatToUriComponent(filter1Value)}`;
		}
		let isExactMatchKeyValue = '';
		if (isExactMatch) {
			isExactMatchKeyValue = `&filter1IsExactMatch=${isExactMatch}`;
		}
		let filter2KeyValue = '';
		if (filter2) {
			filter2KeyValue = `&filter2=${formatToUriComponent(filter2)}`;
		}
		let filter2ExactValueKeyValue = '';
		if (filter2ExactValue) {
			filter2ExactValueKeyValue = `&filter2Value=${formatToUriComponent(filter2ExactValue)}`;
		}
		let filterExclude = '';
		if(excludeFilter){
			if(filter2ExactValue){
				filterExclude = `&filter2Exclude=${excludeFilter}`;
			}
			if(!filter2ExactValue){
				filterExclude = `&filter1Exclude=${excludeFilter}`;
			}
		}

		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let categoryKeyValue = '';
		if (selectedCategory) {
			categoryKeyValue = `&category=${formatToUriComponent(selectedCategory)}`;
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let categoryIsExactMatchFilter = '';
		if(categoryIsExactMatch){
			categoryIsExactMatchFilter = `&categoryIsExactMatch=${true}`;
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metrics?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}&limit=${limit}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&offset=${offset}&shouldGroupInteractionValues=false&categoryIsExactMatch=true` + filter1KeyValue + isExactMatchKeyValue +
			filter2KeyValue + filter2ExactValueKeyValue +
			categoryIsExactMatchFilter + filter1ValueKeyValue + trafficSourceKeyValue +categoryKeyValue
			+ utmSource + utmCampaign + utmContent + utmMedium + device + filterExclude ,
			callbackSuccess,
			callbackError
		);
	}

	getBoardMetricsCategories(
		devices,
		fromTo,
		tenant,
		filter1,
		filter1Value,
		isExactMatch,
		filter2,
		filter2ExactValue,
		trafficSource,
		orderBy,
		orderByDesc,
		limit,
		offset,
		callbackSuccess,
		callbackError
	) {
		
		const { fromDate, toDate } = fromTo;
		// const FortagEncoded = forTag ? encodeURI(forTag) : null;
		let filter1KeyValue = '';
		if (filter1) {
			filter1KeyValue = `&filter1=${formatToUriComponent(filter1)}`;
		}
		let filter1ValueKeyValue = '';
		if (filter1Value) {
			filter1ValueKeyValue = `&filter1Value=${formatToUriComponent(filter1Value)}`;
		}
		let filter2KeyValue = '';
		if (filter2) {
			filter2KeyValue = `&filter2=${formatToUriComponent(filter2)}`;
		}
		let filter2ExactValueKeyValue = '';
		if (filter2ExactValue) {
			filter2ExactValueKeyValue = `&filter2Value=${formatToUriComponent(filter2ExactValue)}`;
		}
		let isExactMatchKeyValue = '';
		if (isExactMatch) {
			isExactMatchKeyValue = `&filter1IsExactMatch=${isExactMatch}`;
		}
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}

		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metrics?from=${moment(fromDate).format(
				'YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}&limit=${limit}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&offset=${offset}`
			+ filter1KeyValue +isExactMatchKeyValue
			+ filter1ValueKeyValue
			+ filter2KeyValue
			+ filter2ExactValueKeyValue
			+ trafficSourceKeyValue + device,
			callbackSuccess,
			callbackError
		);
	}

	getPageMetricsOverTime(
		devices,
		metrics,
		tenant,
		fromTo,
		granularity,
		filter1,
		filter1Value,
		isExactMatch,
		filter2,
		filter2Value,
		filter2IsExactMatch,
		trafficSource,
		utmDimensionsSelected,
		selectedCategory,
		unCategorized,
		excludeFilter,
		categoryIsExactMatch,
		shouldGroupInteractionValues,
		callbackSuccess,
		callbackError
	) {
		
		const {fromDate, toDate} = fromTo;
		// const FortagEncoded = encodeURI(forTag);
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let filter1KeyValue = '';
		if (filter1) {
			filter1KeyValue = `&filter1=${formatToUriComponent(filter1)}`;
		}
		let filter1ValueKeyValue = '';
		if (filter1Value) {
			filter1ValueKeyValue = `&filter1Value=${formatToUriComponent(filter1Value)}`;
		}
		let filter2KeyValue = '';
		if (filter2) {
			filter2KeyValue = `&filter2=${formatToUriComponent(filter2)}`;
		}
		let filter2ValueKeyValue = '';
		if (filter2Value) {
			filter2ValueKeyValue = `&filter2Value=${formatToUriComponent(filter2Value)}`;
		}
		let isExactMatchKeyValue = '&filter1IsExactMatch=false';
		if (isExactMatch && filter2 ) {
			isExactMatchKeyValue = `&filter1IsExactMatch=${isExactMatch}`;
		}
		let isExactMatchFilter2KeyValue = '';
		if (filter2IsExactMatch) {
			isExactMatchFilter2KeyValue = `&filter2IsExactMatch=${filter2IsExactMatch}`;
		}
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let categoryKeyValue = '';
		if (selectedCategory) {
			categoryKeyValue = `&category=${formatToUriComponent(selectedCategory)}`;
		}
		let filterExclude = '';
		if(excludeFilter){
			if(filter2Value){
				filterExclude = `&filter2Exclude=${excludeFilter}`;
			}
			if(!filter2Value){
				filterExclude = `&filter1Exclude=${excludeFilter}`;
			}
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let categoryIsExactMatchFilter = '';
		if(categoryIsExactMatch){
			categoryIsExactMatchFilter = `&categoryIsExactMatch=${true}`;
		}
		let shouldGroupInteractionValuesFilter = '';
		if(shouldGroupInteractionValues){
			shouldGroupInteractionValuesFilter ='&shouldGroupInteractionValues=true';
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metricovertime/${metrics}?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&granularity=${granularity}&tenant=${tenant}&uncategorized=${unCategorized}` +shouldGroupInteractionValuesFilter
			+ filter1KeyValue + filter1ValueKeyValue + filter2KeyValue + filter2ValueKeyValue + isExactMatchKeyValue + isExactMatchFilter2KeyValue + trafficSourceKeyValue+ categoryKeyValue + utmSource + utmCampaign + utmContent + utmMedium + device+ filterExclude + categoryIsExactMatchFilter,
			callbackSuccess,
			callbackError
		);
	}

	getInteractionMetricsSumsOverTime(
		devices,
		tenant,
		fromTo,
		filter1,
		filter1Value,
		isExactMatch,
		filter2,
		filter2Value,
		filter2IsExactMatch,
		trafficSource,
		selectedCategory,
		uncategorized,
		utmDimensionsSelected,
		excludeFilter,
		categoryIsExactMatch,
		shouldGroupInteractionValues,
		callbackSuccess,
		callbackError
	) {

		const { fromDate, toDate } = fromTo;
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium } = transformUtmFilter(utmDimensionsSelected);
		let filter1KeyValue = '';
		if (filter1) {
			filter1KeyValue = `&filter1=${formatToUriComponent(filter1)}`;
		}

		let filter1ValueKeyValue = '';
		if (filter1Value) {
			filter1ValueKeyValue = `&filter1Value=${formatToUriComponent(filter1Value)}`;
		}
		let filter2KeyValue = '';
		if (filter2) {
			filter2KeyValue = `&filter2=${formatToUriComponent(filter2)}`;
		}
		let filter2ValueKeyValue = '';
		if (filter2Value) {
			filter2ValueKeyValue = `&filter2Value=${formatToUriComponent(filter2Value)}`;
		}
		let isExactMatchKeyValue = '&filter1IsExactMatch=false';
		if (isExactMatch && filter2 ) {
			isExactMatchKeyValue = `&filter1IsExactMatch=${isExactMatch}`;
		}
		let isExactMatchFilter2KeyValue = '';
		if (filter2IsExactMatch) {
			isExactMatchFilter2KeyValue = `&filter2IsExactMatch=${filter2IsExactMatch}`;
		}
		let trafficSourceKeyValue = '';
		if (trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(trafficSource);
		}
		let categoryKeyValue = '';
		if (selectedCategory) {
			categoryKeyValue = `&category=${formatToUriComponent(selectedCategory)}`;
		}
		let device = '';
		if(devices.length > 0){
			device = transformDevicesToStrings(devices);
		}
		let filterExclude = '';
		if(excludeFilter){
			if(filter2Value){
				filterExclude = `&filter2Exclude=${excludeFilter}`;
			}
			if(!filter2Value){
				filterExclude = `&filter1Exclude=${excludeFilter}`;
			}
		}
		let categoryIsExactMatchFilter = '';
		if(categoryIsExactMatch){
			categoryIsExactMatchFilter = `&categoryIsExactMatch=${true}`;
		}
		let shouldGroupInteractionValuesFilter = '';
		if(shouldGroupInteractionValues){
			shouldGroupInteractionValuesFilter ='&shouldGroupInteractionValues=true';
		}
		// const FortagEncoded = encodeURI(forTag);
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metricssumsovertime?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}&uncategorized=${uncategorized}`+
			shouldGroupInteractionValuesFilter + filter1KeyValue + filter1ValueKeyValue + filter2KeyValue + filter2ValueKeyValue + isExactMatchKeyValue + isExactMatchFilter2KeyValue
			+ trafficSourceKeyValue + categoryKeyValue + utmSource + utmCampaign + utmContent + utmMedium + device +filterExclude +categoryIsExactMatchFilter ,
			callbackSuccess,
			callbackError
		);
	}

	getInteractionsCsv(
		fromDate,
		toDate,
		tenant,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/interaction/metricsoutput?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&tenant=${tenant}`,
			callbackSuccess,
			callbackError,
			{Accept: 'text/csv'}
		);
	}

}

export class WebAnalyticsOverviewServices {
	constructor($http, accountId, getTenant) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} else {
			this.ANALYTICS_BO_API_URL = `${window.BEYABLE_env.ANALYTICS_BO_API_URL}`;
		}
		this.accountId = accountId;
		this.getTenant = getTenant;
	}

	getMetricsSums(options, callbackSuccess, callbackError) {
		let tenant = '';
		if (this.getTenant() !== '') {
			tenant = `&tenant=${this.getTenant()}`;
		}
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium} = transformUtmFilter(options.utmDimensionsSelected);
		let dimension = '';
		if (options.dimension !== '') {
			dimension = `&dimension=${encodeURIComponent(options.dimension)}`;
		}
		let dimensionId = '';
		if (options.dimensionId !== undefined && options.dimensionId !== '') {
			dimensionId = `&dimensionId=${encodeURIComponent(options.dimensionId)}`;
		}
		let trafficSource = '';
		if (options.trafficSource !== 'All' && options.trafficSource !== '') {
			trafficSource = `&trafficSource=${encodeURIComponent(options.trafficSource)}`;
		}
		let trafficSourceKeyValue = '';
		if (options.trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(options.trafficSource);
		}
		let metrics = options.metrics;
		let metricsArray = metrics.map(metric => `&metrics=${metric}`).join('');
		let dimensionLabelContains = '';
		let device = '';
		if(options.device.length > 0){
			device = transformDevicesToStrings(options.device);
		}
		
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/ecommerce/metricssums/?from=${moment(options.fromDate).format('YYYY-MM-DD')}&to=${moment(options.toDate).format('YYYY-MM-DD')}${tenant}${metricsArray}
			${dimension}${dimensionId}${trafficSourceKeyValue}${device}`+ utmSource + utmCampaign + utmContent + utmMedium,
			callbackSuccess,
			callbackError
		);
	}


	getOverviewMetricsOverTime(
		options,
		callbackSuccess,
		callbackError
	) {
		let tenant = '';
		if (this.getTenant() !== '') {
			tenant = `&tenant=${this.getTenant()}`;
		}
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium} = transformUtmFilter(options.utmDimensionsSelected);
		let granularity = options.granularity;
		granularity = `&granularity=${granularity}`;

		let dimension = '';
		if (options.dimension !== '') {
			dimension = `&dimension=${encodeURIComponent(options.dimension)}`;
		}
		let dimensionId = '';
		if (options.dimensionId !== '') {
			dimensionId = `&dimensionId=${encodeURIComponent(options.dimensionId)}`;
		}
		let trafficSource = '';
		if (options.trafficSource !== 'All' && options.trafficSource !== '') {
			trafficSource = `&trafficSource=${encodeURIComponent(options.trafficSource)}`;
		}
		let trafficSourceKeyValue = '';
		if (options.trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(options.trafficSource);
		}
		let metrics = options.metrics;
		let metricsArray = metrics.map(metric => `&metrics=${metric}`).join('');
		let device = '';
		if(options.device.length > 0){
			device = transformDevicesToStrings(options.device);
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/ecommerce/metricsovertime/?from=${moment(options.fromDate).format('YYYY-MM-DD')}&to=${moment(options.toDate).format('YYYY-MM-DD')}${tenant}${metricsArray}
			${granularity}${dimension}${dimensionId}${trafficSourceKeyValue}${device}`+ utmSource + utmCampaign + utmContent + utmMedium,
			callbackSuccess,
			callbackError
		);
	}

	getDimensionsOverview(
		options,
		callbackSuccess, callbackError) {

		let tenant = '';
		if (this.getTenant() !== '') {
			tenant = `&tenant=${this.getTenant()}`;
		}

		let dimension = options.dimension;

		let dimensionId = '';
		// if (options.dimensionId !== '') {
		// 	dimensionId = `&dimensionId=${encodeURIComponent(options.dimensionId )}`;
		// }
		const { utmSource,
			utmCampaign,
			utmContent,
			utmMedium} = transformUtmFilter(options.utmDimensionsSelected);
		let trafficSource = '';
		if (options.trafficSource !== 'All' && options.trafficSource !== '') {
			trafficSource = `&trafficSource=${encodeURIComponent(options.trafficSource)}`;
		}
		let trafficSourceKeyValue = '';
		if (options.trafficSource.length !== 0) {
			trafficSourceKeyValue = transformToStrings(options.trafficSource);
		}
		let metrics = options.metrics;
		let metricsArray = metrics.map(metric => `&metrics=${metric}`).join('');

		let orderBy = '';

		if (options.orderBy !== '') {
			orderBy = `&orderBy=${options.orderBy}`;
		}

		let orderByDesc = '';
		if (options.orderByDesc !== '') {
			orderByDesc = `&orderByDesc=${options.orderByDesc}`;
		}
		let limit = '';
		if (options.limit && options.limit !== '') {
			limit = `&limit=${options.limit}`;
		}
		let offset = '';
		if (options.offset && options.offset !== '') {
			orderByDesc = `&offset=${options.offset}`;
		}
		let device = '';
		if(options.device.length > 0){
			device = transformDevicesToStrings(options.device);
		}
		this.HttpServices.get(
			`${this.ANALYTICS_BO_API_URL}webanalytics/${this.accountId}/ecommerce/dimension/${dimension}/metrics/?from=${moment(options.fromDate).format('YYYY-MM-DD')}&to=${moment(options.toDate).format('YYYY-MM-DD')}${tenant}${metricsArray}
			${dimensionId}${trafficSourceKeyValue}${orderBy}${orderByDesc}${limit}${offset}${device}`+ utmSource + utmCampaign + utmContent + utmMedium,
			callbackSuccess,
			callbackError
		);
	}
}