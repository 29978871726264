import HttpServices from './HttpServices';
import axios from 'axios';

export default class ProductRankingServices {

	constructor($http, authServices, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
		this.accountId = accountId;
	}
	getProductCollation(category, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-collation/?context=${category}`,
			callbackSuccess, callbackError);
	}
	getRankingList(valueInSearchBar,dataFiltered,descSort,paginationOptions, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-ranking/?offset=${paginationOptions.offset}
		&limit=${paginationOptions.limit}&searchTerm=${valueInSearchBar}&sortBy=${dataFiltered}&descSort=${descSort}`,
		callbackSuccess, callbackError);
	}

	createRanking(ranking, callbackSuccess, callbackError) {
		this.HttpServices.post(
			`${this.BO_API_URL}${this.accountId}/product-ranking`,
			ranking,
			callbackSuccess,
			callbackError
		);
	}
	getRankingById(rankingId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-ranking/${rankingId}`, callbackSuccess, callbackError);
	}
	deleteRanking(rankingId, callbackSuccess, callbackError) {
		this.HttpServices.delete(
			`${this.BO_API_URL}${this.accountId}/product-ranking/${rankingId}`,
			callbackSuccess,
			callbackError
		);
	}
	putRankingById(ranking, rankingId, callbackSuccess, callbackError) {
		this.HttpServices.put(
			`${this.BO_API_URL}${this.accountId}/product-ranking/${rankingId}`,
			ranking,
			callbackSuccess,
			callbackError
		);
	}
	getProductListPreview(options, paginationOptions, ranking, callbackSuccess, callbackError) {
		// ranking.context = 'category'
		let previewTenant = '';
		if (options.hasOwnProperty('previewTenant')) {
			previewTenant = `&previewTenant=${options.previewTenant}`;
		}
		let categoryName = '';
		if (options.hasOwnProperty('categoryName')) {
			if (options.categoryName === '') {
				return;
			}
			categoryName = `?categoryName=${encodeURIComponent(options.categoryName)}`;
		}
		let categoryType = '';
		if (options.hasOwnProperty('categoryType')) {
			if (options.categoryType === '') {
				return;
			}
			const sign = categoryName !== '' ? '&' : '?';
			categoryType = `${sign}categoryType=${encodeURIComponent(options.categoryType)}`;
		}
		let offset = '';
		if (!options.hasOwnProperty('categoryName') && !options.hasOwnProperty('categoryType')) {
			const sign = '?';
			offset = `${sign}ppoffset=${paginationOptions.ppoffset}`;
		} else {
			const sign = '&';
			offset = `${sign}ppoffset=${paginationOptions.ppoffset}`;
		}
		let deviceType = '';
		if (options.hasOwnProperty('deviceType')) {

			deviceType = `${'&'}deviceType=${encodeURIComponent(options.deviceType)}`;
		}
		this.HttpServices.post(
			`${this.BO_API_URL}${this.accountId}/product-ranking-preview/${categoryName}
			${categoryType}
			${offset}
			&pplimit=${paginationOptions.pplimit}
			&poffset=${paginationOptions.poffset}
			&plimit=${paginationOptions.plimit}
			&dpoffset=${paginationOptions.dpoffset}
			&dplimit=${paginationOptions.dplimit}` + previewTenant + deviceType,
			{
				...ranking
			},
			callbackSuccess,
			callbackError
		);
	}

}
