import React, {} from 'react';
import RouterItems from '../../Menu/RouterItems';
import {getFinalUrl} from '../../Menu/utils';
export default function RouterWrapper({
	children,
	props
}) {	


	let list = [
		{
			label : 'Campaigns',
			icon: 'email',
			activeUrls: ['/Dashboard/Home?tab=campaigncrm'],
			link : getFinalUrl('/Dashboard/Home?tab=campaigncrm',props)
		},
		{
			label : 'Email widgets',
			icon: 'widget',
			activeUrls: ['/EmailTemplate/Dashboard'],
			link : getFinalUrl('/EmailTemplate/Dashboard',props)
		},
	];


	return (
		<RouterItems
			title="Email remarketing"
			list={list}
			routes={<></>}
		>
			{children}
		</RouterItems>
	);
}
