import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { FlagFr, FlagUs, FlagEn, FlagSp, FlagGer, FlagEu } from "../../../Assets/svgr";
export const nFormat = (num, digits) => {
    if (isNaN(num)) return "";
    return num.toLocaleString(undefined, { maximumFractionDigits: digits })
  }

export const nFormatShort = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits || 1).replace(rx, "$1") + item.symbol : "0";
  }
  
export const toReadableDuration = (durationInSeconds) => {
  var duration = moment.duration(durationInSeconds, 'seconds')
  const hours = (h) => moment.duration(h, 'hours')
  const minutes = (m) => moment.duration(m, 'minutes')
  const seconds = (s) => moment.duration(s, 'seconds')
  const format = (f) => duration.format(f)

  if (duration >= hours(24)){ return ["many", "days"]
  } else if (duration >= hours(2)){ return [format('H:m'), "hours"]
  } else if (duration >= hours(1)){ return [format('H:m'), "hour"]
  } else if (duration >= minutes(2)) { return [format('m:s'), "minutes"]
  } else if (duration >= minutes(1)) { return [format('m:s'), "minute"]
  } else if (duration >= seconds(2)) { return [format('s'), "seconds"]
  } else { return [format('s'), "second"]
  }
}


const SimilarString = (query, references) => {

  const getBigrams = (string) =>{
    const s = string.toLowerCase();
    const v = new Array(s.length - 1)
    for (let i = 0; i < v.length; i++) {
      v[i] = s.slice(i, i + 2)      
    }
    return v
  }

  const stringSimilarity = (str1, str2) =>{
    if (str1.length > 0 && str2.length > 0){
      const pairs1 = getBigrams(str1)
      const pairs2 = getBigrams(str2)
      const union = pairs1.length + pairs2.length
      let hit_count = 0
      for (let i = 0; i < pairs1.length; i++) {
        const p1 = pairs1[i];
        for (let j = 0; j < pairs2.length; j++) {
          const p2 = pairs2[j];
          if (p1 == p2){
            hit_count++;
          }
        }
      }
      if (hit_count > 0)
        return ((2.0 * hit_count) / union)
    }
    return 0.0
  }

  let results = []
  for (let i = 0; i < references.length; i++) {
    const element = references[i];
    const relevance = stringSimilarity(query, element)
    const result = {element, relevance}
    results.push(result)
  }
  return results.filter(x => x.relevance > 0.1).sort((v1, v2) => v2.relevance - v1.relevance);
}

export const approximateBrowser = (browser) => {
  const noIcon = () => <></>
  const browsers = {
    Chrome: {icon: () => <i className="fab fa-chrome"></i>},
    Safari: {icon: () => <i className="fab fa-safari"></i>},
    Firefox: {icon: () => <i className="fab fa-firefox"></i>},
    Edge: {icon: () => <i className="fab fa-edge"></i>},
    Opera: {icon: () => <i className="fab fa-opera"></i>},
    Pinterest: {icon: () => <i className="fab fa-pinterest"></i>},
    Facebook: {icon: () => <i className="fab fa-facebook"></i>},
    IE: {icon: () => <i className="fab fa-internet-explorer"></i>},
    ["Internet Explorer"]: {icon: () => <i className="fab fa-internet-explorer"></i>},
  }
  const similars = SimilarString(browser, Object.keys(browsers))
  return similars.length > 0 ? browsers[similars[0].element]  : { None: {icon: noIcon} }
}


  export const approximateDevice = (device) => {
  const noIcon = () => <></>
  const devices = {
    Mobile:{icon: () => <i className="fas fa-mobile-alt"></i>},
    Desktop:{icon:()=> <i className="fas fa-desktop"></i>}
  }
  const similars = SimilarString(device, Object.keys(devices))
  return similars.length > 0 ? devices[similars[0].element]  : { None: {icon: noIcon} }
  }



  export const approximateCountry = (country) => {
    const noIcon = () => <></>
  const countries = {
    America:{icon: () => <FlagUs/>},
    Europe:{icon: () => <FlagEu/>},
  }
const similars = SimilarString(country.split('/')[0], Object.keys(countries))
return similars.length > 0 ? countries[similars[0].element]  : { None: {icon: noIcon} }

}
export const approximateLanguage = (language) => {
  const noIcon = () => <></>
  const languages = {
    Fr:{icon: () => <FlagFr/>},
    En:{icon: () => <FlagEn/>},
    Us:{icon: () => <FlagUs/>},
    Ger:{icon: () => <FlagGer/>},
    Sp:{icon: () => <FlagSp/>},
  }
  const similars = SimilarString(language.split('-')[1], Object.keys(languages))
  return similars.length > 0 ? languages[similars[0].element]  : { None: {icon: noIcon} }
}