import { PersoRuleType } from '../../../../Services/RecommendationRuleCompositionServices';

type RuleType = {
    label: string;
    value: string;
    description: string;
    id: (string | null);
    isPersoRule: boolean;
    PersoRuleType: (PersoRuleType | null);
}

export const listRulesByType: Array<RuleType> = [
    {
        label: 'Dynamic collection',
        value: 'DynamicCollection',
        description: 'Customized recommandation rule',
        id: 'e37e95ba-14e8-4079-8c9e-396cac8290cd',
        isPersoRule: false,
        PersoRuleType: null
    },
    {
        label: 'Viewed products history',
        value: PersoRuleType.RecentlyViewedProducts,
        description: 'Remind customers of products they recently viewed',
        id: null,
        isPersoRule: true,
        PersoRuleType: PersoRuleType.RecentlyViewedProducts
    },
    {
        label: 'Abandoned products history',
        value: PersoRuleType.AbandonedCartProducts,
        description: 'Remind customers of products they were interested in but did not buy',
        id: null,
        isPersoRule: true,
        PersoRuleType: PersoRuleType.AbandonedCartProducts
    }
];