import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SystemServices from '../../../../Services/SystemServices';
import CrossSellUpSellServices from '../../../../Services/CrossSellUpSellServices';
import ProductRankingServices from '../../../../Services/ProductRankingServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import ToolingServices from '../../../../Services/ToolingServices';
import AccountTenantServices from '../../../../Services/AccountTenantServices';
import SetupAccountServices from '../../../../Services/SetupAccountServices';
import {
	capitalizeFirstLetter,
	getProductsReplaceInTransformationObject,
	filterJustByReplaceProduct,
	getProductsWhithoutReplaceTransformationObject,
	replaceId,
	demoteId,
	promoteId
} from '../utils';
import {
	getProductCollation,
	getProductFeedCategory,
	getRankingById,
	getPreview,
	createRanking,
	putRankingById,
	deleteRankingById,
	duplicateRankingById,
} from '../services/rankingServices';
import EventTrackingServices from '../../../../Services/EventTrackingServices';

function getUniqueSortingFactors(arrayToFilter) {
	const uniqueItem = arrayToFilter.reduce((acc, curr) => {
		if (curr.collationId === 
			'cfe9fc4b-ee69-4359-82ee-496f787d9414') {
			acc = [...acc, curr.params[0].value.stringValues[0]];
		}
		return acc;
	}, []);

	return [...new Set(uniqueItem)];
}
const RankingContext = createContext();

function useRankingContext() {
	return useContext(RankingContext);
}

const CreateRankingContextProvider = (props) => {
	const $http = props.$http;
	const AuthServices = props.AuthServices;
	const $location = props.$location;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const crossSellUpSellServices = new CrossSellUpSellServices($http);
	const productRankingServices = new ProductRankingServices($http, AuthServices, accountId);
	const toolingServices = new ToolingServices($http, $routeParams);
	const accountTenantServices = new AccountTenantServices(props.$http,
		AuthServices, accountId
	);
	const setupAccountServices = new SetupAccountServices(props.$http);
	const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const [mode, setMode] = useState('creation');
	const [dataIsEmpty, setDataIsEmpty] = useState(false);
	const [originalRankingRule, setOriginalRankingRule] = useState();
	const [modalConfirmIsOpen, setmodalConfirmIsOpen] = useState(false);
	const [modalCancelActions, setmodalCancelActions] = useState(false);
	const [modalManualSorting, setModalManualIsOpen] = useState(false);
	const [loadingProductList, setloadingProductList] = useState(true);
	const [loadingSaving, setloadingSaving] = useState(false);
	const [ruleName, setruleName] = useState('New ranking rule');
	const [descriptionRule, setdescriptionRule] = useState('');
	const [rankingObject, setRankingObject] = useState(
		{
			'name': 'New ranking rule',
			'description': '',
			'tenant': null,
			'sorting': [

			],
			'transformations': [

			]
		}
	);
	const [deviceType, setdeviceType] = useState('Mobile');
	const [allCategoriesData, setallCategoriesData] = useState();
	const [typeCategories, setTypeCategories] = useState();
	const [categorySelected, setCategorySelected] = useState();
	const [typeCategoriesSecondLevel, settypeCategoriesSecondLevel] = useState();
	const [typeCategoriesSecondLevelSelected, setTypeCategoriesSecondLevelSelected] = useState();
	const [errorNoConfiguration, setErrorNoConfiguration] = useState(false);
	const [promotedProducts, setPromotedProducts] = useState([]);

	const [productList, setproductList] = useState([]);
	const [demotedProduct, setDemotedProduct] = useState([]);
	const [replaceProducts, setreplaceProducts] = useState([]);

	const [ListFilter, setListFilter] = useState();
	const [listTenantAccount, setlistTenantAccount] = useState([{
		label: 'Default',
		value: ''
	}

	]);
	const [tenantsCatalog, setTenantsCatalog] = useState([]);

	const [previewTenant, setpreviewTenant] = useState({
		label: 'Default',
		value: ''
	});

	const [idInClipBoard, setidInClipBoard] = useState('');

	const [newPaginationNeed, setNewPaginationNeed] = useState(false);
	const [needRefreshPagination, setneedRefreshPagination] = useState(false);

	const [currentPageProductList, setCurrentPageProductList] = useState(1);
	const [paginationOptions, setpaginationOptions] = useState({
		ppoffset: 0,
		pplimit: 40,
		poffset: 0,
		plimit: 20,
		dpoffset: 0,
		dplimit: 40
	});

	const [needToRefreshTenantCategory, setneedToRefreshTenantCategory] = useState(true);
	const [isFirstRender, setisFirstRender] = useState(true);
	const [arrayOfValuetags, setarrayOfValuetags] = useState([]);
	const [hasDuplicateItem, sethasDuplicateItem] = useState();
	function resetPagination() {
		setpaginationOptions({
			ppoffset: 0,
			pplimit: 40,
			poffset: 0,
			plimit: 20,
			dpoffset: 0,
			dplimit: 40
		});
		setCurrentPageProductList(1);
	}
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setidInClipBoard(value);
	};
	function handleChangePaginationOptions(elemName, elemValue) {
		setpaginationOptions(pagination => ({
			...pagination,
			[elemName]: elemValue
		}));
	}
	let urlReturn = '/ProductSettings/Ranking';
	if ($routeParams && $routeParams.ka) {
		urlReturn += '?ka=' + $routeParams.ka;
	}

	const CreateRanking = () => {
		setloadingSaving(true);
		// Get new value for name of ranking
		const newRanking = {
			...rankingObject
		};
		if((rankingObject.tenant == null && tenantsCatalog.length === 1 ) &&
		listTenantAccount.length > 0){
			newRanking.tenant = '';
		}
		newRanking.name = ruleName;
		newRanking.description = descriptionRule;
		createRanking(newRanking,
			systemServices,
			productRankingServices,
			newData => {
				trackEvent('ranking/new-rule-saved');
				systemServices.showSuccess('Ranking created successfully !');
				let url = '/ProductSettings/Ranking/Setting?id=' + newData.id;
				if ($routeParams && $routeParams.ka) {
					url += '&ka=' + $routeParams.ka;
				}
				setTimeout(() => {
					window.location.href = url;
				}, 1000);
			},
			error => {
				console.log('err', error);
				setloadingSaving(false);
			});
	};
	const PutRankingById = () => {
		const newRanking = {
			...rankingObject
		};
		newRanking.name = ruleName;
		newRanking.description = descriptionRule;
		putRankingById(newRanking,
			rankingObject.id,
			systemServices,
			productRankingServices,
			newData => {
				trackEvent('ranking/existing-rule-saved');
				systemServices.showSuccess('Changes successfully saved !');
				// change original ranking rule with new ranking data for the comparison
				setOriginalRankingRule(JSON.stringify(newRanking));
			},
			error => {
				console.log('err', error);
			});
	};
	const GetProductCollation = (cat = 'global') => getProductCollation(
		cat,
		systemServices,
		productRankingServices,
		newData => {
			setListFilter(newData);
		});
	function hasErrorNoConfiguration(data){
		if(data.status == '404'){
			// systemServices.showError(data.data);
			setErrorNoConfiguration(true);
			setloadingProductList(false);
		}else{
			systemServices.showError();
			setloadingProductList(false);
		}
	}
	const sortByAsc = (a, b) => {

		if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
		if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
		return 0;
	
	};
	const GetTenant = () => {
		accountTenantServices.getTenantsAccount(
			tenantL => {
				const listOfTenant = tenantL.map((tenant) => {
					if (tenant === '') {
						return {
							label: 'Default',
							value: tenant,
						};
					} else {
						return {
							label: tenant,
							value: tenant,
						};
					}
				});
				if (listOfTenant.length > 1) {
					listOfTenant.unshift({
						label: 'All',
						value: 'all'
					});
				}
				const listOfTenantSort = listOfTenant.sort(sortByAsc) ;
				if (listOfTenant.length == 0){
					listOfTenant.push({
						label: 'Default',
						value: ''
					});
				}
				setpreviewTenant(listOfTenantSort[0]);
				setlistTenantAccount(listOfTenantSort);
			},
			(Error,errMessage) => {
				hasErrorNoConfiguration(errMessage);

			}
		);
	};
	const getTenantsCatalogTenant = () => {
		accountTenantServices.getTenantsCatalogTenant(
			(tenantL) => {
				const listOfTenant = tenantL.map((tenant) => {
					if (tenant === '') {
						return {
							label: 'Default',
							value: tenant,
						};
					} else {
						return {
							label: tenant,
							value: tenant,
						};
					}
				});
				if (listOfTenant.length > 1) {
					listOfTenant.unshift({
						label: 'All',
						value: null
					});
				}
				const listOfTenantSort = listOfTenant.sort(sortByAsc) ;
				setTenantsCatalog(listOfTenantSort);
				handleSelectMode(listOfTenantSort);
			},
			(Error,errMessage) => {
				hasErrorNoConfiguration(errMessage);

			}
		);
	};
	function handleSelectMode(tenantsList) {
		// Check mode of editing of creating
		if ($routeParams && $routeParams.id !== undefined) {
			setMode('edition');
			setisFirstRender(false);
			trackEvent('ranking/existing-rule-opened');
			getRankingById($routeParams.id,
				systemServices,
				productRankingServices,
				newData => {
					setRankingObject(newData);
					setruleName(newData.name);
					setdescriptionRule(newData.description !== null ? newData.description : '');
					GetProductCollation();
					setreplaceProducts(getProductsReplaceInTransformationObject(newData.transformations));
					setOriginalRankingRule(JSON.stringify(newData));
					let tenantObj  = {};
					tenantObj.listTenant = tenantsList; 
					if(tenantsList){
						tenantObj.tenantSelected = newData.tenant;
					}
					const arayOfValueTag = getUniqueSortingFactors(newData.sorting);
					setarrayOfValuetags(arayOfValueTag.map(x => ({...x, id: uuidv4()})));
					getProductFeedCategory(accountId,
						tenantObj,
						systemServices,
						crossSellUpSellServices,
						newData => {
							setneedToRefreshTenantCategory(false);
							// Get categories of product feed and transform object to list
							const backendCompleteObject = [...newData];
							if (backendCompleteObject.length === 0) {
								setDataIsEmpty(true);
								settypeCategoriesSecondLevel([{
									name: '',
									label: ''
								}]);
								setTypeCategoriesSecondLevelSelected(
									{
										name: '',
										label: ''
									}
								);
								setTypeCategories([]);
								setCategorySelected({
									name: '',
									label: ''
								});
							}
							setallCategoriesData(backendCompleteObject);
							const arrayOfCategories = backendCompleteObject.map(el => ({ name: el.type, label: capitalizeFirstLetter(el.type) }));
							if (arrayOfCategories.length > 0) {
								setTypeCategories(arrayOfCategories);
								setCategorySelected(arrayOfCategories[0]);
								// Transform list of second category
								const valueOfSecondCategorySelect = backendCompleteObject.find(el => el.type === arrayOfCategories[0].name).knownNames.map(el => ({ name: el, label: el }));
								settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
								setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);
		
							}
						});
				},
				error => {
					// setRankingObject(rankingExample)
				}
			);
		} else if ($routeParams && $routeParams.id === undefined) {
			setMode('creation');
			setisFirstRender(false);
			trackEvent('ranking/new-rule-opened');
			GetProductCollation();
			let tenant = null;
			const tenantObj  = {};
			tenantObj.listTenant = tenantsList; 
			const tenantsListFilter = tenantsList.filter(x=> x.value !== null);
			if(tenantsListFilter.length !== 0){

				tenantObj.tenantSelected = tenantsListFilter[0].value;
				tenant = tenantsListFilter[0].value;
			}else{
				tenantObj.tenantSelected = '';
				tenant = '';
			}
			const arayOfValueTag = getUniqueSortingFactors(rankingObject.sorting);
			setarrayOfValuetags(arayOfValueTag.map(x => ({...x, id: uuidv4()})));
			getProductFeedCategory(accountId,
				tenantObj,
				systemServices,
				crossSellUpSellServices,
				newData => {
					setneedToRefreshTenantCategory(false);
					// Get categories of product feed and transform object to list
					const backendCompleteObject = [...newData];
					if (backendCompleteObject.length === 0) {
						setDataIsEmpty(true);
						settypeCategoriesSecondLevel([{
							name: '',
							label: ''
						}]);
						setTypeCategoriesSecondLevelSelected(
							{
								name: '',
								label: ''
							}
						);
						setTypeCategories([]);
						setCategorySelected({
							name: '',
							label: ''
						});
					}
					setallCategoriesData(backendCompleteObject);
					const arrayOfCategories = backendCompleteObject.map(el => ({ name: el.type, label: capitalizeFirstLetter(el.type) }));
					if (arrayOfCategories.length > 0) {
						setTypeCategories(arrayOfCategories);
						setCategorySelected(arrayOfCategories[0]);
						// Transform list of second category
						const valueOfSecondCategorySelect = backendCompleteObject.find(el => el.type === arrayOfCategories[0].name).knownNames.map(el => ({ name: el, label: el }));
						settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
						setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);

					}
				});
			setRankingObject(r => ({
				...r , tenant :  tenant
			}));
			setOriginalRankingRule(JSON.stringify({
				'name': 'New ranking rule',
				description: '',
				'sorting': [
				],
				'tenant': tenant,
				'transformations': [

				]
			}));
		}
	}
	function changeDeviceSelected(deviceName) {
		setdeviceType(deviceName);
		setneedRefreshPagination(true);
	}
	useEffect(()=>{
		GetTenant();
		getTenantsCatalogTenant();
	},[]);

	useEffect(() => {
		if (needToRefreshTenantCategory && !isFirstRender ){
			const tenantObj  = {};
			tenantObj.tenantSelected = rankingObject.tenant;
			tenantObj.listTenant = tenantsCatalog; 
			getProductFeedCategory(accountId,
				tenantObj,
				systemServices,
				crossSellUpSellServices,
				newData => {
					setneedToRefreshTenantCategory(false);
					// Get categories of product feed and transform object to list
					const backendCompleteObject = [...newData];
					if (backendCompleteObject.length === 0) {
						setDataIsEmpty(true);
						settypeCategoriesSecondLevel([{
							name: '',
							label: ''
						}]);
						setTypeCategoriesSecondLevelSelected(
							{
								name: '',
								label: ''
							}
						);
						setTypeCategories([]);
						setCategorySelected({
							name: '',
							label: ''
						});
					}
					setallCategoriesData(backendCompleteObject);
					const arrayOfCategories = backendCompleteObject.map(el => ({ name: el.type, label: capitalizeFirstLetter(el.type) }));
					if (arrayOfCategories.length > 0) {
						setTypeCategories(arrayOfCategories);
						setCategorySelected(arrayOfCategories[0]);
						// Transform list of second category
						const valueOfSecondCategorySelect = backendCompleteObject.find(el => el.type === arrayOfCategories[0].name).knownNames.map(el => ({ name: el, label: el }));
						settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
						setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);

					}
				
				});
		}
	}, [tenantsCatalog, needToRefreshTenantCategory]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setloadingProductList(true);
			setCurrentPageProductList(1);
			// getProducts With the preview API
			if (categorySelected &&
				typeCategoriesSecondLevelSelected) {
				const optionsObject = {
					// deviceType: deviceType,
				};
				if (!dataIsEmpty) {
					optionsObject['categoryType'] = categorySelected.name;
					optionsObject['categoryName'] = typeCategoriesSecondLevelSelected.name;
				}

				const shouldNotUsePreviewTenant = rankingObject.tenant !== null && tenantsCatalog.length > 1;
				if (!shouldNotUsePreviewTenant) {
					optionsObject['previewTenant'] = previewTenant.value;
				}
				getPreview(
					optionsObject,
					needRefreshPagination ? {
						ppoffset: 0,
						pplimit: 40,
						poffset: 0,
						plimit: 20,
						dpoffset: 0,
						dplimit: 40
					} : paginationOptions,
					rankingObject,
					systemServices,
					productRankingServices,
					newData => {
						setloadingProductList(false);
						setproductList(newData.products);
						setDemotedProduct(newData.demotedProducts);
						setPromotedProducts(newData.promotedProducts);
						needRefreshPagination && resetPagination();
						setneedRefreshPagination(false);
						// setidInClipBoard('')
					},
					error => {
						setloadingProductList(false);
						setneedRefreshPagination(false);
						setproductList([]);
						setDemotedProduct([]);
						setPromotedProducts([]);
					});
			}
		}, 1500);

		return () => clearTimeout(timer);

	}, [rankingObject,
		categorySelected,
		typeCategoriesSecondLevelSelected,
		previewTenant,
		deviceType,
	]);

	useEffect(() => {
		if (newPaginationNeed) {
			setloadingProductList(true);
			if (categorySelected &&
				typeCategoriesSecondLevelSelected) {
				const optionsObject = {
					// deviceType: deviceType,
				};
				if (!dataIsEmpty) {
					optionsObject['categoryType'] = categorySelected.name;
					optionsObject['categoryName'] = typeCategoriesSecondLevelSelected.name;
				}

				const shouldNotUsePreviewTenant = rankingObject.tenant !== null && tenantsCatalog.length > 1;
				if (!shouldNotUsePreviewTenant) {
					optionsObject['previewTenant'] = previewTenant.value;
				}
				getPreview(
					optionsObject,
					paginationOptions,
					rankingObject,
					systemServices,
					productRankingServices,
					newData => {
						setloadingProductList(false);
						setproductList(newData.products);
						setDemotedProduct(newData.demotedProducts);
						setPromotedProducts(newData.promotedProducts);
						setNewPaginationNeed(false);
						// setidInClipBoard('')
					},
					error => {
						setloadingProductList(false);
						setNewPaginationNeed(false);
						setproductList([]);
						setDemotedProduct([]);
						setPromotedProducts([]);
					});
			}
		}
	}, [paginationOptions]);

	function checkIfCanQuitWithoutSave() {
		const stringifyNewObj = { ...rankingObject };
		stringifyNewObj.name = ruleName;
		stringifyNewObj.description = descriptionRule;
		const compareIfIsSave = originalRankingRule === JSON.stringify(stringifyNewObj);

		if (compareIfIsSave) {
			window.location.href = urlReturn;
		} else {
			setmodalConfirmIsOpen(true);
		}
	}
	function cancelActions() {
		const stringifyNewObj = { ...rankingObject };
		stringifyNewObj.name = ruleName;
		stringifyNewObj.description = descriptionRule;

		const compareIfIsSave = originalRankingRule === JSON.stringify(stringifyNewObj);

		if (compareIfIsSave) {
			return;
		} else {
			setmodalCancelActions(true);
		}
	}
	function returnToDashboard() {
		window.location.href = urlReturn;
	}
	function handleCloseConfirm() {
		setmodalConfirmIsOpen(false);
	}
	function handleCloseCancelActions() {
		setmodalCancelActions(false);
	}
	function returnToFirstStateOfRanking() {
		const parseOriginalRankingRule = JSON.parse(originalRankingRule);
		setruleName(parseOriginalRankingRule.name);
		setdescriptionRule(parseOriginalRankingRule.description !== null ? parseOriginalRankingRule.description : '');
		setRankingObject(parseOriginalRankingRule);
		handleCloseCancelActions();
	}
	// Change ranking object 
	function handleChangeRankingName(target) {
		setruleName(target);
	}
	function handleChangeRankingDescription(target) {
		setdescriptionRule(target);
	}
	function handleChangeTenant(target) {
		const targetIsDefault = target === '';
		const targetIsAll = target === 'All' || target ===  null;

		const newTenant = targetIsDefault ?  '' : targetIsAll ? null : tenantsCatalog.find(el => el.label === target).value;
		setneedToRefreshTenantCategory(true);
		setRankingObject((ranking) => ({
			...ranking, tenant: newTenant
		}));
	}
	function handleChangeTenantPreview(target) {
		const newTenant = listTenantAccount.find(el => el.value == target);
		setpreviewTenant(newTenant);
	}
	// Sorting Tag 


	function handleChangeSortingFactors(target, add){		
		const newSorting = add ? [...rankingObject.sorting, target] : rankingObject.sorting.map(x => x.collationId === target.collationId ? target : x);
		const arayOfValueTag = getUniqueSortingFactors(newSorting);
		setarrayOfValuetags(arayOfValueTag);
		setRankingObject((ranking) => ({
			...ranking,
			sorting: newSorting,
		}));
	}
	// Sorting factors
	function handeChangeFilterSorting(target) {
		if(!target){
			return;
		}
		const newCollation = {
			collationId: target.id,
			weight: -0.5
		};
		const newObjectOfSorting = [...rankingObject.sorting, newCollation];
		setRankingObject((ranking) => ({
			...ranking, sorting: newObjectOfSorting
		}));
	}
	function removeAttribute(isTag,target) {
		if(isTag){
			const indexOfItem = rankingObject.sorting.indexOf(target);
			const newSorting =  [...rankingObject.sorting];
			newSorting.splice(indexOfItem,1);
			const arayOfValueTag = getUniqueSortingFactors(newSorting);
			setarrayOfValuetags(arayOfValueTag);
			setRankingObject((ranking) => ({
				...ranking,
				sorting: newSorting,
			}));
		}else{
			const newObjectOfSorting = rankingObject.sorting.filter(
				(el) => el.collationId !== target
			);
			setRankingObject((ranking) => ({
				...ranking,
				sorting: newObjectOfSorting,
			}));
		}
	}
	function updapteAttribute(Collation, target) {
		const indexOfItem = rankingObject.sorting.indexOf(Collation);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const rankingToChange = rankingObject.sorting[indexOfItem];
		if (Math.sign(Collation.weight) === 1) {
			rankingToChange.weight = parseFloat(target);
		}
		else {
			rankingToChange.weight = parseFloat(- target);
		}
		const newArrayOfSorting = replaceAt(rankingObject.sorting, indexOfItem, rankingToChange);
		setRankingObject((ranking) => ({
			...ranking, sorting: newArrayOfSorting
		}));
	}
	function onChangeAttributeOfSign(Collation) {
		const indexOfItem = rankingObject.sorting.indexOf(Collation);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const rankingToChange = rankingObject.sorting[indexOfItem];
		if (Math.sign(Collation.weight) === 1) {
			rankingToChange.weight = parseFloat(-rankingToChange.weight);
		}
		else {
			rankingToChange.weight = Math.abs(rankingToChange.weight);
		}
		const newArrayOfSorting = replaceAt(rankingObject.sorting, indexOfItem, rankingToChange);
		setRankingObject((ranking) => ({
			...ranking, sorting: newArrayOfSorting
		}));
	}
	// Category
	function changeCategorySelected(elem) {
		setloadingProductList(true);
		const nextCategory = typeCategories.find(el => el.name === elem);
		setCategorySelected(nextCategory);
		const valueOfSecondCategorySelect = allCategoriesData.find(el => el.type === nextCategory.name).knownNames.map(el => ({ name: el, label: el }));
		settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
		setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);
		setneedRefreshPagination(true);
	}
	function changeSecondCategorySelected(elem) {
		const newCategory = typeCategoriesSecondLevel.find(el => el.name === elem);
		setTypeCategoriesSecondLevelSelected(newCategory);
		setloadingProductList(true);
		setneedRefreshPagination(true);
	}
	// Promoted Product & Demoted product
	function changeOrderOfPromotedProduct(elem) {
		setPromotedProducts((productItems) => ({
			...productItems,
			pageItems: elem
		}
		));
		handleChangeTranformationByPromotedProduct(elem);

	}
	function changeOrderOfDemotedProduct(elem) {
		setDemotedProduct((productItems) => ({
			...productItems,
			pageItems: elem
		}
		));
		handleChangeTranformationByDemotedProduct(elem);
	}
	function handleChangeTranformationByPromotedProduct(items) {
		const newObjectOfTransformationWithoutPromotedProduct = rankingObject.transformations.filter(el => el.collationId !== promoteId);
		const transformationsByPromotedProducts =
		{
			collationId: promoteId,
			params: [{
				name: 'Product Ids',
				value: {
					stringValues: items.map(it => it.id),
				}
			},

			]
		};
		setRankingObject((rObject) => ({
			...rObject,
			transformations: [...newObjectOfTransformationWithoutPromotedProduct, transformationsByPromotedProducts]
		}
		));
	}
	function handleChangeTranformationByDemotedProduct(items) {
		const newObjectOfTransformationWithoutDemotedProduct = rankingObject.transformations.filter(el => el.collationId !== demoteId);
		const transformationsByDemotedProducts =
		{
			collationId: demoteId,
			params: [{
				name: 'Product Ids',
				value: {
					stringValues: items.map(it => it.id),
				}
			},

			]
		};
		setRankingObject((rObject) => ({
			...rObject,
			transformations: [...newObjectOfTransformationWithoutDemotedProduct, transformationsByDemotedProducts]
		}
		));
	}
	function addPromotedProductManualy(newPromotedproducts) {
		
		const transformationObjectByPromotedProduct = rankingObject.transformations.find(el => el.collationId === promoteId);
	
		let myNewArrayOfPromotedProduct = newPromotedproducts.split(/[;,\n]/).filter(x => x.length > 0);
		const oldArray = transformationObjectByPromotedProduct ? transformationObjectByPromotedProduct.params[0].value.stringValues : [];

		myNewArrayOfPromotedProduct = [...oldArray,...myNewArrayOfPromotedProduct];
		const promoteListN = myNewArrayOfPromotedProduct.reduce((obj, curr) => {
			const elementExistInList = obj.listPromoted.indexOf(curr);
			
			if(elementExistInList === -1){
				return obj ={
					listPromoted : [...obj.listPromoted,curr],
					listDuplicate : [...obj.listDuplicate]
				};
			}else{
				return obj ={
					listPromoted : [...obj.listPromoted],
					listDuplicate : [...obj.listDuplicate,curr]
				};
			}
		}, {
			listPromoted : [],
			listDuplicate : []
		});
		myNewArrayOfPromotedProduct = promoteListN.listPromoted;
		if(promoteListN.listDuplicate.length > 0){
			sethasDuplicateItem(promoteListN.listDuplicate);
		}else{
			setModalManualIsOpen(false);
		}
		function transformationObjectWithoutPromotedProduct() {
			const obj = rankingObject.transformations.filter(el => el.collationId !== promoteId);
			if (obj.length > 0) {
				return obj;
			} else {
				return [];
			}
		}


		function getNewArrayofPromote() {
			if (transformationObjectByPromotedProduct) {
				return ({
					collationId: promoteId,
					params: [{
						name: 'Product Ids',
						value: {
							stringValues: [ ...myNewArrayOfPromotedProduct]
						}
					},

					]
				});
			} else {
				return ({
					collationId: promoteId,
					params: [{
						name: 'Product Ids',
						value: {
							stringValues: [...myNewArrayOfPromotedProduct]
						}
					},

					]
				});
			}


		}
		const newTransformationObj = [...transformationObjectWithoutPromotedProduct(),
			getNewArrayofPromote()
		];
		setRankingObject((rObject) => ({
			...rObject,
			transformations: newTransformationObj
		}
		));
	}
	function addPromotedProductWithId(newPromotedproductsId) {
		function transformationObjectWithoutPromotedProduct() {
			const obj = rankingObject.transformations.filter(el => el.collationId !== promoteId);
			if (obj.length > 0) {
				return obj;
			} else {
				return [];
			}
		}
		const transformationObjectByPromotedProduct = rankingObject.transformations.find(el => el.collationId === promoteId);
		function getNewArrayofPromote() {
			if (transformationObjectByPromotedProduct) {
				const oldArrayOfDemotedProducts = [...transformationObjectByPromotedProduct['params'][0]['value']['stringValues']];

				return ({
					collationId: promoteId,
					params: [{
						name: 'Product Ids',
						value: {
							stringValues: [...oldArrayOfDemotedProducts, newPromotedproductsId]
						}
					},

					]
				});
			} else {
				return ({
					collationId: promoteId,
					params: [{
						name: 'Product Ids',
						value: {
							stringValues: [newPromotedproductsId]
						}
					},

					]
				});
			}


		}
		const newTransformationObj = [...transformationObjectWithoutPromotedProduct(),
			getNewArrayofPromote()
		];
		setRankingObject((rObject) => ({
			...rObject,
			transformations: newTransformationObj
		}
		));
	}
	function deletePromotedProduct(idsOfProduct) {

		if (idsOfProduct.length === 0) {
			const newArrayWithoutPromote = rankingObject.transformations.filter(el => el.collationId !== promoteId);
			const newTransformationObj = [...newArrayWithoutPromote];
			setRankingObject((rObject) => ({
				...rObject,
				transformations: newTransformationObj
			}
			));
		}
		else {

			function transformationObjectWithoutPromotedProduct() {
				const obj = rankingObject.transformations.filter(el => el.collationId !== promoteId);
				if (obj.length > 0) {
					return obj;
				} else {
					return [];
				}
			}

			const transformationObjectByPromotedProduct = rankingObject.transformations.find(el => el.collationId === promoteId);
			function getNewArrayofPromote() {
				if (transformationObjectByPromotedProduct) {
					return ({
						collationId: promoteId,
						params: [{
							name: 'Product Ids',
							value: {
								'stringValues': [...idsOfProduct]
							}
						},

						]
					});

				} else {
					return ({
						collationId: promoteId,
						params: [{
							name: 'Product Ids',
							value: {
								'stringValues': [...idsOfProduct]
							}
						},

						]
					});
				}


			}
			const newTransformationObj = [...transformationObjectWithoutPromotedProduct(),
				getNewArrayofPromote()
			];
			setRankingObject((rObject) => ({
				...rObject,
				transformations: newTransformationObj
			}
			));
		}

	}
	function transformationObjectWithoutDemotedProduct() {
		const obj = rankingObject.transformations.filter(el => el.collationId !== demoteId);
		if (obj.length > 0) {
			return obj;
		} else {
			return [];
		}
	}
	function addDemotedProductManualy(newDemotedproducts) {
		const transformationObjectByDemotedProduct = rankingObject.transformations.find(el => el.collationId === demoteId);
		let myNewArrayOfDemotedProduct = newDemotedproducts.split(/[;,\n]/).filter(x => x.length > 0);
		const oldArray = transformationObjectByDemotedProduct ? transformationObjectByDemotedProduct.params[0].value.stringValues : [];
		myNewArrayOfDemotedProduct = [...oldArray,...myNewArrayOfDemotedProduct];
		const demoteListN = myNewArrayOfDemotedProduct.reduce((obj, curr) => {
			const elementExistInList = obj.listPromoted.indexOf(curr);
			
			if(elementExistInList === -1){
				return obj ={
					listPromoted : [...obj.listPromoted,curr],
					listDuplicate : [...obj.listDuplicate]
				};
			}else{
				return obj ={
					listPromoted : [...obj.listPromoted],
					listDuplicate : [...obj.listDuplicate,curr]
				};
			}
		}, {
			listPromoted : [],
			listDuplicate : []
		});
		myNewArrayOfDemotedProduct = demoteListN.listPromoted;
		if(demoteListN.listDuplicate.length > 0){
			sethasDuplicateItem(demoteListN.listDuplicate);
		}else{
			setModalManualIsOpen(false);
		}
		function transformationObjectWithoutDemotedProduct() {
			const obj = rankingObject.transformations.filter(el => el.collationId !== demoteId);
			if (obj.length > 0) {
				return obj;
			} else {
				return [];
			}
		}

		function getNewArrayofDemote() {
			if (transformationObjectByDemotedProduct) {

				return ({
					collationId: demoteId,
					params: [{
						name: 'Product Ids',
						value: {
							'stringValues': [ ...myNewArrayOfDemotedProduct]
						}
					},

					]
				});
			} else {
				return ({
					collationId: demoteId,
					params: [{
						name: 'Product Ids',
						value: {
							'stringValues': [...myNewArrayOfDemotedProduct]
						}
					},

					]
				});
			}


		}
		const newTransformationObj = [...transformationObjectWithoutDemotedProduct(),
			getNewArrayofDemote()
		];
		setRankingObject((rObject) => ({
			...rObject,
			transformations: newTransformationObj
		}
		));

	}
	function addDemotedProductWithId(newDemotedproductsId) {

		const transformationObjectByDemotedProduct = rankingObject.transformations.find(el => el.collationId === demoteId);
		function getNewArrayofDemote() {
			if (transformationObjectByDemotedProduct) {
				const oldArrayOfDemotedProducts = [...transformationObjectByDemotedProduct['params'][0]['value']['stringValues']];

				return ({
					collationId: demoteId,
					params: [{
						name: 'Product Ids',
						value: {
							'stringValues': [...oldArrayOfDemotedProducts, newDemotedproductsId]
						}
					},

					]
				});
			} else {
				return ({
					collationId: demoteId,
					params: [{
						name: 'Product Ids',
						value: {
							'stringValues': [newDemotedproductsId]
						}
					},

					]
				});
			}
		}
		const newTransformationObj = [...transformationObjectWithoutDemotedProduct(),
			getNewArrayofDemote()
		];
		setRankingObject((rObject) => ({
			...rObject,
			transformations: newTransformationObj
		}
		));
	}
	function deleteDemotedProduct(idsOfProduct) {
		if (idsOfProduct.length === 0) {
			const newArrayWithoutDemote = rankingObject.transformations.filter(el => el.collationId !== demoteId);
			const newTransformationObj = [...newArrayWithoutDemote];
			setRankingObject((rObject) => ({
				...rObject,
				transformations: newTransformationObj
			}
			));
		}
		else {

			function transformationObjectWithoutDemotedProduct() {
				const obj = rankingObject.transformations.filter(el => el.collationId !== demoteId);
				if (obj.length > 0) {
					return obj;
				} else {
					return [];
				}
			}

			const transformationObjectByDemotedProduct = rankingObject.transformations.find(el => el.collationId === demoteId);
			function getNewArrayofDemote() {
				if (transformationObjectByDemotedProduct) {
					return ({
						collationId: demoteId,
						params: [{
							name: 'Product Ids',
							value: {
								'stringValues': [...idsOfProduct]
							}
						},

						]
					});

				} else {
					return ({
						collationId: demoteId,
						params: [{
							name: 'Product Ids',
							value: {
								'stringValues': [...idsOfProduct]
							}
						},

						]
					});
				}


			}
			const newTransformationObj = [...transformationObjectWithoutDemotedProduct(),
				getNewArrayofDemote()
			];
			setRankingObject((rObject) => ({
				...rObject,
				transformations: newTransformationObj
			}
			));
		}
	}
	// Replace Item
	function deleteReplaceProductItem(idsOfProduct) {
		if (idsOfProduct.length === 0) {
			return;
		}
		// Récupérer que les élément Replace sans l'id à delete
		const replaceProductInTransformObj = filterJustByReplaceProduct([...rankingObject.transformations], idsOfProduct);
		// refaire le tableau des transformation avec le nouvel array de Replace
		const newArrayOfTransformations = [...getProductsWhithoutReplaceTransformationObject(rankingObject.transformations), ...replaceProductInTransformObj];
		setRankingObject((rObject) => ({
			...rObject,
			transformations: newArrayOfTransformations
		}
		));

	}
	function addReplaceProductManualy(promotedP) {
		const newReplaceTranformation = {
			collationId: replaceId,
			params: [{
				name: 'First Product Id',
				value: {
					stringValues: [promotedP.replaceId],
				}
			},
			{
				name: 'Second Product Id',
				value: {
					stringValues: [promotedP.toReplaceId],
				}
			}
			]
		};
		const newTransformationObj = [...rankingObject.transformations,
			newReplaceTranformation
		];
		setRankingObject((rObject) => ({
			...rObject,
			transformations: newTransformationObj
		}
		));
	}
	function addReplaceProductWithId(promotedP) {
		const newReplaceTranformation = {
			collationId: replaceId,
			params: [{
				name: 'First Product Id',
				value: {
					stringValues: [promotedP.replaceId],
				}
			},
			{
				name: 'Second Product Id',
				value: {
					stringValues: [promotedP.toReplaceId],
				}
			}
			]
		};
		const newTransformationObj = [...rankingObject.transformations,
			newReplaceTranformation
		];
		setRankingObject((rObject) => ({
			...rObject,
			transformations: newTransformationObj
		}
		));
	}

	return (
		<RankingContext.Provider
			value={{
				errorNoConfiguration,
				deviceType,
				changeDeviceSelected,
				dataIsEmpty,
				idInClipBoard,
				copyToClipBoard,
				loadingSaving,
				checkIfCanQuitWithoutSave,
				modalConfirmIsOpen,
				returnToDashboard,
				handleCloseConfirm,
				modalCancelActions,
				cancelActions,
				handleCloseCancelActions,
				returnToFirstStateOfRanking,
				handleChangeTenantPreview,
				previewTenant,
				// config mode 
				mode,
				
				tenantsCatalog,
				// ranking object 
				ruleName,
				rankingObject,
				handleChangeRankingName,
				handleChangeRankingDescription,
				descriptionRule,
				handleChangeTenant,
				listTenantAccount,

				// filters 
				ListFilter,
				handeChangeFilterSorting,
				updapteAttribute,
				removeAttribute,
				onChangeAttributeOfSign,
				hasDuplicateItem,
				sethasDuplicateItem,

				// list
				productList,
				modalManualSorting,
				setModalManualIsOpen,
				categorySelected,
				typeCategories,
				changeCategorySelected,
				typeCategoriesSecondLevel,
				typeCategoriesSecondLevelSelected,
				changeSecondCategorySelected,
				promotedProducts,
				demotedProduct,
				changeOrderOfPromotedProduct,
				changeOrderOfDemotedProduct,
				loadingProductList,
				addPromotedProductManualy,
				deletePromotedProduct,
				addDemotedProductManualy,
				deleteDemotedProduct,
				replaceProducts,
				deleteReplaceProductItem,
				addReplaceProductManualy,
				addPromotedProductWithId,
				addDemotedProductWithId,
				addReplaceProductWithId,
				//pagination

				paginationOptions,
				handleChangePaginationOptions,
				currentPageProductList,
				setCurrentPageProductList,
				setNewPaginationNeed,
				// API 
				CreateRanking,
				PutRankingById,

				handleChangeSortingFactors,
				accountId,
				setupAccountServices,
				arrayOfValuetags,

			}}
		>
			{props.children}
		</RankingContext.Provider>
	);
};

export default CreateRankingContextProvider;

export { useRankingContext };