import React, { useState, useEffect, useContext } from 'react';
import './TargetSkuListContainer.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
export default function TargetSkuListContainer({ setModalIsOppen, setChangeOnList, setClickOnSave, setIsSaved }) {

	const {
		operation,
		setOperation,
		valueSpecificationToPut,
		targetCondition
	} = useContext(CreateBusinessOperationContext);

	const [skuValue, setSkuList] = useState([]);
	const [skuListArray, setSkuListArray] = useState([]);
	const [stateOfModificationSkuList, setStateOfModificationSkuList] = useState(true);
	const [skuModifyValue, setSkuModifyValue] = useState('');
	const [valueSearchBar, setValueSearchBar] = useState('');
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();

	useEffect(() => {
		if (valueSpecificationToPut && valueSpecificationToPut.valueToCompare.length !== 0) {
			setSkuList(valueSpecificationToPut.valueToCompare.split(';'));
			setStateOfModificationSkuList(false);
		}
	}, [valueSpecificationToPut]);

	const handleChange = (event) => {
		let textAreaValue = { ...skuModifyValue };
		textAreaValue = event.target.value;
		setSkuModifyValue(textAreaValue);
	};
	useEffect(() => {
		const createListOfSku = () => {
			const skuList = skuValue.map(item => item.trim()).filter(x => x.length > 0);
			setSkuListArray(skuList);
		};
		createListOfSku();
	}, [skuValue]);

	const saveChange = () => {
		setClickOnSave(true);
		let op = { ...operation };
		let specificationToPut = op.operation.specifications.filter(x => x.id === valueSpecificationToPut.id)[0];
		specificationToPut.valueToCompare = skuListArray.join(';');
		let newObjSpecifications = [...op.operation.specifications.filter(x => x.id !== valueSpecificationToPut.id), specificationToPut];
		op.operation.specifications = newObjSpecifications;
		setOperation(op);
	};
	const addItem = () => {
		if (!targetCondition)
			return;
		const skuList = skuModifyValue && skuModifyValue.split(targetCondition.inputSplitter).filter(x => x.length > 0);
		const categoryListTrim = targetCondition.inputProcessingMap ? targetCondition.inputProcessingMap(skuList) : skuList;
		setSkuList([...skuValue, ...categoryListTrim]);
		setSkuModifyValue('');
		setStateOfModificationSkuList(false);
		setChangeOnList(true);
	};
	const deleteProp = (x) => {
		let array = skuValue;
		array = array.filter(element => element !== x);
		setSkuList(array);
		setDataFiltered();
		setChangeOnList(true);
		setValueSearchBar('');
	};

	const search = (table, word) => {
		if (word.length > 0) {
			setNoData();
			setDataFiltered([]);
			let resultName = table.filter(
				line =>
					line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				resultName = 'No item found';
				setNoData(resultName);
			}
			else { setDataFiltered(resultName); }

		} else {
			setNoData();
			setDataFiltered();
		}
	};
	const handleChangeSearch = (event) => {
		setValueSearchBar(event.target.value);
		if (skuListArray) {
			search(skuListArray, event.target.value);
		}
	};

	return (
		<div className="" >
			<h2 className="simplify_title_target_selection">{targetCondition.label} <span className="simplify_total_sku_items">  ({skuListArray && skuListArray.length} items)</span></h2>
			{
				stateOfModificationSkuList ?
					<>
						<p className="simplify_target_helper">{targetCondition.inputSplitterInstructions}</p>
						<textarea
							className='simplify_textarea_sku_list'
							name=""
							value={skuModifyValue}
							onChange={handleChange}
							placeholder={`Example: ${targetCondition.inputExample}`} />


						<div className="simplify_actions">
							<button className="simplify_btn simplify_btn-cancelDelete" onClick={() => { setSkuModifyValue(''); setStateOfModificationSkuList(false); }} >Cancel</button>
							<button
								className="simplify_btn simplify_save"
								disabled={skuModifyValue.length === 0}
								onClick={addItem}>Add to my list &nbsp;<i className="fas fa-plus"></i>
							</button>
						</div>
					</>
					:
					<>
						<div className="simplify_header_sku">

							<div className="search_tag">
								<i className="fa fa-search"></i>
								<input className="searchBarTag" type="text"
									onChange={handleChangeSearch}
									value={valueSearchBar}
									placeholder="search an item"
								/>
							</div>
							<button className="simplify_btn simplify_save" onClick={() => { setStateOfModificationSkuList(true); setValueSearchBar(''); setDataFiltered(); setNoData(); }}>Add items &nbsp;<i className="fas fa-plus"></i></button>
						</div>

						{noData ?
							<div className="noData">
								{noData}
							</div>
							:

							<>
								{!dataFiltered ?
									<>
										<ul className="simplify_listOfSkuApi simplify_editSkuList">
											{skuValue && skuValue.map((elem, i) =>
												<li key={i} className="simplify_itemSku">
													<span className="deleteItem" onClick={(e) => deleteProp(elem)}> <i className="fas fa-trash-alt"></i></span>
													<p>{elem}</p>
												</li>
											)}
										</ul>

										<div className="simplify_actions">
											<button className="simplify_btn simplify_btn-cancelDelete" onClick={() => { setModalIsOppen(false); }}>Cancel &nbsp;<i className="fas fa-times"></i></button>

											<button className="simplify_btn simplify_save" onClick={() => { setIsSaved(true); saveChange(); setChangeOnList(false); }}>Save &nbsp;<i className="fas fa-save"></i></button>
										</div>
									</>


									:
									<>
										<ul className="simplify_listOfSkuApi simplify_editSkuList">
											{dataFiltered && dataFiltered.map((elem, i) =>
												<li key={i} className="simplify_itemSku">
													<span className="deleteItem" onClick={(e) => deleteProp(elem)}><i className="fas fa-trash-alt"></i></span>
													<p>{elem}</p>
												</li>
											)}
										</ul>
										<div className="simplify_actions">
											<button className="simplify_btn simplify_btn-cancelDelete" onClick={() => { setModalIsOppen(false); }}>Cancel &nbsp;<i className="fas fa-times"></i></button>
											<button className="simplify_btn simplify_save" onClick={() => { setIsSaved(true); saveChange(); }}>Save &nbsp;<i className="fas fa-save"></i></button>
										</div>
									</>
								}
							</>
						}
					</>}
		</div>
	);
}
