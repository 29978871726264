import React, { createContext, useContext, useState,useEffect } from 'react';
import ReportingCampaignServices from '../../../Services/ReportingCampaignServices';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import AbTestsServices from '../../../Services/AbTestsServices';
import InsightsServices from '../../../Services/InsightsServices';
import { I18nextProvider, useTranslation } from 'react-i18next';
import {getFinalUrl} from '../../Menu/utils';
export const insightsContext = createContext();
export function useInsightsContext() {
	return useContext(insightsContext);
}
const InsightProvider = (props) => {

	const [t] = useTranslation('kpi');
	const routeParams  = props.$routeParams;
	const AuthServices = props.AuthServices;
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	const ReportingCampaignService = new ReportingCampaignServices(
		props.$http,
		props.$routeParams
	);

	const insightsServices = new InsightsServices(
		props.$http,
		props.$routeParams,
		AuthServices
	);
	const isAdmin = impersonatingServices.isAnAdmin();
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const abTestsServices = new AbTestsServices(props.$http, accountId);
	const token = props.AuthServices && props.AuthServices.getAccessToken();
	const optionsDate = {
		weekday: 'long',
		//year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	const today = new Date().toLocaleDateString(undefined,optionsDate);
	const [modulesActivated, setmodulesActivated] = useState([]);

	function getInsightsModules(){
		// Get Available kpis
		
		insightsServices.getInsightsActivated(accountId,(success)=> {
			const arrayModules = [
				{
					id: 'campaigns',
					label: 'dashboard',
					navModule : {
						label: t('common.mainKPI'),
						icon: 'dashboard',
						activeUrls: ['/Insight/kpi'],
						link: getFinalUrl('/Insight/kpi', props)
					},
				},
				{
					id: 'global',
					label: 'traffic',
					navModule : {
						label: t('traffic.traffic'),
						icon: 'traffic',
						activeUrls: ['/Insight/traffic'],
						link: getFinalUrl('/Insight/traffic', props)
					},
				},
				{
					id: 'ecommerce',
					label: 'ecommerce',
					navModule: {
						label: t('ecommerce.ecommerce'),
						icon: 'ecommerce',
						activeUrls: ['/Insight/ecommerce'],
						link: getFinalUrl('/Insight/ecommerce', props) 
					},
				},
				{
					id: 'segments',
					label: 'segments',
					navModule: {
						label: t('segments.segments'),
						icon: 'segments',
						activeUrls: ['/Insight/segment'],
						link: getFinalUrl('/Insight/segment' , props)
					},
				},
				{
					id: 'audit',
					label: 'AI-powered audit',
					navModule: {
						label: t('audit.scenarioRecommendation'),
						aiFlag: true,
						icon: 'audit',
						activeUrls: ['/Insight/audit'],
						link: getFinalUrl('/Insight/audit' , props)
					},
				}
				
			];

			const newModulesClients = [];
			arrayModules.forEach(item => {
				if(item.id === 'segments'){
					newModulesClients.push(item.navModule);
				}
				if(item.id === 'campaigns'){
					newModulesClients.push(item.navModule);
				}
				if(item.id === 'audit' && isAdmin){
					newModulesClients.push(item.navModule);
				}
				else if(success.find(el => el === item.id)){
					newModulesClients.push(item.navModule);
				}});
			const uniq = [...new Set(newModulesClients)];
			setmodulesActivated(uniq);
			// TODO DELETE MODULE  audit
			const newModulesClientsKey = {
				accountId: accountId,
				menu : [...uniq]
			};

			sessionStorage.setItem('insightsdashboard', JSON.stringify(newModulesClientsKey));
			const pathName = window.location;
			if(newModulesClients.length >0 && pathName.pathname.includes('Insight')){
				const newModulesClientsMap = newModulesClients.map(el => el.link);

				if(!newModulesClientsMap.includes(pathName.pathname + pathName.search)){
					window.location.href=newModulesClients[0].link;
				}
			}
		}, () => {
			setmodulesActivated([]);	
			systemServices.showError('An error occurred while trying to get data');
		});
	}
	useEffect(()=>{
		const modulesInsightsSessionStorage = sessionStorage.getItem('insightsdashboard');
		if(modulesInsightsSessionStorage){
			const parseModulesInsights = JSON.parse(modulesInsightsSessionStorage);
			if(parseModulesInsights.accountId !== accountId){
				getInsightsModules();
			}else{
				const pathName = window.location;
				if(parseModulesInsights.menu.length >0 && pathName.pathname.includes('Insight')){
					const newModulesClientsMap = parseModulesInsights.menu.map(el => el.link);
					if(!newModulesClientsMap.includes(pathName.pathname + pathName.search)){
						sessionStorage.removeItem('insightsdashboard');
					}
				}
				setmodulesActivated(parseModulesInsights.menu);
			}

		}else{
			getInsightsModules();
		}

	},[]);
	return (
		<insightsContext.Provider value={{
			routeParams,
			accountId,
			isAdmin,
			token,
			systemServices,
			abTestsServices,
			insightsServices,
			modulesActivated,
			today,
			props
		}}>
			{props.children}
		</insightsContext.Provider>
	);

};
export default InsightProvider;