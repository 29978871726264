import { Template, TemplateDescriptor, TemplateProperty } from '../../../WysiwygEditor/Types/BespokeTypes';

export type EmailTemplateObject = {
	//SlideFormat: string;
	Id: string | null;
	Name: string;
	DesignProperties: [];
	Properties: TemplateProperty[];
	TemplateType: string;
	TemplateName: string;
	TemplateDescriptorDesktop: TemplateDescriptor;
	TemplateDescriptorMobile: TemplateDescriptor;
	SavedStyle: string;
	Template: Template | null;
	Status: number;
}


export const emptyTemplate: EmailTemplateObject = {
	//SlideFormat: '',
	Id: null,
	Name: 'New email widget',
	DesignProperties: [],
	Properties: [],
	TemplateType: '',
	TemplateName: '',
	TemplateDescriptorDesktop: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	TemplateDescriptorMobile: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	SavedStyle: '',
	Template: null,
	Status: 1
};
