import React, { ComponentProps, FC } from "react";
import CreateInteractionContextProvider from "./InteractionContext";
import CreateSessionContextProvider from "./SessionContext";
import CreatePageContextProvider from "./PageContext";
import CreateEcommerceAnalyticsContextProvider from "./EcommerceAnalyticsContext";
const combineComponents = (...components) => {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            return ({ children }) => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({ children }) => <>{children}</>
    );
};

const providers = [
    CreateInteractionContextProvider,
    CreateSessionContextProvider,
	CreatePageContextProvider,
	CreateEcommerceAnalyticsContextProvider
];
export const TabContextProvider = combineComponents(...providers);
