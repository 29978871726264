import { element } from 'prop-types';
import React, { useState, useEffect } from 'react';
import TableColItem from '../UI/TableColItem';
import TableGridContainer from '../UI/TableGridContainer';
import TableRowItem from '../UI/TableRowItem';
import { formatDate } from '../utils/tranformDates';
import DropdownOptions from '../UI/DropdownOptions';
import { duplicateRecoById } from './services/recommendationsServices';
import { deleteRecoById } from './services/recommendationsServices';
import Confirm from '../../../Components/Confirm';
import Pagination from '../UI/Pagination';
export default function RecosListView({
	recoList,
	routeParams,
	systemServices,
	productRecommendationServices,
	totalCount,
	fetchData,
	paginationOptions,
	handleClickFilter,
	dataFiltered,
	descSort
}) {

	const [modalDeleteRecoIsOpen, setModalDeleteRecoIsOpen] = useState(false);
	const [elemToDelete, setElemToDelete] = useState({});

	const needTenantCol = recoList.filter(el => !!el.tenant).length > 0;
	let tableClass = 'table_grid_emerch table_grid_reco';
	if (needTenantCol) {
		tableClass += '_with_tenant';
	}

	function goToEditionOfNewReco(link) {
		window.location.href = link;
	}

	const duplicateRecommendation = (id) => {
		duplicateRecoById(id,
			routeParams,
			systemServices,
			productRecommendationServices,
			(link) => goToEditionOfNewReco(link),
			data => {
				//
			}, error => {
				systemServices.showError('An error occured');
			});
	};

	const deleteRecommendation = (elem) => {
		setModalDeleteRecoIsOpen(true);
		setElemToDelete(elem);
	};

	const deleteARuleByIdFn = () => {
		deleteRecoById(elemToDelete.id,
			systemServices,
			productRecommendationServices,
			succes => {
				fetchData();
			}, error => {
				systemServices.showError('An error occured');
			}
		);
	};

	const getContextName = (elem) => {
		const context = elem.context;
		if (context == 'ProductCategory') return 'Product category';
		if (context == 'ProductTag') return 'Product tag';
		if (context == 'ProductGlobal') return 'Product';
		return context;
	};

	const getContextParam = (elem) => {
		const context = elem.context;
		if (context == 'ProductCategory') return elem.param;
		if (context == 'ProductTag') return elem.param;
	};


	const bodyTableRecos = (i, elem) => {
		let url = '/ProductSettings/Recommendations/Setting?id=' + elem.id;

		if (routeParams && routeParams.ka) {
			url += '&ka=' + routeParams.ka;
			url += '&ku=' + routeParams.ku;
		}

		const contextParam = getContextParam(elem);

		return <TableRowItem
			key={i}
			specialClasseName={'table_body_row'}>

			<TableColItem
				specialClasseName={' '}>
				<div className="flex">
					<div className="flex_item_fix">
						<a className="emerch_avatar emerch_avatar_margin" href={url}>
							<i className="fas fa-thumbs-up"></i>
						</a>
					</div>
					<div className="flex_item_full">
						<a className="table_row_name text_link_hover" href={url}>{elem.name}</a>
						{elem.description && elem.description != '' &&
							<div className="table_row_desc">{elem.description}</div>
						}
						<div className="s_11 grey_3 mt_3">{elem.id}</div>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				<div>
					{getContextName(elem)}
					{contextParam &&
						<div className="emerch_context_tag_wrapper">
							<span className='emerch_context_tag'>{contextParam}</span>
						</div>
					}
				</div>
			</TableColItem>
			{needTenantCol &&
				<TableColItem>
					{elem.tenant}
				</TableColItem>
			}
			<TableColItem>
				{elem.usedCount}
			</TableColItem>
			<TableColItem>
				{formatDate(elem.lastUpdatedUtc)}
			</TableColItem>
			<TableColItem align='right'>
				<DropdownOptions
					duplicateActionFunction={() => duplicateRecommendation(elem.id)}
					openModalCampaignDelete={() => deleteRecommendation(elem)}
				/>
			</TableColItem>
		</TableRowItem>;
	};

	return (
		<>
			<Confirm
				isOpen={modalDeleteRecoIsOpen}
				onClose={(e) => setModalDeleteRecoIsOpen(false)}
				title="Do you really want to delete this rule?"
				text="This action is irreversible"
				cancelText="Cancel"
				confirmText="Delete"
				confirmColor="alert"
				confirmCallback={(e) => deleteARuleByIdFn()}
			/>
			{recoList && recoList.length > 0 &&
				<>
					<TableGridContainer specialClasseName={tableClass}>
						<TableRowItem specialClasseName={'table_head_row'}>
							<TableColItem onClick={()=>handleClickFilter('Name')}>
								<a href={''}></a>
								<a className="table_sort">
								Rule name
									{dataFiltered === 'Name'  && descSort && <i className='fas fa-arrow-down'></i>}
									{dataFiltered === 'Name'  && !descSort && <i className='fas fa-arrow-up'></i>}
								</a>
							</TableColItem>
							<TableColItem 
								onClick={()=>handleClickFilter('Context')}
							>	<a className="table_sort">
							Reference
									{dataFiltered === 'Context'  && descSort && <i className='fas fa-arrow-down'></i>}
									{dataFiltered === 'Context'  && !descSort && <i className='fas fa-arrow-up'></i>}
								</a>
								
							</TableColItem>
							{needTenantCol &&
								<TableColItem onClick={()=>handleClickFilter('Tenant')}>
									<a className="table_sort">
									Tenant
										{dataFiltered === 'Tenant'  && descSort && <i className='fas fa-arrow-down'></i>}
										{dataFiltered === 'Tenant'  && !descSort && <i className='fas fa-arrow-up'></i>}
									</a>
								</TableColItem>
							}
							<TableColItem onClick={()=>handleClickFilter('UsedCount')}>
								<a className="table_sort">
							Nb. campaigns
									{dataFiltered === 'UsedCount'  && descSort && <i className='fas fa-arrow-down'></i>}
									{dataFiltered === 'UsedCount'  && !descSort && <i className='fas fa-arrow-up'></i>}
								</a>
							</TableColItem>
							<TableColItem onClick={()=>handleClickFilter('LastUpdatedUtc')}>
								<a className="table_sort"> Last rule update
									{dataFiltered === 'LastUpdatedUtc'  && descSort && <i className='fas fa-arrow-down'></i>}
									{dataFiltered === 'LastUpdatedUtc'  && !descSort && <i className='fas fa-arrow-up'></i>}
								</a>
							</TableColItem>
						</TableRowItem>
						{recoList.map((elem, i) => bodyTableRecos(i, elem))}
					</TableGridContainer>

					<Pagination
						totalCount={totalCount}
						pageSize={paginationOptions.limit}
						paginate={(e) => {
							fetchData({
								limit: paginationOptions.limit,
								offset: e >= 0 ? e : 0,
							});
						}}
						skipIdNumber={paginationOptions.offset}
					/>
				</>
			}
		</>
	);
}
