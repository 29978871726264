import React from 'react';
import './SideBar.css';
import './Params.css';
import PropertyEditor from '../../../../WysiwygEditor/PropertiesEditor/PropertyEditor';
import PropertiesGroup from '../../../../WysiwygEditor/PropertiesEditor/PropertiesGroup';
import PropertiesCollection from '../../../../WysiwygEditor/PropertiesEditor/PropertiesCollection';
import { propertyAccessor, propertyChangeApplication } from '../../../../WysiwygEditor/Utils/PropertiesOperations';
import { EditorType, Template, TemplateProperty } from '../../../../WysiwygEditor/Types/BespokeTypes';
import { useEmailTemplateEditorContext } from '../../context/EmailTemplateEditorContextProvider';


export default function PropertiesSelector() {

	const {
		emailTemplate,
		currentDevice,
		applyUpdateProperties,
	} = useEmailTemplateEditorContext();

	const {
		applyCollectionPropertyValueChange,
		applyGroupPropertyValueChange,
		applyPropertyValueChange,
		applyAddProperty,
		applyDeleteProperty,
		applyReorderCollectionItems,
		applyChangeActiveCollectionItem,
		applyChangeIsFolded
	} = propertyChangeApplication();

	const { getPropertyValue } = propertyAccessor();
	const currentFormat = emailTemplate.Template;
	const getCurrentVariation = () => emailTemplate;
	const features: any = {};
	const loadHttpConnectorConfigurationCallback = () => 0;
	const handleSetPromoCode = () => 0;
	const handleChangeIsActivePromoCode = () => 0;

	const handlePropertyValueChange = (value: any, editorPropertyName: string) => applyPropertyValueChange(value, editorPropertyName, applyUpdateProperties);

	const propertyEditorContext = {
		features,
		getCurrentVariation,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
	};

	const groupEditorContext = {
		getCurrentVariation,
		handleGroupPropertyValueChange: (value: any, editorPropertyName: string, item: { Name: string; }) => applyGroupPropertyValueChange(value, editorPropertyName, item, applyUpdateProperties),
		handleChangeIsActivePromoCode,
		handleIsFoldedChanged: (property: TemplateProperty, isFolded: boolean) => applyChangeIsFolded(property.Name, isFolded, applyUpdateProperties),
		currentDevice,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
		features
	};

	const collectionEditorContext = {
		getCurrentVariation,
		currentDevice,
		handleCollectionPropertyValueChange: (value: any, editorPropertyName: string, item: { Name: string; }, collectionItemIndex: number) => applyCollectionPropertyValueChange(value, editorPropertyName, item, collectionItemIndex, applyUpdateProperties),
		handleDeleteProperty : (key: string, collectionName: string) => applyDeleteProperty(key, collectionName, applyUpdateProperties),
		handleAddProperty: (collectionName: string, property: TemplateProperty) => applyAddProperty(collectionName, property, applyUpdateProperties),
		handleChangeActiveCollectionItem: (editorProperty: TemplateProperty, collectionItemIndex: number) => applyChangeActiveCollectionItem(editorProperty, collectionItemIndex, applyUpdateProperties),
		reorderCollectionItems: (list, startIndex, endIndex, property: TemplateProperty, template: Template) => applyReorderCollectionItems(list, startIndex, endIndex, property, template, applyUpdateProperties),
		handleIsFoldedChanged: (property: TemplateProperty, isFolded: boolean) => applyChangeIsFolded(property.Name, isFolded, applyUpdateProperties),
		features,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback
	};

	return (
		<div className="flex_item_full vscroll_custom">
			<section className="format_param_list">
				{currentFormat?.Properties && currentFormat.Properties.map((property) => {

					if (property.Device && property.Device !== currentDevice) return null;

					if (property.Editor.Type === EditorType.Group) {
						return (
							<PropertiesGroup
								key={property.Name}
								property={property}
								context={groupEditorContext}
							/>
						);
					}
					else if (property.Editor.Type === EditorType.Collection) {
						return (
							<PropertiesCollection
								key={property.Name}
								property={property}
								context={collectionEditorContext}
							/>
						);
					}
					else return (
						<PropertyEditor
							key={property.Name}
							context={propertyEditorContext}
							data={getPropertyValue(currentFormat.Properties, property)}
							property={property}
							callBack={v => handlePropertyValueChange(v, property.Name)}
							propertyContainer={
								{
									name: property.Name,
									type: property.Editor.Type,
									siblingPropertiesAccessor: {
										getSiblingPropertyData: (subPropertyName: string) => getPropertyValue(currentFormat.Properties, { Name: subPropertyName }),
										setSiblingPropertyData: (subPropertyName: string, v: any) => handlePropertyValueChange(v, subPropertyName)
									}
								}
							}
						/>
					);
				})}
			</section>
		</div>
	);
}
