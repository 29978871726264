


export const getDimensions = () => {
    return [
        { label: 'Devices', key: 'Device', checked: true },
        { label: 'Browsers', key: 'Browser', checked: false },
        { label: 'Operating System', key: 'OperatingSystem', checked: false },
        { label: 'Traffic Source', key: 'TrafficSource', checked: true },
        { label: 'UTM Medium', key: 'UtmMedium', checked: false },
        { label: 'UTM Source (or gclid)', key: 'UtmSource', checked: false },
        { label: 'UTM Campaign', key: 'UtmCampaign', checked: false },
        //{ label: 'Referrer Medium', key: 'ReferrerMedium', checked: false },
        { label: 'Referrer', key: 'ReferrerSource', checked: false },
        { label: 'Timezone', key: 'Timezone', checked: true },
        { label: 'Language', key: 'Language', checked: false },
        { label: 'Landing Page', key: 'LandingPage', checked: false }
    ]
}



// HostName,
// Timezone,
// Language,
// LandingPage,
// LandingPageType,
// ExitPage,
// ExitPageType,
// UtmMedium,
// UtmSource,
// UtmCampaign,
// UtmContent,
// Referrer,
// ReferrerSource,
// ReferrerMedium,
// CountryCode,
// OperatingSystem,
// OperatingSystemVersion,
// Browser,
// BrowserVersion,
// ScreenSize,
// Device,
// TrafficSource


