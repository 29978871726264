import React, { useEffect, useState } from 'react';
import Portal from '../Portal';
import styles from './panel.module.css';

import {
	IPanel,
	IPanelClose,
	IPanelHeader,
	IPanelBody,
	IPanelSection
} from './types';


function Panel({
	children,
	isOpen = false,
	onClose,
	width = 500,
	hasClose = false,
	side = 'right',
	overlay = true,
	closeOnOverlay = false,
	offsetWidth = 400,
	hasOffset = false
} : IPanel): JSX.Element {

	const [shouldRender, setRender] = useState(isOpen);
	const style = {
		width: '',
		height: '',
		minHeight: '',
		paddingLeft: '',
		paddingRight: ''
	};
	const panelClass = [styles.panel_inner];
	const overlayClass = [styles.panel_overlay];

	const hide = (ev:any) => {
		if (typeof onClose === 'function') {
			onClose(ev);
		}
	};

	useEffect(() => {
		if (overlay) {
			document.body.style.overflow = isOpen ? 'hidden' : '';
		}
		if (isOpen) {
			setRender(true);
		}
	}, [isOpen]);

	const onAnimationEnd = () => {
		if (!isOpen) {
			setRender(false);
		}
	};

	const overlayClick = (ev:any) => {
		if (closeOnOverlay) {
			hide(ev);
		}
	};

	if (hasOffset) {
		if (side == 'left') {
			style.paddingLeft = offsetWidth + 'px';
		} else {
			style.paddingRight = offsetWidth + 'px';
		}
		width += offsetWidth;
	}
	if (width) {
		style.width = width + 'px';
	}
	if (side == 'left') {
		panelClass.push(styles.left);
	}
	if (isOpen) {
		panelClass.push(styles.anim_show);
		overlayClass.push(styles.anim_show);
	} else {
		panelClass.push(styles.anim_hide);
		overlayClass.push(styles.anim_hide);
	}

	if (!shouldRender) return <></>;

	const target = document.querySelector('#by_app_root') || document.body;

	return (
		<Portal target={target}>
			{overlay &&
				<div className={overlayClass.join(' ')} onClick={(ev) => overlayClick(ev)}></div>
			}
			<div className={panelClass.join(' ')} style={style} tabIndex={-1} onAnimationEnd={onAnimationEnd}>
				{hasClose &&
					<PanelClose
						onClick={(ev) => hide(ev)}
						position={'absolute'}
					/>
				}
				{children}
			</div>
		</Portal>
	);
}


function PanelClose({
	onClick,
	position = 'none'
} : IPanelClose): JSX.Element {

	let outerClass = styles.panel_close;
	if (position == 'absolute') {
		outerClass = styles.panel_close_absolute;
	} else if (position == 'header_left') {
		outerClass = styles.panel_close_left;
	}

	return (
		<button className={outerClass} onClick={onClick}>
			<span className={styles.panel_close_inner}>
				<svg className={styles.panel_close_img} xmlns="http://www.w3.org/2000/svg" width="14.142" height="14.142" viewBox="0 0 14.142 14.142">
					<g transform="translate(-9.076 -25.075)">
						<rect width="18" height="2" rx="1" transform="translate(10.49 25.075) rotate(45)" fill="#757b96"/>
						<rect width="18" height="2" rx="1" transform="translate(23.218 26.489) rotate(135)" fill="#757b96"/>
					</g>
				</svg>
			</span>
		</button>
	);
}


function PanelHeader({
	children,
	className = '',
	title = '',
	intro = '',
	desc = '',
	hasClose = true,
	hasBorder = true,
	hasBottomPadding = true,
	primaryAction,
	secondaryAction,
	onClose,
	size = 'm'
} : IPanelHeader): JSX.Element {

	let cssClass = [styles.panel_header];

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Border
	if (hasBorder) {
		cssClass.push(styles.has_border);
	}

	// Bottom margin
	if (!hasBottomPadding) {
		cssClass.push(styles.has_no_bottom_padding);
	}

	// Size
	if (size) {
		cssClass.push(styles['size_' + size]);
	}

	return (
		<div className={cssClass.join(' ')}>
			<div className='flex'>
				{hasClose &&
					<div className="flex_item_fix">
						<PanelClose
							position='header_left'
							onClick={onClose}
						/>
					</div>
				}
				<div className='flex_item_full'>
					{intro && <div className={styles.panel_intro}>{intro}</div>}
					{title && <div className={styles.panel_title}>{title}</div>}
					{desc && <div className={styles.panel_desc}>{desc}</div>}
					{children}
				</div>
				{(primaryAction || secondaryAction) &&
					<div className='flex_item_fix ml_20'>{secondaryAction}{primaryAction}</div>
				}
			</div>
		</div>
	);
}


function PanelBody({
	children,
	className = '',
	style,
	color = '',
	position = 'static',
	hasVerticalMargin = true,
	hasHorizontalMargin = true
} : IPanelBody): JSX.Element {

	let outerClass = [styles.panel_body_outer];
	const innerClass = [styles.panel_body_inner];

	// className
	if (className) {
		outerClass = outerClass.concat(className);
	}

	// Color
	if (color == 'grey') {
		outerClass.push(styles.panel_body_outer_grey);
	}

	// Position
	if (position == 'relative') {
		outerClass.push(styles.panel_body_outer_rel);
	}

	// Margin
	if (!hasVerticalMargin) {
		innerClass.push(styles.panel_body_inner_no_vertical_margin);
	}
	if (!hasHorizontalMargin) {
		innerClass.push(styles.panel_body_inner_no_horizontal_margin);
	}

	return (
		<div className={outerClass.join(' ')} style={style}>
			<div className={innerClass.join(' ')}>
				{children}
			</div>
		</div>
	);
}

function PanelSection({
	children,
	className = '',
	style,
	color = '',
	position = 'static',
	hasBottomBorder = true,
	size = 'm'
} : IPanelSection): JSX.Element {

	let outerClass = [styles.panel_section_outer];
	const innerClass = [styles.panel_section_inner];

	// className
	if (className) {
		outerClass = outerClass.concat(className);
	}

	// Color
	if (color == 'grey') {
		outerClass.push(styles.panel_section_outer_grey);
	}

	// Position
	if (position == 'relative') {
		outerClass.push(styles.panel_section_outer_rel);
	}

	// Border
	if (hasBottomBorder) {
		outerClass.push(styles.has_bottom_border);
	}

	// Size
	if (size) {
		innerClass.push(styles['size_' + size]);
	}

	return (
		<div className={outerClass.join(' ')} style={style}>
			<div className={innerClass.join(' ')}>
				{children}
			</div>
		</div>
	);
}

export {
	Panel,
	PanelClose,
	PanelHeader,
	PanelBody,
	PanelSection
};
