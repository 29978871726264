export const allModules = [
	{
		'id': 'insights',
		'name': 'Insights',
		'icon': 'insight', 
		'baseUrl': '/Insight/kpi',
		'activeUrls': ['/Insight/'],
	},
	{
		'id': 'onsite',
		'name': 'On-site activation',
		'icon': 'onsite', 
		'baseUrl': '/Onsite/Dashboard',
		'activeUrls': [
			'/Onsite/',
			'/Dashboard/Orchestration',
			'/Onsite/Planning',
			'/ABTests/',
			'/Onsite/Dashboard',
			'/CampaignReporting/Home/',
			'/Performance/Dashboard',
			'/Performance/Reporting'
		],
	},
	{
		'id': 'ready',
		'name': 'Ready',
		'icon': 'ready', 
		'baseUrl': '/Onsite/Dashboard',
		'activeUrls': [
			'/Onsite/',
			'/Dashboard/Orchestration',
			'/Onsite/Planning',
			'/ABTests/',
			'/Onsite/Dashboard',
			'/CampaignReporting/Home/'
		],
	},
	{
		'id': 'simplify',
		'name': 'Simplify',
		'icon': 'simplify', 
		'baseUrl': '/BusinessOperations/Planning',
		'activeUrls': ['/BusinessOperations/'],
		'intercomSubject': 'wantReady'
	},
	{
		'id': 'emr',
		'name': 'Email remarketing',
		'icon': 'mail', 
		'baseUrl': '/Dashboard/Home?tab=campaigncrm',
		'activeUrls': ['/Dashboard/Home', '/EmailingReporting/'],
		'intercomSubject': 'wantReady'
	},
	{
		'id': 'emailing',
		'name': 'Email remarketing',
		'icon': 'mail', 
		'baseUrl': '/EmailTemplate/Dashboard',
		'activeUrls': ['/EmailTemplate/'],
		'intercomSubject': 'wantReady'
	},
	{
		'id': 'analytics',
		'name': 'Analytics',
		'icon': 'barchart', 
		'baseUrl': '/WebAnalytics/Dashboard/?subdimension=overview&dimension=sessions',
		'activeUrls': ['/WebAnalytics/'],
		'intercomSubject': 'wantAnalytics'
	},
	{
		'id': 'scoring',
		'name': 'Scoring',
		'icon': 'scoring', 
		'baseUrl': '/VisitorEngagementReports/Home',
		'activeUrls': ['/VisitorEngagementReports/', '/VisitorEngagement/'],
		'intercomSubject': 'wantReady'
	},
	{
		'id': 'productMonitoring',
		'name': 'Product insights',
		'icon': 'monitoring', 
		'baseUrl': '/Dashboard/InsightsProducts',
		'activeUrls': ['/Dashboard/InsightsProducts'],
		'intercomSubject': 'wantReady'
	},
	{
		'id': 'eMerch',
		'name': 'E-merch.',
		'icon': 'bag', 
		'baseUrl': '/ProductSettings/Reco',
		'activeUrls': ['/ProductSettings/'],
		'intercomSubject': 'wantAnalytics'
	},
	{
		'id': 'productCatalog',
		'name': 'Product catalog',
		'icon': 'catalog', 
		'baseUrl': '/ProductFeed/Products',
		'activeUrls': ['/ProductFeed/Products', '/ProductFeed/Config'],
	},
	{
		'id': 'settings',
		'name': 'Settings',
		'icon': 'settings', 
		'baseUrl': '/EditAccount',
		'activeUrls': ['/EditAccount', '/EditAccount/'],
		'intercomSubject': 'wantReady',
		'adminClone' : true
	},
	{
		'id': 'Account/AccountHome',
		'name': '',
		'icon': '', 
		'baseUrl': 'Account/AccountHome',
		'activeUrls': ['Account/AccountHome'],
		'intercomSubject': ''
	},
	
];

export const adminModules = [
	{
		'id': 'admin',
		'name': 'Settings',
		'icon': 'settings', 
		'baseUrl': '/Admin/EditAccount',
		'activeUrls': ['/Admin/EditAccount'],
		'needKa': true
	},
];

export const internalModules = [
	{
		'id': '/Internal/AccountList',
		'name': 'Internal accounts',
		'icon': '', 
		'baseUrl': '/Internal/AccountList',
		'activeUrls': ['/Internal/AccountList'],
		'intercomSubject': ''
	},
];