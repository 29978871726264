import React, {useState} from 'react';
import Modal from './Modal';
import Btn from './Btn';
import InputCustom from './InputCustom';

export default function Confirm({
	children,
	title = 'Are you sure?', 
	text = '',
	type = '',
	isOpen = false,
	onClose,
	confirmCallback,
	cancelCallback,
	confirmText = 'Confirm',
	cancelText = 'Cancel',
	confirmColor = 'primary',
	confirmInput,
	confirmInputLabel,
	width = 430
}) {

	const [confirmInputValue, setConfirmInputValue] = useState('');
	const [confirmInputError, setConfirmInputError] = useState('');

	const close = (ev) => {
		if (typeof onClose === 'function') {
			onClose(ev);
		}
		setConfirmInputValue('');
		setConfirmInputError('');
	};

	const confirm = (ev) => {
		if (typeof confirmCallback === 'function') {
			if (confirmInput && confirmInput !== confirmInputValue) {
				setConfirmInputError('The entered text does not match');
				return;
			}
			confirmCallback(ev);
		}
		close();
	};

	const cancel = (ev) => {
		if (typeof cancelCallback === 'function') {
			cancelCallback(ev);
		}
		close();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => onClose()}
			width={width}
			animation="slide_down"
		>
			<div className="modal_body">
				<div className="fw_medium s_18">{title}</div>
				<div className="s_15 grey_0 mt_5">{text}</div>
				{children &&
					<>{children}</>
				}
				{confirmInput &&
					<InputCustom
						placeholder={confirmInput}
						label={confirmInputLabel}
						value={confirmInputValue}
						onChange={(ev) => setConfirmInputValue(ev.target.value)}
						fullWidth={true}
						blockClassName='mt_20'
						isAnError={confirmInputError}
					/>
				}
			</div>
			<div className="modal_footer al_right mt_10">
				<Btn
					style="ghost"
					color="secondary"
					message={cancelText}
					onClick={(e) => cancel()}
				/>
				<Btn
					message={confirmText}
					onClick={(e) => confirm()}
					color={confirmColor}
				/>
			</div>
		</Modal>
	);
}
