import React, {} from 'react';
import './SideBar.css';
import { useEmailTemplateEditorContext } from '../../context/EmailTemplateEditorContextProvider';
import PropertiesSelector from './PropertiesSelector';
import { EditorStep } from '../../context/EditorStateTypes';
import FormatSelector from '../FormatSelector/FormatSelector';

export default function SideBarContainer() {
	const {
		editorState,
	} = useEmailTemplateEditorContext();

	const sidebarClass = ['format_sidebar', 'flex_item_fix'];

	return  (
		<div className={sidebarClass.join(' ')}>
			<div className='format_sidebar_inner flex flex_v'>
				{editorState.sideBarStep === EditorStep.One && !editorState.formatIsSelected &&
					<FormatSelector />
				}
				{editorState.sideBarStep === EditorStep.One && editorState.formatIsSelected &&
					<PropertiesSelector/>
				}
			</div>
		</div>
	);
}
