import React from 'react';
import BasedOnSelect from './BasedOnSelect';
import FiltersList from './Filtering/FiltersList';
import HeaderPannelOptions from './HeaderPannelOptions';
import ManualSortingContainer from './ManualSorting/ManualSortingContainer';
import SortingFactors from './SortingFactors/SortingFactors';
import SelectCustom from '../../../Components/SelectCustom';
import OperatorBetweenFilters from './Filtering/OperatorBetweenFilters';
import EmptyState from '../../../Components/EmptyState';
import AutocompleteContainer from './ProductList/AutocompleteContainer';
import CategoryDropDownList from './ProductList/CategoryDropDownList';
import SelectDropdown from '../../../Components/SelectDropdown';
import ProviderFilter from './Filtering/ProviderFilter';
/*import useFeature from '../../../../flags/useFeature';*/
/*import TransformationsDemo from './Transformations/TransformationsDemo';*/

export default function PannelOptionsList({
	data,
	typeDimensions,
	setRuleName,
	handleChangeContext,
	handeChangeFilterSorting,
	ListFilter,
	updapteAttribute,
	removeAttribute,
	onChangeAttributeOfSign,
	nameInput,
	descriptionRule,
	handleChangeRankingDescription,
	promotedProducts,
	demotedProduct,
	replaceProducts,
	handleChangeTenant,
	listTenantAccount,
	tenantSelected,
	onAddFilter,
	onEditFilter,
	onRemoveFilter,
	handleChangeOperatorOfFilters,
	handleChangeTenantPreview,
	previewTenant,
	categoryBasedOn,
	tenantsCatalog,
	setupAccountServices,
	accountId,
	autocompleteTagListIsLoading,
	handleChangenemOfTagbasedOn,
	inputTagBasedOn,
	handleSelectidOfTag,
	contextBasedOn,
	listTags,
	valueOfSelectedTag,
	changeCategorySelected,
	categorySelected,
	typeCategories,
	handleChangeSortingFactors,
	arrayOfValuetags,
	loadingProductList
}) {

	/*const { features } = useFeature();*/

	function seeAutocompleteSelect() {

		if (typeDimensions === 'recommendations' && contextBasedOn) {

			if (contextBasedOn === 'ProductTag') {
				return true;
			}

		} else {
			return false;
		}

	}
	function seeCategoryDropDown() {

		if (
			typeDimensions === 'recommendations' && contextBasedOn === 'ProductCategory'
		) {
			return true;
		}
		// return false
	}
	function getReturnBody() {
		switch (typeDimensions) {
			case 'recommendations':
				return (
					<>
						<div className='collection_sidebar_block'>
							<HeaderPannelOptions
								ruleName={nameInput}
								setRuleName={setRuleName}
								descriptionRule={descriptionRule}
								handleChangeRankingDescription={handleChangeRankingDescription}
							/>
						</div>
						<div className='collection_sidebar_block'>
							<div className='collection_sidebar_block_title'>Context</div>
							<div className='collection_sidebar_context'>
								{tenantsCatalog.length > 1 &&
									<div className='collection_sidebar_context_row'>
										<div className='collection_sidebar_context_label'>Tenant</div>
										<div className='collection_sidebar_context_value'>
											<SelectDropdown
												optionsList={tenantsCatalog}
												value={data.tenant}
												onChange={(v) => handleChangeTenant(v)}
												size="s"
												color='none'
											/>
										</div>
									</div>
								}
								<div className='collection_sidebar_context_row'>
									<div className='collection_sidebar_context_label'>Based on</div>
									<div className='collection_sidebar_context_value'>
										<BasedOnSelect
											list={categoryBasedOn}
											value={data.context}
											handleSelect={(v) =>
												handleChangeContext(v)
											}
										/>
									</div>
								</div>
								{seeCategoryDropDown() &&
									<div className='collection_sidebar_context_row'>
										<div className='collection_sidebar_context_label'>Categorie</div>
										<div className='collection_sidebar_context_value'>
											<CategoryDropDownList
												changeCategorySelected={changeCategorySelected}
												categorySelected={categorySelected}
												typeCategories={typeCategories}
												labelPosition='outer'
												color='none'
												label=''
												size='s'
											/>
										</div>
									</div>
								}
								{seeAutocompleteSelect() &&
									<div className='collection_sidebar_context_row'>
										<div className='collection_sidebar_context_label'>Tag</div>
										<div className='collection_sidebar_context_value'>
											<AutocompleteContainer
												contextBasedOn={contextBasedOn}
												optionsList={listTags}
												value={valueOfSelectedTag}
												onChange={(v) => handleSelectidOfTag(v)
												}
												autocompleteValue={inputTagBasedOn}
												autocompletePlaceholder={'Search for a tag'}
												onAutocomplete={(v) => handleChangenemOfTagbasedOn(v)}
												labelPosition="outer"
												color="none"
												size="s"
												emptyState={
													<EmptyState
														icon={'fas fa-tag'}
														text={'Please start typing a tag name'
														}
														textSize="s"
													/>
												}
												autocompleteListIsLoading={autocompleteTagListIsLoading}
											
											/>
										</div>
									</div>
								}
							</div>
						</div>
						<div className='collection_sidebar_block'>
							<SortingFactors
								data={data}
								handeChangeFilterSorting={handeChangeFilterSorting}
								ListFilter={ListFilter}
								removeAttribute={removeAttribute}
								updapteAttribute={updapteAttribute}
								onChangeAttributeOfSign={onChangeAttributeOfSign}
								handleChangeSortingFactors={handleChangeSortingFactors}
								tenantSelected={data.tenant}
								setupAccountServices={setupAccountServices}
								accountId={accountId}
								arrayOfValuetags={arrayOfValuetags}
								loadingProductList={loadingProductList}
							/>
						</div>
						<div className='collection_sidebar_block'>
							<OperatorBetweenFilters
								op={data.filtering.op}
								userfiltersList={data.filtering.filters}
								handleChangeOperator={handleChangeOperatorOfFilters}
							/>
							<ProviderFilter
								tenantSelected={data.tenant}
								previewTenant={previewTenant}
								needTenantForSearch={tenantsCatalog.length > 1}
								setupAccountServices={setupAccountServices}
								accountId={accountId}
								ListFilterAvailable={ListFilter}
								userfiltersList={data.filtering.filters}
								operator={data.filtering.op}
								onAddFilter={onAddFilter}
								onEditFilter={onEditFilter}
								onRemoveFilter={onRemoveFilter}
								handleChangeOperator={handleChangeOperatorOfFilters}
							/>
						</div>
						{/* {features.includes('emerch-demo') &&
						<TransformationsDemo></TransformationsDemo>
					} */}
					</>
				);
			case 'ranking':
				return <>
					<div className='collection_sidebar_block'>
						<HeaderPannelOptions
							ruleName={nameInput}
							setRuleName={setRuleName}
							descriptionRule={descriptionRule}
							handleChangeRankingDescription={handleChangeRankingDescription}
						/>
					</div>
					{tenantsCatalog.length > 1 &&
						<div className='collection_sidebar_block'>
							<SelectCustom
								label="Tenant"
								optionsList={tenantsCatalog}
								value={data.tenant}
								onChange={(e) => handleChangeTenant(e.target.value)}
								fullWidth={true}
								labelClassName="s_16"
							/>
						</div>
					}
					<div className='collection_sidebar_block'>
						<SortingFactors
							loadingProductList={loadingProductList}
							data={data}
							handeChangeFilterSorting={handeChangeFilterSorting}
							ListFilter={ListFilter}
							removeAttribute={removeAttribute}
							updapteAttribute={updapteAttribute}
							onChangeAttributeOfSign={onChangeAttributeOfSign}
							handleChangeSortingFactors={handleChangeSortingFactors}
							tenantSelected={data.tenant}
							setupAccountServices={setupAccountServices}
							accountId={accountId}
							arrayOfValuetags={arrayOfValuetags}
						/>
					</div>
					<div className='collection_sidebar_block'>
						<ManualSortingContainer
							data={data}
						/>
					</div>
				</>;
			case 'tagging':
				return <>
					<div className='collection_sidebar_block'>
						<HeaderPannelOptions
							ruleName={nameInput}
							setRuleName={setRuleName}
							descriptionRule={descriptionRule}
							handleChangeRankingDescription={handleChangeRankingDescription}
						/>
					</div>
					{tenantsCatalog.length > 1 &&
						<div className='collection_sidebar_block'>
							<SelectCustom
								label="Tenant"
								optionsList={tenantsCatalog}
								value={data.tenant}
								onChange={(e) => handleChangeTenant(e.target.value)}
								fullWidth={true}
								labelClassName="s_16"
							/>
						</div>
					}
					<div className='collection_sidebar_block'>
						<OperatorBetweenFilters
							op={data.filtering.op}
							userfiltersList={data.filtering.filters}
							handleChangeOperator={handleChangeOperatorOfFilters}
						/>
						<ProviderFilter
							previewTenant={previewTenant}
							tenantSelected={data.tenant}
							needTenantForSearch={tenantsCatalog.length > 1}
							setupAccountServices={setupAccountServices}
							accountId={accountId}
							ListFilterAvailable={ListFilter}
							userfiltersList={data.filtering.filters}
							operator={data.filtering.op}
							onAddFilter={onAddFilter}
							onRemoveFilter={onRemoveFilter}
							onEditFilter={onEditFilter}
							handleChangeOperator={handleChangeOperatorOfFilters}
						/>
					</div>
				</>;

			default:
				break;
		}
	}

	return (

		<div className="collection_sidebar flex_item_fix vscroll_custom">
			{getReturnBody()}
		</div>
	);
}
