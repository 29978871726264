import React, { useContext, useEffect, useState } from 'react';
import Btn from './../../../../Components/Btn';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import TargetListContainer from './TargetListContainer';
import './TargetBusinessOperationContainer.css';
import TargetSkuListContainer from './TargetSkuListContainer';
import TargetConditionsContainer from './TargetConditionsContainer';
import Modal from './../../../../Components/Modal';
import Confirm from './../../../../Components/Confirm';
import PopinConfirm from './../../../../Components/PopinConfirm';
export default function TargetBusinessOperationContainer() {

	const {
		setStepBusinessOperationCreate
	} = useContext(CreateBusinessOperationContext);
	
	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [changeOnList, setChangeOnList] = useState(false);
	const [poppinIsOppen, setPoppinIsOppen] = useState(false);
	const [clickOnSave, setClickOnSave] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const cancelledChange = () => {
		if (changeOnList) {
			setPoppinIsOppen(true);
		} else {
			setModalIsOppen(false);
		}
	};
	useEffect(() => {
		if (clickOnSave && isSaved) {
			setClickOnSave(false);
			setModalIsOppen(false);
			setIsSaved(false);
		}
	}, [clickOnSave]);
	return (
		<div className="new-module">
			<div className="simplify_target_container">
				<h1>Target</h1>
				<div className="simplify_subtitles">
					<p>Who can see this operation? Where and when can it be displayed? Specificy here details about visitors and products that are targeted by this scenario.</p>
				</div>
				<div className="simplify_target_main_container">
					<TargetListContainer />
					<div className="simplify_target_type_selection">

						{modalIsOppen && !clickOnSave &&
							<Modal
								isOpen={modalIsOppen}
								width="550"
								height="auto"
								minHeight="196"
								onClose={() => cancelledChange()}
							>

								<div className="modal_body">
									<TargetSkuListContainer
										setModalIsOppen={(e) => cancelledChange(e)}
										setChangeOnList={(e) => setChangeOnList(e)}
										setClickOnSave={(e) => setClickOnSave(e)}
										setIsSaved={(e) => setIsSaved(e)}
									/>
								</div>
							</Modal>
						}
						{!clickOnSave &&
							<Confirm
								isOpen={poppinIsOppen}
								title='Close item list'
								text='Are you sure to close without saving?'
								onClose={() => setPoppinIsOppen(false)}
								confirmCallback={() => {setModalIsOppen(false); setChangeOnList(false);}}
								confirmText='Quit without saving'
								cancelText='Stay'
							/>
						}
						<TargetConditionsContainer
							setModalIsOppen={(e) => setModalIsOppen(e)}
							setChangeOnList={(e) => setChangeOnList(e)}
							setClickOnSave={(e) => setClickOnSave(e)}
						/>

					</div>
				</div>
				<div className="target_next_btn">
					<Btn
						onClickFunction={(e) => { setStepBusinessOperationCreate(2); }}
						message="Next step"
						icon="fas fa-chevron-right"
						iconPosition="after"
						size="l"
					/>
				</div>
			</div>
		</div>
	);
}
