/* eslint-disable no-prototype-builtins */
import {v4 as uuidv4} from 'uuid';
import {isEqual} from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import {getFakeProducts} from './fakeData';
import getVideoId from 'get-video-id';
import { ProductRecommendationsValue, ProductReferencesValue } from '../WysiwygEditor/Utils/TemplateOperations';

const generateProducts = (ids) => {
	return getFakeProducts(ids.length)
		.map(x => ({
			...x,
			Id: x,
		}));
};


export function intToLetterVariation(i) {
	return i === 0 ? 'Original (A)'
		: i === 1 ? 'B'
			: i === 2 ? 'C'
				: i === 3 ? 'D'
					: i === 4 ? 'E'
						: i === 5 && 'F';
}

export function checkURL(str) {
	let url;
	try {
		url = new URL(str);
	} catch (_) {
		return false;
	}
	return true;
}

export function jsonTryParse (str) {
	try {
		return JSON.parse(str);
	} catch (error) {
		return false;
	}
}


export function groupBy(items, getKey, getValue) {
	const grouping = {};
	for (let i = 0; i < items.length; i++) {
		const item = items[i];
		const key = getKey(item);
		if (!grouping[key]) {
			grouping[key] = [];
		}
		grouping[key].push(getValue ? getValue(item) : item);
	}
	return grouping;
}

export function propertiesProcessing() {

	function setupTemplatePropertyValue(param) {
		let newParam = { ...param };
		if (param.Editor.Type === 'CountDown') {
			if (!param.Value) {
				const timeZone = param.Editor.Timezone || moment.tz.guess() || 'Europe/Paris';
				const date = moment().add(7, 'days').tz(timeZone, true);
				newParam.Value = new String(date.format('ddd MMM DD YYYY HH:mm [GMT]ZZ'));
			} else {
				newParam.Value = new String(newParam.Value);
			}
			newParam.Value.isCountDown = true;
		}
		if (param.Editor.Type === 'ProductReferences') {
			newParam.Value = new ProductReferencesValue(param.Value || '');
		}
		if (param.Editor.Type === 'ProductRecommendations') {
			newParam.Value = new ProductRecommendationsValue(param.Value || '');
		}
		if (param.Editor.Type === 'DatePicker') {
			if (param.Value) {
				newParam.Value = new String(newParam.Value);
			}
		}
		if (param.Editor.Type === 'Font') {
			newParam.Value = new String(newParam.Value);
			newParam.Value.isFont = true;
		}
		return newParam;
	}

	function templatePropertyToStyleProperty(param) {
		if (param.Editor.Type === 'Collection') {
			const item = param.Content.reduce((prev, content) => {
				const newParam = setupTemplatePropertyValue(content);
				return { ...prev, [newParam.Name]: newParam.Value };
			}, { Key: uuidv4() });

			return {
				Key: uuidv4(),
				Name: param.Name,
				IsCollection: true,
				Value: [item]
			};
		} else if (param.Editor.Type === 'Data') {
			const json = jsonTryParse(param.Value);
			return {
				Key: uuidv4(),
				Name: param.Name,
				IsCollection: true,
				Value: json || []
			};
		} else if (param.Editor.Type === 'Group') {
			let newValue = {};
			param.Content.forEach(item => {
				const newParam = setupTemplatePropertyValue(item);
				newValue[newParam.Name] = newParam.Value;
			});
			const isActiveProperty = param.IsActiveProperty;
			const multistepProperty = param.MultiSteps;
			const valueProperty = isActiveProperty ? { ...newValue, [isActiveProperty.Name]: isActiveProperty.Value } : newValue;

			return {
				Key: uuidv4(),
				Name: param.Name,
				IsGroup: true,
				Value: multistepProperty ? {
					...valueProperty,
					[multistepProperty.Name]: multistepProperty.Value
				} : valueProperty
			};
		} else {
			const newParam = setupTemplatePropertyValue(param);
			return {
				Key: uuidv4(),
				Name: newParam.Name,
				Value: newParam.Value
			};
		}
	}

	const areSameStyles = (s1Properties, s2Properties) =>
		isEqual(s1Properties.map(removePropertyKeys), s2Properties.map(removePropertyKeys));


	const addPropertyKeys = (property) => {
		if (property.IsCollection) {
			return { ...property, Key: uuidv4(), Value: (property.Value || []).map(v => ({ ...v, Key: uuidv4() })) };
		}
		return { ...property, Key: uuidv4() };
	};

	const removePropertyKeys = (property) => {
		if (property.IsCollection) {
			return { ...property, Key: null, Value: (property.Value || []).map(v => ({ ...v, Key: null })) };
		}
		return { ...property, Key: null };
	};

	const setActiveItemIntoCollection = (property, items, activeItemIndex) => {
		const activeItemPropertyName = (property.Editor && property.Editor.ActiveItemPropertyName) || 'IS_ACTIVE_ITEM';
		for (let i = 0; i < items.length; i++) {
			const value = items[i];
			value[activeItemPropertyName] = i === activeItemIndex;
		}
	};

	const formatPropertiesToStyleProperties = (formatProperties) => {
		const buildStyleProperty = (property) => {
			if (property.Editor.Type === 'Group') {
				return templatePropertyToStyleProperty(property);

			} else if (property.Editor.Type === 'Collection') {
				const p = templatePropertyToStyleProperty(property);
				const singleValue = p.Value[0];
				let collectionItems = [];
				for (let i = 0; i < property.Editor.CollectionDefaultSize; i++) {
					collectionItems.push(singleValue);
				}
				setActiveItemIntoCollection(property, collectionItems, 0);
				return { ...p, Value: collectionItems };
			} else {
				return templatePropertyToStyleProperty(property);
			}
		};
		return formatProperties.map(buildStyleProperty).map(addPropertyKeys);
	};

	const getLiquidModel = (modelProperties) => {
		const calculateCountdownProperties = (property, value) => {

			let date;
			const matchHourFormat = value.match(/^(\d?\d:\d\d)-(.*)/g);
			if (matchHourFormat) {
				date = getNextEndDate(value);
			} else {
				date = Date.parse(value);
			}

			const totalSeconds = Math.floor((date - Date.now()) / 1000);
			const getDhms = () => {
				const totalMinutes = Math.floor(totalSeconds / 60);
				const totalHours = Math.floor(totalMinutes / 60);
				const totalDays = Math.floor(totalHours / 24);

				const hours = totalHours % 24;
				const minutes = totalMinutes % 60;
				const seconds = totalSeconds % 60;

				return [totalDays, hours, minutes, seconds, false];
			};
			const format2Digits = (d) => d.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
			const dhms = totalSeconds < 0 ? ['0', '00', '00', '00', true] : getDhms();
			const countDownProperties = {};
			countDownProperties[property + '_DAYS'] = dhms[0];
			countDownProperties[property + '_HOURS'] = format2Digits(dhms[1]);
			countDownProperties[property + '_MINUTES'] = format2Digits(dhms[2]);
			countDownProperties[property + '_SECONDS'] = format2Digits(dhms[3]);
			countDownProperties[property + '_HASFINISHED'] = dhms[4];
			return countDownProperties;
		};

		const getProductShelvingProperty = (shelvingRules) => {
			const nbOfProducts = shelvingRules.map(x => x.NumberOfProducts).reduce((a, b) => a + b, 0);
			const refs = new Array(nbOfProducts).fill().map((_, i) => 'REF'+i);
			const property = {};
			property['RecommandationProducts'] = generateProducts(refs);
			return property;
		};

		const getProductReferencesProperty = (productReferences) => {
			const property = {};
			property['Products'] = generateProducts(productReferences.filter(x => x));
			return property;
		};

		const addSpecialModelProperties = (key, value, model) => {
			if (value && value.isCountDown) {
				const countdownProperties = calculateCountdownProperties(key, value);
				for (const [key2, value2] of Object.entries(countdownProperties)) {
					model[key2] = value2;
				}
			}
			if (value && value instanceof ProductReferencesValue) {
				const contentProductsProperty = getProductReferencesProperty(ProductReferencesValue.fromStringInput(value));
				for (const [key2, value2] of Object.entries(contentProductsProperty)) {
					model[key2] = value2;
				}
			}
			if (value && value instanceof ProductRecommendationsValue) {
				const contentProductsProperty = getProductShelvingProperty(ProductRecommendationsValue.fromStringInput(value));
				for (const [key2, value2] of Object.entries(contentProductsProperty)) {
					model[key2] = value2;
				}
			}
		};
		const values = {};
		for (var i = 0; i < modelProperties.length; i++) {
			const modelProperty = modelProperties[i];
			const getModelEntries = () => {
				const modelEntries = {};
				if (modelProperty.IsGroup === true) {
					const groupProperty = {};
					for (const [key, value] of Object.entries(modelProperty.Value)) {
						groupProperty[key] = value;
						addSpecialModelProperties(key, value, groupProperty);
					}
					modelEntries[modelProperty.Name] = groupProperty;
					return modelEntries;
				}
				else if (modelProperty.IsCollection === true) {
					const collectionProperty = [];
					for (var j = 0; j < modelProperty.Value.length; j++) {
						const collectionItem = {};
						collectionProperty.push(collectionItem);
						for (const [key, value] of Object.entries(modelProperty.Value[j])) {
							const name = key;
							collectionItem[name] = value;
							addSpecialModelProperties(key, value, collectionItem);
						}
					}
					modelEntries[modelProperty.Name] = collectionProperty;
					return modelEntries;
				}
				else {
					modelEntries[modelProperty.Name] = modelProperty.Value;
					addSpecialModelProperties(modelProperty.Name, modelProperty.Value, modelEntries);
					return modelEntries;
				}
			};

			for (const [key, value] of Object.entries(getModelEntries())) {
				values[key] = value;
			}
		}
		return values;
	};

	const hasCountDowns = (modelProperties) => {
		for (var i = 0; i < modelProperties.length; i++) {
			const modelProperty = modelProperties[i];

			if (modelProperty.IsGroup === true) {
				for (const [key, value] of Object.entries(modelProperty.Value)) {
					if (value && value.isCountDown) {
						return true;
					}
				}
			}
			else if (modelProperty.IsCollection === true) {
				for (var j = 0; j < modelProperty.Value.length; j++) {
					for (const [key, value] of Object.entries(modelProperty.Value[j])) {
						if (value && value.isCountDown) {
							return true;
						}
					}
				}
			}
			else {
				if (modelProperty.Value && modelProperty.Value.isCountDown) return true;
			}
		}
		return false;
	};

	return {
		setActiveItemIntoCollection,
		formatPropertiesToStyleProperties,
		templatePropertyToStyleProperty,
		getLiquidModel,
		addPropertyKeys,
		areSameStyles,
		hasCountDowns
	};
}


export function validate() {

	function validateEditorState(variations, saveError) {
		let areStyleSelected = true;
		let areFormatSelected = true;

		variations.forEach(variation => {
			if (!variation.EditorState || !variation.EditorState.currentStyle) areStyleSelected = false;
			if (!variation.EditorState || !variation.EditorState.selectedFormat || !variation.EditorState.selectedFormat.Identifier) areFormatSelected = false;
		});
		let newSaveError = [...saveError];
		if (!areStyleSelected) {
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'selectedStyle');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'selectedStyle', error: 'Each variation must have a style selected' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'selectedStyle');
			if (index !== -1) newSaveError.splice(index, 1);
		}

		if (!areFormatSelected) {
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'selectedFormat');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'selectedFormat', error: 'Each variation must have a format selected' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'selectedFormat');
			if (index !== -1) newSaveError.splice(index, 1);
		}
		return { isValid: (areFormatSelected && areStyleSelected), newSaveError };
	}

	function validateFormatData(variations, saveError) {
		let areFormatsValid = true;
		let areTagsValid = true;

		variations.forEach(variation => {
			if (variation.Template.Identifier === undefined) areFormatsValid = false;
			if (variation.VariationType === 'PageTag' && variation.Tag === '') areTagsValid = false;
		});

		let newSaveError = [...saveError];
		if (!areFormatsValid) {
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'format');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'format', error: 'Each variation must have a display format' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'format');
			if (index !== -1) newSaveError.splice(index, 1);
		}

		if (!areTagsValid) {
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'tag');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'tag', error: 'Each variation must have a valid tag name' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'tag');
			if (index !== -1) newSaveError.splice(index, 1);
		}
		return { isValid: (areFormatsValid && areTagsValid), newSaveError };
	}
	function validateFormatDataAbtest(variations, saveError) {

		let areTagsValid = true;

		variations.forEach(variation => {
			if (variation.VariationType === 'PageTag' && variation.Tag === '') areTagsValid = false;
		});

		let newSaveError = [...saveError];

		if (!areTagsValid) {
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'tag');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'tag', error: 'Each variation must have a valid tag name' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'tag');
			if (index !== -1) newSaveError.splice(index, 1);
		}
		return { isValid:  areTagsValid, newSaveError };
	}
	function validateInformationsData(campaign, saveError) {
		let isCampaignNameValid = true;
		let isPreviewUrlValid = true;
		let isActivationDatesValid = true;

		let newSaveError = [...saveError];
		if (campaign.Name.length < 2) {
			isCampaignNameValid = false;
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'campaignName');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'campaignName', error: 'Campaign name is mandatory' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'campaignName');
			if (index !== -1) newSaveError.splice(index, 1);
		}

		const previewURL = campaign.UrlDebug || '';
		if (previewURL && previewURL.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) === null) {
			isPreviewUrlValid = false;
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'previewUrl');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'previewUrl', error: 'Please enter a valid url as preview url' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'previewUrl');
			if (index !== -1) newSaveError.splice(index, 1);
		}
		// const startDate = campaign.StartDateUtc
		// const endDate = campaign.EndDateUtc
		// if (campaign.HasEndDate && endDate && endDate < startDate) {
		// 	isActivationDatesValid = false
		// 	const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === "dates")
		// 	if (isAlreadyDisplayed === -1) newSaveError.push({ name: "dates", error: "The end date has to be after the start date" })

		// }
		const startDateInTimezone = campaign.StartDateInTimezone;
		const endDateInTimezone = campaign.EndDateInTimezone;
		if (campaign.HasEndDate && endDateInTimezone && moment.parseZone(endDateInTimezone).toDate() < moment.parseZone(startDateInTimezone).toDate()) {
			isActivationDatesValid = false;
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'dates');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'dates', error: 'The end date has to be after the start date' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'dates');
			if (index !== -1) newSaveError.splice(index, 1);
		}

		return { isValid: (isActivationDatesValid === true && isPreviewUrlValid === true && isCampaignNameValid === true), newSaveError };
	}

	function validateTriggersData(triggers, saveError) {
		let newSaveError = [...saveError];
		const triggersByGroup = groupBy(triggers, t => t.Group ? t.Group.Id : null);

		let isExitValid = true;
		for (const key in triggersByGroup) {
			if (Object.hasOwnProperty.call(triggersByGroup, key)) {
				const group = triggersByGroup[key];
				if (group.find(x => x.Name === 'TRGEXITINTENT' && x.ValueToCompare === true) &&
					group.find(x => x.Name === 'TRGEXITCOMEBACK' && x.ValueToCompare === true) &&
					group.find(x => x.Name === 'TRGCHECKDEVICE' && (x.ValueToCompare.includes(1) || x.ValueToCompare.includes(2)))) {
					isExitValid = false;
				}
			}
		}

		if (!isExitValid) {
			const isAlreadyDisplayed = newSaveError.findIndex(error => error.name === 'exitComeBack');
			if (isAlreadyDisplayed === -1) newSaveError.push({ name: 'exitComeBack', error: 'You cannot choose the trigger \'Visitor left and comes back\' and Exit Intent in the same time' });
		}
		else {
			const index = newSaveError.findIndex(error => error.name === 'exitComeBack');
			if (index !== -1) newSaveError.splice(index, 1);
		}

		return { isValid: (isExitValid === true), newSaveError };
	}
	function validateCustomData(variations, saveError){
		let newSaveError = [...saveError];
		let areFormatsValid = true;

		variations.forEach(variation => {
			if (variation.SlideFormat === null || variation.SlideFormat === '' ) {
				newSaveError.push({ name: 'SlideFormat', error: 'You need to choose a slide type' });
				areFormatsValid = false;
			}
		});
		return { isValid: (areFormatsValid), newSaveError };
	}
	return {
		validateFormatData,
		validateEditorState,
		validateInformationsData,
		validateTriggersData,
		validateCustomData,
		validateFormatDataAbtest
	};
}

export function propertyAccessor() {
	const getPropIfDefined = (properties, name) => {
		if (!properties) return null;
		const prop = properties.find(x => x.Name === name);
		const propValue = prop && prop.Value;
		return { hasValue: propValue !== null && propValue !== undefined, value: propValue }; // allow returning "" or 0
	};
	const getGroupPropertyValue = (properties, editor, containerPropertyName) => {
		var groupProperty = getPropIfDefined(properties, containerPropertyName);
		return (groupProperty.hasValue && groupProperty.value[editor.Name] !== null) ? groupProperty.value[editor.Name] : editor.Value;
	};
	const getPropertyValue = (properties, editor) => {
		var property = getPropIfDefined(properties, editor.Name);
		return property.hasValue ? property.value : editor.Value;
	};
	const getCollectionItems = (properties, collection) => {
		const valuesFromProperties = getPropIfDefined(properties, collection.Name);
		return (valuesFromProperties.hasValue ? valuesFromProperties.value || [] : []);
	};

	const getDependentPropertyValue = (properties, dependentPropertyName, collectionItem) => {
		const split1 = dependentPropertyName.split('.');
		const split2 = split1[0].split('['); // collection case (dependentPropertyName <= "$CURRENT_ITEM$.POSITION")
		const firstLevelPropertyName = split2[0];
		const firstLevelProperty = properties.find(p => p.Name === firstLevelPropertyName);
		if (!firstLevelProperty) return { found: false };
		if (firstLevelProperty.IsCollection) {
			if (split1.length !== 2 || !collectionItem.hasOwnProperty(split1[1])) return { found: false };
			return { found: true, value: collectionItem[split1[1]] };
		} else if (firstLevelProperty.IsGroup) {
			if (split1.length !== 2 || !firstLevelProperty.Value.hasOwnProperty(split1[1])) return { found: false };
			return { found: true, value: firstLevelProperty.Value[split1[1]] };
		} else {
			return { found: true, value: firstLevelProperty.Value };
		}
	};

	return {
		getPropertyValue,
		getGroupPropertyValue,
		getCollectionItems,
		getDependentPropertyValue
	};
}


export function dateUtils() {

	const addDays = (date, days) => {

		return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
	};

	return {
		addDays
	};
}


export const isAnUrl = (url) => {
	if (url.match(/http?:|https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#/=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
		return true;
	} else {
		return false;
	}
};
export const validateConnector = (connector) => {
	const arrayOfErrors = [];

	if (connector.authorizationType == 'NoAuth') { /* empty */ }
	if (connector.authorizationType == 'ApiKey') {
		if (connector.apiKeyHeader.length === 0) {
			const message = {
				name: 'apiKeyHeader',
				message: 'Api key header can\'t be empty',
			};
			arrayOfErrors.push(message);
		}
		if (connector.apiKey.length === 0) {
			const message = {
				name: 'apiKey',
				message: 'Api key can\'t be empty',
			};
			arrayOfErrors.push(message);
		}
	}
	if (connector.authorizationType == 'Token') {
		if (connector.authorizationScheme.length === 0) {
			const message = {
				name: 'authorizationScheme',
				message: 'Authorization scheme can\'t be empty',
			};
			arrayOfErrors.push(message);
		}
		if (connector.authorizationCredentials.length === 0) {
			const message = {
				name: 'authorizationCredentials',
				message: 'Authorization credentials can\'t be empty',
			};
			arrayOfErrors.push(message);
		}
	}
	return arrayOfErrors;
};

export const richTextOption = {
	toolbar_items_size: 'small',
	statusbar: false,
	height: 260,
	fontsize_formats: '8px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px 62px 64px 66px 68px 70px 72px',
	font_formats: `
		Arial=arial,helvetica,sans-serif;
		Helvetica=helvetica,arial,sans-serif;
		Verdana=verdana,helvetica,sans-serif;
		Courier=courier new,courier,monospace;
	`,
	font_css: ['https://beyableprod.blob.core.windows.net/fonts/_v1/all.min.css'],
	content_style: 'p {margin-top: 0; margin-bottom: 0;}',
	plugins: [
		'autolink link',
		'code',
		'emoticons',
		'paste'
	],
	menubar: 'edit insert format tools',
	toolbar: [
		'bold italic underline | alignleft aligncenter alignright | forecolor backcolor',
		'fontsizeselect | lineheight | fontselect'
	],
	paste_as_text: true
};

export const richTextOptionMinimal = {
	toolbar_items_size: 'small',
	statusbar: false,
	height: 260,
	font_css: ['https://beyableprod.blob.core.windows.net/fonts/_v1/all.min.css'],
	content_style: 'p {margin-top: 0; margin-bottom: 0;}',
	plugins: [
		'code',
		'paste'
	],
	menubar: '',
	toolbar: [
		'bold',
	],
	paste_as_text: true
};

export function fontUtil(fontData) {

	const getFontFamily = (htmlText) => {
		return htmlText.split('font-family:').reduce((acc,val,i)=>{
			if(i<1) return acc;
			let fontFamily = val.split(';')[0].trim();
			if(fontFamily.contains(',')) fontFamily = fontFamily.split(',')[0].trim();
			let fontArray = [ ...acc, fontFamily];
			return [...new Set(fontArray)];
		},[]);
	};

	const getFontImport = (fonts) => {
		let fontImports = [];
		for(let i=0; i<fonts.length; i++) {
			let f = fonts[i];
			for(let j=0; j<fontData.length; j++) {
				let fd = fontData[j];
				if(f.indexOf(fd.displayName) != -1) {
					fontImports.push(fd.fontsURL + 'style.css');
				}
			}
		}
		return fontImports;
	};

	const extractFontImport = (value) => {
		if (typeof value === 'string' || value instanceof String){
			let font = [];
			if (value.isFont) {
				if (value.toString() !== 'inherit') {
					font.push(value.toString());
				}
			} else {
				font = getFontFamily(value);
			}
			const fontImports = getFontImport(font);
			return fontImports.map(url => `@import url('${url}')`);
		}
		return [];
	};

	return {
		extractFontImport
	};
}


/// Youtube video format
export default function videosUtils() {
	function sendErrorId() {
		return {
			type: 'error',
			key: 'url',
			message: 'Please enter a valid youtube URL'
				
		};
	}
	function getDataYoutubeForIdVideo(youtubeId, url) {
		return fetch(`https://www.googleapis.com/youtube/v3/videos?id=${youtubeId}&key=${window.BEYABLE_env.YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics`
		).then(resJson => resJson.json())
			.then(res => {
				if (res.items.length !== 0) {
					let videoData = {};
					videoData = res.items[0].snippet;
					videoData.type = 'success';
					videoData.youtubeId = youtubeId;
					videoData.url = url;
					return videoData;
				} else {
					return sendErrorId();
				}
			}).catch(error => {
				console.error('error e', error);
			});
	}

	const getId = async (url) => {
		const id = getVideoId(url);
		if (id.id === undefined) {
			return sendErrorId();
		} else {
			const res = await getDataYoutubeForIdVideo(id.id, url);
			return res;
		}
	};

	function isCorrectUrlYoutube(elem) {
		if (elem.contains('www.youtube.com')) {
			return true;
		} else {
			return false;
		}
	}

	function checkErrors(url) {
		const arrayOfErrors = [];
		if (!isCorrectUrlYoutube(url)) {
			arrayOfErrors.push({
				key: 'url',
				message: 'Please enter a valid youtube URL'
			});
		}
		return arrayOfErrors;
	}

	return {
		checkErrors,
		getId,
		isCorrectUrlYoutube,
		sendErrorId,

	};
}


function getNextEndDate(input_string) {
	// input_string : 18:00-Europe/Paris-0:2:3

	function __get_tz_offset(tz) {
		var today = new Date();
		today.setHours(0, 0, 0, 0);

		var date_string;
		try {
			date_string = today.toLocaleString('fr-FR', {timeZone: tz});
		} catch (error) {}

		if (!date_string) return false;

		var date = __local_string_to_date(date_string);
		var rest = today - date;
		var diff = {};
	
		diff.milli = rest;
		rest = Math.floor(rest / 1000);
		diff.sec = rest % 60;
		rest = Math.floor((rest - diff.sec) / 60);
		diff.min = rest % 60, 0;
		rest = Math.floor((rest - diff.min) / 60);
		diff.hour = rest;
	
		return diff;
	}
	
	function __local_string_to_date(date_str) {
		var sp = date_str.split(' ');
		var datePart = sp[0].split('/');
		var timePart = sp[1].split(':');
		var date = new Date(datePart[2], parseInt(datePart[1], 10) - 1, datePart[0], timePart[0], timePart[1], timePart[2]);
		return new Date(date);
	}
	
	function __next_day_of_week(day_of_week, hour, min) {
		day_of_week = parseInt(day_of_week, 10);
		var result = new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate() + (7 + day_of_week - now.getDay()) % 7,
			hour,
			min
		);
		if (result < now) result.setDate(result.getDate() + 7);
		return result;
	}
	
	function __get_modulo(value, mod) {
		return ((value % mod) + mod) % mod;
	}
	
	var now = new Date();
	var sp = input_string.split(/:|-/);
	var hour = parseInt(sp.shift(), 10);
	var min = parseInt(sp.shift(), 10);
	var tz = sp.shift();
	var days_of_week = sp;
	var tz_offset = __get_tz_offset(tz);
	if (!tz_offset) {
		return now;
	}
	
	var all_min = min + tz_offset.min;
	min = __get_modulo(all_min, 60);

	var all_hour = hour + tz_offset.hour + Math.floor(all_min / 60);
	hour = __get_modulo(all_hour, 24);

	var day_offset = Math.floor(all_hour / 24);
	var end_date = null;
	if (days_of_week.length) {
		for (var i = 0; i < days_of_week.length; i++) {
			var d = parseInt(days_of_week[i], 10) + day_offset;
			var tmp_end = __next_day_of_week(d, hour, min);
			if (tmp_end > now && (!end_date || tmp_end < end_date)) {
				end_date = tmp_end;
			}
		}
	} else {
		end_date = new Date(now.getTime());
		end_date.setHours(hour,min,0,0);
		if (end_date < now) end_date.setDate(end_date.getDate() + 1);
	}
	return end_date;
}

export const getLocalisedValue = (v) => {
	const i18nL= localStorage.getItem('i18nL');
	const currLangCode = i18nL.includes('fr') ? 'fr' : 'en';
	const defaultLangCode = 'en';

	if (!v) return '';

	if (typeof v === 'string') return v;

	if (typeof v === 'object') {
		if (currLangCode in v) return v[currLangCode];
		// if (defaultLangCode in v) return v[defaultLangCode];

		const [firstKey, firstValue] = Object.entries(v)[0];
		return firstValue;
	}
};

export const designPropertiesAbTestRedirection = {
	Name: "redirections",
	Value :{
		Name: "Original variation",
		RedirectionType : {
			value : "unique",
		},
		RedirectionUrl : ""
	} 
};

export function dividePercent(nb, nbParty) {

	const base = Math.floor(nb / nbParty);

	//montant restant après division en parties égales
	const rest = nb % nbParty;

	// tableau pour stocker les montants pour chaque partie
	const amountParty = new Array(nbParty).fill(base);
	
	// Répartir le reste de manière équitable
	for (let i = 0; i < rest; i++) {
		amountParty[i]++;
	}
	
	return amountParty;
}
export function getElementInteractionName(mainElement){
	let name = mainElement.selectorName || '';
	name = name.split(' ').join('_');
	name = encodeURIComponent(name);
	return 'evtcpg:click_' + name;
}
