import React, {useEffect, useState} from 'react';
import './index.css';
import Header from './components/Header';
import {CampaignsContext} from './context';
import ABToolbar from './components/ABToolbar';
import FormatToolbar from './components/FormatToolbar';
import FormatEditor from './components/FormatEditor/FormatEditor';
import CSSEditor from './components/CSSEditor';
import Informations from './components/Informations';
import Triggers from './components/Triggers';
import PatchEditorIde from './components/PatchEditor/PatchEditorIde';
import PatchEditorToolBar from './components/PatchEditor/PatchEditorToolBar';
import GraphEditor from './components/GraphEditor/GraphEditor';
import GraphEditorToolbar from './components/GraphEditor/GraphEditorToolbar';
import PanelHttpConnector from './components/PanelHttpConnector';
import PanelRecommendations from './components/PanelRecommendations';
import BuilderEditorContainer from './components/BuilderEditor/BuilderEditorContainer';
import BuilderEditorToolbar from './components/BuilderEditor/BuilderEditorToolbar';
import CreateBuildEditorContextProvider from './components/BuilderEditor/BuildEditorContext';
import CustomCampaignContainer from './components/CustomCampaign/CustomCampaignContainer';
import AbTestContainer from './components/AbTestRedirection/AbTestContainer';
import MobileContainer from './components/MobileEditor/MobileContainer';
export default function CampaignCreationContainer() {

	const {
		step,
		mode,
		campaign,
		handleSetBuilderPropertyOnCampaign,
		isSaving,
		isABTestingActive,
		variations,
		getCurrentVariation,
		handleSetBuilderDefinitions,
		editorMode,
		availableFormats,
		authServices,
		accountId,
		$http,
		systemServices
	} = CampaignsContext();

	const [dynamicHeader, setDynamicHeader] = useState();
	const [dynamicTemplate, setDynamicTemplate] = useState();
	const [showTriggers, setShowTriggers] = useState(true);
	const [bodyColor, setBodyColor] = useState('grey');

	useEffect(() => {
		if (editorMode) {
			if (editorMode === 'patch/code') {
				setDynamicHeader(<PatchEditorToolBar />);
				setDynamicTemplate(<PatchEditorIde />);
			} else if (editorMode === 'patch/graph') {
				setDynamicHeader(<GraphEditorToolbar />);
				setDynamicTemplate(<GraphEditor />);
			} else if (editorMode === 'campaign/builder') {
				setDynamicHeader(<BuilderEditorToolbar/>);
				setDynamicTemplate(<BuilderEditorContainer/>);
			} else if (editorMode === 'custom') {
				setDynamicHeader(<FormatToolbar />);
				setDynamicTemplate(<CustomCampaignContainer />);
			} else if (editorMode === 'abTest') {
				setDynamicHeader(<></>);
				setDynamicTemplate(<AbTestContainer />);
			}else if (editorMode === 'appNative/patch/code') {
				setDynamicHeader(<></>);
				setDynamicTemplate(<MobileContainer />);
			}else {
				setDynamicHeader(<FormatToolbar />);
				setDynamicTemplate(<FormatEditor />);
			}
		} else {
			setDynamicHeader(<FormatToolbar />);
			setDynamicTemplate(<FormatEditor />);
		}
	}, [editorMode]);

	useEffect(() => {
		setBodyColor(step === 3 ? 'white' : 'grey');
	}, [step]);

	return !availableFormats.loaded ? (
		<div className="mask">
			<div className="wheel size_32"></div>
		</div>
	) : (
		<div className="campaign_editor flex flex_v">
			{editorMode === 'campaign/builder' ?
				<CreateBuildEditorContextProvider
					step={step}
					mode={mode}
					campaign={campaign}
					handleSetBuilderPropertyOnCampaign={handleSetBuilderPropertyOnCampaign}
					isSaving={isSaving}
					isABTestingActive={isABTestingActive}
					variations={variations}
					getCurrentVariation={getCurrentVariation}
					handleSetBuilderDefinitions={handleSetBuilderDefinitions}
					authServices={authServices}
					accountId={accountId}
					$http={$http}
					systemServices={systemServices}
				>
					<div className="flex_item_fix">
						<Header />
						<ABToolbar />
						{dynamicHeader}
					</div>
					<div className={bodyColor == 'grey' ? 'campaign_editor_body campaign_editor_body_grey flex_item_full flex flex_v' : 'campaign_editor_body flex_item_full flex flex_v'}>
						<div className="flex_item_full vscroll">
							{dynamicTemplate}
							{showTriggers && <Triggers />}
							<Informations />
						</div>
					</div>
				</CreateBuildEditorContextProvider>
				:
				<>
					<div className="flex_item_fix">
						<Header />
						<ABToolbar />
						{dynamicHeader}
					</div>
					<div className={bodyColor == 'grey' ? 'campaign_editor_body campaign_editor_body_grey flex_item_full flex flex_v' : 'campaign_editor_body flex_item_full flex flex_v'}>
						<div className="flex_item_full vscroll">
							{dynamicTemplate}
							{showTriggers && <Triggers />}
							<Informations />
						</div>
					</div>
				</>
			}

			<div className="flex_item_fix">
				<CSSEditor />
			</div>
			<PanelHttpConnector />
			<PanelRecommendations />
		</div>
	);
}
