import React, { useState, useEffect } from 'react';
import SpinnerWheel from './../../../Components/SpinnerWheel';
import Btn from '../../../Components/Btn';
import SearchBar from '../../../Components/SearchBar';
import EmptyState from '../../../Components/EmptyState';
import TableGridContainer from '../UI/TableGridContainer';
import TableRowItem from '../UI/TableRowItem';
import TableColItem from '../UI/TableColItem';
import { getRamdomDate, formatDate } from '../utils/tranformDates';
import DropdownRanking from './DropdownTagging';
import { duplicateTaggingById, deleteTagById } from './services/taggingServices';
import Confirm from '../../../Components/Confirm';
import Pagination from '../UI/Pagination';

const defaultPagin = {
	offset: 0,
	limit: 10,
};

export default function ProductTaggingContainer({
	systemServices,
	productTaggingServices,
	$routeParams
}) {

	const [rankingList, setRankingList] = useState();
	const [firstLoading, setFirstLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [dataFiltered, setDataFiltered] = useState('Name');
	const [noData, setNoData] = useState(false);
	const [zeroData, setZeroData] = useState(false);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [modalDeleteRuleIsOpen, setmodalDeleteRuleIsOpen] = useState(false);
	const [elementToDelete, setelementToDelete] = useState({});
	const [paginationOptions, setPaginationOptions] = useState(defaultPagin);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, settotalCount] = useState(0);
	const [descSort, setdescSort] = useState(true);

	const getRanking = (valueInSearchBar, dataFiltered, descSort, paginationOptions, callbackSuccess, callbackError) => {
		return productTaggingServices.getTaggingList(valueInSearchBar, dataFiltered, descSort, paginationOptions, callbackSuccess, error => {
			systemServices.showError();
			callbackError();
		});
	};

	const fetchData = (newPaginationOptions) => {
		if (newPaginationOptions) {
			setPaginationOptions(newPaginationOptions);
		} else {
			newPaginationOptions = {...paginationOptions};
		}
		setLoading(true);
		getRanking(valueInSearchBar, dataFiltered, descSort, newPaginationOptions, (newData, headers) => {
			const totalCount = headers()['x-total-count'];
			settotalCount(totalCount);
			setLoading(false);

			if(newData.length === 0){
				// Cas de la suppression
				if (newPaginationOptions.offset > 1 && totalCount > 0) {
					fetchData(defaultPagin);
					return;
				}
				setNoData(true);
				if (firstLoading) {
					setZeroData(true);
				}
			}else{
				setNoData(false);
			}

			setRankingList(newData);
			setFirstLoading(false);
		}, error => {
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchData(defaultPagin);
	}, [descSort, dataFiltered]);

	function handleClickFilter(el){
		if(dataFiltered === el){
			setdescSort(!descSort);
		}else{
			setDataFiltered(el);
			setdescSort(false);
		}
	}

	const handleChange = (event) => {
		const value = event.target.value;
		const str = value;
		setValueInSearchBar(str);
	};

	useEffect(()=>{
		if (!firstLoading) {
			const timer = setTimeout(() => {
				fetchData(defaultPagin);
			}, 1000);
			return () => {
				clearTimeout(timer);
			};
		}
	},[valueInSearchBar]);

	function goToEditionOfNewRule(link) {
		window.location.href = link;
	}
	function duplicateARuleById(id) {
		setLoading(true);
		duplicateTaggingById(id,
			$routeParams,
			systemServices,
			productTaggingServices,
			(link) => goToEditionOfNewRule(link),
			data => {
				//
			}, error => {
				systemServices.showError('An error occured');
			}
		);
	}
	function deleteARuleByIdFn() {
		setLoading(true);
		deleteTagById(elementToDelete.id,
			systemServices,
			productTaggingServices,
			succes => {
				fetchData();
			}, error => {
				systemServices.showError('An error occured');
			}
		);
	}
	function deleteARuleById(elem) {
		setmodalDeleteRuleIsOpen(true);
		setelementToDelete(elem);
	}

	const elementRanking = (i, elem) => {
		let url = '/ProductSettings/Tagging/Setting?id=' + elem.id;
		if ($routeParams && $routeParams.ka) {
			url += '&ka=' + $routeParams.ka;
		}
		return <TableRowItem
			key={elem.id}
			specialClasseName={'table_body_row'}>
			<TableColItem
				specialClasseName={' '}>
				<div className="flex">
					<div className="flex_item_fix">
						<a className="emerch_avatar emerch_avatar_margin" href={url}>
							<i className="fas fa-tag"></i>
						</a>
					</div>
					<div className="flex_item_full">
						<a className="table_row_name text_link_hover" href={url}>{elem.name}</a>
						<div className="s_11 grey_3 mt_3">{elem.id}</div>
					</div>
				</div>
			</TableColItem>

			<TableColItem specialClasseName={''}>
				{formatDate(elem.lastUpdatedUtc)}
			</TableColItem>
			<TableColItem specialClasseName={''} align="right">
				<DropdownRanking
					duplicateActionFunction={(id) => duplicateARuleById(elem.id)}
					openModalCampaignDelete={() => deleteARuleById(elem)}
				/>
			</TableColItem>
		</TableRowItem>;
	};

	let urlNewRanking = '/ProductSettings/Tagging/Setting';
	if ($routeParams && $routeParams.ka) {
		urlNewRanking += '?ka=' + $routeParams.ka;
	}

	return (
		<>
			<Confirm
				isOpen={modalDeleteRuleIsOpen}
				onClose={(e) => setmodalDeleteRuleIsOpen(false)}
				title="Do you really want to delete this rule?"
				text="This action is irreversible"
				cancelText="Cancel"
				confirmText="Delete"
				confirmColor="alert"
				confirmCallback={(e) => deleteARuleByIdFn()}
			/>
			<section className="section">
				<div className="page_block">
					{!firstLoading && !zeroData &&
						<div className="flex mb_15">
							<div className="flex_item_full">
								<SearchBar
									onChangeValue={(e) => handleChange(e)}
									valueInSearchBar={valueInSearchBar}
									placeholder="Search"
									fullWidth={false}
								/>
							</div>
							<div className="flex_item_fix">
								<Btn
									href={urlNewRanking}
									message="New tagging rule"
									icon="fas fa-plus"
									onClick={() => noop()}
								/>
							</div>
						</div>
					}

					<div className='pos_rel'>
						{!noData &&
							<>
								{rankingList &&
									<TableGridContainer specialClasseName="table_grid_emerch table_grid_ranking">
										<TableRowItem specialClasseName={'table_head_row'}>
											<TableColItem onClick={()=>handleClickFilter('Name')}>
												<a href={''}></a>
												<a className="table_sort">
													Rule name
													{dataFiltered === 'Name'  && descSort && <i className='fas fa-arrow-down'></i>}
													{dataFiltered === 'Name'  && !descSort && <i className='fas fa-arrow-up'></i>}
												</a>
											</TableColItem>
											<TableColItem onClick={()=>handleClickFilter('LastUpdatedUtc')}>
												<a className="table_sort">
												Last rule update
													{dataFiltered === 'LastUpdatedUtc'  && descSort && <i className='fas fa-arrow-down'></i>}
													{dataFiltered === 'LastUpdatedUtc'  && !descSort && <i className='fas fa-arrow-up'></i>}
												</a>
											</TableColItem>
										</TableRowItem>
										{rankingList.map((elem, i) =>
											elementRanking(i, elem)
										)}
									</TableGridContainer>
								}
								<Pagination
									totalCount={totalCount}
									pageSize={paginationOptions.limit}
									paginate={(e) => {
										fetchData({
											limit: paginationOptions.limit,
											offset: e >= 0 ? e : 0,
										});
									}}
									skipIdNumber={paginationOptions.offset}
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
								/>
							</>
						}
						
						{noData && !zeroData &&
							<EmptyState
								icon="fas fa-search"
								title="No matching rule"
								text="Please try another search"
								verticalSize="l"
								textSize="l"
							/>
						}

						{noData && zeroData &&
							<EmptyState
								icon="fas fa-tag"
								title="No tagging rule yet"
								text="Configure the best tagging for your products"
								verticalSize="l"
								textSize="l"
								primaryAction={
									<Btn
										href={urlNewRanking}
										message="New tagging rule"
									/>
								}
							/>
						}

						{!firstLoading && loading &&
							<SpinnerWheel wheelSize="l" />
						}
					</div>

					{firstLoading &&
						<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
					}
				</div>
			</section>
		</>
	);
}

