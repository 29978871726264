import HttpServices from './HttpServices';
import axios from 'axios';

export default class ProductRecommendationServices {
	constructor($http, authServices, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
		this.accountId = accountId;
	}

	getRecommendationsList(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product-recommendation/${this.accountId}`, callbackSuccess, callbackError);
	}

	createRecommendation(recommendation, callbackSuccess, callbackError) {
		this.HttpServices.post(
			`${this.BO_API_URL}product-recommendation/${this.accountId}`,
			recommendation,
			callbackSuccess,
			callbackError
		);
	}
	getRecommendationsById(associationId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product-recommendation/${this.accountId}/${associationId}`, callbackSuccess, callbackError);
	}
	deleteRecommendation(recommendationId, callbackSuccess, callbackError) {
		this.HttpServices.delete(
			`${this.BO_API_URL}}product-recommendation/${this.accountId}/${recommendationId}`,
			callbackSuccess,
			callbackError
		);
	}
	putRecommendationById(reco, recommendationId, callbackSuccess, callbackError) {
		this.HttpServices.put(
			`${this.BO_API_URL}}product-recommendation/${this.accountId}/${recommendationId}`,
			reco,
			callbackSuccess,
			callbackError
		);
	}

}
