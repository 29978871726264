import React, { useState, useEffect } from 'react';
//import { useTranslation } from 'react-i18next';
import { ReactLiquid } from 'react-liquid';
import { useEmailTemplateEditorContext } from '../../context/EmailTemplateEditorContextProvider';
import { propertiesProcessing } from '../../../../WysiwygEditor/Utils/TemplateOperations';
import Iframe from '../../../../../Components/Iframe';
import './TemplateDisplay.css';
import { DeviceType } from '../../../../WysiwygEditor/Types/BespokeTypes';
import { useTranslation} from 'react-i18next';
import { EditorStep ,EditorCreateOrUpdateMode} from '../../context/EditorStateTypes';

export default function TemplateDisplay() {
	const [t] = useTranslation('common');

	const { getLiquidModel, hasCountDowns } = propertiesProcessing();

	const {
		emailTemplate,
		editorState,
		currentDevice,
		templateSelected,
		mode,
		modelProperties,
		setModelProperties
	} = useEmailTemplateEditorContext();
		console.log('🚀🐱 😻 --///** ~ file: TemplateDisplay.tsx:26 ~ TemplateDisplay ~ templateSelected:', templateSelected)

	const [otherProperties, setOtherProperties] = useState({});

	const [liquidModel, setLiquidModel] = useState({});

	const [modelHasCountDowns, setModelHasCountDowns] = useState<boolean>(false);

	useEffect(() => {
		setModelProperties([...emailTemplate.Properties || []]);
	}, [emailTemplate, ]);

	const refreshTemplateDisplay = () => {
		const values = getLiquidModel(modelProperties);
		const result = {
			...values,
			...otherProperties,
		};
		setLiquidModel(result);
	};

	useEffect(() => {
		refreshTemplateDisplay();
		setModelHasCountDowns(hasCountDowns(modelProperties));
	}, [modelProperties, otherProperties]);

	useEffect(() => {
		const getCssFromDescriptor = () => {
			const descriptor = currentDevice === DeviceType.Desktop ? emailTemplate.TemplateDescriptorDesktop : emailTemplate.TemplateDescriptorMobile;
			return descriptor && descriptor.EditableCss;
		};
		// const getCssFromSelectedFormat = () => {
		// 	if (editorState.selectedFormat) {
		// 		const descriptor = currentDevice === DeviceType.Desktop ? editorState.selectedFormat.TemplateDescriptorDesktop : editorState.selectedFormat.TemplateDescriptorMobile;
		// 		return descriptor && descriptor.Css;
		// 	}
		// 	return null;
		// };
		// const currentVariationTemplate = emailTemplate && emailTemplate.Template;
		// const currentVariationAndFormatHaveSameIdentifier = () => (!currentVariationTemplate?.TemplateName || currentVariationTemplate.TemplateName !== editorState.selectedFormat?.TemplateName);
		// const templateIsNotChosen = editorState.sideBarStep === 1 && currentVariationTemplate && currentVariationAndFormatHaveSameIdentifier();

		// const css = templateIsNotChosen ? getCssFromSelectedFormat() : getCssFromDescriptor();
		const css = getCssFromDescriptor();
		css && setOtherProperties(x => ({ ...x, Css: css }));
	}, [currentDevice, emailTemplate,templateSelected]);

	// periodically refresh countdowns
	const [seconds, setSeconds] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds => seconds + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (modelHasCountDowns)
			refreshTemplateDisplay();
	}, [seconds]);

	return (
		<>
		{editorState?.sideBarStep  === EditorStep.One &&  !templateSelected && mode === EditorCreateOrUpdateMode.Create &&
	
				<div className="format_preview_empty">
					<img className="format_preview_empty_img" src={'/Assets/format_editor_empty.svg'} />
					<div className="format_preview_empty_title">{t('designEditor.welcomeToDesign')}</div>
					<div className="format_preview_empty_text">
						{t('designEditor.objective')}
					</div>
					<div className="format_preview_empty_sub">
						{t('designEditor.needHelp')}
					</div>
				</div>

		}
		{(templateSelected &&  editorState?.sideBarStep  === EditorStep.One)  &&
			<div className={'format_preview flex_item_full scroll_custom ' + currentDevice}>
				<div className="format_mockup" style={{}}>
					<ReactLiquid
						template={editorState.currentWysiwyg?.Template || ''}
						data={liquidModel}
						render={(renderedTemplate) => {
							return (
								<Iframe className="format_mockup_inner">
									<style>
										@import url('https://beyableprod.blob.core.windows.net/fonts/_v1/all.min.css');
									</style>
									<span className={currentDevice} dangerouslySetInnerHTML={renderedTemplate} />
								</Iframe>
							);
						}}
					/>
				</div>
			</div>}
		
		</>
	);
}