import React, { useEffect, useState } from 'react';
import BeyableLogo from './BeyableLogo';
import Btn from '../../Components/Btn';
import './Onboarding.css';
import { react2angular } from 'react2angular';
import OnboardingServices from '../../Services/OnboardingServices';
import EventTrackingServices from '../../Services/EventTrackingServices';
import SystemServices from '../../Services/SystemServices';
import WebSiteInfo from './Slides/WebSiteInfo/WebSiteInfo';
import AboutYourself from './Slides/AboutYourself/AboutYourself';
import PlanSelection from './Slides/PlanSelection/PlanSelection';
import InstallationCode from './Slides/InstallationCode/InstallationCode';
import CalendlySlide from './CalendlySlide';
import useInputValidation from '../../Hooks/useInputVal';
import websiteType from './Slides/WebSiteInfo/config';
import { WebAnalyticsConfigServices } from '../../Services/WebAnalyticsServices';

export default function OnboardingContainer(props) {
	// initStates
	const initialState = {
		name: '',
		accountType: websiteType[0].value,
		installationType: '',
		authorizedUrls: [],
		plans: [],
		additionalInfo: {
			companySize: '',
			accountCreatorRole: ''
		},
	};
	const initError = {
		name: '',
		accountType: '',
		installationType: '',
		authorizedUrls: '',
		plans: '',
		companySize: '',
		accountCreatorRole: ''
	};

	const [contact, setContact] = useState(false);
	const [state, setState] = useState(initialState);
	const [error, setError] = useState(initError);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [installedModules, setInstalledModules] = useState([]);
	const { isAnUrl, isNotEmpty } = useInputValidation();

	// init service classes
	const systemServices = new SystemServices(props.$rootScope);

	const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const accountId = props.$rootScope.User.Account.Key;
	const beyableKey = props.$rootScope.User.Account.ClientKey;

	const onboardingServices = new OnboardingServices(props.$http);
	const webAnalyticsServices = new WebAnalyticsConfigServices(props.$http, accountId);

	const getCallbackError = (message) => (error) => {
		console.log(error);
		systemServices.showError(message);
	};

	// init AJAX methods
	const getOnboardingAccountData = (callbackSuccess) =>
		onboardingServices.getOnboardingAccountData(
			accountId,
			callbackSuccess,
			getCallbackError('An error occured while getting your profile. Please contact BEYABLE support team')
		);

	const putOnboardingAccountData = (data, callbackSuccess) =>
		onboardingServices.putOnboardingAccountData(
			accountId,
			data,
			callbackSuccess,
			getCallbackError('An error occured while saving your profile. Please contact BEYABLE support team')
		);

	const getModules = (callbackSuccess) => {
		onboardingServices.getModules(
			accountId,
			callbackSuccess,
			getCallbackError('An error occured while getting your profile. Please contact BEYABLE support team')
		);
	};
	const addModules = (modules, callbackSuccess) => {
		onboardingServices.addModules(
			accountId,
			modules,
			(data) => {
				props.AccountServices.RefreshCurrentUser('Welcome to BEYABLE!').then(() => callbackSuccess(data));
			},
			getCallbackError('An error occured while saving your profile. Please contact BEYABLE support team')
		);
	};

	const activateModules = (callbackSuccess) => {
		const modules = [];
		const shouldActivateAnalytics = state.plans.includes('analytics');
		const shouldActivateReady = state.plans.includes('ready');
		if (shouldActivateAnalytics) {
			modules.push('WebAnalytics');
		}
		if (shouldActivateReady) {
			modules.push('PalModules');
		}
		addModules(modules, (() => {
			if (shouldActivateAnalytics) {
				webAnalyticsServices.createAccountConfig(beyableKey, callbackSuccess, () => {
					getCallbackError('An error occured while activating your modules. Please contact BEYABLE support team');
				});
			} else {
				callbackSuccess();
			}
		}));
	};

	// send form method
	const handleSubmit = (e) => {
		trackEvent('onboarding/completed');

		const storeOnboardingData = () => {
			putOnboardingAccountData(
				state,
				() => props.$location.path('/Redirect')
			);
		};

		if (state.plans.length && (state.plans.includes('analytics') || state.plans.includes('ready'))) {
			activateModules(storeOnboardingData);
		} else {
			storeOnboardingData();
		}

	};

	// we get the name from the websites url
	const getNameFromUrl = (url) => {
		const domain = (new URL(url)).hostname.replace('www.', '');
		const splitedDomainName = domain.split(/[.-]+/);
		let name = '';
		for (let i = 0; i < splitedDomainName.length - 1; i++) {
			let space = i < (splitedDomainName.length - 2) ? ' ' : '';
			name += splitedDomainName[i].charAt(0).toUpperCase() + splitedDomainName[i].slice(1) + space;
		}
		return name;
	};

	// next slide button method and error handler required inputs
	const clickNextSlide = (ev) => {
		let isValid = false;
		switch (currentSlide) {
			case 0:
				{
					let url = state.authorizedUrls[0];
					if (url && url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
						url = 'https://' + url;
					}
					const isValidUrl = isAnUrl(url);
					if (isValidUrl && state.accountType) {
						isValid = true;
						let name = getNameFromUrl(url);
						setState({ ...state, name });
						setError({ ...error, authorizedUrls: '' });
					} else {
						setError({ ...error, authorizedUrls: isValidUrl ? '' : 'Please enter a valid URL, for example: www.mywebsite.com' });
					}
				}
				break;

			case 1:
				{
					if (contact || state.plans.length) {
						isValid = true;
						if (state.plans.length) {
							setError({ ...error, plans: '' });
						}
					} else {
						setContact(true);
					}
				}
				break;

			case 2:
				isValid = true;
				break;

			case 3:
				isValid = true;
				handleSubmit();
				break;

			default:
		}

		if (isValid && currentSlide < slides.length - 1) {
			setCurrentSlide(currentSlide + 1);

			if (ev && ev.currentTarget) {
				ev.currentTarget.blur();
			}
		}
	};


	const onContactClickHandler = (status) => {
		setContact(status);
	};

	const clickPrevSlide = (ev) => {
		if (currentSlide >= 1) {
			setCurrentSlide(currentSlide - 1);
			if (ev && ev.currentTarget) {
				ev.currentTarget.blur();
			}
		}
	};

	// init state if user exsist
	useEffect(() => {
		const init = async () => {

			getModules(modules => {
				setInstalledModules(modules || []);
			});
			getOnboardingAccountData((newData) => {
				setState({
					name: newData.name === 'ACCOUNT NOT SET' ? '' : newData.name || '',
					accountType: newData.accountType || websiteType[0].value,
					installationType: newData.installationType || '',
					authorizedUrls: newData.authorizedUrls || [],
					plans: newData.plans,
					additionalInfo: newData.additionalInfo || {},
				});
			});
		};
		init();

		trackEvent('onboarding/started');

	}, []);

	useEffect(() => {
		if (contact === true) {
			clickNextSlide();
		}
	}, [contact]);

	const hasSelectedAnalytics = state.plans.length > 0 && state.plans.includes('analytics');
	const hasSelectedReady = state.plans.length > 0 && state.plans.includes('ready');
	function signOut(){
		props.AuthServices.logout();
	}
	const slides = [
		[
			<WebSiteInfo
				key="webSiteInfo"
				state={state}
				setState={setState}
				error={error}
			/>,
			'Some info about your Web site',
		],
		[
			<PlanSelection
				key="planSelection"
				state={state}
				setState={setState}
				error={error}
				onContactClick={onContactClickHandler}
			/>,
			'Select your plan',
		],

		(state.plans.length > 0 && state.plans.length <= 2 && (hasSelectedAnalytics || hasSelectedReady) && contact === false)
			? [
				<InstallationCode
					key="installationCode"
					accountId={accountId}
					showAnalytics={hasSelectedAnalytics}
					showReady={hasSelectedReady}
					beyableKey={beyableKey}
				/>,
				'Install BEYABLE code'
			]
			: [
				<CalendlySlide key="calendly" />,
				'Calendly'
			],
		[
			<AboutYourself key="about" state={state} setState={setState} error={error} />,
			'Tell us a bit about yourself',
		],
	];
	return (
		<div className="ob_slider_container">
			<a className="navigation_item flex" onClick={signOut}>
				<div className="navigation_item_picto flex_item_fix">
					<span className="icon_24 icon_color_grey icon_logout"></span>
				</div>
				<div className="navigation_item_text ellips">
					Sign Out
				</div>
			</a>
			<div className="ob_logo">
				<BeyableLogo />
			</div>
			<div className={`ob_slide_form ${slides[currentSlide][1] == 'Install BEYABLE code' ? 'ob_large' : ''}`}>
				{slides[currentSlide][0]}
				<div className="ob_buttons flex">
					{/* button prev not displayed if it's the first slide */}
					{currentSlide >= 1 && (
						<>
							<Btn
								onClick={(ev) => clickPrevSlide(ev)}
								color="secondary"
								style="outline"
								id="ob_prev"
								icon="fas fa-long-arrow-alt-left"
								size="l"
							>
								Back
							</Btn>
						</>
					)}
					<div className="flex_item_full"></div>
					{/* button prev not displayed if it's the last slide  */}
					{currentSlide < slides.length - 1 ? (
						<Btn
							onClick={(ev) => clickNextSlide(ev)}
							color="primary"
							id="ob_prev"
							icon="fas fa-long-arrow-alt-right"
							iconPosition="after"
							size="l"
						>
							Next
						</Btn>
					) : (
						<Btn
							onClick={(ev) => clickNextSlide(ev)}
							//size="m"
							color="primary"
							id="ob_start"
							size="l"
						>
							Start using BEYABLE
						</Btn>
					)}
				</div>
			</div>
				
		</div>
	);
}

angular
	.module('beyableSaasApp.Account.Onboarding', [])
	.component(
		'onboardingcontainer',
		react2angular(
			OnboardingContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', 'AccountServices', 'AuthServices']
		)
	);
