import React, { memo,useState, useEffect, useCallback} from 'react';
import Modal from '../../../../../Components/Modal';
import ModalHeader from '../../../../../Components/ModalHeader';
import ModalBody from '../../../../../Components/ModalBody';
import ModalFooter from '../../../../../Components/ModalFooter';
// import InputCustom from '../../../../../Components/InputCustom';
// import Checkbox from '../../../../../Components/Checkbox';
import Btn from '../../../../../Components/Btn';
import IconBtn from '../../../../../Components/IconBtn';
import {List, ListItem} from '../../../../../Components/List';
import { v4 as uuidv4 } from 'uuid';
import {TransformationType} from './transformationTypes';
import FieldTransformation from './FieldTransformation';
import FieldTransformationCheckBox from './FieldTransformationCheckBox';
import TypeNumberDictionary from './TypeNumberDictionary';

function getObjectFromReference (objectIdenfifier:TransformationType, uuid){
	const objId = JSON.parse(JSON.stringify(objectIdenfifier));
	const parametersValue = objId.parameters.map(x => {
		return {
			name :x.name,
			isRequired: x.isRequired,
			valueType: x.valueType,
			description: x.description
		};
	});
	const newParams = {};
	for (const key of parametersValue) {
		newParams[key.name] = '';
	}
	const newObjectType = {
		name : objId.name,
		uuid:uuid ? uuid :  uuidv4(),
		params : newParams
	};
	return newObjectType;
}

type ErrorOnItem = {
	text: string,
	name: string
}

type TransformationElementLabels = {
	name?: string,
	description?: string
}

function getListParametersByIdTransformation(listArg, typeTransformation){
	return listArg.find(el => el.name ==  typeTransformation);
}

export default memo(function TransformationModal({
	modalIsOpen,
	setModalIsOpen,
	list,
	addTransformation,
	isExistingKey,
	objectTransformationToEdit,
	transformationTypeSelected,
	settransformationTypeSelected,
	getListEmptyFormNewForm,
	setgetListEmptyFormNewForm,

}: any) {

	const [parametersField, setparametersField] = useState<React.JSX.Element[] | undefined>();
	const [selectedTransformationLabels, setSelectedTransformationLabels] = useState<TransformationElementLabels>({});
	const [valueOfParameters, setvalueOfParameters] = useState<any>();
	const [hasErrorsOnFields, sethasErrorsOnFields] = useState<ErrorOnItem[]>([]);

	const [needToRefresh, setNeedToRefresh] = useState(false);
	const [step, setStep] = useState(1);

	const [listOpenTransformationWithEmptyParams, setlistOpenTransformationWithEmptyParams] = useState({});


	function getFormByTypeOfTransformation (transformationType, valueParam,hasErrorsOnFields,params = null) {
		if(transformationType){
			const parameters = 	getTransformationsFields(transformationType, valueParam,hasErrorsOnFields, params);
			return parameters;
		}
	}
	
	const handleChange = useCallback((value: any) => {
		settransformationTypeSelected(value);
		setStep(2);
		setlistOpenTransformationWithEmptyParams({});
		setgetListEmptyFormNewForm(true);
	},[]);
	function handleAddOrDeleteParam(paramId:string, bool:boolean){
		const newList = JSON.parse(JSON.stringify(listOpenTransformationWithEmptyParams));
		if(bool){
			Object.assign(newList, {[paramId] : ''});
		}else{
			delete newList[paramId];
		}
		setNeedToRefresh(true);
		setlistOpenTransformationWithEmptyParams(newList);
		// setgetListEmptyFormNewForm(true);
	}
	function handleChangeParameters(name, value, valueParam){

		const elementValueClone = JSON.parse(JSON.stringify(valueParam));
		elementValueClone.params[name] = value;
		setvalueOfParameters(elementValueClone);
		setNeedToRefresh(true);
	}
	function handleChangeParametersCheck(event: React.SyntheticEvent, valueParam){
		event.persist();
		const target = event.target as HTMLInputElement;
		const elementValueClone = JSON.parse(JSON.stringify(valueParam));
		const name = target.name;
		const value = target.checked;
		elementValueClone.params[name] = value;
		setvalueOfParameters(elementValueClone);
		setNeedToRefresh(true);
		handleAddOrDeleteParam(name, value);
	}

	function removeCamelCase(str) {
		return str[0] + str.slice(1, str.length).replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
	}

	function getTransformationsFields(transformationType: TransformationType, valueParam, hasErrorsOnFields, params){

		return transformationType.parameters.map((x,i) => {

			const isOpen = params && params.hasOwnProperty(x.name);

			if(x.valueType !== 'Boolean' && x.valueType !== 'NumberDictionary'){
				const hasError = hasErrorsOnFields && hasErrorsOnFields.find(d => d.name ===x.name );

				return (
					<ListItem key={x.name + '_' + i}>
						<FieldTransformation 
							modalIsOpen={modalIsOpen}
							x={x}
							isOpen={isOpen}
							// valueIsNotUndefined={valueParam.params[x.name] !== null}
							hasError={hasError}
							valueParam={valueParam}
							handleChangeParameters={handleChangeParameters}
							handleAddOrDeleteParam={handleAddOrDeleteParam}
						/>
					</ListItem>
				);
			}
			else if(x.valueType === 'NumberDictionary'){
				return <div key={x.name + '_' + i}>
					<TypeNumberDictionary 
						// valueIsNotUndefined={valueParam.params[x.name] !== null}
						modalIsOpen={modalIsOpen}
						valueOfParameters={valueParam}
						handleChangeParameters={handleChangeParameters}
					
					/>

				</div>;
			}
			else{
				const hasError = hasErrorsOnFields && hasErrorsOnFields.find(d => d.name ===x.name );
				
				const defaultValue = x.defaultValue !== null ?  x.defaultValue === 'False' ? false : true : null;
				const value = valueParam.params[x.name] ? valueParam.params[x.name] : defaultValue;
				let checked = value;
				if( valueParam.params !== null){
					checked = !!valueParam.params[x.name];
				}
				return (
					<ListItem key={x.name + '_' + i}>

						<FieldTransformationCheckBox 
							x={x}
							isOpen={isOpen}
							// valueIsNotUndefined={valueParam.params[x.name] !== null}
							modalIsOpen={modalIsOpen}
							checked={checked}
							hasError={hasError}
							valueParam={valueParam}
							handleChangeParametersCheck={handleChangeParametersCheck}
							handleAddOrDeleteParam={handleAddOrDeleteParam}
						/>
						{hasError && <> Please check this element</>}
					</ListItem>
				);
			}
		});
	}

	function addToTransformationsElement(){
		const elementValidationOfTransformationSelected = list.find(transform => transform.name === transformationTypeSelected);

		const errorsOnItems:ErrorOnItem[] = [];
		const copyOfElementValue = {...valueOfParameters};
		if(elementValidationOfTransformationSelected){
			elementValidationOfTransformationSelected.parameters.map(param => {
				const valueOfParam = valueOfParameters.params[param.name];
				if (param.isRequired){
					if(valueOfParam === undefined || valueOfParam.length === 0){
						errorsOnItems.push({
							text : 'This field is required',
							name : param.name
						});
					}
				} else if(valueOfParam === undefined ) {
					const hasProperty = listOpenTransformationWithEmptyParams.hasOwnProperty(param.name);
					if(hasProperty){
						copyOfElementValue.params[param.name] = '' ;
					}else{
						delete copyOfElementValue.params[param.name];

					}
				} else if (valueOfParam.length === 0 ){
					const hasProperty =	listOpenTransformationWithEmptyParams.hasOwnProperty(param.name);
					if(!hasProperty){
						const params = copyOfElementValue.params;
						delete params[param.name];
						copyOfElementValue.params = params ;
					}
				} else {
					const hasProperty =	listOpenTransformationWithEmptyParams.hasOwnProperty(param.name);
					if(!hasProperty){
						const params = copyOfElementValue.params;
						delete params[param.name];
						copyOfElementValue.params = params ;
					}
				}	
			}
			);
			sethasErrorsOnFields(errorsOnItems);
		}

		if(errorsOnItems.length === 0){
			addTransformation(copyOfElementValue);
		}else{
			const elmentWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
			if(elmentWithParameters){
				const valueFieldsJsx = getFormByTypeOfTransformation(elmentWithParameters, valueOfParameters,errorsOnItems);
				setparametersField(valueFieldsJsx);
			}
		}
		
	}

	useEffect(()=> {
		setStep(transformationTypeSelected ? 2 : 1);
		setgetListEmptyFormNewForm(false);
		if(transformationTypeSelected && !isExistingKey){			
			const elementWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
			if(elementWithParameters){
				const valueParam = getObjectFromReference(elementWithParameters, null);
				const valueFieldsJsx = getFormByTypeOfTransformation(elementWithParameters, valueParam, hasErrorsOnFields);
				if(valueFieldsJsx){
					setSelectedTransformationLabels({
						name: removeCamelCase(elementWithParameters.name),
						description: elementWithParameters.description
					});
					setvalueOfParameters(valueParam);
					setparametersField(valueFieldsJsx);
				}
			}else{
				setparametersField(undefined);
				setSelectedTransformationLabels({});
				setvalueOfParameters(undefined);
			}
		}else if(isExistingKey){
			if(transformationTypeSelected !== objectTransformationToEdit.name){

				const elementWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
				const valueParam = getObjectFromReference(elementWithParameters, objectTransformationToEdit.uuid);
				valueParam.uuid = objectTransformationToEdit.uuid;
				const valueFieldsJsx = getFormByTypeOfTransformation(elementWithParameters, valueParam,hasErrorsOnFields,objectTransformationToEdit.params);
				setSelectedTransformationLabels({
					name: removeCamelCase(elementWithParameters.name),
					description: elementWithParameters.description
				});
				setparametersField(valueFieldsJsx);
				if(getListEmptyFormNewForm){
					setvalueOfParameters(valueParam);
					setlistOpenTransformationWithEmptyParams({...objectTransformationToEdit.params});
				}
			}else{
				
				const elementWithParameters = getListParametersByIdTransformation(list, objectTransformationToEdit.name);
				const valueFieldsJsx = getFormByTypeOfTransformation(elementWithParameters, objectTransformationToEdit,hasErrorsOnFields,objectTransformationToEdit.params);

				if(!elementWithParameters)return;
				setSelectedTransformationLabels({
					name: removeCamelCase(elementWithParameters.name),
					description: elementWithParameters.description
				});

				if(getListEmptyFormNewForm){
					setlistOpenTransformationWithEmptyParams({...objectTransformationToEdit.params});
					setvalueOfParameters(objectTransformationToEdit);
				}
				setparametersField(valueFieldsJsx);
			}
		}
		
	},[list,transformationTypeSelected,isExistingKey, objectTransformationToEdit,modalIsOpen,getListEmptyFormNewForm, listOpenTransformationWithEmptyParams ]);

	useEffect(()=>{
		if(needToRefresh){
			setNeedToRefresh(false);
			const elmentWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
			if(elmentWithParameters){
				const valueFieldsJsx = getFormByTypeOfTransformation(elmentWithParameters, valueOfParameters,hasErrorsOnFields);
				setparametersField(valueFieldsJsx);
			}
			sethasErrorsOnFields([]);
		}
	},[needToRefresh, transformationTypeSelected,hasErrorsOnFields]);


	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				width={600}
				onClose={() => {setModalIsOpen(false); sethasErrorsOnFields([]);}}
			>

				{step === 1 &&
					<>
						<ModalHeader title="Available transformations" />
						<ModalBody>
							<List size="s" horizontalAreaOffset={true}>
								{list.map((t) => 
									<ListItem
										key={t.name}
										text={removeCamelCase(t.name)}
										description={t.description}
										onClick={() => handleChange(t.name)}
										hasArrow={true}
									/>
								)}
							</List>
						</ModalBody>
					</>
				}
				{step === 2 &&	
					<>
						<ModalHeader title="Transformation settings" />
						<div className='modal_section modal_section_grey flex_item_fix'>
							<div className='flex'>
								<div className='flex_item_fix'>
									<IconBtn
										icon="fas fa-arrow-left"
										onClick={() => setStep(1)}
										size="xxl"
										className="modal_header_icon"
										isActive={true}
									/>
								</div>
								<div className='flex_item_full'>
									{selectedTransformationLabels && selectedTransformationLabels.name &&
										<div className='s_17 fw_medium'>{selectedTransformationLabels.name}</div>
									}
									{selectedTransformationLabels && selectedTransformationLabels.description &&
										<div className='s_14 grey_2 mt_2'>{selectedTransformationLabels.description}</div>
									}
								</div>
							</div>
						</div>
						<ModalBody>
							{parametersField && 
								<List size="l">{parametersField}</List>
							}
							{parametersField && parametersField.length === 0 &&
								<>This transformation does not require any configuration.</>
							}
						</ModalBody>
						<ModalFooter
							hasBorder={true}
							primaryAction={
								<Btn
									message={'Add'}
									onClick={() => addToTransformationsElement()}
								/>
							}
							secondaryAction={
								<Btn
									color="secondary"
									style="ghost"
									message={'Cancel'}
									onClick={() => {setModalIsOpen(false);sethasErrorsOnFields([]);}}
								/>
							}
						/>
					</>
				}
			</Modal>
		</>
	);
});


