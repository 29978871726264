
import React, {  } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ReportGeneratorContext } from '../Context';
import { List, ListItem } from '../../../Components/List';
import IconBtn from '../../../Components/IconBtn';
import EngagementLevel from './EngagementLevel';

import formatMoment from '../../../Constants/FormatMoment';
export default function CampaignSelection() {
	const { formatDateToLocal } = formatMoment;
	const {
		selectedCampaigns,
		handleCampaignUnSelected,
		handleCampaignOrderChange,
		selectedCampaignsFiltered,
		setselectedCampaignOptions,
		setmodalIsOpen
	} = ReportGeneratorContext();

	const onDragEnd = (result) => {
		if (!result.destination) return;
		const items = [...selectedCampaigns.items];
		const [moved] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, moved);
		handleCampaignOrderChange(items);
	};

	function openModalKpi(cp){
		setselectedCampaignOptions(cp);
		setmodalIsOpen(true);
	}

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={'droppable'}>
					{(provided, snapshot) => (
						<List size='s'>
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{selectedCampaignsFiltered.map((campaign, index) => {
									const nbKPI = campaign.indicators?.length || 0;
									const today = moment();
									const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
									const dateEndOfOperation = formatDateToLocal(campaign.endDate);
									const isStoppedBoolOperation = campaign.isStopped;
									const isTestModeOperationBool = false;
									let statusOperation = {};
									//if dateEnd > today
									if (dateEndOfOperation > today) {
										//if begin > today and notStopped
										if (dateBeginOfOperation > today && !isStoppedBoolOperation) {
											if (isTestModeOperationBool) {
												statusOperation = { id: 'Testing', label: 'Testing', cssClass: 'status-planned testing' };
											} else {
												statusOperation = { id: 'Scheduled', label: 'Scheduled', cssClass: 'status-planned' };
											}
										}
										else if (!isStoppedBoolOperation) {
											if (isTestModeOperationBool) {
												statusOperation = { id: 'Testing', label: 'Testing', cssClass: 'status-testing' };
											} else {
												statusOperation = { id: 'Ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
											}
										} else {
											statusOperation = { id: 'Stopped', label: 'Stopped', cssClass: 'status-stopped' };
										}
									} else {
										statusOperation = { id: 'Past', label: 'Past', cssClass: 'status-past' };
									}
									return (
										<Draggable
											key={campaign.id}
											draggableId={campaign.id}
											index={index}
										>
											{(provided) => (
												<div className='flex'
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													ref={provided.innerRef}
													key={campaign.id}
												>
													<div >
														<a className={''} style={{margin : '2px 10px'}} >
															<span className={'status_bullet '
                    + statusOperation.cssClass
															}></span>
				
														</a> </div>
													<div>

														<ListItem 
															text={<>{campaign.name} {dateBeginOfOperation.format('DD/MM/YYYY')} - {campaign.endDate !== null ?dateEndOfOperation.format('DD/MM/YYYY') : 'No end date'}</>}
															description={campaign.description}
															textIsBold={true}
															forceBorder={index > 0}
															actions={
																<div className='flex'>
																	<div className='report_generator_list_col_kpi flex_item_fix'>
																		<a className={nbKPI == 0 ? 'report_generator_list_btn has_error' : 'report_generator_list_btn'}
																			onClick={()=>openModalKpi(campaign)}>
																			{nbKPI} KPI
																		</a>
																	</div>
																	<div className='report_generator_list_col_engagement flex_item_fix'>
																		<EngagementLevel
																			campaign={campaign}
																			allowSelect={true}
																		/>
																	</div>
																	<div className='flex_item_fix'>
																		<IconBtn
																			icon='fas fa-minus-circle fa-sm'
																			tooltip='Remove campaign from your report'
																			isActive={true}
																			hoverColor='alert'
																			onClick={() => handleCampaignUnSelected(campaign)}
																		/>
																	</div>
																</div>
															}
														/>
													</div>
												</div>
											)}
										</Draggable>
									);
								})}
							</div>
						</List>
					)}
				</Droppable>
			</DragDropContext>
		</>
	);
}


