import React, { useEffect, useState } from 'react';
import styles from './WebAnalyticsContainer.module.css';

import SessionsTabContainer from './components/Containers/SessionsTabContainer';
import PageTabContainer from './components/Containers/PageTabContainer';
import LayoutAnalytics from './components/Containers/LayoutAnalytics';
import { useAnalyticsContext } from './context/AnalyticsContextProvider';
import { useEcommerceAnalyticsContext } from './context/EcommerceAnalyticsContext';
import { useSessionContext } from './context/SessionContext';
import BehaviorFlowTabContainer from './components/Containers/BehaviorFlowTabContainer';
import InteractionTabContainer from './components/Containers/InteractionTabContainer';
import EcommerceTabContainer from './components/Containers/Ecommerce/EcommerceTabContainer';


export default function WebAnalyticsMainDashboard({
	socket,
	accountInfo,
	$routeParams,
	selectedTenant,
	switchTenant,
	sessionService,
	pageService,
	interactionServices,
	settingServices,
	systemServices,
	customDimensionServices,
	customDimensionConfigsForTenant,
	location,
	trackEvent,
	timeout,
	$http,
	accountId,
	userId,
	$rootScope,
}) {
	const {
		// Tab state & utils
		activeDimensionTab,
		setActiveDimensionTab,
	} = useAnalyticsContext();


	const {
		activeSubDimensionTab,
		changeActiveSubDimensionTab,
	} = useEcommerceAnalyticsContext();


	const [forceUpdtate, setforceUpdtate] = useState(false);
	const [reload, setReload] = useState(false);

	const {
		setChips
	} = useSessionContext();

	// const { features } = useFeature();


	const noData = !accountInfo.hasData;
	const receivesActiveVisitors = accountInfo.receivesActiveVisitors;
	const hasCheckedActiveVisitors = accountInfo.hasCheckedActiveVisitors;
	const docURL =
		'https://polite-coast-000c04e03.azurestaticapps.net/docs/intro';
	let settingsURL = '/EditAccount?tab=webanalytics';
	if ($routeParams.ka) {
		settingsURL += '&ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
	}

	const swithTenantAndRefresh = (tenant) => {
		setChips([]);
		// setSelectedPeriod({ ...initialDates });
		switchTenant(tenant);
		// initialize();
	};

	const changeActiveDimensionTab = (dimension, subdimension) => {
		setActiveDimensionTab(dimension);
		location.search('dimension', dimension);
		setReload(false);
		if (subdimension) {
			location.search('subdimension', subdimension);
			changeActiveSubDimensionTab(subdimension);
		}
		timeout();
	};

	useEffect(() => {
		if (!activeDimensionTab) {
			changeActiveDimensionTab();
			setActiveDimensionTab('sessions');
		}
	}, [activeDimensionTab, location]);
	useEffect(() => {
		if (reload) {
			setforceUpdtate(!forceUpdtate);
			setReload(false);
		}

	}, [reload]);

	return (
		<div className={`page_full section_root ${styles.bodyContainer}`}>
			{noData && (
				<>
					{receivesActiveVisitors && (
						<div className="empty_page_flex vscroll">
							<section className="section">
								<img
									src="/Assets/empty_analytics.svg"
									className="empty_page_img"
									styles={{ width: '260px' }}
								/>
								<div className="empty_page_title">
									You have now
									<span className="text_badge text_badge_orange">
										{socket}
									</span>
									visitors on your website
								</div>
								<div className="empty_page_text l">
									Little patience, your dashboard will be
									ready very soon !<br />
									{/* No worry, you will receive an email to let you know when it’s ready */}
								</div>
								<div className="empty_page_text s mt_20">
									In the meantime, check-out our&nbsp;
									<a
										className="c_turquoise"
										href={docURL}
										target="_blank" rel="noreferrer"
									>
										documentation
									</a>
									&nbsp;to know more about our solution
								</div>
							</section>
						</div>
					)}
					{!receivesActiveVisitors && !hasCheckedActiveVisitors && (
						<div className="empty_page_flex vscroll">
							<section className="section">
								<img
									src="/Assets/empty_analytics.svg"
									className="empty_page_img"
									styles={{ width: '260px' }}
								/>
								<div className="empty_page_title">
									We are checking active visitors on your
									website...
								</div>
							</section>
						</div>
					)}
					{!receivesActiveVisitors && hasCheckedActiveVisitors && (
						<div className="empty_page_flex vscroll">
							<section className="section">
								<img
									src="/Assets/empty_analytics.svg"
									className="empty_page_img"
									styles={{ width: '260px' }}
								/>
								<div className="empty_page_title">
									Oops..! Analytics data is not yet available
									!
								</div>
								<div className="empty_page_text l">
									Check your tracking code{' '}
									<a
										className="c_turquoise"
										href={settingsURL}
										target="_blank" rel="noreferrer"
									>
										right here
									</a>
								</div>
								{/* <div className="empty_page_text s mt_20">When we will collect enough data, we will inform you by email.</div> */}
								<div className="empty_page_text s mt_20">
									Also, you can check-out our&nbsp;
									<a
										className="c_turquoise"
										href={docURL}
										target="_blank" rel="noreferrer"
									>
										documentation
									</a>
									&nbsp;to know more about our solution
								</div>
							</section>
						</div>
					)}
				</>
			)}

			{!noData && !reload && (
				<LayoutAnalytics
					$routeParams={$routeParams}
					accountInfo={accountInfo}
					selectedTenant={selectedTenant}
					swithTenantAndRefresh={swithTenantAndRefresh}
					settingsURL={settingsURL}
					docURL={docURL}
					customDimensionServices={customDimensionServices}
					activeDimensionTab={activeDimensionTab}
					changeActiveDimensionTab={changeActiveDimensionTab}
					activeSubDimensionTab={activeSubDimensionTab}
					changeActiveSubDimensionTab={changeActiveSubDimensionTab}
					userPermissions={$rootScope.$$childHead.permissions || {}}
					settingServices={settingServices}
					systemServices={systemServices}
					forceUpdtate={forceUpdtate}
				>
					<section className="section">
						{/* {activeDimensionTab === "dashboard" && (
                            <div>dashboard</div>
                        )} */}

						{activeDimensionTab == 'sessions' && (
							<SessionsTabContainer
								styles={styles}
								trackEvent={trackEvent}
								sessionService={sessionService}
								selectedTenant={selectedTenant}
								socket={socket}
								timeout={timeout}
							/>
						)}

						{activeDimensionTab == 'pages' && (
							<PageTabContainer
								trackEvent={trackEvent}
								pageService={pageService}
								sessionsServices={sessionService}
								fetchCsvService={((fromDate, toDate, callbackSuccess, callbackError) => {
									pageService.getPagesCsv(fromDate, toDate, selectedTenant, callbackSuccess, callbackError);
								})}								customDimensionConfigsForTenant={customDimensionConfigsForTenant}							/>
						)}
						{/* {activeDimensionTab == "behaviorFlow" && (
                            <BehaviorFlowTabContainer
                                styles={styles}
                                handleClick={onChangeNavFilter}
                                pageService={pageService}
                                initialDates={selectedPeriod}
                                activeDimensionTab={activeDimensionTab}
                                selectedTimeGranularity={
                                    selectedTimeGranularity
                                }
                                setSelectedTimeGranularity={
                                    setSelectedTimeGranularity
                                }
                            />
                        )} */}
						{activeDimensionTab == 'interactions' && (
							<InteractionTabContainer
								trackEvent={trackEvent}
								interactionServices={interactionServices}
								sessionsServices={sessionService}
								fetchCsvService={((fromDate, toDate, callbackSuccess, callbackError) => {
									interactionServices.getInteractionsCsv(fromDate, toDate, selectedTenant, callbackSuccess, callbackError);
								})}
							/>
						)}


						{activeDimensionTab == 'ecommerce' &&

							<EcommerceTabContainer
								trackEvent={trackEvent}
								ecommerceServices={interactionServices}
								location={location}
								timeout={timeout}
								sessionService={sessionService}
								$http={$http}
								accountId={accountId}
								userId={userId}
								$routeParams={$routeParams}
								$rootScope={$rootScope}
								selectedTenant={selectedTenant}
								styles={styles}
								changeActiveDimensionTabOver={(x, y) => {
									setReload(true);
								}
								}

							/>
						}
					</section>
				</LayoutAnalytics>
			)}
		</div>
	);
}


