import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import EmailTemplateEditor from './EmailTemplateEditor';
import WysiwygEditorProvider from '../../WysiwygEditor/context/WysiwygEditorContext';
import CreateEmailTemplateEditorContextProvider from './context/EmailTemplateEditorContextProvider';

export default function EmailTemplateEditorContainer(props) {

	return <WysiwygEditorProvider
		$rootScope={props.$rootScope}
		$http={props.$http}
		$routeParams={props.$routeParams}
		$timeout={props.$timeout}
		AuthServices={props.AuthServices}
	>
		<CreateEmailTemplateEditorContextProvider

			$http={props.$http}
			$routeParams={props.$routeParams}
			$rootScope={props.$rootScope}
			$timeout={props.$timeout}
			$location={props.$location}
			AuthServices={props.AuthServices}
		>
			<EmailTemplateEditor></EmailTemplateEditor>
		</CreateEmailTemplateEditorContextProvider>
	</WysiwygEditorProvider>;

}

angular.module('beyableSaasApp.EmailTemplateEditor', [])
	.component(
		'emailtemplateeditorcontainer',
		react2angular(
			EmailTemplateEditorContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', 'AuthServices']
		)
	);

