import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import StatusOperation from './../UI/StatusOperation';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import formatMoment from '../../../Constants/FormatMoment';
import useStatusOperation from '../../../Hooks/useStatusOperation';
import { check } from '../../../Components/Can';
import LabelList from '../../Labels/LabelList';
import { Trans, useTranslation } from 'react-i18next';
export default function OperationCard({
	routeParams,
	op,
	loadOperationStatistics,
	reloadTheOperationItem,
	pauseTheOperation,
	setModalIsClicked,
	onStartCapture,
	setStateOfOperationToValidate,
	setDataOfOperationToPut,
	setIdToDelete,
	setPoppinIsOppen,
	idOfOperationPut,
	isPlanningMode,
	permissions,
	accountId,
	userSimplifyRole
}) {
	const [stats, setStats] = useState({});

	let canAccessToOperationStatistics;
	let canEditStatus;
	let canEditCampaignInfo;
	if (permissions && userSimplifyRole) {
		canAccessToOperationStatistics = check(permissions, userSimplifyRole, 'simplifyReporting:canAccessToOperationStatistics', null);
		canEditStatus = check(permissions, userSimplifyRole, 'simplifyOperation:canEditStatusOperation', null);
		canEditCampaignInfo = check(permissions, userSimplifyRole, 'simplifyOperation:canEditCampaignInformations', null);
	}
	const { formatDateToLocal, getTimeZone } = formatMoment;
	let statusOperation = useStatusOperation(op);
	const [t, i18n] = useTranslation('common');
	const idTooltipOfMobile = uuidv4();
	const idTooltipOfDesktop = uuidv4();
	const idTooltipOfPause = uuidv4();
	const idTooltipOfPlayOp = uuidv4();
	const idTooltipOfEditOPi = uuidv4();
	const idTooltipOfDeleteOp = uuidv4();
	const idTooltipOfEarth = uuidv4();

	const stat_route = {};
	const dateBebingOperationToLocal = formatDateToLocal(op.operation.startDate);
	const dateEndOperationToLocal = formatDateToLocal(op.operation.endDate);
	let timeZoneUser;
	if (isPlanningMode) {
		timeZoneUser = getTimeZone(op.operation.startDateUtc, true , t('getTimeZone.yourTimeZone'));
	}
	else {
		timeZoneUser = getTimeZone(op.operation.startDate, true,t('getTimeZone.yourTimeZone') );
	}

	useEffect(() => {
		if (op.operation && op.operation.lazyLoadStatistics && loadOperationStatistics) {
			loadOperationStatistics(op.operation.operationId, op.operation.startDate.format('YYYY-MM-DD'), op.operation.endDate.format('YYYY-MM-DD'),
				data => setStats(data)
				, _ => setStats(op));
		} else {
			setStats(op);
		}
	}, []);

	stat_route.pathname = `/BusinessOperations/statistics?id=${op.operation.operationId}&startDate=${dateBebingOperationToLocal.format('YYYY-MM-DD')}&endDate=${dateEndOperationToLocal.format('YYYY-MM-DD')}`;

	if (routeParams && routeParams.ka) {
		stat_route.pathname += `&ka=${routeParams.ka}&ku=${routeParams.ku}`;
	}
	if (isPlanningMode) {
		stat_route.pathname += '&prev=planning';
	} else {
		stat_route.pathname += '&prev=dashboard';
	}
	return (
		<Fragment >
			<div className="operationItems">
				<Link to={canAccessToOperationStatistics ? stat_route : ''} className={!canAccessToOperationStatistics ? 'operation-inner disabled' : 'operation-inner'}>
					<p className="operation-name">{op.operation.name}</p>
					<div className="operation-labels">
						{op.operation.operationLabels && op.operation.operationLabels.length > 0 &&
							<LabelList
								labels={op.operation.operationLabels}
								inline={true}
							/>
						}
					</div>
					<div className="operation-devices">
						{op.operation.extensions.devices.includes('mobile') &&
							<>
								<ReactTooltip id={idTooltipOfMobile} className="labelKpi" backgroundColor='black' effect='solid' />
								<i data-for={idTooltipOfMobile} className="fas fa-mobile-alt" data-tip="Mobile"></i>
							</>
						}
						{op.operation.extensions.devices.includes('desktop') &&
							<>
								<ReactTooltip id={idTooltipOfDesktop} className="labelKpi" backgroundColor='black' effect='solid' />
								<i data-for={idTooltipOfDesktop} className="fas fa-desktop" data-tip="Desktop"></i>
							</>
						}
					</div>
					{op.operation.description &&
						<p className="operation-description">
							{op.operation.description.split('\n').map((item, key) => {
								return <Fragment key={key}>{item}<br /></Fragment>;
							})}
						</p>
					}
					<div className="operation-date">
						{statusOperation.id === 'planned'
							? dateBebingOperationToLocal.format('DD/MM/YYYY') + ' at ' + dateBebingOperationToLocal.format('HH:mm')
							: dateBebingOperationToLocal.format('DD/MM/YYYY')
						}
						<i className="fas fa-caret-right s_12 grey_3"></i>
						{dateEndOperationToLocal.format('DD/MM/YYYY') + ' at ' + dateEndOperationToLocal.format('HH:mm')}
						&nbsp;
						(
						{
							dateEndOperationToLocal.diff(dateBebingOperationToLocal, 'days') > 1 ?
								dateEndOperationToLocal.diff(dateBebingOperationToLocal, 'days') + ' days' :
								dateEndOperationToLocal.diff(dateBebingOperationToLocal, 'days') + ' day'
						}
						)
						<ReactTooltip id={idTooltipOfEarth} backgroundColor='black' effect='solid' />
						<i data-for={idTooltipOfEarth} className="fas fa-globe s_12 grey_3" data-tip={timeZoneUser}> </i>
					</div>
					<div className="operation-img">
						{op.operation.imagePath
							?
							<img src={op.operation.imagePath} alt={op.operation.name} />
							:
							<div className="operation-img-empty">
								<i className="fas fa-image"></i>
							</div>
						}
					</div>
					{
						stats != {} && stats.numberOfImpressionsNonUnique !== 0 &&
						<div className="operation-stats">
							<p className="operation-stats-title">Stats</p>
							<div className="operation-stats-grid">
								<p className="operation-stat-label">Impressions</p>
								<p className="operation-stat">{stats.numberOfImpressionsNonUnique}</p>
								<p className="operation-stat-label">Unique impressions</p>
								<p className="operation-stat">{stats.numberOfImpressions}</p>
								<p className="operation-stat-label">Clicks</p>
								<p className="operation-stat">{stats.numberOfClicks}</p>
							</div>
						</div>
					}
				</Link>
				<div className="operation-footer">
					<div className="operation-btns">
						{canEditStatus && <>
							{!op.operation.isStopped ?
								<div onClick={(e) => { pauseTheOperation(op); setDataOfOperationToPut(op); }} >
									<ReactTooltip id={idTooltipOfPause} className="labelKpi" backgroundColor='black' effect='solid' />
									<i data-for={idTooltipOfPause} className="fas fa-pause" data-tip="Pause the operation"></i>
								</div>
								:
								<>

									<div onClick={() => { setModalIsClicked(true); setStateOfOperationToValidate('stopped'); setDataOfOperationToPut(op); }} >
										<ReactTooltip id={idTooltipOfPlayOp} className="labelKpi" backgroundColor='black' effect='solid' />
										<i data-for={idTooltipOfPlayOp} className="fas fa-play" data-tip="Play the operation"></i>
									</div></>
							}</>
						}
						<Link
							to={routeParams && routeParams.ka ? { pathname: `/BusinessOperations/Create?id=${op.operation.operationId}&ka=${routeParams.ka}&ku=${routeParams.ku}&prev=${isPlanningMode ? 'planning' : 'dashboard'}`, } : { pathname: `/BusinessOperations/Create?id=${op.operation.operationId}&prev=${isPlanningMode ? 'planning' : 'dashboard'}` }}>
							<div>
								<ReactTooltip id={idTooltipOfEditOPi} className="labelKpi" backgroundColor='black' effect='solid' />
								<i data-for={idTooltipOfEditOPi} className="fas fa-pen" data-tip="Edit the operation"></i>
							</div>
						</Link>
						{canEditCampaignInfo &&
							<div onClick={(e) => { setIdToDelete(op.operation.operationId); setPoppinIsOppen(true); }} >
								<ReactTooltip id={idTooltipOfDeleteOp} className="labelKpi" backgroundColor='black' effect='solid' />
								<i data-for={idTooltipOfDeleteOp} className="fas fa-trash-alt" data-tip="Delete the operation"></i>
							</div>
						}
					</div>
					<StatusOperation
						op={op}
						accountId={accountId}
						onStartCapture={onStartCapture}
						onClickFunction={(e) => setModalIsClicked(e)}
						setStateOfOperationToValidate={(e) => setStateOfOperationToValidate(e)}
						setDataOfOperationToPut={(e) => setDataOfOperationToPut(e)}
						permissions={permissions}
						userSimplifyRole={userSimplifyRole}
					/>
				</div>
				{
					reloadTheOperationItem && idOfOperationPut === op.operation.operationId &&
					<div className="spinner_overlay">
						<div className="spinner">
							<div className="rect1"></div>
							<div className="rect2"></div>
							<div className="rect3"></div>
							<div className="rect4"></div>
							<div className="rect5"></div>
						</div>
					</div>
				}
			</div>
		</Fragment>
	);
}
