import React, {useState, useEffect} from 'react';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function SelectOpts({
	handleChangeValue,
	selections,
	surveyInfos,
	isMultiple,
	elementControl,
	isIgnoreQuestion = false
}) {

	const [selectedOption, setSelectedOption] = useState<any>();

	useEffect(()=>{
		if(selections && !isMultiple){
			if(!isIgnoreQuestion){
				const elementSelected = selections.find(x => x.value === surveyInfos.nextQuestion );
				if(elementSelected){
					setSelectedOption(elementSelected);
				}else{
					setSelectedOption({
						label: 'Question suivante',
						value: 'next'
					});
				}
			}else{
				const elementSelected = selections.find(x => x.value === surveyInfos.ignoreQuestion );
				if(elementSelected){
					setSelectedOption(elementSelected);
				}else{
					setSelectedOption({
						label: 'Question suivante',
						value: 'next'
					});
				}
			}

		}else{// multipleQuestionsBasedOnAnswer
			const current = {...surveyInfos};
			const currentControl = current.multipleQuestionsBasedOnAnswer.find(x => x.controlId === elementControl?.id);
			if(currentControl){
				const elementSelected = selections.find(x => x.value === currentControl.nextQuestion );
				setSelectedOption(elementSelected);
			}else{
				setSelectedOption(undefined);
			}
		}
	},[selections, isMultiple, surveyInfos, elementControl]);

	return (
		<SelectDropdown
			value={selectedOption && selectedOption.value}
			optionsList={selections}
			onChange={(v)=>handleChangeValue(v, elementControl)}
			ellips={true}
			fullWidth={true}
		/>
	);
}
