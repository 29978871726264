import React from 'react';
import TrafficContainer from './Traffic/TrafficContainer';
import Ecommerce from './Ecommerce/Ecommerce';
import KpiContainer from './KPI/KpiContainer';
import Segments from './Segments/Segments';

import RouterItems from '../Menu/RouterItems';
import {useInsightsContext} from './context/ContextInsights';
import AuditContainer from './Audit/AuditContainer';

export default function RouterInsights(props) {
	const {
		modulesActivated,
		isAdmin
	} = useInsightsContext();

	return (
		<RouterItems
			title="Insights"
			list={modulesActivated}
			routes={<></>}
		>
			<KpiContainer/>
			<TrafficContainer props={{...props}}/>
			<Ecommerce props={{...props}}/>
			<Segments/>
			{isAdmin &&
			<AuditContainer props={{...props}}/>}
		</RouterItems>
	);
}
