import React, {useEffect, useState} from 'react';
import {useInteractionContext} from '../../context/InteractionContext';
import InteractionNoCategoryBoardContainer from '../Board/InteractionNoCategoryBoardContainer';
import InteractionCategoryBoardContainer from './InteractionCategoryBoardContainer';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';

export default function InteractionBoardContainer({interactionServices}) {

	const {
		selectedCategory,
		reportTypeSelected,
		step,
		isLoadingHasCategories,
		setIsLoadingHasCategories,
		hasCategories,
		setHasCategories,
	} = useInteractionContext();

	const {selectedTenant} = useAnalyticsContext();

	const getHasCategories = () => {
		interactionServices.getHasCategories(
			selectedTenant,
			(res, headers) => {
				setHasCategories(res);
				setIsLoadingHasCategories(false);
			},
			(err) => console.log(err)
		);
	};


	useEffect(() => {
		if ((reportTypeSelected === 'Interaction' && !selectedCategory) || (reportTypeSelected === 'Page' && step === 2 && !selectedCategory)) {
			getHasCategories();
		}  else {
				setIsLoadingHasCategories(false);
				setHasCategories(false);	
		}
	}, [reportTypeSelected, selectedCategory, step,selectedTenant]);

	return (
		<>
			{isLoadingHasCategories &&
            <div className="mask"><span className="wheel"></span>
            </div>
			}
			{!isLoadingHasCategories &&
                <>{hasCategories &&
                 <InteractionCategoryBoardContainer
						interactionServices={interactionServices}
                        />}
                {!hasCategories &&
                <InteractionNoCategoryBoardContainer
				interactionServices={interactionServices}
                />
                }
                </>
			}
		</>
	);
}