import React, { createContext, useContext, useState, useEffect } from 'react';

const FormContext = createContext();

function useFormContext() {
	return useContext(FormContext);
}

const ContextFormAndSurveyProvider = (props) => {

	const {
		formData,
		panelSelected,
		updatePanel,
		setpanelSelected,
		stepSurvey,
		stepSelected,
		setFormData,
		needUpdateState,
		formSurveyStyles,
		deleteAnswers,
		isLast,
		setisLast
	} = props;

	const [listOfIdQuestion, setlistOfIdQuestion] = useState([]);

	useEffect(()=>{
		if(formData){
			const formattedData = JSON.parse(formData);
			if(formattedData.sections[0]?.panels.length > 0){
				const arrayOfQuestions = formattedData.sections[0].panels.map((el, idx)=> {
					return {...el, idInList : idx+1};
				});
				setlistOfIdQuestion(arrayOfQuestions);
			}
		}
	},[formData, panelSelected]);
	
	return (
		<FormContext.Provider
			value={{
				panelSelected,
				updatePanel,
				listOfIdQuestion,
				setpanelSelected,
				formData,
				stepSurvey,
				stepSelected,
				setFormData,
				needUpdateState,
				formSurveyStyles,
				deleteAnswers,
				isLast,
				setisLast
			}}
		>
			{props.children}
		</FormContext.Provider>
	);
};

export default ContextFormAndSurveyProvider;
export { useFormContext };
