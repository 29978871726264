import React from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import styles from './Picto.module.css';

function Picto({
	icon,
	iconCustom,
	color = 'grey',
	size = 'm',
	tooltip,
	tooltipHTML
}) {

	const tooltipID = uuidv4();
	const cssClass = [styles.picto];

	// SIZE
	if (size) {
		cssClass.push(styles['size_' + size]);
	}

	// COLOR
	if (color) {
		cssClass.push(styles['color_' + color]);
	}

	return (
		<>
			{tooltip &&
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="mouseup"
					delayShow={600}
				/>
			}
			{tooltipHTML &&
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="mouseup"
					delayShow={600}
					getContent={function() {
						return tooltipHTML;
					}}
				/>
			}
			<div
				className={cssClass.join(' ')}
				data-tip={tooltipHTML ? 'a' : tooltip}
				data-for={tooltipID}
			>
				{iconCustom && 
					<span className={styles.icon_custom}>{iconCustom}</span>
				}
				{icon &&
					<i className={icon + ' fa-fw'}></i>
				}
			</div>
		</>
	);
}

export default Picto;
