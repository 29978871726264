import React from 'react';
import { ReportGeneratorContext } from '../Context';
import { List, ListItem } from '../../../Components/List';
import IconBtn from '../../../Components/IconBtn';

import formatMoment from '../../../Constants/FormatMoment';
export default function CampaignSelector() {

	const {
		setmodalIsOpen,
		dataFiltered,
		handleChangeCpgIdOptions,
		setcampaignSelected
	} = ReportGeneratorContext();

	const { formatDateToLocal } = formatMoment;
	
	return (
		<List size='s'>
			{dataFiltered.map(campaign => {
				const today = moment();
				const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
				const dateEndOfOperation = formatDateToLocal(campaign.endDate);
				const isTestModeOperationBool  = campaign.executionMode === 'DEFAULT' ? true : false;
				let statusOperation = {};
							
				const isStoppedBoolOperation = campaign.isStopped;
				function getStatuCss(){
					if (isStoppedBoolOperation) {
						return statusOperation = { id: 'stopped', label: 'Stopped', cssClass: 'status-stopped' };
					}
			
					else if (isTestModeOperationBool) {
						return statusOperation = { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
					}
			
					else if (campaign.endDate && dateEndOfOperation < today) {
						return statusOperation = { id: 'past', label: 'Past', cssClass: 'status-past' };
					}
			
					else if (dateBeginOfOperation > today) {
						return statusOperation = { id: 'planned', label: 'Scheduled', cssClass: 'status-planned' };
					}
			
					else {return statusOperation = { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' }};
				}
				getStatuCss();
				return	<div className='flex' key={campaign.id}>
					<a className={''} style={{margin : '2px 10px'}} >
						<span className={'status_bullet '
                    + statusOperation.cssClass
						}></span>
				
					</a>
					<ListItem
						
						text={<>{campaign.name} {dateBeginOfOperation.format('DD/MM/YYYY')} - {campaign.endDate !== null ?dateEndOfOperation.format('DD/MM/YYYY') : 'No end date'}</>}
						description={campaign.description}
						textIsBold={true}
						actions={<>
							<IconBtn
								icon='fas fa-plus-circle fa-sm'
								tooltip='Add campaign to your report'
								isActive={true}
								onClick={() => {
									setcampaignSelected(campaign);
									handleChangeCpgIdOptions(campaign);
									setmodalIsOpen(true);
								}}
							/>
						</>
						}
					/>
				</div>;}
			)}
		</List>
	);
}
