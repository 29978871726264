import React, { createContext, useContext, useEffect, useState, SetStateAction, Dispatch } from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import EmailTemplateServices from '../../../../Services/EmailTemplateServices';
// import EventTrackingServices from '../../../../Services/EventTrackingServices';
// import { v4 as uuidv4 } from 'uuid';
import { EmailTemplateObject, emptyTemplate } from './EmailTemplateTypes';
import { initializeFromFormat, validate, ValidationError } from './EditorOperations';
import { EmailTemplateSaaSFormat, TemplateProperty, DeviceType } from '../../../WysiwygEditor/Types/BespokeTypes';
import { convertSaaSFormatsFromApiType, convertEmailTemplateForSaving,convertEmailTemplateForUpdate } from './TypeAdapters';
import { EditorState, EditorCreateOrUpdateMode, EditorStep } from './EditorStateTypes';
import { Style } from './StyleTypes';

const EmailTemplateEditorContext = createContext<EmailTemplateEditorContextType | undefined>(undefined);

function useEmailTemplateEditorContext() {
	const context = useContext(EmailTemplateEditorContext);
	if (!context) throw Error('useEmailTemplateEditorContext can only be used inside an EmailTemplateEditorContextProvider');
	return context;
}

const CreateEmailTemplateEditorContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	// const authServices = props.AuthServices;
	// const getAccessToken = authServices && authServices.getAccessToken;
	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	//const { trackEvent } = EventTrackingServices(props.$rootScope.User);
	const crud = new EmailTemplateServices(accountId, $http);
	// let urlReturn = '/EmailTemplate/Dashboard';
	// if ($routeParams && $routeParams.ka && isAdmin) {
	// 	urlReturn += '?ka=' + $routeParams.ka;
	// }

	const mode: EditorCreateOrUpdateMode = !$routeParams.key ? EditorCreateOrUpdateMode.Create : EditorCreateOrUpdateMode.Update;
	const [currentDevice, setCurrentDevice] = useState<DeviceType>(DeviceType.Desktop);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [emailTemplate, setEmailTemplate] = useState<EmailTemplateObject>(emptyTemplate);

	const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

	const [formatSavedStyles] = useState<Style[]>([]);
	const [matchedExistingStyle] = useState<Style | null>(null);

	const [saasFormats, setSaasFormats] = useState<EmailTemplateSaaSFormat[]>([]);
	
	const [personalisationParameterName, setPersonalisationParameterName] = useState<string>('');
	const [templateSelected, settemplateSelected] = useState<EmailTemplateSaaSFormat>()
	const [editorState, setEditorState] = useState<EditorState>({
		sideBarStep: EditorStep.One,
		formatIsSelected: false,
		selectedFormat: null,
		selectedStyle: { Id: 'default', Properties: [] },
		defaultStyle: null,
		currentStyle: null,
		currentWysiwyg: null,
	});

	const loadSaaSFormats = () => {
		crud.getEmailTemplateSaaSFormats(formats => {
			const result = convertSaaSFormatsFromApiType(formats);
			setSaasFormats(result);
		}, err => {
			console.log(err);
			systemServices.showError('An error occured while getting templates.');
		});
	};

	const initializeFromSelectedFormat = (format: EmailTemplateSaaSFormat): void => {
		const { updateEditorState, updateVariation } = initializeFromFormat(
			currentDevice,
			mode,
			format.template,
			true,
			true,
			editorState,
			emailTemplate,
			true
		);
		setEmailTemplate(updateVariation);
		setEditorState(updateEditorState);
	};

	const checkValidationErrors = (emailTemplate:EmailTemplateObject): boolean => {
		const { validateEditorState, validateInformationsData, validateFormatData } = validate();
		const { isValid: isValid0, errors: errors0 } = validateEditorState(editorState, validationErrors);
		const { isValid: isValid1, errors: errors1 } = validateInformationsData(emailTemplate, errors0);
		const { isValid: isValid2, errors: errors2 } = validateFormatData(emailTemplate, errors1);
		setValidationErrors(errors2);
		return isValid0 && isValid1 && isValid2;
	};


	useEffect(() => {
		setEditorState((s) => ({
			...s,
			currentWysiwyg:
				editorState.selectedFormat == null ? s.currentWysiwyg :
					(currentDevice === DeviceType.Desktop
						? editorState.selectedFormat.WysiwygDesktop
						: editorState.selectedFormat.WysiwygMobile),
		}));
	}, [currentDevice]);


	useEffect(() => {
		loadSaaSFormats();
		setIsLoading(true);
	}, []);

	// useEffect(() => {
	// 	if (saasFormats.length > 0) initializeFromSelectedFormat(saasFormats[0]);
	// }, [saasFormats]);



	function handleCloseEditor() {
		const returnFrom = props.$routeParams?.from  ? props.$routeParams.from :'EmailTemplate/Dashboard'; 
		if (isAdmin && props.$routeParams.ka) {
			window.location.href = `${returnFrom}?ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}`;
		} else {
			window.location.href = returnFrom;
		}
	}

	// events

	const handleChangeStep = (step: EditorStep) => {
		setEditorState(x => ({ ...x, sideBarStep: step }));
	};

	const handleFormatPreviewClicked = (format: EmailTemplateSaaSFormat) => {
		console.log('🚀🐱 😻 --///** ~ file: EmailTemplateEditorContextProvider.tsx:138 ~ handleFormatPreviewClicked ~ format:', format)
		settemplateSelected(format);
		const { updateEditorState,updateVariation} = initializeFromFormat(
			currentDevice,
			mode,
			format.template,
			false,
			false,
			editorState,
			emailTemplate,
			false,
		)
		setEmailTemplate(updateVariation);
		setEditorState(updateEditorState);
	};

	const handleFormatChosen = () => {
		if(templateSelected){
			// handleChangeStep(EditorStep.Three);
			initializeFromSelectedFormat(templateSelected);
		}
	};

	const handleGenerateOuput = (successCallback: (result: string) => void) => {
		crud.generateEmailTemplateOutput('...', successCallback,
			err => {
				console.log(err);
				systemServices.showError('An error occured while generating output.');
			}
		);
	};

	const handleSaveEmailTemplate = (template:EmailTemplateObject) => {
		if (!checkValidationErrors(template)) {
			return;
		}
		const onSuccess = () => systemServices.showSuccess('Your email template has been saved successfully');
		const onError = () => systemServices.showError('An error occured while saving your email template');
		if (mode === EditorCreateOrUpdateMode.Create) {
			crud.createEmailTemplate(convertEmailTemplateForSaving(emailTemplate), onSuccess, onError);
		} else {
			crud.updateEmailTemplate(emailTemplate.Id,convertEmailTemplateForUpdate(emailTemplate), onSuccess, onError);
		}
	};


	// callbacks

	const applyUpdateProperties = (updateProperties: (p: TemplateProperty[]) => TemplateProperty[]) => {
		setEmailTemplate(v => ({ ...v, Properties: updateProperties(v.Properties) }));
	};

	const handleChangeName = (n) => {
		setEmailTemplate(v => ({ ...v,Name : n}));
	}

	const context: EmailTemplateEditorContextType = {
		isLoading,
		emailTemplate,
		saasFormats,
		editorState,
		formatSavedStyles,
		matchedExistingStyle,
		currentDevice,
		mode,
		personalisationParameterName,
		setPersonalisationParameterName,
		setCurrentDevice,
		handleFormatPreviewClicked,
		handleFormatChosen,
		handleChangeStep,
		handleGenerateOuput,
		handleSaveEmailTemplate,
		applyUpdateProperties,
		templateSelected,
		initializeFromSelectedFormat,
		handleChangeName,
		handleCloseEditor
	};

	return (
		<EmailTemplateEditorContext.Provider
			value={context}
		>
			{props.children}
		</EmailTemplateEditorContext.Provider>
	);
};

export default CreateEmailTemplateEditorContextProvider;

export { useEmailTemplateEditorContext };

type EmailTemplateEditorContextType = {
	isLoading: boolean;
	emailTemplate: EmailTemplateObject;
	saasFormats: EmailTemplateSaaSFormat[];
	editorState: EditorState;
	formatSavedStyles: Style[];
	matchedExistingStyle: Style | null;
	currentDevice: DeviceType;
	mode: EditorCreateOrUpdateMode;
	personalisationParameterName: string;
	setPersonalisationParameterName: Dispatch<SetStateAction<string>>;
	handleFormatPreviewClicked: (format: EmailTemplateSaaSFormat) => void;
	handleFormatChosen: () => void;
	setCurrentDevice: Dispatch<SetStateAction<DeviceType>>;
	handleGenerateOuput: (successCallback: (result: string) => void) => void;
	handleChangeStep: (step: EditorStep) => void;
	handleSaveEmailTemplate: (tp:EmailTemplateObject) => void;
	applyUpdateProperties: (arg0: (propsToUpdate: TemplateProperty[]) => TemplateProperty[]) => void;
	templateSelected:any,
	initializeFromSelectedFormat: (any)=> void;
	handleChangeName: Dispatch<SetStateAction<EmailTemplateObject>>;
	handleCloseEditor: () => void;
}
