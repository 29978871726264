import React, { useEffect, useState } from 'react';
import InputCustom from '../../../../../Components/InputCustom';
import SelectCustom from '../../../../../Components/SelectCustom';
import RangeSlider from '../../../../../Components/RangeSlider';
import Checkbox from '../../../../../Components/Checkbox';
import SectionMessage from '../../../../../Components/SectionMessage';
const operators = [
	{
		value: 'In',
		label: 'In',
	},
	{
		value: 'NotIn',
		label: 'Not In',
	},
];

export default function NumberRangeContainer({
	data,
	handleChangeFilterByUniqueId,
}) {

	const [currentValue, setcurrenValue] = useState(
		data.value['numberRange'] || { min: '', max: '' }
	);

	const [hasMin, setHasMin] = useState(true);
	const [hasMax, setHasMax] = useState(true);
	const [needValueError, setNeedValueError] = useState(false);

	const [valueOperatorSelected, setvalueOperatorSelected] = useState();
	const [valueOperatorMinSelected, setvalueOperatorMinSelected] = useState(-20);
	const [valueOperatorMaxSelected, setvalueOperatorMaxSelected] = useState(20);

	const selectOperatorMin = (operator) => {
		setvalueOperatorMinSelected(parseInt(operator, 10));
	};
	const selectOperatorMax = (operator) => {
		setvalueOperatorMaxSelected(parseInt(operator, 10));
	};
	const selectOperator = (operator) => {
		setvalueOperatorSelected(operator);
	};

	function getValueOfStringComponent() {
		let valueSimple = data.value.hasOwnProperty('numberRange');
		if (valueSimple && data.value['numberRange'] !== null) {
			setcurrenValue(data.value['numberRange']);
		}
	}

	function handleChangeValuesById() {
		if (currentValue) {
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: valueOperatorSelected,
				// value: { numberRange: currentValue },
				value: {
					numberRange: {
						min: valueOperatorMinSelected !== null ? valueOperatorMinSelected / 100 : valueOperatorMinSelected,
						max: valueOperatorMaxSelected !== null ? valueOperatorMaxSelected / 100 : valueOperatorMaxSelected
					}
				},
			});
		}
	}


	useEffect(() => {
		selectOperator(data.op || operators[0].value);
		if (data.value !== {} && data.value.hasOwnProperty('numberRange')) {
			const min = data.value.numberRange.min !== null ? data.value.numberRange.min * 100 : data.value.numberRange.min;
			const max = data.value.numberRange.max !== null ? data.value.numberRange.max * 100 : data.value.numberRange.max;
			setvalueOperatorMinSelected(min);
			setvalueOperatorMaxSelected(max);
			setHasMin(min !== null ? true : false);
			setHasMax(max !== null ? true : false);
		}
		getValueOfStringComponent();
	}, [data]);

	useEffect(() => {
		handleChangeValuesById();
	}, [data, currentValue, valueOperatorSelected, valueOperatorMinSelected,
		valueOperatorMaxSelected]);


	useEffect(() => {
		setNeedValueError(!hasMin && !hasMax);
	}, [hasMin, hasMax]);
	function handleChangeMinOrMax(sign, value) {

		if (sign === 'min') {
			if (!value) {
				setvalueOperatorMinSelected(null);
			} else {
				setvalueOperatorMinSelected(-20);
			}
			setHasMin(value);
		} else {
			if (!value) {
				setvalueOperatorMaxSelected(null);
			} else {
				setvalueOperatorMaxSelected(20);
			}
			setHasMax(value);
		}
	}
	return (
		<>
			{data && data.name && data.name == 'Price comparison' &&
				<div className="mb_20">
					Filter products by comparing their price to the reference product.
					<br />You can set a minimum price, a maximum price or both.
					<br />The value is a percentage of the price of the reference product
				</div>
			}
			<div className="mb_15">
				<div className="mb_5">
					<Checkbox
						label="Minimum"
						forcedChecked={hasMin}
						onChange={(ev) => handleChangeMinOrMax('min', ev.target.checked)}
					/>
				</div>
				{hasMin &&
					<RangeSlider
						min={-100}
						max={100}
						value={valueOperatorMinSelected}
						onChange={(e) => selectOperatorMin(e.target.value)}
						inputUnit="%"
						legendList={['-100%', '0%', '+100%']}
						step={5}
						reverse={true}
					/>
				}
			</div>
			<div className="mb_15">
				<div className="mb_5">
					<Checkbox
						label="Maximum"
						forcedChecked={hasMax}
						onChange={(ev) => handleChangeMinOrMax('max', ev.target.checked)}
					/>
				</div>
				{hasMax &&
					<RangeSlider
						min={-100}
						max={100}
						value={valueOperatorMaxSelected}
						onChange={(e) => selectOperatorMax(e.target.value)}
						inputUnit="%"
						legendList={['-100%', '0%', '+100%']}
						step={5}
					/>
				}
			</div>
			{needValueError &&
				<SectionMessage
					type="warning"
					title="Choose an option"
					text="You must choose at least one option : Minimum or Maximum."
				/>
			}
			{hasMin && hasMax && (valueOperatorMinSelected > valueOperatorMaxSelected) &&
				<SectionMessage
					type="warning"
					title="The values are not correct"
					text="Maximum should be higher than Minimum."
				/>
			}
		</>
	);
}


