
export class CompositionGroup {
	id: string;
	size: (number | null);
	rules: ComposableRule[];

	public hasFillUpRule() {
		return this.rules.some(x => x.isFillUp);
	}

	public updateRule(rule: ComposableRule, property: string, value: boolean | number | string): CompositionGroup {
		return new CompositionGroup(this.id, this.rules.map(r => r.id === rule.id ? { ...r, [property]: value } : r), this.size);
	}

	public addRule(rule: ComposableRule): CompositionGroup {
		return new CompositionGroup(this.id, [...this.rules, rule], this.size);
	}

	public deleteRule(rule: ComposableRule): CompositionGroup {
		return new CompositionGroup(this.id, this.rules.filter(r => r.id !== rule.id), this.size);
	}

	public setRules(rules: ComposableRule[]): CompositionGroup {
		return new CompositionGroup(this.id, rules, this.size);
	}

	public setSize(size: (number | null)): CompositionGroup {
		return new CompositionGroup(this.id, this.rules, size);
	}

	public setIsFillUp(rule: ComposableRule, isFillUp: boolean) {
		if (isFillUp) {
			return new CompositionGroup(this.id, 
				this.rules.map(r => r.id === rule.id ? ({ ...r, isFillUp: true }) : ({ ...r, isFillUp: false })), this.size);
		} else {
			return new CompositionGroup(this.id, 
				this.rules.map(r => ({ ...r, isFillUp: false })), this.size);
		}
	}

	constructor(id: string, rules: ComposableRule[], size: (number | null)) {
		this.id = id;
		this.rules = rules;
		this.size = size;
	}
}

export type ComposableRule = {
	id: string;
	label: string;
	ruleLimit: (number | null);
	isFillUp: boolean;
}

export type Composition = {
	id: (string | null);
	name: string;
}