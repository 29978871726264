import React, {useRef, useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Fieldset from './Fieldset';
export default function InputCustom({
	value = '',
	type = 'text',
	onChange,
	handleChangeForm, // DEPRECATED : replace by onChange
	placeholder = '',
	placeHolder = '',
	disabled = false,
	readonly = false,
	clearable = false,
	id,
	name,
	min,
	max,
	step,
	label = '',
	labelClassName = '',
	description = '',
	descriptionBottom = '',
	labelTooltip = '',
	blockClassName = '',
	blockSize,
	size='m',
	color='grey',
	unit,
	isAnError,
	fullWidth = false,
	width, // 'Full' || 'xl' || 'l' || 's' || 'xs' || 'xxs'
	className = '',
	style,
	selectOnFocus = false,
	autoFocus = false,
	autoComplete = true,
	forceFocus = false,
	spellCheck = true,
	onEnter,
	onFocus,
	onBlur,
	append,
	prepend,
	rows = 3, // For textarea
	autogrow = false, // Vertical for textarea, Horizontal for text
	maxHeight, // For textarea autogrow
	maxWidth, // For text autogrow,
	minWidth, // For text autogrow,
	icon,
	iconPosition = 'before',
	doBtnHug = false,
	hasEffect = true
}) {

	const inputEl = useRef(null);
	const inputID = id || uuidv4();
	const inputClass = ['custom_input'];

	const [showPassword, setShowPassword] = useState(false);
	const [inputType, setInputType] = useState(type);

	useEffect(() => {
		if (type != 'password') return;
		setInputType(showPassword ? 'text' : type);
	}, [showPassword]);

	if( autogrow && type == 'text' ){
		inputClass.push('w_auto');
		inputClass.push('is_autogrow');
		fullWidth = null;
		width = null;
	}
	if( fullWidth ){
		width = 'full';
	}
	if( width ){
		inputClass.push('w_' + width);
	}
	if( style === 'ghost' ){
		inputClass.push('ghost');
	}
	if( color === 'white' ){
		inputClass.push('bg_' + color);
	}
	if( className ){
		inputClass.push(className);
	}
	if( disabled ){
		inputClass.push('disabled');
	}
	if( readonly ){
		inputClass.push('readonly');
	}
	if( isAnError ){
		inputClass.push('error');
	}
	if( doBtnHug ){
		inputClass.push('do_btn_hug');
	}
	if (!hasEffect) {
		inputClass.push('no_effect');
	}

	// Size : xs, s, default, l, xl, xxl
	if (size) {
		inputClass.push(size);
	}

	const change = (e) => {
		if (typeof onChange === 'function') {
			onChange(e);
		}
		if (typeof handleChangeForm === 'function') {
			handleChangeForm(e);
		}
	};

	const handleKeyPress = (e) => {
		if (typeof onEnter === 'function') {
			if (e.key === 'Enter') {
				onEnter(e);
			}
		}
	};

	const handleFocus = (e) => {
		if( selectOnFocus ){
			e.target.select();
		}
		if (typeof onFocus === 'function') {
			onFocus(e);
		}
	};

	const handleBlur = (e) => {
		if (typeof onBlur === 'function') {
			onBlur(e);
		}
	};

	const onInput = () => {
		if(! autogrow) return;
		if(! inputEl.current ) return;
		const c = inputEl.current;

		if (c.clientHeight == 0) return;

		// Autogrow
		if( type == 'textarea' ){
			c.style.height = 'auto';
			const h = maxHeight ? Math.min(maxHeight, c.scrollHeight) : c.scrollHeight;
			c.style.height = h + 'px';
			return;
		}

		if( type == 'text' ){
			c.style.width = 'auto';
			c.style.maxWidth = '80px';
			let w = maxWidth ? Math.min(maxWidth, c.scrollWidth) : c.scrollWidth;
			if (minWidth) {
				w = Math.max(minWidth, w);
			}
			c.style.width = w + 'px';
			c.style.maxWidth = '';
			return;
		}
	};

	const handleClear = (e) => {
		e.target.value = '';
		if (name) {
			e.target.name = name;
		}
		change(e);
	};

	useEffect(() => {
		if(! autogrow) return;
		if(! inputEl.current ) return;

		var observer = new IntersectionObserver(function(entries) {
			if (entries[0]['intersectionRatio'] > 0) {
				onInput();
				observer.disconnect();
			}
		}, { threshold: 1 });

		observer.observe(inputEl.current);
            
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		onInput();
	}, [value]);

	useEffect(() => {
		if (forceFocus && inputEl.current) {
			inputEl.current.focus();
		}
	}, [forceFocus]);

	const input = (
		<>
			<label className={inputClass.join(' ')}>
				{icon && iconPosition == 'before' &&
					<i className={icon + ' fa-fw custom_input_icon_before'}></i>
				}
				{prepend && prepend}
				{type == 'textarea' ?
					<textarea
						ref={inputEl}
						id={inputID}
						name={name}
						onChange={(e) => change(e)}
						value={value}
						placeholder={placeholder || placeHolder}
						onFocus={(e) => handleFocus(e)}
						onBlur={(e) => handleBlur(e)}
						autoFocus={autoFocus}
						rows={rows}
						onKeyPress={(e) => handleKeyPress(e)}
						disabled={disabled}
						readOnly={readonly}
						spellCheck={spellCheck}
						autoComplete={autoComplete === false ? 'new-password' : undefined}
						role={autoComplete === false ? 'presentation' : undefined}
					/>
					:
					<input
						ref={inputEl}
						id={inputID}
						type={inputType}
						name={name}
						min={min}
						max={max}
						step={step}
						onChange={(e) => change(e)}
						value={value}
						placeholder={placeholder || placeHolder}
						onFocus={(e) => handleFocus(e)}
						onBlur={(e) => handleBlur(e)}
						autoFocus={autoFocus}
						onKeyPress={(e) => handleKeyPress(e)}
						disabled={disabled}
						readOnly={readonly}
						autoComplete={autoComplete === false ? 'new-password' : undefined}
						role={autoComplete === false ? 'presentation' : undefined}
					/>
				}
				{clearable && value && value.length >= 1 &&
					<a className="custom_input_reset" onClick={(ev) => handleClear(ev)}>
						<i className="fas fa-times-circle"></i>
					</a>
				}
				{unit &&
                    <span className="custom_input_unit">{unit}</span>
				}
				{append && append}
				{type == 'password' &&
					<a className="custom_input_reset" onClick={() => setShowPassword(!showPassword)}>
						<i className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}></i>
					</a>
				}
				{icon && iconPosition == 'after' &&
					<i className={icon + ' fa-fw custom_input_icon_after'}></i>
				}
			</label>
			{isAnError &&
                <div className="custom_input_sub_error">{isAnError}</div>
			}
			{descriptionBottom &&
                <div className="custom_input_sub_desc">{descriptionBottom}</div>
			}
		</>
	);

	if( label ){
		return (
			<Fieldset
				inputID={inputID}
				label={label}
				description={description}
				labelTooltip={labelTooltip}
				labelClassName={labelClassName}
				blockClassName={blockClassName}
				blockSize={blockSize}
			>
				{input}
			</Fieldset>
		);
	}

	return input;
}
