import React, {useState} from 'react';
import Btn from '../../../Components/Btn';
import TableGridContainer from '../../../Components/Table/TableGridContainer';
import TableRowItem from '../../../Components/Table/TableRowItem';
import TableColItem from '../../../Components/Table/TableColItem';
import { useEmailTemplateListContext } from './context/EmailTemplateListContextProvider';
import { EmailTemplateStatus, EmailTemplateInListApiType } from '../../../Services/EmailTemplateServicesTypes'
import ModalHtmlTemplate from './ModalHtmTemplate';
import { Article } from '../../../Components/Article/Article';
import EmptyState from '../../../Components/EmptyState';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';

const rowCss = {
	gridTemplateColumns: '2.5fr 1fr 1fr 1fr 1fr 170px',
};

export default function EmailTemplateList() {

	const {
		isLoading,
		templates,
		getCreationLink,
	} = useEmailTemplateListContext();

	return (
		<>
			<div className='page_full_inner'>
				<section className='section no_bottom_pad section_primary'>
					<div className='flex'>
						<div className='flex_item_full h1'>
							Email widgets
						</div>
						{!isLoading && templates.length > 0 &&
							<div className='flex_item_fix ml_30'>
								<Btn
									message="Documentation"
									color="secondary"
									style="ghost"
									target="_blank"
									rel="noreferrer"
									icon="far fa-sm fa-question-circle"
									href="https://docs.beyable.com/"
								/>
								<Btn
									href={getCreationLink()}
									message="New email widget"
								/>
							</div>
						}
					</div>
				</section>
				<section className='section'>
					<Article>
						{!isLoading && templates.length > 0 &&
							<TableGridContainer>
								<TableRowItem specialClasseName={'table_head_row'} styleObj={rowCss}>
									<TableColItem>
										Email Template
									</TableColItem>
									<TableColItem>
										Status
									</TableColItem>
									<TableColItem>
										Views
									</TableColItem>
									<TableColItem>
										Click rate
									</TableColItem>
									<TableColItem>
										Conversion rate
									</TableColItem>
									<TableColItem align="right"></TableColItem>
								</TableRowItem>

								{templates.map(t => 
									<EmailTemplateRow
										template={t}
									/>
								)}

							</TableGridContainer>
						}
						{!isLoading && templates.length === 0 &&
							<EmptyState
								verticalSize="l"
								textSize="l"
								title="Personalize your emails with BEYABLE"
								text="Integrate our product recommendations into your email campaigns in just a few clicks"
								imageUrl="/Assets/empty_email_widget.svg"
								primaryAction={
									<Btn
										href={getCreationLink()}
										message="Create an email widget"
									/>
								}
								secondaryAction={
									<Btn
										message="Read documentation"
										color="secondary"
										style="outline"
										target="_blank"
										rel="noreferrer"
										href="https://docs.beyable.com/"
									/>
								}
							/>
						}
					</Article>
				</section>
			</div>
			<ModalHtmlTemplate/>
		</>
	);
}

function EmailTemplateRow({
	template
}: {template: EmailTemplateInListApiType}): JSX.Element {

	const {
		getEditLink,
		handleChangesStatusModalHtml
	} = useEmailTemplateListContext();

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

	return (
		<TableRowItem key={template.id} styleObj={rowCss}>
			<TableColItem>
				<span className='fw_medium'>{template.name}</span>
			</TableColItem>
			<TableColItem>
				{template.status == EmailTemplateStatus.Live ? 'Live' : 'Draft'}
			</TableColItem>
			<TableColItem>0</TableColItem>
			<TableColItem>0.00%</TableColItem>
			<TableColItem>0.00%</TableColItem>
			<TableColItem align="right">
				<Btn
					icon="fas fa-code"
					onClick={()=>handleChangesStatusModalHtml(true)}
					tooltip="Get widget HTML code"
					size="xs"
					horizontalSize="xxs"
					color="secondary"
					style="ghost"
				/>
				<Btn
					icon="fas fa-pen"
					href={getEditLink(template.id)}
					tooltip="Edit widget"
					size="xs"
					horizontalSize="xxs"
					color="secondary"
					style="ghost"
				/>
				<Dropdown
					isOpen={dropdownIsOpen}
					toggle={(s) => setDropdownIsOpen(s)}
					button={
						<Btn
							icon="fas fa-ellipsis-v"
							size="xs"
							horizontalSize="xxs"
							color="secondary"
							style="ghost"
						/>
					}
				>
					<Listbox>
						<ListboxItem
							message="Delete widget"
							icon="fas fa-trash"
						/>
					</Listbox>
				</Dropdown>
			</TableColItem>
		</TableRowItem>
	);
}