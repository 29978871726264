import React, { Fragment, useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn';
import FilterExpressionCard from './FilterExpressionCard';
import ModalFilters from './ModalFilters';


import OperatorBetweenFilters from './OperatorBetweenFilters';
import {useFiltersContext} from './contextFilters';
export default function FiltersList({
	userfiltersList,
	operator,
	ListFilterAvailable,
	onAddFilter,
	onRemoveFilter,
	onEditFilter,
	handleChangeOperator,
	setupAccountServices,
	accountId,
	tenantSelected,
	previewTenant,
	needTenantForSearch
}) {

	const {
		userFiltersWithId,
		handleClickEditFilter,
		openNewFilter,
		getNameFilter,
		arrayOfFilters,
	} = useFiltersContext();

	return (
		<>
			
			<div className='collection_sidebar_block_title flex'>
				<div className='flex_item_full'>Filters</div>
				{userfiltersList.length > 1 &&
					<div className='flex_item_fix ml_20'>
						<OperatorBetweenFilters
							op={operator}
							handleChangeOperator={handleChangeOperator}
							filters={userfiltersList}
						/>
					</div>
				}
			</div>
			{userFiltersWithId.map((filter, i) =>
				<FilterExpressionCard
					key={filter.idFilter}
					mainFilter={filter}
					userFiltersWithId={userFiltersWithId}
					onRemoveFilter={onRemoveFilter}
					getNameFilter={getNameFilter}
					handleClickEditFilter={handleClickEditFilter}
				/>
			)}
			<Btn
				onClick={() => openNewFilter()}
				icon="fas fa-plus-circle"
				message="Add filter"
				color="primary"
				style="outline"
				fullWidth={true}
			/>
			<ModalFilters
				filterList={arrayOfFilters}
				accountId={accountId}
				tenantSelected={tenantSelected}
				onAddFilter={onAddFilter}
				onEditFilter={onEditFilter}
				ListFilterAvailable={ListFilterAvailable}
				previewTenant={previewTenant}
				needTenantForSearch={needTenantForSearch}
				setupAccountServices={setupAccountServices}
			/>
			
		</>
	);
}
