/* eslint-disable no-mixed-spaces-and-tabs */
import React,{useState,useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import InputCustom from '../../../../../Components/InputCustom';
import NumericUpDown from '../../../../../Components/NumericUpDown';
import Fieldset from '../../../../../Components/Fieldset';
import { Switch } from '../../../../../Components/Checkbox/Checkbox';

export default function FieldTransformation({
	x,
	hasError,
	valueParam,
	handleChangeParameters,
	handleAddOrDeleteParam,
	isOpen,
	modalIsOpen,
	
}) {
	const inputID = uuidv4();
	const [show, setshow] = useState(isOpen);

	function removeCamelCase(str) {
		return str[0] + str.slice(1, str.length).replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
	}

	const valueType = x.valueType === 'String' ? 'text' : 'number';
	let label = removeCamelCase(x.name);
	if (x.isRequired) label += ' *';
	let showChoiceElement = false;
	if(!x.isRequired){
		showChoiceElement = true;
	}
	function handleClickToShow(){
		setshow(!show);
		handleAddOrDeleteParam(x.name,!show);
	}
	useEffect(()=>{
		setshow(isOpen ? isOpen : false);
	},[modalIsOpen]);
	const value = valueParam.params[x.name] ? valueParam.params[x.name] : x.defaultValue;

	return (
		<div className="flex flex_align_start">
			<div className='flex_item_full flex_item_align_center'>
				<Fieldset
					label={label}
					description={x.description}
					inputID={inputID}
					blockClassName="no_margin">
					{(show || !showChoiceElement) &&
						<>
							{valueType === 'number' &&
								<NumericUpDown
									name={x.name}
									value={valueParam.params[x.name] || 0}
									canEdit={true}
									onChange={(evt)=>handleChangeParameters(x.name, evt.target.value, valueParam)}
									isAnError={hasError && hasError.text}
									min={-Infinity}
									max={Infinity}
								/>
							}
							{valueType === 'text' &&
								<InputCustom
									id={inputID}
									value={value}
									type={valueType}
									name={x.name}
									placeholder={x.description}
									onChange={(evt)=>handleChangeParameters(x.name, evt.target.value, valueParam)}
									fullWidth={true}
									isAnError={hasError && hasError.text}
								/>
							}
						</>
					}
				</Fieldset>
			</div>
			{showChoiceElement &&
				<div className='flex_item_fix ml_10'>
					<Switch
						onChange={()=>handleClickToShow()}
						forcedChecked={show}
					/>
				</div>
			}
		</div>
	);
}
