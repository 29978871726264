/* eslint-disable no-mixed-spaces-and-tabs */
import React,{useState,useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '../../../../../Components/Checkbox';
import Fieldset from '../../../../../Components/Fieldset';
import { Switch } from '../../../../../Components/Checkbox/Checkbox';
export default function FieldTransformationCheckBox({
	x,
	hasError,
	valueParam,
	handleChangeParametersCheck,
	handleAddOrDeleteParam,
	isOpen,
	modalIsOpen,
	checked
}) {
	const inputID = uuidv4();
	const [show, setshow] = useState(isOpen);

	function removeCamelCase(str) {
		return str[0] + str.slice(1, str.length).replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
	}


	let label = removeCamelCase(x.name);
	if (x.isRequired) label += ' *';
	let showChoiceElement = false;
	if(!x.isRequired){
		showChoiceElement = true;
	}
	function handleClickToShow(){
		setshow(!show);
		handleAddOrDeleteParam(x.name,!show);
	}
	useEffect(()=>{
		setshow(isOpen ? isOpen : false);
	},[modalIsOpen]);


	return (
		<div className="flex flex_align_start">
			<div className='flex_item_full flex_item_align_center'>
				{hasError && <> Please check this element</>}
				<Fieldset
					label={label}
					inputID={inputID}
					blockClassName="no_margin">
					{(show || !showChoiceElement) &&
               <Checkbox 
               	// label={label}
               	labelIsStrong={true}
               	noMargin={true}
               	description={x.description}
               	forcedChecked={checked}
               	name={x.name}
               	onChange={(evt)=>handleChangeParametersCheck(evt, valueParam)}
               	size="xl"
               />
					}
				</Fieldset>
			</div>
			{showChoiceElement &&
        <div className='flex_item_fix ml_10'>
        	<Switch
        		onChange={()=>handleClickToShow()}
        		forcedChecked={show}
        	/>
        </div>
			}
		</div>
	);
}
