import React, { useState, useEffect } from 'react';
import Range from '../../../../Components/RangeSlider';

const SortingCard = (state) => {
	const { factor,
		onChangeAttribute,
		onRemoveAttribute,
		onChangeAttributeOfSign,
		onEditAttribute,
		name,
		warning,
		isSingleElement,
		first,
		loadingProductList
	} = state;

	const [eventValue, seteventValue] = useState();
	const { weight, collationId, params } = factor;
	const canBeEdited = params && params.length > 0;
	const isTag = name === 'Tag';
	const min = 0;
	const stopMin = 10;
	const max = 100;
	const [valuForFront, setValueForFront] = useState();
	const valueForFrontOld = weight >= 0 ? weight * 100 : Math.abs(weight) * 100;
	function onChangeAttributeWithTransfomation(evt) {
		const valueForTransform = evt/ max;
		onChangeAttribute(factor, valueForTransform);
	}
	function handleChangeValueForFront (e) {
		const val = e / 100;
		seteventValue(e);
		setValueForFront( val >= 0 ? val * 100 : Math.abs(val) * 100);
	}
	useEffect(()=> {
		const timer = setTimeout(() => {
			if (eventValue && !first) {
				onChangeAttributeWithTransfomation(eventValue);
			}
		}, 200);
		return () => {
			clearTimeout(timer);
		};
	},[valuForFront]);
	
	function getValueOfTag () {
		if(name === 'Tag'){
			return ' - ' + factor.params[0].value.stringValues.toString();
		}
	}
	return (
		<div className="collection_sidebar_item">
			<div className="flex">
				<div className='flex_item_full'>
					{canBeEdited &&
						<span className="fw_medium" onClick={() => onEditAttribute()}>{name} {isTag && getValueOfTag()}</span>
					}
					{!canBeEdited &&
						<span className="fw_medium">{name} {isTag && getValueOfTag()}</span>
					} 

					<span className='dot'></span>

					{Math.sign(weight) !== 0 ?
						<span className="c_turquoise pointer fw_medium pointer"
							onClick={() => onChangeAttributeOfSign(factor)}
						>
							{Math.sign(weight) === -1 && <>
								Higher first
								<i className="fas fa-caret-down ml_3"></i>
							</>}
							{Math.sign(weight) === 1 && <>
								Lower first
								<i className="fas fa-caret-up ml_3"></i>
							</>}
						</span>
						:
						<span className="c_turquoise  fw_medium">
							No effect
						</span>
					}
				</div>
				<div className='flex_item_fix ml_10'>
					<a className='icon_btn xs collection_sidebar_item_del' onClick={() => onRemoveAttribute(isTag, isTag ? factor : collationId)}>
						<i className="fas fa-trash"></i>
					</a>
				</div>
			</div>
			{warning && 'The element is not compatible with based on selected'}
			{!isSingleElement &&
				<div className='collection_sidebar_item_range'>
					<Range
						value={valuForFront ? valuForFront :valueForFrontOld}
						min={min}
						max={max}
						disabled={loadingProductList}
						stopMin={stopMin}
						legendList={['Low weight', 'High weight']}
						step={10}
						onChange={(e) => handleChangeValueForFront(e.target.value)}
						hasInput={true}
						inputUnit='%'
						isOnKeyUp={true}
					/>
				</div>
			}
		</div >
	);
};


export default SortingCard;