import React, { Fragment, useState,useEffect } from 'react';
import Btn from '../../Components/Btn';
import SectionMessage from '../../Components/SectionMessage';
import Link from '../../Components/Link';
import EmptyState from '../../Components/EmptyState';
import SpinnerWheel from '../../Components/SpinnerWheel';
import './TargetingRulesContainer.css';
import '../Campaigns/Triggers/TriggersContainer.css';
import TargetingRulePanel from './TargetingRulePanel';
import { useTriggerContext } from './TriggerContext';
import TargetingRuleCard from './TargetingRuleCard';
import SegmentRule from './SegmentRule';
import HelpDeskServices from '../../Services/HelpdeskServices';
import {useTranslation} from 'react-i18next';
import renderJsxTag from '../../Translation/translation-utils';

export default function TargetingRulesContainer({
	isABTestingActive,
	systemServices,
	needWrapper = true
}) {

	const {
		mode,
		targetingRules,
		trackingMode,
		handleTrackingModeChange,
		isModuleEditorMode,
		segmentLibrary,
		favoriteRules,
		isLoading,
		canSwitchToNoTracking,
		hasNotAvailableTriggerGroup,
		isAbTestMode
	} = useTriggerContext();

	const [t, i18n] = useTranslation('common');
	const [targetingRulePanelIsOpen, setTargetingRulePanelIsOpen] = useState(false);

	const [notUsedSegmentLibrary, setnotUsedSegmentLibrary] = useState([]);

	const { askHelpAbout } = HelpDeskServices();
	useEffect(()=>{
		setnotUsedSegmentLibrary(segmentLibrary.filter(s => s.isUsed !== true));
	},[segmentLibrary]);
	const handleClickTrackingMode = (trackingMode) => {
		if (trackingMode === 1 && isABTestingActive) {
			systemServices.showSuccess('In functional mode, your A/B test variations have been automatically disabled');
		}
		handleTrackingModeChange(trackingMode);
	};

	const content = (
		<>
			{isLoading &&
				<SpinnerWheel wheelSize="l" />
			}
			{!isLoading &&
				<>
					{mode === 'creating' && isModuleEditorMode && segmentLibrary && segmentLibrary.length > 0 &&
						<div className='segment_section_group l'>
							<SectionMessage
								title={t('triggers.goodToKnow')}
								text={
									<>
										{t('triggers.weHaveSelected')}
										{segmentLibrary.length > 1 && favoriteRules.length == 0 &&
											<><br /> {t('triggers.butYouCan')}</>
										}
										{segmentLibrary.length > 1 && favoriteRules.length > 0 &&
											<><br />{t('triggers.chooseAnother')}</>
										}
										{segmentLibrary.length == 1 && favoriteRules.length > 0 &&
											<><br />{t('triggers.chooseFavorite')}</>
										}
									</>
								}
								type="success"
							/>
						</div>
					}

					{isModuleEditorMode && hasNotAvailableTriggerGroup  || isAbTestMode && hasNotAvailableTriggerGroup &&
						<div className='segment_section_group l'>
							<SectionMessage
								title={t('triggers.popularSegment')}
								text={
									<>
										{renderJsxTag( t('triggers.pleaseContact'),
										)}						
										<br/>
									</>
								}
								links={
									<Link 
										message={t('triggers.ContactUs')}
										onClick={() => askHelpAbout('MoreTriggers')}
									/>
								}
							/>
						</div>
					}

					{!isAbTestMode && canSwitchToNoTracking &&
					<div className='segment_section_group l'>
						<div className="segment_section_label">
							<div>{t('triggers.tracking')}</div>
						</div>
						<div className="btn_switch w_full">
							<Btn
								style="outline"
								color="secondary"
								onClick={() => handleClickTrackingMode(1)}
								isActive={trackingMode === 1}
								className="trigger_btn"
								size="l"
							>
								<div>
									<div className="trigger_btn_text">{t('triggers.trackFunctional')}</div>
									<div className="trigger_btn_desc">{t('triggers.functionalDesc.1')} <br />{t('triggers.functionalDesc.2')}</div>
								</div>
							</Btn>
							<Btn
								style="outline"
								color="secondary"
								onClick={() => handleClickTrackingMode(2)}
								isActive={trackingMode === 2}
								className="trigger_btn"
								size="l"
							>
								<div>
									<div className="trigger_btn_text">Marketing</div>
									<div className="trigger_btn_desc">
										{t('triggers.marketing.1')} <br />{t('triggers.marketing.2')}
									</div>
								</div>
							</Btn>
						</div>
					</div>
					}

					<div className='segment_section_group'>
						<div className="segment_section_label">
							<div>{t('triggers.activeSegments')} ({targetingRules.length})</div>
						</div>
						{targetingRules.length === 0 &&
							<div className="segment_section">
								<EmptyState
									title={t('triggers.NoSegmentYet')}
									text={t('triggers.everyPage')}
									titleBold={false}
									imageUrl={'/Assets/empty_targeting.svg'}
									verticalSize={'s'}
									primaryAction={
										<Btn
											color="primary"
											style="reverse"
											onClick={() => setTargetingRulePanelIsOpen(true)}
											message={t('triggers.createSegment')}
										/>
									}
								/>
							</div>
						}
						{targetingRules.length > 0 &&
							<>
								{targetingRules.map((e, i) => (
									<Fragment key={i}>
										{i >= 1 &&
											<div className='segment_section_or'>
												<div className='segment_section_or_inner'>
													{t('triggers.OR')}
												</div>
											</div>
										}
										<TargetingRuleCard
											key={i}
											targetingRule={e}
										/>
									</Fragment>
								))}
							</>
						}
					</div>
					{(isModuleEditorMode && notUsedSegmentLibrary.length > 0 ) &&
						<>
							<div className='segment_section_group'>
								<div className="segment_section_sub_label">
									<div>{t('triggers.others')}</div>
								</div>
								{segmentLibrary.map((e, i) => (
									<Fragment key={i}>
										{!e.isUsed &&
										<SegmentRule
											rule={e}
											type="segment"
										/>
										}
									</Fragment>
								))}
							</div>
						</>
					}
					{(isAbTestMode  && notUsedSegmentLibrary.length > 0)
					&&
					<>
						<div className='segment_section_group'>
							<div className="segment_section_sub_label">
								<div>{t('triggers.others')}</div>
							</div>
							{segmentLibrary.map((e, i) => (
								<Fragment key={i}>
									{!e.isUsed &&
										<SegmentRule
											rule={e}
											type="segment"
										/>
									}
								</Fragment>
							))}
						</div>
					</>
					}


					{targetingRules.length > 0 &&
						<div className='segment_section_group'>
							<div className="segment_section_sub_label">
								<div>{t('triggers.createSegmentFromScratch')}</div>
							</div>
							<div className='segment_section segment_card segment_card_close'
								onClick={() => setTargetingRulePanelIsOpen(true)}>
								<div className='segment_card_header s flex clickable'>
									<div className='segment_card_icon flex_item_fix'>
										<i className='fas fa-plus'></i>
									</div>
									<div className='segment_card_name flex_item_full ellips'>{t('triggers.newSegment')}</div>
									<div className='segment_card_arrow flex_item_fix'>
										<i className='fas fa-chevron-right'></i>
									</div>
								</div>
							</div>
						</div>
					}

					{favoriteRules && favoriteRules.length > 0 &&
						<div className='segment_section_group'>
							<div className="segment_section_sub_label">
								<div>{t('triggers.favoriteSegments')}</div>
							</div>
							{favoriteRules.map((rule, i) => (
								<SegmentRule
									key={i}
									rule={rule}
									type="favorite"
									isUsed={rule.isUsed}
								/>
							))}
						</div>
					}

				</>
			}
			{targetingRulePanelIsOpen &&
				<TargetingRulePanel
					panelIsOpen={targetingRulePanelIsOpen}
					setPanelIsOpen={setTargetingRulePanelIsOpen}
				/>
			}
		</>
	);

	if (needWrapper) {
		return (
			<div className={'target_rules_outer'}>
				<div className='target_rules_inner'>
					{content}
				</div>
			</div>
		);
	}

	return content;
}
