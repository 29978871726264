import React from 'react';
import SpinnerWheel from './SpinnerWheel';

export default function EmptyState({
	title = '',
	text = '',
	primaryAction,
	secondaryAction,
	imageUrl,
	imageWidth,
	icon,
	verticalSize = 'm',
	verticalAlign = false,
	textSize = 'm',
	width = 'full',
	titleBold = true,
	spinner = false,
	hasBorder = false 
}) {
	
	const cssClass = ['empty_state'];
	const titleClass = ['empty_state_title'];

	// Width
	if (width) {
		cssClass.push('w_' + width);
	}

	// verticalSize
	if (verticalSize) {
		cssClass.push('v_pad_' + verticalSize);
	}
	
	// verticalAlign
	if (verticalAlign) {
		cssClass.push('abs');
	}
	
	// textSize
	if (textSize) {
		cssClass.push('text_size_' + textSize);
	}
	
	// Border
	if (hasBorder) {
		cssClass.push('has_border');
	}
	
	// titleBold
	if (!titleBold) {
		titleClass.push('fw_normal');
	}
	
	return (
		<div className={cssClass.join(' ')}>
			{spinner &&
				<SpinnerWheel
					display="block"
					verticalSize="s"
					wheelSize="xl"
				/>
			}
			{imageUrl && 
				<img className="empty_state_img" src={imageUrl} width={imageWidth} />
			}
			{icon && 
				<div className="empty_state_icon">
					<i className={icon}></i>
				</div>
			}
			{title && 
				<div className={titleClass.join(' ')}>{title}</div>
			}
			{text && 
				<div className='empty_state_text'>{text}</div>
			}
			{(primaryAction || secondaryAction) &&
				<div className='empty_state_btn'>{secondaryAction}{primaryAction}</div>
			}
		</div>
	);
}
