import React, {useState} from 'react';
import { ReportGeneratorContext } from './Context/index.js';
import { Panel, PanelHeader, PanelBody} from './../../Components/Panel/Panel';
import Btn from './../../Components/Btn';
import InputCustom from './../../Components/InputCustom';
import Fieldset from './../../Components/Fieldset';
import IconBtn from './../../Components/IconBtn';
import ModalKpi from './ModalKpi';
import {getIndicatorByKey} from '../ReportingCampaign/Indicators';
import SelectorDevices from './SelectorDevices';
const devices = [
	// {
	// 	value : 'all',
	// 	label : 'All'
	// },
	{
		value : 'desktop',
		label : 'Desktop'
	},
	{
		value : 'mobile',
		label : 'Mobile'
	},
	// {
	// 	value : 'Tablet',
	// 	label : 'Tablet'
	// }
];
export default function ModalKpiSelectionCampaign() {
	const {
		modalIsOpen,
		setmodalIsOpen,
		selectedCampaignOptions,
		handleChangeCpgOptions,
		handleCampaignSelected,
		handleChangeDeviceSelected,
		resetSource,
	} = ReportGeneratorContext();

	const [modalKpiIsOpen, setmodalKpiIsOpen] = useState(false);
	function handleChangeInput(event : React.SyntheticEvent){
		event.persist();
		const target = event.target as HTMLInputElement;
		const name = target.name;
		const value = target.value;
		handleChangeCpgOptions(name, value);
	}
	function handleValid(){
		handleCampaignSelected(selectedCampaignOptions);

		setmodalIsOpen(false);
	}

	return (
		<>
			<Panel
				isOpen={modalIsOpen}
				width={560}
				side="right"
				offsetWidth={400}
				hasOffset={modalKpiIsOpen}
			>
				<PanelHeader
					title="Campaign settings"
					desc={selectedCampaignOptions ? selectedCampaignOptions.name : ''}
					onClose={() => setmodalIsOpen(false)}
					primaryAction={
						<Btn
							message="Done"
							onClick={() => handleValid()}
						/>
					}
				/>
				<PanelBody>
					<Fieldset label='KPIs'>
						<Btn
							onClick={() =>setmodalKpiIsOpen(true) }
							message="Add"
							style="reverse"
							icon="fas fa-plus fa-sm"
							size="xs"
							className="mr_8 mb_8"
						/>
						{selectedCampaignOptions
							&& selectedCampaignOptions.indicators
							&& selectedCampaignOptions.indicators.map((indicator) => 
								<Indicator
									key={indicator}
									value={indicator}
								/>
							)
						}
					</Fieldset>
					<Fieldset label="Devices">
						{selectedCampaignOptions && <SelectorDevices
							resetSource={resetSource}
							deviceSelected={selectedCampaignOptions?.devices || []}
							initialValue={devices}
							cpg={selectedCampaignOptions}
							handleChangeDeviceSelected={handleChangeDeviceSelected}
						/>}
					</Fieldset>
					<InputCustom
						label="Objective"
						placeholder="Recrute new clients"
						name="objectiveDescription"
						rows="2"
						type="textarea"
						value={selectedCampaignOptions?.objectiveDescription}
						onChange={(e) => handleChangeInput(e)}
						fullWidth={true}
					/>
					<InputCustom
						label="Location"
						placeholder="Product page side bar"
						name="displayArea"
						rows="2"
						type="textarea"
						value={selectedCampaignOptions?.displayArea}
						onChange={(e) => handleChangeInput(e)}
						fullWidth={true}
					/>
					<InputCustom
						label="Triggers"
						placeholder="New visitors who have seen 2 product pages"
						name="targetAudience"
						rows="2"
						type="textarea"
						value={selectedCampaignOptions?.targetAudience}
						onChange={(e) => handleChangeInput(e)}
						fullWidth={true}
					/>
				</PanelBody>
			</Panel>
			<ModalKpi
				setmodalIsOpen={setmodalKpiIsOpen}
				modalIsOpen={modalKpiIsOpen}
			/>
		</>
	);
}

function Indicator ({
	value
}) {

	const {
		handleChangeCpgOptions,
		selectedCampaignOptions
	} = ReportGeneratorContext();

	const {label} = getIndicatorByKey(value);

	const handleRemove = () => {
		const selectedIndicators:any[] = [...(selectedCampaignOptions.indicators || [])];
		const newSelectedIndicators = selectedIndicators.filter(k => k !== value);
		handleChangeCpgOptions('indicators', newSelectedIndicators);
	};

	return (
		<span className='report_kpi'>
			<span className='report_kpi_label'>{label}</span>
			<IconBtn
				icon="fas fa-times"
				onClick={handleRemove}
				size="s"
				className="report_kpi_icon"
				hoverColor="alert"
				tooltip="Remove KPI"
			/>
		</span>
	);
}
