import React, { useEffect, useState } from "react";
import Btn from "../../../../Components/Btn";
import Modal from "../../../../Components/Modal";
import Checkbox from "../../../../Components/Checkbox";

export default function OptionFilter ({
    optionList,
    setOptionList
}) {
    const [modalOptionsIsOpen, setModalOptionsIsOpen] = useState(false)
    const [tmpList, setTmpList] = useState(optionList)

    const updateTmpBavList = (key) => {
        const index = tmpList.findIndex(e => e.key === key)
        let newState = [...tmpList]
        newState[index].checked = !newState[index].checked
        setTmpList(newState)
    }

    return(
        <>
            <Btn
                message="Displayed data"
                style="outline"
                color="secondary"
                icon="fas fa-sliders-h"
                onClick={() => setModalOptionsIsOpen(true)}
            />
            <Modal
                isOpen={modalOptionsIsOpen}
                onClose={() => setModalOptionsIsOpen(false)}
                width="380"
                animation="slide_down"
            >
                <div className="modal_header has_border">Select displayed data</div>
                <div className="modal_body grey_0">
                    {optionList.map(item => (
                        <div key={item.key} className="form_line s_15 has_border">
                            <Checkbox
                                checked={item.checked}
                                onChange={() => updateTmpBavList(item.key)}>
                                {item.label}
                            </Checkbox>
                        </div>
                    ))}
                </div>
                <div className="modal_footer al_right">
                    <Btn
                        onClick={() => setModalOptionsIsOpen(false)}
                        message="Cancel"
                        style="ghost"
                        color="secondary"
                    />
                    <Btn
                        onClick={() => {setModalOptionsIsOpen(false); setOptionList(tmpList);}}
                        message="Ok"
                    />
                </div>
            </Modal>
        </>
    )
}