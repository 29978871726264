import React, { useState, useEffect } from 'react';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SystemServices from '../../../Services/SystemServices';
import OrchestrationServices from '../../../Services/OrchestrationServices';
import PopinConfirm from '../../../Components/PopinConfirm';
import Btn from '../../../Components/Btn';
import SmartCampaignGroupDashboard from './SmartCampaignsGroupDashboard';
import EditSmartCampaignsGroup from './EditSmartCampaignsGroup';

export default function SmartCampaignContainer({ $rootScope, $routeParams, $http, accountId }){

	const systemServices = new SystemServices($rootScope);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	
	const orchestrationServices = new OrchestrationServices($http, $routeParams);

	const [currentTab, setCurrentTab] = useState(1);
	const [currentOrchestration, setCurrentOrchestration] = useState();
	const [oppenSettingsForCurrentorchestration, setOppenSettingsForCurrentorchestration] = useState();
	const [clickToSave, setClickToSave] = useState(false);
	const [clickToCancel, setClickToCancel] = useState(false);
	const [messageInformations, setMessageInformations] = useState('');
	const [hasNotSavedChanges, sethasNotSaveChanges] = useState(false);
	const [modalCOnfirmIsOppen, setModalCOnfirmIsOppen] = useState(false);
	const returnToDashBoard = (deleting) => {
		if (!deleting && hasNotSavedChanges) {
			setModalCOnfirmIsOppen(true);
		} else {
			setCurrentTab(1);
			setCurrentOrchestration();
		}
	};

	return (
		<>
			{
				modalCOnfirmIsOppen &&
				<PopinConfirm
					cancel={() => setModalCOnfirmIsOppen(false)}
					title={'Are you sure you want to quit without saving?'}
				>
					<Btn
						onClickFunction={(e) => { setModalCOnfirmIsOppen(false); }}
						message="No"
						color="alert"
					/>
					<Btn
						onClickFunction={(e) => {
							setModalCOnfirmIsOppen(false); setCurrentTab(1); setCurrentOrchestration(); sethasNotSaveChanges(false);
						}}
						message="Yes i'am sure"
						color="primary"
					/>
				</PopinConfirm>
			}
			{currentTab === 1 &&
				<section className='section'>
					<div className='page_block'>
						<SmartCampaignGroupDashboard
							accountId={accountId}
							setCurrentTab={(e) => setCurrentTab(e)}
							setCurrentOrchestration={(e) => setCurrentOrchestration(e)}
							orchestrationServices={orchestrationServices}
							systemServices={systemServices}
						/>
					</div>
				</section>
			}
			{currentTab === 2 &&
				<div className="page_full page_full_grey section_root flex flex_v hide_subaccount_nav">
					<div className="page_breadcrumb_header flex_item_fix flex">
						<div className="flex_item_full">
							<ul className="page_breadcrumb">
								<li>
									<a className="page_breadcrumb_link" onClick={() => returnToDashBoard()}>
										Smart campaigns
									</a>
								</li>
								{currentOrchestration &&
									<li>{currentOrchestration.group.name}</li>
								}
							</ul>
						</div>
						<div className="flex_item_fix">
							<Btn
								onClickFunction={hasNotSavedChanges ? () => { setClickToCancel(!clickToCancel); sethasNotSaveChanges(false); } : null}
								message="Cancel"
								style="ghost"
								color="secondary"
								disabled={!hasNotSavedChanges}

							/>
							<Btn
								onClickFunction={hasNotSavedChanges ? () => setClickToSave(!clickToSave) : null}
								message="Save changes"
								disabled={!hasNotSavedChanges}
							/>
						</div>
					</div>
					<div className="flex_item_full vscroll">
						<div className="page_center">
							{currentOrchestration &&
								<>

									<div className="flex flex_justify_between">
										<p>{messageInformations}</p>
									</div>

									<EditSmartCampaignsGroup
										accountId={accountId}
										clickToSave={clickToSave}
										setClickToSave={(e) => setClickToSave(e)}
										clickToCancel={clickToCancel}
										setClickToCancel={(e) => setClickToCancel(e)}
										setCurrentTab={(e) => setCurrentTab(e)}
										currentOrchestration={currentOrchestration}
										setCurrentOrchestration={(e) => setCurrentOrchestration(e)}
										returnToDashBoard={returnToDashBoard}
										oppenSettingsForCurrentorchestration={oppenSettingsForCurrentorchestration}
										setOppenSettingsForCurrentorchestration={(e) => setOppenSettingsForCurrentorchestration(e)}
										orchestrationServices={orchestrationServices}
										systemServices={systemServices}
										sethasNotSaveChanges={(e) => sethasNotSaveChanges(e)}
									/>
								</>
							}
						</div>
					</div>
				</div>
			}
		</>
	);
}