import React from 'react';
import s from './List.module.css';

const List = React.forwardRef(({
	children,
	className = '',
	size = 'm',
	hasLastBorder = false,
	hasBorder = true,
	borderPosition = 'outer',
	removeVerticalMargin = false,
	horizontalAreaOffset = false,
	...otherProps
}, ref) => {

	let cssClass = [s.list];

	if (className) {
		cssClass = cssClass.concat(className);
	}

	if (size) {
		cssClass.push(s['list_size_' + size]);
	}

	if (hasBorder) {
		cssClass.push(s.list_has_border);

		if (hasLastBorder) {
			cssClass.push(s.list_has_last_border);
		}
	
		if (borderPosition == 'inner') {
			cssClass.push(s.list_has_inner_border);
		}
	
		if (borderPosition == 'inner_picto') {
			cssClass.push(s.list_has_inner_picto_border);
		}
	}

	if (removeVerticalMargin) {
		cssClass.push(s.list_remove_vertical_margin);
	}

	if (horizontalAreaOffset) {
		cssClass.push(s.list_horizontal_area_offset);
	}

	return (
		<div className={cssClass.join(' ')} {...otherProps} ref={ref}>
			{children}
		</div>
	);
});

const ListItem = React.forwardRef(({
	children,
	className,
	picto,
	pictoHasMargin = false,
	text,
	description,
	actions,
	onClick,
	value,
	valueMaxWidth = 200,
	hasArrow = false,
	textIsBold = undefined,
	textIsItalic = false,
	forceBorder = false,
	isActive = false,
	isDragging = false,
	...otherProps
}, ref) => {

	const hasClickHandler = typeof onClick === 'function';
	let cssClass = [s.list_item];
	const style = {};

	if (valueMaxWidth) {
		style.maxWidth = valueMaxWidth + 'px';
	}

	if (hasClickHandler) {
		cssClass.push(s.list_item_clickable);
	}

	if (className) {
		cssClass = cssClass.concat(className);
	}

	if (textIsItalic) {
		cssClass.push(s.list_item_italic);
	}

	if (forceBorder) {
		cssClass.push(s.list_item_force_border);
	}

	if (isActive) {
		cssClass.push(s.list_item_is_active);
	}

	if (isDragging) {
		cssClass.push(s.list_item_is_dragging);
	}

	const doesTextIsBold = () => {
		if (textIsBold === true) return true;
		if (textIsBold === false) return false;
		return !!(description || value);
	};

	const handleClick = (ev) => {
		if (hasClickHandler) {
			onClick(ev);
		}
	};

	return (
		<div className={cssClass.join(' ')} onClick={(ev) => handleClick(ev)} {...otherProps} ref={ref}>
			{picto && <div className={pictoHasMargin ? s.col_picto_margin :  s.col_picto}>{picto}</div>}
			<div className={s.col_main}>
				{children}
				{text &&
					<div className={doesTextIsBold() ? s.text_strong : s.text}>{text}</div>
				}
				{description && <div className={s.description}>{description}</div>}
			</div>
			{value && <div className={s.col_value} style={{}}>{value}</div>}
			{actions && <div className={s.col_actions}>{actions}</div>}
			{hasArrow &&
				<div className={s.col_arrow}>
					<i className='fas fa-chevron-right'></i>
				</div>
			}
		</div>
	);
});

List.displayName = 'List';
ListItem.displayName = 'ListItem';

export {List, ListItem};
