import React from 'react';
import styles from './Article.module.css';
import {IArticle} from './types';

function Article({
	children,
	title,
	hasMask = false,
	hasMargin = true,
	hasPadding = true,
	hasShadow = false,
	size = 'm',
	innerSize = undefined,
	titleSize = 'm',
	className,
	fullHeight = false
}: IArticle): JSX.Element {

	const outerClass = [styles.outer];
	const innerClass = [styles.inner];
	const titleClass = [styles.title];

	if (className) {
		outerClass.push(className);
	}

	if (hasMask) {
		outerClass.push(styles.outer_has_mask);
	}

	if (hasShadow) {
		innerClass.push(styles.inner_has_shadow);
	}

	if (size) {
		outerClass.push(styles['outer_size_' + size]);
		if (hasPadding) {
			innerClass.push(styles['inner_size_' + (innerSize || size)]);
		}
	}

	if (!hasPadding) {
		innerClass.push(styles['inner_no_padding']);
	}

	if (titleSize) {
		titleClass.push(styles['title_size_' + titleSize]);
	}

	if (!hasMargin) {
		outerClass.push(styles.outer_no_margin);
	}

	if (fullHeight) {
		outerClass.push(styles.outer_full_height);
	}

	return (
		<article className={outerClass.join(' ')}>
			{title &&
				<div className={titleClass.join(' ')}>{title}</div>
			}
			<div className={innerClass.join(' ')}>{children}</div>
		</article>
	);
}

export {
	Article
};
