import React, {} from 'react'
import ItemFilterCard from './ItemFilterCard'

export default function ModalFiltersList({ addFiltering,
	ListFilterAvailable,
	tabFilterSelected,
	noData
}) {

	const categoryIcon = {
		'Attributes': 'data',
		'Scores': 'score',
		'Product comparison': 'comparison'
	};

	const getCategoryIcon = (name) => {
		if (!(name in categoryIcon)) {
			name = 'Attributes';
		}
		return categoryIcon[name];
	}

	return (
		<div className="trigger_store">
			<div className="trigger_store_category">
				{ListFilterAvailable &&
					ListFilterAvailable.map((x, i) => {
						const icon = getCategoryIcon(x.name);
						return (
							<div className='trigger_store_category' key={i}>
								<div className="trigger_store_category_label">
									{x.name}
								</div>
								<ul className="trigger_store_list">
									{x.itemList.map((value, i) =>
										<ItemFilterCard
											key={i}
											value={value}
											addFiltering={addFiltering}
											tabFilterSelected={tabFilterSelected}
											categoryIcon={icon}
										/>
									)
									}
								</ul>
							</div>
						)
					})
				}
			</div>
			{noData &&
				<p>No filters available</p>
			}
		</div>
	)
}
