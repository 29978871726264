import React, { useContext, useState, useEffect } from 'react';
import './NavBusinessOperationContainer.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import { check } from '../../../../Components/Can';
import PopinConfirm from '../../../../Components/PopinConfirm';
import Btn from '../../../../Components/Btn';
import EventTrackingServices from '../../../../Services/EventTrackingServices';

export default function NavBusinessOperationContainer({ accountId, isAdmin, changeStepCreation, returnBtnCreationBusinessOp, forwardBtnCreationBusinessOp, isClickLink, recomputeOrchestration, postOperationById, routeParams, getUserById, putOperationById, idOfExistantOp, deleteLibrary, setIsSavingOperation }) {
	const {
		selectionPagesType,
		zonesDataOfAccount,
		pageNameSelected,
		setPageNameSelected,
		stepBusinessOperationCreate,
		isVisitedLink,
		setIsVisitedLink,
		operation,
		setPoppinIsClicked,
		isexistant,
		setSelectedZone,
		userSimplifyRole,
		planAccount,
		operationSavedCallback,
		$rootScope,
		operationLabelIds
	} = useContext(CreateBusinessOperationContext);
	const { trackEvent } = EventTrackingServices($rootScope.User);
	const [nameOfAccount, setnameOfAccount] = useState();
	const [redirect, setRedirect] = useState(false);
	const [returnLink, setReturnLink] = useState();
	const [showRecomputeConfirm, setShowRecomputeConfirm] = useState(false);
	const [savedOperation, setSavedOperation] = useState();

	let canEditCampaignInformations;
	if (planAccount && userSimplifyRole) {
		canEditCampaignInformations = check(planAccount, userSimplifyRole, 'simplifyOperation:canEditCampaignInformations', null);
	}
	useEffect(() => {
		$('[title]').tooltip();
	}, []);
	const getNameOfAccount = () => {
		if (routeParams && routeParams.ku) {
			getUserById(routeParams.ku,
				newData => {
					setnameOfAccount(newData);
				}
			);
		}
	};
	useEffect(() => {
		getNameOfAccount();
	}, []);
	const changePageType = (e) => {
		setPageNameSelected(e);
		setSelectedZone();
	};
	const nextStep = () => {
		let index = selectionPagesType.indexOf(pageNameSelected);
		if (selectionPagesType.indexOf(pageNameSelected) === selectionPagesType.length - 1) {
			return;
		}
		setPageNameSelected(selectionPagesType[index + 1]);
		setIsVisitedLink(selectionPagesType[index + 1]);
	};


	const prevStep = () => {
		if (selectionPagesType.indexOf(pageNameSelected) === 0) {
			setPageNameSelected();
			changeStepCreation(4);
			setIsVisitedLink([]);
		}
		else {
			let index = selectionPagesType.indexOf(pageNameSelected);
			setPageNameSelected(selectionPagesType[index - 1]);
			setIsVisitedLink(selectionPagesType[index - 1]);
		}
	};

	const createNewOperation = () => {
		// operation.operation.extensions.pagesTypes = selectionPagesType
		// putOperationById(accountId, idOfExistantOp, operation)
		operation.operation.specifications = operation.operation.specifications.filter(el => el.valueToCompare.length !== 0);
		operation.operation.extensions.pagesTypes = selectionPagesType;
		setIsSavingOperation(true);
		const objectOperationWithLabels = {
			...operation,
			labelIds: operationLabelIds
		};
		putOperationById(accountId, idOfExistantOp, objectOperationWithLabels,
			e => {
				setIsSavingOperation(false);
				trackEvent('simplify/create-campaign');
			},
			e => {
				setIsSavingOperation(false);
				console.log('fail: ', e);
			}
		);
	};

	const recomputeCurrentOrchestration = (doRecompute) => {
		setShowRecomputeConfirm(false);
		if (!doRecompute || !savedOperation){
			setRedirect(true);
			return;
		} else {
			recomputeOrchestration(savedOperation, () => {
				setRedirect(true);
			}, () => {
				setRedirect(true);
			});	
		}
	};

	const wurthAccountId = 'da03b450-0785-4d64-ba79-4bfe61a6d324';

	const createAndQuit = () => {
		setSavedOperation();
		setShowRecomputeConfirm(false);

		operation.operation.specifications = operation.operation.specifications.filter(el => el.valueToCompare.length !== 0);
		operation.operation.extensions.pagesTypes = selectionPagesType;
		const objectOperationWithLabels = {
			...operation,
			labelIds: operationLabelIds
		};
		setIsSavingOperation(true);
		if (!isexistant) {
			postOperationById(accountId, objectOperationWithLabels,
				e => {
					if (accountId == wurthAccountId) {
						setSavedOperation(e.operationId);
						setShowRecomputeConfirm(true);
						trackEvent('simplify/create-campaign');
					} else {
						setRedirect(true);
					}
				},
				e => {
					setIsSavingOperation(false);
					console.log('fail: ', e);
				}
			);
		}
		else {
			putOperationById(accountId, idOfExistantOp, objectOperationWithLabels,
				e => {
					if (accountId == wurthAccountId) {
						setSavedOperation(idOfExistantOp);
						setShowRecomputeConfirm(true);
						trackEvent('simplify/saved-campaign');
					} else {
						setRedirect(true);
					}
				},
				e => {
					setIsSavingOperation(false);
					console.log('fail: ', e);
				}
			);
		}

	};

	const redirectToDashboard = () => {
		if (redirect) {
			window.location.href = returnLink;
		}
	};
	useEffect(() => {
		redirectToDashboard();
	}, [redirect]);

	function sortPageType(a, b) {
		if (a && b) {
			if (a.toUpperCase() < b.toUpperCase()) return -1;
			if (a.toUpperCase() > b.toUpperCase()) return 1;
		}
		return 0;
	}
	useEffect(() => {
		if (routeParams.prev === 'dashboard') {
			if (isAdmin && routeParams.ka) {
				setReturnLink(`BusinessOperations/Dashboard?ka=${routeParams.ka}&ku=${routeParams.ku}`);
			} else {
				setReturnLink('BusinessOperations/Dashboard');
			}
		}
		if (routeParams.prev === 'planning') {
			if (isAdmin && routeParams.ka) {
				setReturnLink(`BusinessOperations/Planning?ka=${routeParams.ka}&ku=${routeParams.ku}`);
			} else {
				setReturnLink('BusinessOperations/Planning');
			}
		}

	}, []);
	return (
		<>
			<nav className="creation-navigation has_menu">
				<div>
					<button onClick={() => setPoppinIsClicked(true)} className="btn-grey btn-hv-red"><i className="icofont-close"></i> Cancel</button>
				</div>
				<div>
					<ul>
						<li>
							<button className="btn-grey btn-hv-blue" onClick={!pageNameSelected ? () => returnBtnCreationBusinessOp() : () => { prevStep(); }}>
								<i className="icofont-rounded-left"></i>
								<span>Previous step</span>
							</button>
						</li>
						<li>

							<button className="btn-grey btn-hv-blue" onClick={selectionPagesType.length > 0 && !pageNameSelected && stepBusinessOperationCreate === 4 ?
								() => { setPageNameSelected(selectionPagesType[0]); setIsVisitedLink(selectionPagesType[0]); }
								:
								!pageNameSelected ? () => forwardBtnCreationBusinessOp()
									:
									() => nextStep()}
							>
								<i className="icofont-rounded-right"></i>
								<span>Next step</span>
							</button>

						</li>
						{canEditCampaignInformations && <>
							<li>
								<button className={!isexistant ? 'btn-orange disabled' : 'btn-orange'}
									disabled={!isexistant ? true : false}
									onClick={() => createNewOperation()}
								><i className="fas fa-save"></i><span>Save & Stay</span></button>
							</li>
							<li>
								<button className={operation.operation.name.length === 0 ? 'btn-blue disabled' : 'btn-blue'}
									disabled={operation.operation.name.length === 0 ? true : false}
									onClick={() => createAndQuit()}
								>
									<i className="icofont-verification-check"></i>Save & Quit
								</button>
							</li> </>
						}
					</ul>
				</div>
			</nav>
			<aside className="navigation is_fixed has_grey_bg">
				<h1>
					<a onClick={() => setPoppinIsClicked(true)} style={{ 'cursor': 'pointer' }}>
						<img src="Assets/logo-beyable.png" alt="BEYABLE" />
					</a>
				</h1>
				{routeParams && routeParams.ka &&
					<div className="nav_client_email">
						<a href={returnLink}
							title="">
							<button><i className="icofont-arrow-left"></i></button>
							<span> {nameOfAccount && nameOfAccount.Account.Name && nameOfAccount.Account.Name !== 'ACCOUNT NOT SET' ? nameOfAccount.Account.Name : nameOfAccount && nameOfAccount.Email}</span>
						</a>
					</div>
				}
				<nav className="thumbail-nav-check">
					<p>Operation setup</p>
					<ul>
						<li className={`${isClickLink.length && isClickLink.includes(1) ? 'activeNavLink' : ''} ${stepBusinessOperationCreate === 1 ? 'currentNavLink' : ''}`} onClick={(e) => { changeStepCreation(1); setIsVisitedLink([]); }}>
							<span className="thumbail-nav-check-icon"></span>
							Create
						</li>
					</ul>
					{
						<ul>
							<li className={`${isClickLink.length && isClickLink.includes(3) ? 'activeNavLink' : ''} ${stepBusinessOperationCreate === 3 ? 'currentNavLink' : ''}`} onClick={(e) => { changeStepCreation(3); setIsVisitedLink([]); }}>
								<span className="thumbail-nav-check-icon"></span>
								Target
							</li>
						</ul>
					}
					<ul>
						<li className={`${isClickLink.length && isClickLink.includes(2) ? 'activeNavLink' : ''} ${stepBusinessOperationCreate === 2 ? 'currentNavLink' : ''}`} onClick={(e) => { changeStepCreation(2); setIsVisitedLink([]); }}>
							<span className="thumbail-nav-check-icon"></span>
							Design
						</li>
					</ul>
					<ul>
						<li className={`${isClickLink.length && isClickLink.includes(4) ? 'activeNavLink' : ''} ${stepBusinessOperationCreate === 4 ? 'currentNavLink' : ''}`} onClick={(e) => { changeStepCreation(4); setPageNameSelected(); setIsVisitedLink([]); }}>
							<span className="thumbail-nav-check-icon"></span>
							Customize
						</li>
						{selectionPagesType.sort(sortPageType).map((elem, i) =>
							<li className={isVisitedLink && isVisitedLink.includes(elem) ? 'itemPageType currentNavLink ' : 'itemPageType'} key={i} onClick={(e) => { changeStepCreation(4); changePageType(elem); setIsVisitedLink([elem]); }}>
								<i className="fas fa-level-up-alt"></i>
								{zonesDataOfAccount.filter(x => x.pageType === elem)[0].name}
								{/* {ListTypesPages.filter(x => x.key == elem)[0].label} */}
							</li>
						)}
					</ul>
				</nav>
			</aside>
			{showRecomputeConfirm &&
				<PopinConfirm
					cancel={() => setShowRecomputeConfirm(false)}
					title={'Do you want to recompute orchestration?'}
					message={''}
				>
					<Btn
						onClickFunction={() => recomputeCurrentOrchestration(true)}
						message="Yes"
					/>
					<Btn
						onClickFunction={(e) => recomputeCurrentOrchestration(false)}
						message="No"
						color="alert"
					/>
				</PopinConfirm>
			}
		</>
	);
}