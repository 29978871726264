import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Draggable } from 'react-beautiful-dnd';
import { PanelSelect, PanelInitTypeSelection, PanelActions, PanelHeader } from './FormCustomPanelSubComponents';
import FormCustomMultipleControlsHook from './FormCustomMultipleControlsHook';
import FormCustomRateControl from './FormCustomRateControl.js';
import styles from './FormCustomPanel.module.css';
import PanelArticle from '../../../../Components/PanelArticle';
import InputCustom from '../../../../Components/InputCustom';
import Checkbox from '../../../../Components/Checkbox';
import controlTypes from './controlTypes';
import ChoicesSelect from './ChoicesSelect';
import Fieldset from '../../../../Components/Fieldset';

const getPanelStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	boxShadow: isDragging ? '0 3px 5px #000' : 'none',
	...draggableStyle
});

function FormCustomPanelHook(props) {

	const [t] = useTranslation(['survey']);
	const [showNameField, setShowNameField] = useState(!!props.name);
	
	function remove() {
		props.onRemove(props.id, props.sections.current);
	}
	
	function duplicate() {
		props.onDuplicate(props.id, props.sections.current);
	}
	
	function setName(v) {
		const state = {name: v};
		props.onContentChange(props.id, props.sections.current, state);
	}
	
	function setAsRequired(e) {
		const state = {required: e.target.checked};
		props.onContentChange(props.id, props.sections.current, state);
	}
	function setAsShowQuest(e) {
		const state = {showQuestion: e.target.checked};
		props.onContentChange(props.id, props.sections.current, state);
	}
	
	function handleFieldChange(evt) {
		const state = {[evt.target.name]: evt.target.value};
		props.onContentChange(props.id, props.sections.current, state);
	}
	
	function setControls(controls,controlId) {
		let state = {controls};

		if(controlId){
			const selectTheSection =props.surveyInfos;
			if(controlId.action === 'add'){
				selectTheSection.multipleQuestionsBasedOnAnswer = [...props.surveyInfos.multipleQuestionsBasedOnAnswer, {controlId : controlId.controlId, nextQuestion : controlId.nextQuestion}];
				state = {...state,surveyInfos: {...selectTheSection}};
			}else if(controlId.action === 'update'){
				selectTheSection.multipleQuestionsBasedOnAnswer = props.surveyInfos.multipleQuestionsBasedOnAnswer.map(x=> {
					if(x.controlId ===controlId.controlId){
						return {controlId : controlId.controlId, ...x};
					}else{
						return {
							...x
						};
					}
				});
				state = {...state,surveyInfos: {...selectTheSection}};
			}
			else if(controlId.action === 'delete'){
				
				selectTheSection.multipleQuestionsBasedOnAnswer = props.surveyInfos.multipleQuestionsBasedOnAnswer.filter(x=> {
					if(x.controlId !==controlId.controlId){
					
						return {
							...x};
					}});
				state = {controls: state.controls.filter((x,i) => {
					if(x.id !==controlId.controlId){
						
						return {
							...x};
					}}),surveyInfos: {...selectTheSection}};
			}
			// else{
			// 	selectTheSection.multipleQuestionsBasedOnAnswer = props.surveyInfos.multipleQuestionsBasedOnAnswer.filter(x=> {
			// 		x.controlId !==controlId.controlId;
			// 	});
			// 	state = {...state,surveyInfos: {...selectTheSection}};
			// }
		}
		props.onContentChange(props.id, props.sections.current, state);
	}

	
	function selectControlType(evt) {
		const type = getType(evt.target.value).id ;
		const state = {type: type , surveyInfos: {
			ignoreQuestion:'next',
			multipleQuestionsBasedOnAnswer : [],
			nextQuestion : 'next'
		}, 
		valueForTest : '',
		controls : props.getDefaultControls(type)
		};

		props.onContentChange(props.id, props.sections.current, state);
	}
	function onContentChange(d){
		const state = [...props.controls];
		const nState = {
			controls : state.map((x,i) => {
				if(x.id ===d.controlId ){
					return {...x, text :d.text};
				}else{
					return {...x};
				}})
		};
		props.onContentChange(props.id, props.sections.current, nState);

	}

	function getType(id) {
		const identifier = parseInt(id, 10);
		const ctrl = props.controlTypes.find(controlType => controlType.id === identifier);
		if(ctrl){
			return ctrl;
		}else{
			return;
		}
	}
	

	function getPlaceholderField() {
		return ( 
			<InputCustom
				label="Placeholder"
				labelClassName='s_14 grey_0'
				placeHolder='Help text present when field is empty'
				name="placeholder"
				value={props.placeholder}
				onChange={(e) => handleFieldChange(e)}
				fullWidth={true}
			/>
		);
	}
	function getQuestionField() {
		return ( 
			<Fieldset>
				<InputCustom
					type='textarea'
					placeHolder={props.type === 3 ? '' : t('typeYourQuestion')}
					name="question"
					value={props.question}
					onChange={(e) => handleFieldChange(e)}
					fullWidth={true}
					rows={2}
					autogrow={true}
					isAnError={props.question === '' ? t('aQuestionCannotBeEmpty') : ''}
				/>
			</Fieldset>
		);
	}

	function getNameField() {
		return ( 
			<div className={styles.field_col_s}>
				<InputCustom
					label="Name"
					labelClassName='s_14 grey_0'
					placeHolder='Technical name'
					value={props.name}
					onChange={(e) => setName(e.target.value)}
					fullWidth={true}
				/>
			</div>
		);
	}

	function toggleNameField() {
		if (showNameField === true) {
			setName('');
		}
		setShowNameField(!showNameField);
	}

	const typeObj = getType(props.type);
	const panelActions = {
		duplicate: props.type !== 3 ? duplicate : null,
		remove: remove,
		toggleNameField: toggleNameField
	};
		
	return (
		<>
			{props.type ? (
				<Draggable draggableId={`panel-${props.id}`} index={props.index}>
					{(provided, snapshot) => (
						<div
							onClick={()=>props.onClickToPanel()}
							ref={provided.innerRef}
							{...provided.draggableProps}
							style={getPanelStyle(
								snapshot.isDragging,
								provided.draggableProps.style
							)}>
							<PanelArticle size="l">
								<div className={snapshot.isDragging ? styles.panelForm + ' ' + styles.panelFormDragging : styles.panelForm}>
									<PanelHeader styles={styles}>
										<div className={styles.panelHeaderLeft}>
											<span className={styles['panelHeaderNumber__' + typeObj.color]}>{props.index + 1}</span>
											<PanelSelect
												styles={styles}
												options={controlTypes}
												typeObj={typeObj}
												type={props.type}
												onSelectChange={(e)=>selectControlType(e)}
											/>
										</div>
										<div className={styles.panelHeaderCenter}>
											<span {...provided.dragHandleProps}>
												<span className={styles.panelHeaderDragHandle}></span>
											</span>
										</div>
										<div className={styles.panelHeaderRight}>
											<PanelActions
												styles={styles}
												actions={panelActions}
												options={{showNameField: showNameField}}
											/>
										</div>
									</PanelHeader>

									{props.type !== 8 && getQuestionField()}

									{props.displayRequired &&
										<Fieldset>
											<Checkbox
												label={t('requiredAnswer')}
												onChange={setAsRequired}
												checked={props.required}
												checkboxSize='s'
											/>
										</Fieldset>
									}

									{!typeObj.multiple && !typeObj.rate && getPlaceholderField()}

									{props.type !== 3 && showNameField && getNameField()}

									{typeObj.multiple &&
										<FormCustomMultipleControlsHook
											styles={styles}
											type={typeObj.name}
											controls={props.controls}
											setControls={setControls}
											onContentChange={onContentChange}
											idPanel={props.id}
										/>
									}

									{typeObj.rate &&
										<FormCustomRateControl
											type={props.type}
											name={typeObj.name}
											controls={props.controls}
											setControls={setControls}
											idPanel={props.id}
											handleFieldChange={handleFieldChange}
											panel={props.panel}
										/>
									}

									{/* <div>
										<Checkbox
											label="Show question"
											onChange={setAsShowQuest}
											checked={props.showQuestion}
										/>
									</div> */}
									
									<ChoicesSelect 
										currentS={props.panel}
										id={props.id}
										controls={props.controls}
										type={props.type}
										surveyInfos={props.surveyInfos}
									/>
								</div>
							</PanelArticle>
						</div>
					)}
				</Draggable>
			) : (
				<PanelInitTypeSelection
					styles={styles}
					controlTypes={props.controlTypes}
					onSelect={(e)=>selectControlType(e)}
					remove={panelActions.remove}
				/>
			)}
		</>
	);
}

export default FormCustomPanelHook;