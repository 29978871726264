import React, { useState, useEffect, Fragment } from 'react';
import './SideBar.css';
import './Params.css';
import { CampaignsContext } from '../../context';
import PropertyEditor from '../../../WysiwygEditor/PropertiesEditor/PropertyEditor';
import PropertiesGroup from '../../../WysiwygEditor/PropertiesEditor/PropertiesGroup';
import PropertiesCollection from '../../../WysiwygEditor/PropertiesEditor/PropertiesCollection';
import { propertyAccessor } from '../../util';
import InPageContainer from '../InPage/InPageContainer';

export default function SideBar(props) {

	const {
		getCurrentVariation,
		handlePropertyValueChange,
		currentDevice,
		features,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
		handleGroupPropertyValueChange,
		handleChangeIsActivePromoCode,
		editorState,
		handleCollectionPropertyValueChange,
		handleDeleteProperty,
		handleAddProperty,
		handleChangeActiveCollectionItem,
		reorderCollectionItems,
	} = CampaignsContext();

	const propertyEditorContext = {
		features,
		getCurrentVariation,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
	};

	const groupEditorContext = {
		getCurrentVariation,
		handleGroupPropertyValueChange,
		handleChangeIsActivePromoCode,
		handleIsFoldedChanged: () => 0,
		currentDevice,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
		features
	};

	const collectionEditorContext = {
		getCurrentVariation,
		currentDevice,
		handleCollectionPropertyValueChange,
		handleDeleteProperty,
		handleAddProperty,
		handleChangeActiveCollectionItem,
		handleIsFoldedChanged: () => 0,
		reorderCollectionItems,
		features,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback
	};

	const { getPropertyValue } = propertyAccessor();
	const currentVariation = getCurrentVariation() || {};
	const currentFormat = currentVariation.Template || {};

	return (
		<div className="flex_item_full vscroll_custom">
			<section className="format_param_list">
				{editorState.selectedFormat.Format === 'INAPP' && <InPageContainer/>}

				{currentFormat.Properties && currentFormat.Properties.map((property, i) => {

					if( property.Device && property.Device !== currentDevice ) return null;

					if (property.Editor.Type === 'Group') {
						return (
							<PropertiesGroup
								key={property.Key}
								property={property}
								context={groupEditorContext}
							/>
						);
					}
					else if (property.Editor.Type === 'Collection') {
						return (
							<PropertiesCollection
								key={property.Key}
								property={property}
								context={collectionEditorContext}
							/>
						);
					}
					else return (
						<PropertyEditor
							key={property.Key}
							context={propertyEditorContext}
							data={getPropertyValue(currentVariation.Properties, property)}
							property={property}
							callBack={v => handlePropertyValueChange(v, property.Name)}
							propertyContainer={
								{
									name: property.Name, 
									type: property.Editor.Type, 
									siblingPropertiesAccessor: {
										getSiblingPropertyData: subPropertyName => getPropertyValue(currentVariation.Properties, { Name: subPropertyName }),
										setSiblingPropertyData: (subPropertyName, v) => handlePropertyValueChange(v, subPropertyName)
									}
								}
							}
						/>
					);
				})}
			</section>
		</div>
	);
}
