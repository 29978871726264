import React, { createContext, useContext, useEffect, useState } from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import RecommendationRuleCompositionServices from '../../../../Services/RecommendationRuleCompositionServices';
import EventTrackingServices from '../../../../Services/EventTrackingServices';
import { v4 as uuidv4 } from 'uuid';
import { ComposableRule, CompositionGroup, Composition } from './EntityTypes';

const RuleCompositionContext = createContext<RuleCompositionContextType | undefined>(undefined);

function useRuleCompositionContext() {
	const context = useContext(RuleCompositionContext);
	if (!context) throw Error('useRuleCompositionContext can only be used inside an RuleCompositionContextProvider');
	return context;
}

const CreateRuleCompositionContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	//const AuthServices = props.AuthServices;

	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	const crud = new RecommendationRuleCompositionServices($http, accountId);

	const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const [loadingSaving, setLoadingSaving] = useState<boolean>(false);
	const [loadingSettings, setloadingSettings] = useState(true);

	const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState<boolean>(false);

	const [initialState, setInitialState] = useState<string>();
	const [composition, setComposition] = useState<Composition>({ id: null, name: 'New Composition' });

	const [compositionGroups, setCompositionGroups] = useState<CompositionGroup[]>([new CompositionGroup(uuidv4(), [], null)]);


	let urlReturn = '/ProductSettings/Reco';
	if ($routeParams && $routeParams.ka && isAdmin) {
		urlReturn += '?ka=' + $routeParams.ka;
	}

	useEffect(() => {
		const compositionId = $routeParams.id;
		setloadingSettings(true);
		trackEvent('reco-composition/started');
		if (compositionId) {
			crud.getComposition(compositionId, c => {

				console.log('c', c); // TODO

				setInitialState(JSON.stringify([composition, compositionGroups]));
				setloadingSettings(false);
			}, () => {
				setloadingSettings(false);
				systemServices.showError('Cannot get composition');
			});
		} else {
			setInitialState(JSON.stringify([composition, compositionGroups]));
			setloadingSettings(false);
		}

	}, []);

	const handleValidate = () => {
		setLoadingSaving(true);
		//TODO
		if ($routeParams.id) {
			crud.updateComposition(composition,
				() => {
					trackEvent('reco-composition/rule-updated');
					systemServices.showSuccess('The composition has been saved');
					setLoadingSaving(false);
					returnToDashboard();
				},
				(err) => {
					console.error(err);
					systemServices.showError('Error while saving');
					setLoadingSaving(false);
				}
			);
		} else {
			crud.createComposition(composition,
				() => {
					trackEvent('reco-composition/rule-created');
					systemServices.showSuccess('The composition has been saved');
					setLoadingSaving(false);
					returnToDashboard();
				},
				(err) => {
					console.error(err);
					systemServices.showError('Error while saving');
					setLoadingSaving(false);
				}
			);
		}
	};

	const loadPossibleRules = (callback: ((rules: ComposableRule[]) => void)) => {
		const empty: ComposableRule[] = [];
		const allRules = compositionGroups.reduce((acc, val) => acc.concat(val.rules), empty); // cannot use flatMap.. (es2019)

		crud.getPossibleRules(allRules
			, (possibleRules) => {
				console.log('possibleRules', possibleRules);

				if (possibleRules && possibleRules.composableRules && possibleRules.composableRules.length > 0) {
					const rules = possibleRules.composableRules.map((r) => {
						return {
							id: r.id,
							label: r.name,
							ruleLimit: null,
							isFillUp: false
						};
					});
					console.log('possibleRules', rules);
					callback(rules);
				} else {
					// Fake data if empty
					// TODO : remove after demo
					callback([{
						id: uuidv4(),
						label: 'Top vente des accessoires',
						ruleLimit: null,
						isFillUp: false
					}]);
				}
			}, (err) => {
				console.error(err);
				systemServices.showError('Cannot get possible rules');
			});
	};

	const handleSetCompositionName = (name: string) => {
		setComposition(c => ({ ...c, name: name }));
	};

	const setCompositionGroupsWithRuleUpdate = (group: CompositionGroup, rule: ComposableRule, property: string, value: boolean | number | string) => {
		setCompositionGroups(x => x.map(g => g.id == group.id ? g.updateRule(rule, property, value) : g));
	};

	const handleAddGroup = () => {
		setCompositionGroups(x => [...x, new CompositionGroup(uuidv4(), [], null)]);
	};

	const handleDeleteGroup = (group: CompositionGroup) => {
		setCompositionGroups(x => x.filter(g => g.id !== group.id));
	};

	const handleAddRuleToGroup = (group: CompositionGroup, rule: ComposableRule) => {
		setCompositionGroups(x => x.map(g => g.id == group.id ? g.addRule(rule) : g));
	};

	const handleDeleteRuleFromGroup = (group: CompositionGroup, rule: ComposableRule) => {
		setCompositionGroups(x => x.map(g => g.id == group.id ? g.deleteRule(rule) : g));
	};

	const handleRulesOrderChanged = (group: CompositionGroup, rules: ComposableRule[]) => {
		setCompositionGroups(x => x.map(g => g.id == group.id ? g.setRules(rules) : g));
	};

	const handleGroupsOrderChanged = (groups: CompositionGroup[]) => {
		setCompositionGroups(groups);
	};

	const handleChangeGroupSize = (group: CompositionGroup, value: number) => {
		setCompositionGroups(x => x.map(g => g.id == group.id ? g.setSize(value) : g));
	};

	const handleChangeRuleLimit = (group: CompositionGroup, rule: ComposableRule, value: number) => {
		setCompositionGroupsWithRuleUpdate(group, rule, 'ruleLimit', value);
	};

	const handleSetRuleAsFillUp = (group: CompositionGroup, rule: ComposableRule, value: boolean) => {
		setCompositionGroups(x => x.map(g => g.id == group.id ? g.setIsFillUp(rule, value) : g));
	};

	const returnToDashboard = () => {
		window.location.href = urlReturn;
	};

	const checkIfCanQuitWithoutSave = () => {
		const newState = JSON.stringify([composition, compositionGroups]);
		if (newState === initialState) {
			returnToDashboard();
		} else {
			setModalConfirmIsOpen(true);
		}
	};

	const context: RuleCompositionContextType = {
		compositionGroups,
		handleAddGroup,
		handleDeleteGroup,
		handleAddRuleToGroup,
		handleDeleteRuleFromGroup,
		handleRulesOrderChanged,
		handleGroupsOrderChanged,
		handleChangeGroupSize,
		handleChangeRuleLimit,
		handleSetRuleAsFillUp,
		loadPossibleRules,

		handleSetCompositionName,
		compositionName: composition.name,

		modalConfirmIsOpen,
		handleCloseConfirm: () => setModalConfirmIsOpen(false),
		checkIfCanQuitWithoutSave,
		returnToDashboard,

		handleValidate,
		loadingSaving,
		loadingSettings,

		cancelActions: () => { console.log('ok'); },
	};

	return (
		<RuleCompositionContext.Provider
			value={context}
		>
			{props.children}
		</RuleCompositionContext.Provider>
	);
};

export default CreateRuleCompositionContextProvider;

export { useRuleCompositionContext };

type RuleCompositionContextType = {
	compositionGroups: CompositionGroup[],
	loadPossibleRules: (callback: ((rules: ComposableRule[]) => void)) => void,
	handleSetCompositionName: (name: string) => void,
	handleAddGroup: () => void,
	handleDeleteGroup: (group: CompositionGroup) => void,
	handleAddRuleToGroup: (group: CompositionGroup, rule: ComposableRule) => void,
	handleDeleteRuleFromGroup: (group: CompositionGroup, rule: ComposableRule) => void,
	handleRulesOrderChanged: (group: CompositionGroup, rules: ComposableRule[]) => void,
	handleGroupsOrderChanged: (groups: CompositionGroup[]) => void,
	handleChangeGroupSize: (group: CompositionGroup, value: number) => void,
	handleChangeRuleLimit: (group: CompositionGroup, rule: ComposableRule, value: number) => void,
	handleSetRuleAsFillUp: (group: CompositionGroup, rule: ComposableRule, value: boolean) => void,
	compositionName: string,

	modalConfirmIsOpen: boolean,
	handleCloseConfirm: () => void,
	returnToDashboard: () => void,
	checkIfCanQuitWithoutSave: () => void,

	handleValidate: () => void,
	loadingSaving: boolean,
	loadingSettings: boolean,

	cancelActions: any
}