import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import NavigationServices from '../../Services/NavigationServices';
import {Switch} from '../../Components/Checkbox/Checkbox';

import ModuleTab from './ModuleTab';

import styles from './Menu.module.css';
import tabsStyles from './ModuleTabs.module.css';
import { getUserFullName } from '../../Hooks/useDisplayName';
import {getFinalUrl, getAccountModules} from './utils';

import { allModules, adminModules, internalModules } from './MenuData';
const allModulesAdmin = [
	'insights',
	'onsite',
	'abtests',
	'simplify',
	'emr',
	'emailing',
	'analytics',
	'scoring',
	'productMonitoring',
	'eMerch',
	'productCatalog',
];
import { useTranslation } from 'react-i18next';

export default function Navigation({...props}) {

	const [idSelected, setidSelected] = useState();
	const [callModules, setcallModules] = useState(false);
	const impersonatingServices = new ImpersonatingServices(
		props.props.$rootScope,
		props.props.$routeParams
	);
	const navigationServices = new NavigationServices(
		props.props.$http,
		props.props.$routeParams
	);
	const authServices = props.props.AuthServices;

	function isActive(urls) {
		if (!urls || urls.lenght == 0) return false;
	
		const currentPath = window.location.pathname;
		const activeUrl = urls.find((u) => currentPath.indexOf(u.split('?')[0]) === 0);
		return !!activeUrl;
	}

	const getCurrentModule = () => {
		const p = window.location.pathname;
		const s = window.location.search;
		const module = [...adminModules,...allModules].find(m => {
			if (!m.activeUrls) return false;
			return m.activeUrls.find(u => {
				const split = u.split('?');
				const activePath = split[0] && p.indexOf(split[0]) === 0;
				if (!activePath) return false;
				if (split[1] && split[1] != '') {
					return s.indexOf(split[1]) > -1;
				}
				return true;
			});
		});
		return module || allModules[0];
	};
	const [needToFetchDatas, setneedToFetchDatas] = useState(true);

	const [accountId, setAccountId] = useState('');
	const [isAdmin, setisAdmin] = useState(false);

	const [isASupervisor, setisASupervisor] = useState(false);

	const [currModule, setCurrModule] = useState(getCurrentModule());
	const [user, setUser] = useState({});
	const [adminModule, setadminModule] = useState(<></>);
	const [settingsModule, setsettingsModule] = useState([]);
	const [navigationListModules, setNavigationListModules] = useState();
	const [clientModules, setclientModules] = useState([]);
	const [adminView, setAdminView] = useState();
	const [hasInternalModules, sethasInternalModules] = useState(false);

	const [t] = useTranslation('navigation');
	const handlePopState = () => {
		setCurrModule(getCurrentModule());
	};

	function checkAdminModules(){
		const currentUser = props.props.AuthServices.GetAuthenticatedUser();
		const isAdminTest = currentUser?.roles.includes('Admin') ;
		const isSuperVisorTest =  currentUser?.roles.includes('Supervisor');
		setisASupervisor(isSuperVisorTest);
		setisAdmin(isAdminTest);
	
		if(props.props.$routeParams && isAdminTest && adminModules){		
			const modulAdmin =()=> {
				return <>
					{adminModules.map((module) => 
						<ModuleTab
							key={module.id}
							module={module}
							url={getFinalUrl(module, props.props, module.needKa, currentUser.Account)}
							isActive={isActive(module.activeUrls)}
							onClick={() => setidSelected(module.baseUrl)}
						/>
					)}
				</>;
			};
			setadminModule(modulAdmin);
			setCurrModule(getCurrentModule());
		}else{
			setadminModule(<></>);
		}
	}
	function getModulesAndIdSelected(e){
		const prev = e;
		if( !prev || prev.includes('Administration/AdminHome') || prev.includes('Switch') || prev.includes('Callback') || prev.includes('/Redirect') || prev.includes('AccountHome') || prev.includes('/SignIn')  ){
			setneedToFetchDatas(true);
			setNavigationListModules([]);
		}
		const nonLightModules = ['/Administration/AdminHome', '/Account/AccountHome'];
		if(!nonLightModules.includes(window.location.href)){
			const currM = getCurrentModule();
			setidSelected(currM.baseUrl);
		}else{
			setidSelected(window.location.href);
		}
	}
	useEffect(()=>{
		props.props.$rootScope.$on('event:routeChangeStart', (f,e) => { 
			const timer = setTimeout(() => {
				getModulesAndIdSelected(e);
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
		props.props.$rootScope.$on('event:auth-loginConfirmed', () => { 
			const timer = setTimeout(() => {
				getModulesAndIdSelected('/SignIn');
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
	},[props.props.$rootScope, props.props.$location]);
	useEffect(()=>{
		const interval = setInterval(()=>{
			getModules();
		},100);
		if(callModules){
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	},[callModules]);

	useEffect(()=>{
		checkAdminModules();
	},[navigationListModules, idSelected, adminView]);

	useEffect(() => {
		window.addEventListener('popstate', handlePopState);
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	function getSettingsModule (){
		const settings = allModules.find(item => item.id === 'settings');
		
		return [settings];
	}

	function getPermissionValue (user, name) {
		if (user && user.Permissions) {
			let perm = user.Permissions.find(element => element.Name === name);
			if (perm) {
				return perm.Value;
			}
		}
		return false;
	}

	function getModules(){
		const tmpUser = {...props.props.$rootScope.User};
		tmpUser.FullName = getUserFullName(tmpUser);
		const INTERNAL_ACCOUNT_LIST_CAN_VIEW = getPermissionValue(tmpUser, 'INTERNAL_ACCOUNT_LIST_CAN_VIEW');
		
		setUser(tmpUser);
		const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
		const tmpAccountId = impersonatedAccount || (props.props.$rootScope && tmpUser?.Account?.Key);
		const adminModeView= sessionStorage.getItem('adminModeView');
		if(adminModeView){
			setAdminView(adminModeView);
		}else{
			setAdminView('client');
			sessionStorage.setItem('adminModeView','client');
		}	

		if(tmpAccountId){
			sethasInternalModules(INTERNAL_ACCOUNT_LIST_CAN_VIEW);
			setcallModules(true);
			navigationServices.getAccountModules(tmpAccountId, success => {
				setneedToFetchDatas(false);
				setclientModules(success);
				if(adminModeView === 'admin'){
					setAdminView('admin');
					setNavigationListModules(getAccountModules(allModulesAdmin, allModules));
					setsettingsModule(getSettingsModule());
				}else{
					setAdminView('client');
					setsettingsModule(getSettingsModule());
					setNavigationListModules(getAccountModules(success, allModules));
				}
			});
			setAccountId(tmpAccountId);	
		}
	}
	useEffect(()=>{
		if(needToFetchDatas){
			var timer = setTimeout(() => {
				const authorizationToken = authServices && authServices.isAuthenticated();
				if(!authorizationToken){
					return;
				}else{
					getModules();
				}
			},300);
			return () => {
				clearTimeout(timer);
			};
		} 
	},[needToFetchDatas]);


	function handleSwitchMenuClienAdmin(){
		const newMode = adminView === 'client' ? 'admin' : 'client';
		sessionStorage.setItem('adminModeView',newMode);
		
		if(newMode === 'admin'){
			setsettingsModule(getSettingsModule());
			setNavigationListModules(getAccountModules(allModulesAdmin,allModules));
		}else{
			setNavigationListModules(getAccountModules(clientModules,allModules));
			setsettingsModule(getSettingsModule());
		}
		setAdminView(newMode);

	}
	return (
		<div className={tabsStyles.sidebar + ' flex flex_v'}>
			<div className={styles.body + ' flex_item_full vscroll_custom'}>
				<div className={adminView == 'admin' ? tabsStyles.tabs_small : tabsStyles.tabs}>
					{navigationListModules && navigationListModules.map((module) => {
						if(module.id !== 'Account/AccountHome' ){
							if(props.props.$routeParams.ka && module.hasOwnProperty('adminClone')){
								return <></>;
							}
							return (
								<ModuleTab
									key={module.id}
									module={module}
									url={getFinalUrl(module, props.props)}
									isActive={isActive(module.activeUrls)}
									onClick={() => setidSelected(module.baseUrl)}
								/>
							);
						}
					})}
					{adminModule}
					{!isAdmin && props.$routeParams && !props.props.$routeParams.ka && settingsModule && settingsModule.map(module => {
						return (
							<ModuleTab
								key={module.id}
								module={module}
								url={getFinalUrl(module, props.props)}
								isActive={isActive(module.activeUrls)}
								onClick={() => setidSelected(module.baseUrl)}
							/>
						);
					})}
					{!isAdmin && isASupervisor && settingsModule && settingsModule.map(module => {
						return (
							<ModuleTab
								key={module.id}
								module={module}
								url={getFinalUrl(module, props.props)}
								isActive={isActive(module.activeUrls)}
								onClick={() => setidSelected(module.baseUrl)}
							/>
						);
					})}
					{!isAdmin && !isASupervisor && settingsModule && settingsModule.map(module => {
						return (
							<ModuleTab
								key={module.id}
								module={module}
								url={getFinalUrl(module, props.props)}
								isActive={isActive(module.activeUrls)}
								onClick={() => setidSelected(module.baseUrl)}
							/>
						);
					})}
					{!isAdmin && hasInternalModules &&
						<ModuleTab
							key={'/Internal/AccountList'}
							module={internalModules[0]}
							url={getFinalUrl(internalModules[0], props.props)}
							isActive={isActive(internalModules[0].activeUrls)}
							onClick={() => setidSelected(internalModules[0].baseUrl)}
						/>
					}

				</div>

			</div>
			{isAdmin && 
				<div className={styles.footer}>
					<div className={styles.footer_user_wrapper}>
						<div className={styles.switch_btn} onClick={handleSwitchMenuClienAdmin}>
							<Switch
								forcedChecked={adminView === 'admin'}
								size='xs'
							/>
							<div className={styles.switch_btn_text}>{t('moduleNav.adminMode')}</div>
						</div>
					</div>
				</div>
			}
			
		</div>
	);
}
