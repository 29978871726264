import React from "react";
import FilterCard from "./FilterCard";

export default function FilterGroup({
	mainFilter,
	getNameFilter,
	onRemoveFilter,
	userFiltersWithId,
	handleClickEditFilter
}) {

	const isExpression = mainFilter.hasOwnProperty("expression") && mainFilter.expression !== null;
	const operator = isExpression ? mainFilter.expression.op : null;
	const subFilters = isExpression ? mainFilter.expression.filters : null;

	const handleRemove = (ev) => {
		ev.stopPropagation();
		onRemoveFilter(userFiltersWithId, mainFilter.idFilter);
	}

	return (
		<div className='collection_sidebar_item s clickable'
			onClick={() => handleClickEditFilter(mainFilter.idFilter)}>
			<div className='flex flex_align_stretch'>
				<div className='flex_item_full overflow_hidden'>
					{isExpression ?
						<>
							{subFilters.length > 0 &&
								subFilters.map((filter, i) => (
									<FilterCard
										key={i}
										filter={filter}
										nameFilter={getNameFilter(filter)}
									/>
								))
							}
						</>
						:
						<FilterCard
							filter={mainFilter}
							nameFilter={getNameFilter(mainFilter)}
						/>
					}
				</div>
				<div className='flex_item_fix flex flex_v flex_justify_between' style={{ 'height': 'auto' }}>
					{isExpression &&
						<span className="collection_sidebar_item_operator">{operator}</span>
					}
					<div className="al_right">
						<a className='icon_btn xs collection_sidebar_item_del' onClick={(e) => handleRemove(e)}>
							<i className="fas fa-trash" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
