import React, {useEffect,useState} from 'react';
import {getFinalUrl} from '../../Menu/utils';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { useInsightsContext } from '../context/ContextInsights.js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
	OrderByModificationDateDesc,
} from '../../../Services/Queries/OrderByFilter';
import { getCampaigns } from '../../../Services/Queries/OnSiteDashboardQueries';
import useCampaignStatus from '../../../Hooks/useCampaignStatus';
import {
	OnGoing,
	Scheduled,
	OnGoingScheduled
	// All,
} from '../../../Services/Queries/DefinitionFilters';
import {SumUp,Percent} from '../SumUp';
import {CampaignList} from '../Campaigns/Campaigns';
import { useLocation } from 'react-router-dom';
import { Section } from '../../../Components/Section/Section';
import { Article } from '../../../Components/Article/Article';
import { Pie } from '../../../Components/Pie/Pie';
import  SpinnerWheel from '../../../Components/SpinnerWheel';
import  Link from '../../../Components/Link';
import  EmptyState from '../../../Components/EmptyState';
import { useTranslation } from 'react-i18next';
import {getDeviceTranslationKey} from '../Utils';
import {
	insightsKpis,
	commerceDatas1,
	commerceDatas2,
	DevicesWebSite,
	dataCpg
} from './typesKpiContainer';

import kpiStyle from '../kpi.module.css';

export default function KpiContainer() {
	const {
		token,
		accountId,
		systemServices,
		abTestsServices,
		insightsServices,
		today,
		props,
		routeParams
	} = useInsightsContext();

	const [t] = useTranslation('kpi');
	const [hasErrorOnTraffic, sethasErrorOnTraffic] = useState<boolean>(false);
	const [hasErrorOnEcommerce, setHasErrorOnEcommerce] = useState<boolean>(false);
	const [insightsActived, setinsightsActived] = useState<string[]>();

	const [onSiteCampaignInfos, setOnSiteCampaignInfos] = useState(
		{
			onsiteCampaigns: {
				name : t('campaign.ongoingCampaigns'),
				value : 0,
				isLoading : true
			},
			scheduledCampaigns: {
				name : t('campaign.scheduledCampaigns'),
				value : 0,
				isLoading : true
			},
			abTestOnGoingCampaigns: {
				name : t('campaign.ongoingABTests'),
				value : 0,
				isLoading : true
			},
			availableCampaigns: {
				name : t('campaign.customTemplates'),
				value : 0,
				isLoading : true
			},
		}
	);
	const [turnoverIncremental, setTurnoverIncremetal] = useState<any>({
		name : t('campaign.incrementalTurnover'),
		value : 0,
		unit : '€',
		isLoading : true
	},);
	const [webSiteTraffic, setwebSiteTraffic] = useState<insightsKpis>(
		{
			sessions : {
				isLoading : true,
				value: 0,
			},
			visitors : {
				isLoading : true,
				value: 0,
			},
			pageView : {
				isLoading : true,
				value: 0,
			},
		}
	);
	const [ecommerceDatas1, setEcommerceDatas1] = useState<commerceDatas1>(
		{
			turnover : {
				isLoading : true,
				value: 0,
			},
			transactionsCount : {
				isLoading : true,
				value: 0,
			},
			conversionRate : {
				isLoading : true,
				value: 0,
			},
		}
	);
	const [ecommerceDatas2, setEcommerceDatas2] = useState<commerceDatas2>(
		{
			averageCartValue : {
				isLoading : true,
				value: 0,
			},
			abandonedCartsCount : {
				isLoading : true,
				value: 0,
			},
		}
	);
	const [devicesWebSiteTraffic, setdevicesWebSiteTraffic] = useState<DevicesWebSite>();
	const [devicesTurnover, setdevicesTurnover] = useState<DevicesWebSite>();

	const [cpgStartedRecently, setCpgStartedRecently] = useState<dataCpg>({
		isLoading: true,
		data: []
	});
	const [cpgComingSoon, setCpgComingSoon] = useState<dataCpg>({
		isLoading: true,
		data: []
	});
	
	// tslint:disable:no-var-requires
	
	const httpLink = createHttpLink({
		// @ts-ignore 
		uri: window.BEYABLE_env.URI_GRAPHQL,
	});
	// graphQl config
	const authLink = setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		};
	});
	const defaultOptions = {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	};
	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
		// @ts-ignore
		defaultOptions: defaultOptions,
	});
	const location = useLocation();
	const notOnTestExecutionModeFilter = {
		executionMode: { neq: 'TEST' }
	};
	if(location.pathname === '/Insight/kpi'){	
		
		useEffect(()=>{
			// Get Available kpis
			insightsServices.getInsightsActivated(accountId,(success)=> {
				setinsightsActived(success);		
			}, () => {
				setinsightsActived([]);	
				systemServices.showError('An error occurred while trying to get data');
			});
		},[]);
		const controllerAbTest = new AbortController();
		const signalAbTest = controllerAbTest.signal;
		const controllerInsightKPI = new AbortController();
		const signalInsightKpi = controllerInsightKPI.signal;
		useEffect(()=>{
			if(insightsActived && insightsActived?.length > 0){
				if(insightsActived.includes('campaigns') || insightsActived.includes('global')){
				// GetCampaigns startes recently + scheduled 
					client
						.query({
							query: getCampaigns,
							variables: {
								accountId: accountId,
	
								order: [OrderByModificationDateDesc],
								where: {
									and: [OnGoingScheduled,notOnTestExecutionModeFilter],
								},
							},
						})
						.then((response) => {
							const campaignsToSor = response.data.account.onSiteCampaigns.items.map(campaign => {
								const statusCpg = useCampaignStatus(campaign);
								return {
									...campaign, 
									status: statusCpg.Label
								};
							});
							function sortByDate(a, b) {
								if (a && b) {
									if (moment(a.startDate) > moment(b.startDate)) return -1;
									if (moment(a.startDate) < moment(b.startDate)) return 1;
								}
								return 0;
							}
							function sortByDateAsc(a, b) {
								if (a && b) {
									if (moment(a.startDate) < moment(b.startDate)) return -1;
									if (moment(a.startDate) > moment(b.startDate)) return 1;
								}
								return 0;
							}
							function filterBy(cpg,switchFilter){
								if(switchFilter === 'in'){
									const startDate = moment(cpg.startDate);
									const thirty_Days = moment().subtract(30,'days');
									const isAfter = startDate.isAfter(thirty_Days);
									if(isAfter){
										return cpg;
									}
								}
								if(switchFilter === 'notIn'){
									const startDate = moment(cpg.startDate);
									const thirty_Days = moment().add(30,'days');
									const isAfter = startDate.isAfter(thirty_Days);
									if(!isAfter){
										return cpg;
									}
								}
							}
							function getLinkCampaign(cpg){
								let campaignUrl = '/CampaignReporting/Home/?dimension=onSite&id=' + cpg.id;
								if (routeParams) {
									if (routeParams.ka) {
										return {...cpg, urlToCampaign : campaignUrl += '&ka=' + accountId + '&ku=' + routeParams.ku};
									}else{
										return {...cpg, urlToCampaign : campaignUrl};
									}
								}
							}
							const cpgStartedRecentlyS = campaignsToSor.filter(cpg => cpg.status === 'Ongoing' || cpg.status === 'En cours').sort(sortByDate).filter(cp =>filterBy(cp,'in')).map(x =>getLinkCampaign(x));						
							setCpgStartedRecently({
								isLoading : false,
								data : cpgStartedRecentlyS
							});
							const cpgComingSoonS = campaignsToSor.filter(cpg => cpg.status === 'Scheduled' || cpg.status === 'Programmée').sort(sortByDateAsc).filter(cp =>filterBy(cp,'notIn')).map(x =>getLinkCampaign(x));
							setCpgComingSoon({
								isLoading : false,
								data : cpgComingSoonS
							});
						})
						.catch((e) => {
							console.log('err', e);
							setCpgStartedRecently({
								isLoading : false,
								data : []
							});
							setCpgComingSoon({
								isLoading : false,
								data : []
							});
							systemServices.showError();
						});
					// GetCampaigns onGoing 
					client
						.query({
							query: getCampaigns,
							variables: {
								accountId: accountId,
			
								order: [OrderByModificationDateDesc],
								where: {
									and: [{and: [OnGoing, notOnTestExecutionModeFilter],}],
								},
							},
						})
						.then((response) => {
							const nbCampaignOngoing = {
								name : t('campaign.ongoingCampaigns'),
								isLoading : false,
								value: response.data.account.onSiteCampaigns.totalCount
							};
							setOnSiteCampaignInfos(kpis => ({
								...kpis, 
								['onsiteCampaigns'] : nbCampaignOngoing
							}));

						})
						.catch((e) => {
							console.log('err', e);
							systemServices.showError();
						});
					// Get campaign Scheduled
					client
						.query({
							query: getCampaigns,
							variables: {
								accountId: accountId,
			
								order: [OrderByModificationDateDesc],
								where: {
									and: [{and: [Scheduled],}],
								},
							},
						})
						.then((response) => {
							const scheduledCampaigns = {
								name: t('campaign.scheduledCampaigns'),
								isLoading : false,
								value: response.data.account.onSiteCampaigns.totalCount
							};
							setOnSiteCampaignInfos(kpis => ({
								...kpis, 
								['scheduledCampaigns'] : scheduledCampaigns
							}));

						})
						.catch((e) => {
							console.log('err', e);
							systemServices.showError();
						});
						
					// Get OnGoing A/B Tests
					abTestsServices.getCount((success)=> {
						const totalC = success;
						const abTestOnGoingCampaigns = {
							name : t('campaign.ongoingABTests'),
							isLoading : false,
							value: totalC, 
							pendingCpg: success
						};
						setOnSiteCampaignInfos(kpis => ({
							...kpis, 
							['abTestOnGoingCampaigns'] : abTestOnGoingCampaigns
						}));
		
					}, () => {
						systemServices.showError('An error occurred while trying to get data');
					},signalAbTest);
					// Get KPIS datas
					insightsServices.getInsightsKpis(accountId,signalInsightKpi).then((success)=> {
						const result  = {
							sessions : {
								name : t('traffic.sessions'),
								isLoading : false,
								value: success.data.sessionsCount,
								rate : success.data.sessionsCountRate,
							},
							visitors : {
								name : t('traffic.visitors'),
								isLoading : false,
								value: success.data.visitorsCount,
								rate : success.data.visitorsCountRate,
							},
							pageView : {
								name : t('traffic.pageViews'),
								isLoading : false,
								value: success.data.pageViewsCount,
								rate : success.data.pageViewsCountRate,
							},
						};
						// setTurnoverIncremetal({
						// 	name : 'Incremental turnover (Last 30 days)',
						// 	value : success.turnoveRate,
						// 	unit : '€',
						// 	isLoading : true
						// });
						setwebSiteTraffic(result);
						const getDataSet = success.data.sessionsByDeviceRate.map(el => {
							return {
								label: t(getDeviceTranslationKey(el.device)),
								value: el.rate * 100,
							};
						});
						const devicesWebSite = {
							dataset : getDataSet,
							name: ''
						};
						setdevicesWebSiteTraffic(devicesWebSite);
						sethasErrorOnTraffic(false);
					},).catch((err) => {
						console.log('🚀--** ~ file: KpiContainer.tsx:396 ~ insightsServices.getInsightsKpis ~ err:', err);
						if(err.status === 404){
							sethasErrorOnTraffic(true);
							setwebSiteTraffic( {
								sessions : {
									name : t('traffic.sessions'),
									isLoading : false,
									value: 0,
									rate : 0,
								},
								visitors : {
									name : t('traffic.visitors'),
									isLoading : false,
									value: 0,
									rate : 0,
								},
								pageView : {
									name : t('traffic.pageViews'),
									isLoading : false,
									value: 0,
									rate : 0,
								},
							});
						}
						//systemServices.showError('An error occurred while trying to get data');
					});
					// Get insightsServices.chargeAvailableFormats
					insightsServices.chargeAvailableFormats(accountId,(success)=> {
						const availableCampaigns = {
							name : t('campaign.customTemplates'),
							isLoading : false,
							value: success.length
						};
						setOnSiteCampaignInfos(kpis => ({
							...kpis, 
							['availableCampaigns'] : availableCampaigns
						}));
					
			
					}, () => {
						systemServices.showError('An error occurred while trying to get data');
					});
					insightsServices.getInsightsCampaign(accountId,(success)=> {

						const incremental ={
							name : t('campaign.incrementalTurnover'),
							value : success.incrementalTurnover,
							unit : '€',
							isLoading : false
						};
						setTurnoverIncremetal(incremental);
					
			
					}, () => {
						systemServices.showError('An error occurred while trying to get data');
					});
				}
				// GEt Ecommerce datas
				if(insightsActived.includes('ecommerce')){
					insightsServices.getEcommerceDataByDate(accountId,'last7Days',(success)=> {
						const getDataSet = success.turnoverByDeviceRate.map(el => {
							return {
								label: t(getDeviceTranslationKey(el.device)),
								value : el.rate * 100,
							};
						});
						const devicesTurnOner = {
							dataset : getDataSet, name :''
						};

						setdevicesTurnover(devicesTurnOner);

						const result1  = {
							turnover : {
								name : t('ecommerce.turnover'),
								isLoading : false,
								value: success.turnover,
								rate : success.turnoveRate,
								unit: '€'
							},
							transactionsCount : {
								name : t('ecommerce.transactionCount'),
								isLoading : false,
								value: success.transactionsCount,
								rate : success.transactionsCountRate,
							},
							conversionRate : {
								name : t('ecommerce.conversionRate'),
								isLoading : false,
								value: success.conversionRate,
								rate : success.conversionRate,
								unit: '%'
							},
						};
						const result2  = {
							averageCartValue : {
								name : t('ecommerce.averageCartValue'),
								isLoading : false,
								value: success.averageCartValue,
								rate : success.averageCartValueRate,
								unit: '€'
							},
							abandonedCartsCount : {
								name : t('ecommerce.abandonedCartsCount'),
								isLoading : false,
								value: success.abandonedCartsCount,
								rate : success.abandonedCartsCountRate,
								inversedColor: true
							},	
						};
						setEcommerceDatas1(result1);
						setEcommerceDatas2(result2);
						setHasErrorOnEcommerce(false);
		
					}, (err, errData) => {
						if(errData.status === 404){
							console.error(err);
							setHasErrorOnEcommerce(true);
						}
					//systemServices.showError('An error occurred while trying to get data');
					});
				}
			}
			return () => {
				if(controllerAbTest){
					controllerAbTest.abort();				
				}

				if(controllerInsightKPI){
					controllerInsightKPI.abort();				
				}
			};
		},[insightsActived]);
	
		

		return (
			<ApolloProvider client={client}>
				<Section width='m'>
					{!insightsActived &&
						<SpinnerWheel wheelSize="l" />
					}
					{insightsActived &&
						<section className="section no_bottom_pad section_primary">
							<div className="flex flex_align_baseline">
								<div className="flex_item_full">
									<div className="h1">{t('common.mainKPI')}</div>
								</div>
								<div className="flex_item_fix ml_20">
									<span className={kpiStyle.date}>{today}</span>
								</div>
							</div>
						</section>
					}

					{insightsActived && insightsActived.length == 0 &&
						<section className="section">
							<Article>
								<EmptyState
									title={t('common.noSection')}
									text={t('common.contactUsForSections')}
									imageUrl="/Assets/empty_section.svg"
									textSize="xl"
									verticalSize="l"
								/>
							</Article>
						</section>
					}

					{insightsActived && insightsActived.length > 0 &&
						<section className='section'>
							{insightsActived && insightsActived.includes('campaigns') &&
								<div className={kpiStyle.section}>
									<div className={kpiStyle.section_header}>
										<div className='flex'>
											<div className='flex_item_full'>
												<div className='h3'>{t('campaign.whatsNewOnCampaigns')}</div>
											</div>
											<div className='flex_item_fix'>
												<Link href={getFinalUrl('/Onsite/Dashboard',props)} icon="fas fa-arrow-right" iconPosition="after">
													{t('campaign.viewAllCampaigns')}
												</Link>
											</div>
										</div>
									</div>
									<div className={kpiStyle.section_body}>
										<Article hasMargin={false} innerSize='l'>
											<div className={kpiStyle.sumup_list}>
												{Object.keys(onSiteCampaignInfos).map(item =>
													<SumUp
														key={uuidv4()}
														item={onSiteCampaignInfos[item]}
														size={insightsActived.includes('turnover') ? 'm' : 'l'}
													/>
												)}
												{insightsActived.includes('turnover') &&
													<SumUp
														key={uuidv4()}
														item={turnoverIncremental}
														size={insightsActived.includes('turnover') ? 'm' : 'l'}
													/>
												}
											</div>
										</Article>
										<div className={kpiStyle.campaigns}>
											<div className={kpiStyle.campaigns_col}>
												<Article hasMargin={false} innerSize='l'>
													<CampaignList
														isLoading={cpgStartedRecently.isLoading}
														list={cpgStartedRecently?.data}
														title={t('campaign.recentlyStartedCampaigns')}
														emptyText={t('campaign.noLaunchedCampaigns', {days: 30})}
														type="ongoing"
													/>
												</Article>
											</div>
											<div className={kpiStyle.campaigns_col}>
												<Article hasMargin={false} innerSize='l'>
													<CampaignList
														isLoading={cpgComingSoon.isLoading}
														list={cpgComingSoon?.data}
														title={t('campaign.upcomingCampaigns')}
														emptyText={t('campaign.noUpcomingCampaigns', {days: 30})}
														type="scheduled"
													/>
												</Article>
											</div>
										</div>
									</div>
								</div>
							}
							{insightsActived && insightsActived.includes('global') &&
								<div className={kpiStyle.section}>
									<div className={kpiStyle.section_header}>
										<div className='flex'>
											<div className='flex_item_full'>
												<span className='h3'>{t('traffic.traffic')}</span>
												<span className='dot'></span>
												<span className='s_13 grey_3 fw_medium'>{t('common.lastNDays', {days: 7})}</span>
											</div>
											<div className='flex_item_fix'>
												<Link href={getFinalUrl('/Insight/traffic',props)} icon="fas fa-arrow-right" iconPosition="after">
													{t('traffic.trafficDashboard')}
												</Link>
											</div>
										</div>
									</div>
									<div className={kpiStyle.section_body}>
										{hasErrorOnTraffic ?
											<Article>
												<EmptyState
													title={t('common.noDataForSection')}
													titleBold={false}
													icon="far fa-clock"
													verticalSize="s"
												/>
											</Article>
											:
											<div className={kpiStyle.list_wrapper}>
												<div className={kpiStyle.list}>
													{webSiteTraffic &&
														<>
															{Object.keys(webSiteTraffic).map(item =>
																<div key={uuidv4()} className={kpiStyle.item_s}>
																	<Percent item={webSiteTraffic[item]} />
																</div>
															)}
														</>
													}
													{devicesWebSiteTraffic &&
														<div className={kpiStyle.item_m}>
															<Pie
																title={t('traffic.sessionsByDevice')}
																data={devicesWebSiteTraffic.dataset}
																width={70}
																thickness={12}
															/>
														</div>
													}
												</div>
											</div>
										}
									</div>
								</div>
							}
							{insightsActived && insightsActived.includes('ecommerce') &&
								<div className={kpiStyle.section}>
									<div className={kpiStyle.section_header}>
										<div className='flex'>
											<div className='flex_item_full'>
												<span className='h3'>{t('ecommerce.ecommerce')}</span>
												<span className='dot'></span>
												<span className='s_13 grey_3 fw_medium'>{t('common.lastNDays', {days: 7})}</span>
											</div>
											<div className='flex_item_fix'>
												<Link href={getFinalUrl('/Insight/ecommerce',props)} icon="fas fa-arrow-right" iconPosition="after">
													{t('ecommerce.ecommerceDashboard')}
												</Link>
											</div>
										</div>
									</div>
									<div className={kpiStyle.section_body}>
										{hasErrorOnEcommerce ?
											<Article>
												<EmptyState
													title={t('common.noDataForSection')}
													titleBold={false}
													icon="far fa-clock"
													verticalSize="s"
												/>
											</Article>
											:
											<div className={kpiStyle.list_wrapper}>
												<div className={kpiStyle.list}>
													{ecommerceDatas1 &&
														<>
															{Object.keys(ecommerceDatas1).map(item =>
																<div key={uuidv4()} className={kpiStyle.item_s}>
																	<Percent item={ecommerceDatas1[item]} />
																</div>
															)}
														</>
													}
													{devicesTurnover &&
														<div className={kpiStyle.item_m}>
															<Pie
																title={t('ecommerce.turnoverByDevice')}
																data={devicesTurnover.dataset}
																width={70}
																thickness={12}
															/>
														</div>
													}
												</div>
												<div className={kpiStyle.list}>
													{ecommerceDatas2 &&
														<>
															{Object.keys(ecommerceDatas2).map(item =>
																<div key={uuidv4()} className={kpiStyle.item_s}>
																	<Percent item={ecommerceDatas2[item]} />
																</div>
															)}
														</>
													}
												</div>
											</div>
										}
									</div>
								</div>
							}
						</section>
					}
				</Section>
			</ApolloProvider>
		);
	}else{
		return <></>;
	}
}