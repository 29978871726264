import React, { useState, useEffect, useRef } from 'react';
import Portal from './Portal';
import Btn from "./Btn";
import './Dropdown.css';
import { v4 as uuidv4 } from 'uuid';
export default function Dropdown({
	button = <Btn style="outline" color="secondary" arrow="true">More</Btn>, // need to accept onMouseDown and ref props
	buttonRef,
	onHide,
	setIsOpen,
	toggle, // replace onHide and setIsOpen
	isOpen,
	posX = 'button',
	posY,
	clickEvent,
	children,
	scrollbar = false,
	flip = true,
	minWidth = 0,
	maxWidth
}) {
	const refs = useRef({});
	const idDropDown = uuidv4();
	const defaultCssClass = ['dropdown_outer'];

	if (scrollbar) {
		defaultCssClass.push('has_scroll');
	}

	const [cssClass, setCssClass] = useState(defaultCssClass);

	const handleToggle = (state, event) => {
		if (state == false && typeof onHide === 'function') {
			onHide(event);
			return;
		}
		if (state == true && typeof setIsOpen === 'function') {
			setIsOpen(event);
			return;
		}
		if (typeof toggle === 'function') {
			toggle(state, event);
		}
	};

	const btnMouseDown = (ev) => {
		ev.stopPropagation();
		handleToggle(true, ev);
	};

	const btnClick = (ev) => {
		ev.stopPropagation();
	};

	const wrapperClick = (ev) => {
		ev.stopPropagation();
	};

	const wrapperMouseDown = (ev) => {
		if( refs.current && refs.current['btn'] ){
			ev.nativeEvent.dropdownButtonRef = refs.current['btn'];
		}
	};

	const position = (btn, dd) => {
		const btn_rect = btn.getBoundingClientRect();
		const dd_rect = dd.getBoundingClientRect();
		const dd_height = dd.offsetHeight;
		const dd_width = dd.offsetWidth;
		const s = 5;
		const margin = 5;
		const margin_h = 20;
		const scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		const btn_absolute_top = btn_rect.top + scroll;
		const btn_absolute_bottom = btn_rect.bottom + scroll;

		if (dd_height + btn_rect.bottom + s + margin > window.innerHeight) {
			// Il n'y a pas assez de place en bas, j'essaye en haut
			const tryTop = btn_rect.top - dd_height - s;
			if (tryTop > margin) {
				dd.style.top = btn_absolute_top - dd_height - s + 'px';
			} else {
				dd.style.top = window.innerHeight - margin - dd_height + 'px';
			}
		} else {
			// Il y a assez de place en bas, je positionne en bas.
			dd.style.top = btn_absolute_bottom + s + 'px';
		}

		let atLeft = btn_rect.left;
		if (posX == "mouse" && clickEvent) {
			atLeft = clickEvent.clientX;
		}

		if (dd_width + atLeft + margin_h > window.innerWidth) {
			if( flip == true && posX == "button" ){
				dd.style.left = "auto";
				dd.style.right = window.innerWidth - btn_rect.right + 'px';
			}else{
				dd.style.left = window.innerWidth - dd_width - margin_h + 'px';
				dd.style.right = "auto";
			}
		} else {
			dd.style.left = atLeft + 'px';
			dd.style.right = "auto";
		}

		if ( ! buttonRef) {
			const tmpMinWidth = Math.max(btn_rect.width, minWidth);
			dd.style.minWidth = tmpMinWidth + 'px';
		}

		if (maxWidth) {
			dd.style.maxWidth = maxWidth + 'px';
		}

		const tmpCssClass = [...cssClass];
		if (!tmpCssClass.includes('is_visible')) {
			tmpCssClass.push('is_visible');
		}
		setCssClass(tmpCssClass);
	};

	useEffect(() => {
		if (isOpen && refs) {
			function handleClickOutside(event) {
				if (event.target.id && event.target.id === idDropDown) {
					return
				}
				if (refs.current && refs.current['wrapper'] ){
					if (event.dropdownButtonRef && refs.current['wrapper'].contains(event.dropdownButtonRef)) {
						return;
					}
					if (refs.current['wrapper'].contains(event.target)) {
						return;
					}
				}
				event.stopPropagation()
				handleToggle(false, event);
			}
			document.addEventListener("mousedown", handleClickOutside);

			function _position() {
				if (buttonRef && buttonRef.current) {
					position(buttonRef.current, refs.current.wrapper);
				} else {
					position(refs.current.btn, refs.current.wrapper);
				}
			}
			_position();
			window.addEventListener("resize", _position);

			// Si le dropdown contient des images
			// il faut re-positionner après le chargement des images
			if (refs.current && refs.current['wrapper']) {
				const images = refs.current['wrapper'].querySelectorAll('img');
				for (var i = 0; i < images.length; i++) {
					if (!images[i].complete) {
						images[i].addEventListener('load', _position);
					}
				}
			}

			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
				window.removeEventListener("resize", _position);
			};
		}
	}, [refs, isOpen, children]);

	const buttonClone = React.cloneElement(
		button,
		{
			onMouseDown: (e) => btnMouseDown(e),
			onClick: (e) => btnClick(e),
			ref: (el) => {refs.current['btn'] = el}
		}
	);

	return (
		<>
			{!buttonRef && buttonClone && buttonClone}
			{isOpen &&
				<Portal>
					<div className={cssClass.join(' ')}
					     tabIndex="-1"
					     ref={el => refs.current['wrapper'] = el}
					     onMouseDown={(e) => wrapperMouseDown(e)}
						 onClick={(e) => wrapperClick(e)}>
						<div className="dropdown_inner" id={idDropDown}>
							{children}
						</div>
					</div>
				</Portal>
			}
		</>
	)
}