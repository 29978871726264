import React, {useEffect, useState} from 'react';
import { CampaignsContext } from '../../context/index.js';
import Panel from '../../../../Components/Panel';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import Btn from '../../../../Components/Btn';
import PanelArticle from '../../../../Components/PanelArticle';
import InputCustom from '../../../../Components/InputCustom';

type Redirection = {
	Link: string;
	Text: string;
}
export default function PanelRedirection({
	panelRedirectionIsOpen,
	handleOpenClosePanel,
}) {
	const {
		variations,
		currentVariationId,
		getCurrentVariation,
		handleChangeCallToAction,
	} = CampaignsContext();

	const [canConfirm, setCanConfirm] = useState(false);
	const [linkError, setLinkError] = useState('');
	const [redirectionObj, setRedirectionObj] = useState<Redirection>({
		Link: '',
		Text: ''
	});
	
	function handleChangeForm(event: Event){
		const target = event.target as HTMLButtonElement;
		const name = target.name;
		const value = target.value;
		setRedirectionObj(redir =>({
			...redir,
			[name]: value
		}));
	}

	function close() {
		reset();
		handleOpenClosePanel(false);
	}

	function confirm() {
		const redirection = {...redirectionObj};
		let url = redirection.Link;
		if (
			url.indexOf('http://') === -1 &&
            url.indexOf('https://') === -1
		) {
			url = 'https://' + url;
			redirection.Link = url;
		}

		const isValid = urlIsValid(redirection.Link);
		if (!isValid) {
			setLinkError('Error');
			setCanConfirm(false);
			return;
		}

		handleChangeCallToAction(redirection);
		handleOpenClosePanel(false);
	}

	function reset() {
		const currentVariationSaved = getCurrentVariation();
		if(currentVariationSaved){
			if(currentVariationSaved.hasOwnProperty('Answer')){
				const callToAction = currentVariationSaved.Answer.CallToAction;
				if(currentVariationSaved.Answer.CallToAction !== null){
					setRedirectionObj(callToAction);
				}
			}			
		}
	}

	function urlIsValid(str:string) {
		const pattern = new RegExp(
			'^(https:\\/\\/)?' +
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
				'((\\d{1,3}\\.){3}\\d{1,3}))' +
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
				'(\\?[;&a-z\\d%_.~+=-]*)?' +
				'(\\#[-a-z\\d_]*)?$',
			'i'
		);
		return !!pattern.test(str);
	}

	useEffect(()=>{
		if (!redirectionObj.Link) {
			setCanConfirm(false);
			setLinkError('');
			return;
		}

		setCanConfirm(true);
		setLinkError('');
	},[redirectionObj]);

	useEffect(()=>{
		reset();
	},[variations,currentVariationId]);

	return (
		<Panel
			width="680"
			isOpen={panelRedirectionIsOpen}
			onClose={() => close()}
			noClose={true}
		>
			<ModalHeader>
				<div className='flex'>
					<div className="flex_item_fix">
						<a className="panel_close panel_close_left" onClick={() => close()}></a>
					</div>
					<div className='flex_item_full'>
							Redirection
					</div>
					<div className='flex_item_fix ml_30'>
						<Btn
							message="Confirm"
							color="primary"
							onClick={()=>confirm()}
							disabled={!canConfirm}
						/>
					</div>
				</div>
			</ModalHeader>
			<ModalBody color='grey'>
				<PanelArticle size='xl'>
					<InputCustom
						value={redirectionObj.Link}
						type="text"
						label="Redirection URL"
						name="Link"
						placeholder="https://www.beyable.com"
						onChange={handleChangeForm}
						fullWidth={true}
						isAnError={linkError}
					/>
					<InputCustom
						value={redirectionObj.Text}
						type="text"
						label="Button text (optional)"
						name="Text"
						placeholder="Confirm"
						onChange={handleChangeForm}
						fullWidth={true}
					/>
				</PanelArticle>
			</ModalBody>
		</Panel>
	);
}
