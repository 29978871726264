
const HttpVerb = {
	GET: 'get',
	POST: 'post',
	PUT: 'put',
	DELETE: 'delete'
};

const headers = {
	'Content-Type': ''
};

export default class HttpServices {
	constructor($http) {
		this.$http = $http;
		this.BASE_URL = 'https://localhost:5001';
	}

	_send(httpVerb, url, callbackError, callbackSuccess = null, data = null, headers = null, signal = null) {
		this.$http({
			method: httpVerb,
			url: url,
			data: data,
			headers: headers,
			signal : signal
		}).then(result => {
			if (result && result.status >= 200 && result.status < 300) {
				if (callbackSuccess) {
					callbackSuccess(result.data,result.headers);
				}
			}
			else {
				console.error(result);
				callbackError('API didn\'t return 200 OK.', result);
			}
		}, error => {
			console.error(error);
			callbackError('An error occurred. Please check the console to get more technical details', error);
		});
	}

	get(url, callbackSuccess, callbackError, headers = null,signal = null) {
		this._send(HttpVerb.GET, url, callbackError, callbackSuccess, null, headers,signal);
	}

	post(url, data, callbackSuccess, callbackError, headers = null,signal = null) {
		this._send(HttpVerb.POST, url, callbackError, callbackSuccess, data, headers,signal);
	}

	put(url, data, callbackSuccess, callbackError, headers = null,signal = null) {
		this._send(HttpVerb.PUT, url, callbackError, callbackSuccess, data, headers,signal);
	}

	delete(url, callbackSuccess, callbackError, data = null, headers = null,signal = null) {
		this._send(HttpVerb.DELETE, url, callbackError, callbackSuccess, data, headers,signal);
	}
}