import React, { createContext, useContext, useEffect, useState,Dispatch, SetStateAction } from 'react';
import EmailTemplateServices from '../../../../Services/EmailTemplateServices';
import { EmailTemplateInListApiType, EmailTemplateStatus } from '../../../../Services/EmailTemplateServicesTypes';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
// import EventTrackingServices from '../../../../Services/EventTrackingServices';
// import { v4 as uuidv4 } from 'uuid';
// import {  } from './EntityTypes';


const EmailTemplateListContext = createContext<EmailTemplateListContextType | undefined>(undefined);

function useEmailTemplateListContext() {
	const context = useContext(EmailTemplateListContext);
	if (!context) throw Error('useEmailTemplateListContext can only be used inside an EmailTemplateListContextProvider');
	return context;
}

const CreateEmailTemplateListContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;

	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	//const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const crud = new EmailTemplateServices(accountId, $http);

	// let urlReturn = '/EmailTemplate/Dashboard';
	// if ($routeParams && $routeParams.ka && isAdmin) {
	// 	urlReturn += '?ka=' + $routeParams.ka;
	// }

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [templates, setTemplates] = useState<EmailTemplateInListApiType[]>([]);
	const [modaHtmlIsOpen, setmodaHtmlIsOpen] = useState<boolean>(false);
	useEffect(() => {
		setIsLoading(true);
		crud.getEmailTemplates(
			result => {
				setTemplates(result);
				setIsLoading(false);
			},
			err => {
				console.error(err);
				setIsLoading(false);
				systemServices.showError('An error occured while loading email templates');
			}
		);
	}, []);

	const getEditLink = (emailTemplateId: string): string => {
		const ka = isAdmin && props.$routeParams?.ka ? `&ka=${accountId}` : '';
		const url = `/EmailTemplate/Editor?id=${emailTemplateId}${ka}`;
		return url;
	};
	const getCreationLink = (): string => {
		const ka = isAdmin && props.$routeParams?.ka ? `?ka=${accountId}` : '';
		const url = `/EmailTemplate/Editor${ka}`;
		return url;
	};

	const changeEmailTemplateStatus = (emailTemplateId: string, status: EmailTemplateStatus) => {
		crud.updateEmailTemplateStatus(emailTemplateId, status, () => {
			systemServices.showSuccess(status === EmailTemplateStatus.Live ? 'Template is running' : 'Stopped Template');
		}, err => {
			console.log(err);
			systemServices.showError(status === EmailTemplateStatus.Live ? 'An error occured while starting Template' : 'An error occured while stopping Template');
		});
	};

	const handleChangesStatusModalHtml = (b) => {
		setmodaHtmlIsOpen(b);
	}
	const context: EmailTemplateListContextType = {
		isLoading,
		templates,
		changeEmailTemplateStatus,
		getEditLink,
		getCreationLink,
		modaHtmlIsOpen,
		handleChangesStatusModalHtml
		
	};

	return (
		<EmailTemplateListContext.Provider
			value={context}
		>
			{props.children}
		</EmailTemplateListContext.Provider>
	);
};

export default CreateEmailTemplateListContextProvider;

export { useEmailTemplateListContext };

type EmailTemplateListContextType = {
	isLoading: boolean;
	templates: EmailTemplateInListApiType[];
	changeEmailTemplateStatus: (emailTemplateId: string, status: EmailTemplateStatus) => void;
	getEditLink: (emailTemplateId: string) => string;
	getCreationLink: () => string;
	modaHtmlIsOpen:boolean;
	handleChangesStatusModalHtml:Dispatch<SetStateAction<boolean>>;
}