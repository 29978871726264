
import HttpServices from './HttpServices';
import axios from 'axios';

export default class ProductEMerchServices {
	constructor($http, authServices, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
		this.accountId = accountId;
	}
	getProductCollation(category, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-collation/?context=${category}`,
			callbackSuccess, callbackError);
	}
	getRuleList(valueInSearchBar,dataFiltered,descSort,paginationOptions, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/emerch-rule/?offset=${paginationOptions.offset}
		&limit=${paginationOptions.limit}&searchTerm=${valueInSearchBar}&sortBy=${dataFiltered}&descSort=${descSort}`,
		callbackSuccess, callbackError);
	}

	createRecommendation(association, callbackSuccess, callbackError) {
		const recoNewObj = { ...association };
		const filters = recoNewObj.filtering;

		if (filters !== null) {
			if (recoNewObj.filtering.filters.length === 0) {
				delete recoNewObj.filtering;
			}
		}
		this.HttpServices.post(
			`${this.BO_API_URL}${this.accountId}/product-recommendation/`,
			recoNewObj,
			callbackSuccess,
			callbackError
		);
	}
	getRecommendation(associationId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-recommendation/${associationId}`, callbackSuccess, callbackError);
	}
	deleteRecommendation(associationId, callbackSuccess, callbackError) {
		this.HttpServices.delete(
			`${this.BO_API_URL}${this.accountId}/product-recommendation/${associationId}`,
			callbackSuccess,
			callbackError
		);
	}
	updateRecommendation(association, associationId, callbackSuccess, callbackError) {
		const recoNewObj = { ...association };
		if (recoNewObj.filtering.filters.length === 0) {
			delete recoNewObj.filtering;
		}
		this.HttpServices.put(
			`${this.BO_API_URL}${this.accountId}/product-recommendation/${associationId}`,
			recoNewObj,
			callbackSuccess,
			callbackError
		);
	}
	getProductListPreview(options, paginationOptions, reco, callbackSuccess, callbackError) {
		const recoNewObj = { ...reco };
		if (recoNewObj.filtering.filters.length === 0) {
			delete recoNewObj.filtering;
		}

		let previewTenant = '';
		if (options.hasOwnProperty('previewTenant')) {
			previewTenant = `&previewTenant=${options.previewTenant}`;
		}
		let referenceProductId = '';
		if (options.hasOwnProperty('referenceProductId')) {
			referenceProductId = `&referenceProductId=${options.referenceProductId}`;
		}
		let categoryName = '';
		if (options.hasOwnProperty('categoryName')) {
			if (options.categoryName === '') {
				return;
			}
			categoryName = `?categoryName=${encodeURIComponent(options.categoryName)}`;
		}
		let categoryType = '';
		if (options.hasOwnProperty('categoryType')) {
			if (options.categoryType === '') {
				return;
			}
			const sign = categoryName !== '' ? '&' : '?';
			categoryType = `${sign}categoryType=${encodeURIComponent(options.categoryType)}`;
		}
		let tagName = '';
		if (options.hasOwnProperty('tagName')) {

			if (options.tagName === '') {
				return;
			}
			const sign = categoryName !== '' ? '&' : '?';
			tagName = `${sign}tagName=${encodeURIComponent(options.tagName)}`;
		}
		let deviceType = '';
		if (options.hasOwnProperty('deviceType')) {
			const sign = !options.hasOwnProperty('categoryType') && !options.hasOwnProperty('categoryName') && !options.hasOwnProperty('tagName') ? '?' : '&';
			deviceType = `${sign}deviceType=${encodeURIComponent(options.deviceType)}`;
		}
		this.HttpServices.post(
			`${this.BO_API_URL}${this.accountId}/product-recommendation-preview/
			${categoryName}${tagName}${categoryType}${deviceType}
			&plimit=${paginationOptions.plimit}
			&poffset=${paginationOptions.poffset}
			` + previewTenant + referenceProductId,
			recoNewObj,
			callbackSuccess,
			callbackError
		);
	}
}
