import React from 'react';

export default function SpinnerWheel({
    wheelSize = 'm', // xxs, xs, s, m, l
    display = 'overlay', // block | inline | overlay
    verticalSize = 'l', // xs, s, m, l, xl - Only if display == 'block'
    className
}) {

    const cssClass = ['wheel'];

    // wheelSize
    if (wheelSize) {
        cssClass.push('size_' + wheelSize);
    }

    // Display
    if (display == 'block') {
        cssClass.push('full');

        // verticalSize
        if (verticalSize) {
            cssClass.push('vertical_size_' + verticalSize);
        }
    }

    if (className) {
        cssClass.push(className);
    }

    const wheel = (
        <div className={cssClass.join(' ')}></div>
    );

    if (display == 'overlay') {
        return (
            <div className='mask'>{wheel}</div>
        );
    }

    return wheel;
}
