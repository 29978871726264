import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Menu.module.css';
import { Flag } from '../../Components/Flag/Flag';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

function isActive(item) {

	if (typeof item.isActive === 'boolean') return item.isActive;

	const urls = item.activeUrls;
	const queries = item.activeQueries;
	
	if (!urls || urls.lenght == 0) return false;

	const currentPath = window.location.pathname;
	const currentSearch = window.location.search;

	const activeUrl = urls.find((u) => u.split('?')[0] === currentPath);
	const url_ok = !!activeUrl;

	let queries_ok = true;
	
	if (queries) {
		for (const [key, value] of Object.entries(queries)) {
			const search = key + '=' + value;
			if (!currentSearch.includes(search)) {
				queries_ok = false;
				break;
			}
		}
	}

	return url_ok && queries_ok;
}


function MenuList({
	items,
	level = 1
}) {

	return (
		<>
			<ul className={styles.list}>
				{items && items.map((item) => {
					if (level == 1 && 'items' in item) {
						return (
							<MenuGroup
								key={item.label}
								item={item}
								subItems={item.items}
							/>
						);
					} else {
						return (
							<MenuItem
								key={item.label}
								label={item.label}
								icon={item.icon}
								aiFlag={item.aiFlag}
								isActive={isActive(item)}
								url={item.link}
								onClick={item.onClick}
								level={level}
							/>
						);
					}
				})}
			</ul>
		</>
	);
}

function MenuItem({
	icon = '',
	aiFlag = false,
	label = '',
	url,
	isActive = false,
	isDisabled = false, 
	hasArrow = false,
	isOpen = false,
	onClick = null,
	level = 1
}) {

	const tooltipID = uuidv4();
	const itemClass = [styles.item, 'flex'];
	const iconClass = ['icon_20', 'icon_'+icon];
	iconClass.push(isActive ? 'icon_color_turquoise' : 'icon_color_grey');

	if (isActive) {
		itemClass.push(styles.selected);
	}

	if (isDisabled) {
		itemClass.push(styles.disabled);
	}

	itemClass.push(styles['item_level_' + level]);

	return (
		<li className={styles.item_wrapper}>
			{level == 2 &&
				<span className={styles.item_indent}></span>
			}
			<a className={itemClass.join(' ')} href={url} onClick={onClick}>
				{level == 1 &&
					<span className={styles.item_picto + ' flex_item_fix'}>
						{icon && <i className={iconClass.join(' ')}></i>}
					</span>
				}
				<span
					className={styles.item_text + ' flex_item_full ellips'}
					data-tip={label}
					data-for={tooltipID}>
					{label}
				</span>
				{aiFlag &&
					<span className='flex_item_fix'>
						<Flag
							icon="ai"
							text="AI"
							hasBorder={true}
							tooltip="This feature is powered by BEYABLE AI"
							className='v_al_middle'
						/>
					</span>
				}
				{hasArrow &&
					<span className={isOpen ? styles.item_arrow_open : styles.item_arrow}>
						<i className='fas fa-chevron-down fa-fw'></i>
					</span>
				}
			</a>
			<ReactTooltip
				id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="mouseup"
				delayShow={600}
			/>
		</li>
	);
}

function MenuGroup({
	item,
	subItems
}) {

	const location = useLocation();
	const getHasActiveSubItem = () => {
		if (item.isOpenDefault) return true;
		const activeSubItem = subItems.find((el) => {
			return isActive(el);
		});
		return !!activeSubItem;
	};

	const [isOpen, setIsOpen] = useState(getHasActiveSubItem());

	useEffect(() => {
		setIsOpen(getHasActiveSubItem());
	}, [location, item.isOpenDefault]);

	return (
		<>
			<MenuItem
				label={item.label}
				icon={item.icon}
				onClick={() => setIsOpen(!isOpen)}
				hasArrow={true}
				isOpen={isOpen}
			/>
			{isOpen &&
				<li>
					<MenuList
						items={subItems}
						level={2}
					/>
				</li>
			}
		</>
	);
}

export {
	MenuList,
	MenuItem,
	MenuGroup
};
