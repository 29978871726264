
import React, { useEffect, useState } from 'react';
import Btn from '../../../../../Components/Btn';
import InputCustom from '../../../../../Components/InputCustom';
import Range from '../../../../../Components/RangeSlider';
import SelectCustom from '../../../../../Components/SelectCustom';
import Switch from '../../../../../Components/Switch';
const operators = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'GreaterOrEquals',
		label: 'Is greater or equal to',
	},
	{
		value: 'LowerOrEquals',
		label: 'Is lower or equal to',
	},
	{
		value: 'Greater',
		label: 'Is greater than',
	},
	{
		value: 'Lower',
		label: 'Is lower than',
	},
];
const operatorForDecils = [
	{
		value: 'Top',
		label: 'Top',
	},
	{
		value: 'Flop',
		label: 'Flop',
	},
];
export default function BoundedNumberContainer({
	data,
	handleChangeFilterByUniqueId,
}) {
	console.log('🚀🐱 😻 --///** ~ file: BoundedNumberContainer.js:48 ~ handleChangeFilterByUniqueId:', handleChangeFilterByUniqueId);
		
	const desiredIncrementCount = 10;
	const max = data.hasOwnProperty('valueMax') ? data.valueMax : data.specificData
		.valueMax;
	const min = data.hasOwnProperty('valueMin') ? data.valueMin : data.specificData
		.valueMin;

	const stepInterval = (max - min) / desiredIncrementCount;

	const [currentValue, setcurrenValue] = useState(
		parseFloat(data.value['numberValues']) || 0.5
	);

	const [valueOperatorSelected, setvalueOperatorSelected] = useState();
	const [valueOperatorDecilsSelected, setvalueOperatorDecilsSelected] = useState();

	const [methodComputation, setmethodComputation] = useState('normal');
	const selectOperator = (operator) => {
		setvalueOperatorSelected(operator);
	};

	const handleChangeValue = (value) => {
		setcurrenValue(value.target.value);
	};

	function getValueOfStringComponent() {
		if (data.op === 'Flop') {
			setvalueOperatorDecilsSelected('Flop');
			setmethodComputation('decils');
		}
		else if (data.op === 'Top') {
			setvalueOperatorDecilsSelected('Top');
			setmethodComputation('decils');
		} else {
			setmethodComputation('normal');
			let valueSimple = data.value.hasOwnProperty('numberValues');
			if (valueSimple && data.value['numberValues'] !== null) {
				setcurrenValue(parseFloat(data.value['numberValues']));
			}
		}
	}

	function handleChangeValuesById() {
		if (data.op === 'Flop' || data.op === 'Top' || methodComputation !== 'normal' ) {
			if (methodComputation === 'normal') {
				handleChangeFilterByUniqueId({
					idFilter: data.idFilter,
					op: operators[2].value,
					value: { numberValues: [parseFloat(0.5)] },
				});
			} else {
				handleChangeFilterByUniqueId({
					idFilter: data.idFilter,
					op: valueOperatorDecilsSelected,
					value: {numberValues: []},
				});
			}
		}
		else if (currentValue) {
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: valueOperatorSelected,
				value: { numberValues: [parseFloat(currentValue)] },
			});
		} 
	}
	
	function handleChangeMethodComputation() {
		const method = methodComputation === 'normal' ? 'decils' : 'normal';
		setmethodComputation(method);
	}
	useEffect(() => {
		selectOperator(data.op || operators[2].value);
		getValueOfStringComponent();
	}, [data]);

	useEffect(() => {
		handleChangeValuesById();
	}, [data,
		currentValue,
		valueOperatorSelected,
		valueOperatorDecilsSelected,
		methodComputation]);

	return (
		<>

			<div className="btn_switch">
				<Btn
					message="Top"
					icon="fas fa-thumbs-up"
					color="secondary"
					style="outline"
					isActive={methodComputation === 'decils' && valueOperatorDecilsSelected === 'Top'}
					onClick={() => {setmethodComputation('decils'); setvalueOperatorDecilsSelected('Top'); }}
				/>
				<Btn
					message="Flop"
					icon="fas fa-thumbs-down"
					color="secondary"
					style="outline"
					isActive={methodComputation === 'decils' && valueOperatorDecilsSelected === 'Flop'}
					onClick={() => {setmethodComputation('decils'); setvalueOperatorDecilsSelected('Flop'); }}
				/>
				<Btn
					message="Value"
					icon="fas fa-sort-numeric-up"
					color="secondary"
					style="outline"
					isActive={methodComputation === 'normal'}
					onClick={() => setmethodComputation('normal')}
				/>
			</div>

			{methodComputation === 'normal' && 
				<div className="flex flex_align_start mt_20">
					<div className="flex_item_fix mr_10">
						<SelectCustom
							optionsList={operators}
							name="operator"
							onChangeFunction={(e) => selectOperator(e.target.value)}
							value={valueOperatorSelected}
							autoWidth={true}
						/>
					</div>
					<div className="flex_item_fix ">
						<Range
							value={currentValue}
							min={min}
							max={max}
							legendList={['Min', 'Max']}
							step={stepInterval}
							onChange={(e) => handleChangeValue(e)}
							hasInput={true}
							isOnKeyUp={true}
						/>
					</div>
				</div>
			}
		</>
	);
}
