import HttpServices from './HttpServices';

export default class RecommendationRuleCompositionServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getPossibleRules(rules, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/product-recommendation-composable-rule`, {'rules': rules}, callbackSuccess, callbackError);
	}


	getComposition(compositionId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition/${compositionId}`, callbackSuccess, callbackError);
	}

	updateComposition(composition, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition/${composition.id}`, composition, callbackSuccess, callbackError);
	}

	deleteComposition(compositionId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition/${compositionId}`, callbackSuccess, callbackError);
	}

	createComposition(composition, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition`, composition, callbackSuccess, callbackError);
	}
}
