import React , {useState} from 'react';
import { useEmailTemplateEditorContext } from '../../context/EmailTemplateEditorContextProvider';
import InputCustom from '../../../../../Components/InputCustom';
import SelectCustom from '../../../../../Components/SelectCustom';
import { Section } from '../../../../../Components/Section/Section';
import { Article } from '../../../../../Components/Article/Article';

export default function OutputGenerator() {
	const [btn, setBtn] = useState('Splio')
	//const { t } = useTranslation(['common']);
	const {
		personalisationParameterName,
		setPersonalisationParameterName,
		handleChangeName,
		emailTemplate
	} = useEmailTemplateEditorContext();

	const updatePersonalisationParameterName = (e) =>{
		e.preventDefault();
		setPersonalisationParameterName(e.target.value);
	};
	function handleButtons(opt){
		setBtn(opt.target.value);
	}
	return (
		<Section width='xs' hasMargin={true}>
			<Article innerSize='l' title="Campaign information">
				<InputCustom
					name='Name'
					label='Name'
					value={emailTemplate?.Name}
					onChange={e => handleChangeName(e.target.value)}
					fullWidth={true}
				/>
				<InputCustom
					name='Description'
					label='Description'
					placeholder="Optional"
					type="textarea"
					rows="2"
					autogrow={true}
					value={''}
					onChange={e => e}
					fullWidth={true}
				/>
				
			</Article>
			<Article innerSize='l' title="Connector settings">
				<SelectCustom
					label="Email provider"
					value={btn}
					onChange={(e) => handleButtons(e)}
					optionsList={[
						{value: 'Brevo', label: 'Brevo'},
						{value: 'Splio', label: 'Splio'}
					]}
				/>
				<InputCustom
					name=''
					label='Name of email variable'
					value={personalisationParameterName}
					onChange={e => updatePersonalisationParameterName(e)}
				/>
			</Article>
		</Section>
	);
}