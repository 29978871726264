import HttpServices from './HttpServices';

export default class ExternalAppServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
		this.accountId = accountId;
	}

	getExternalAppSetupInfo(moduleIdentifier, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/external-app?module=${moduleIdentifier}`, callbackSuccess, callbackError);
	}	
}