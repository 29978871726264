export default class ImpersonatingServices {

	constructor($rootScope, $routeParams) {
		this.$rootScope = $rootScope;
		this.$routeParams = $routeParams;
	}

	getImpersonatedAccount() {
		const token = this.$routeParams.ka;
		return this.canImpersonate() && token;
	}

	canImpersonate() {
		return this.isAnAdmin() || this.isAnOrgnizationAdmin();
	}

	isAnAdmin() {
		if(!this.$rootScope.User) return;
		return this.$rootScope.User.Role == 'Admin';
	}

	isASupervisor() {
		if(!this.$rootScope.User) return;
		return this.$rootScope.User.Role == 'Supervisor';
	}

	isAnOrgnizationAdmin() {
		if(!this.$rootScope.User) return;
		return this.$rootScope.User.Role == 'OrganizationAdmin';
	}

	getImpersonatedUrl(url) {
		const ka = this.getImpersonatedAccount();
		const ku = this.$routeParams.ku;
		if (!ka) return url;
		const sep = url.includes('?') ? '&' : '?';
		url += sep + 'ka=' + ka + '&ku=' + ku;
		return url;
	}
}