import React, { useEffect, use } from 'react';
import './index.css';
import { react2angular } from 'react2angular';
import { ContextProvider } from './context';
import { I18nextProvider } from 'react-i18next';
import WysiwygEditorProvider from '../WysiwygEditor/context/WysiwygEditorContext';
import CampaignCreationContainer from './CampaignCreationContainer';
import i18next from 'i18next';
import campaign_react_en from '../../Translation/jsonfiles/en/campaign_react_en.json';
import campaign_react_fr from '../../Translation/jsonfiles/fr/campaign_react_fr.json';
import utils_en from '../../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../../Translation/jsonfiles/fr/utils_fr.json';
import { mergeJson } from '../../Translation/translation-utils';

export default function CampaignCreation(props) {
	const i18nL = localStorage.getItem('i18nL');
	const newInstanceI18nextOnsite = i18next.createInstance();
	newInstanceI18nextOnsite.init({
		lng: 'en-EN',
		resources: {
			'en-US': {
				common: mergeJson(campaign_react_en, utils_en)
			},
			'en-EN': {
				common: mergeJson(campaign_react_en, utils_en)
			},
			'fr-FR': {
				common: mergeJson(campaign_react_fr, utils_fr)
			},
		}
	},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextOnsite.changeLanguage(JSON.parse(i18nL));
	});


	return (
		<I18nextProvider i18n={newInstanceI18nextOnsite}>
			<WysiwygEditorProvider
				$rootScope={props.$rootScope}
				$http={props.$http}
				$routeParams={props.$routeParams}
				$timeout={props.$timeout}
				AuthServices={props.AuthServices}
			>
				<ContextProvider
					$rootScope={props.$rootScope}
					$http={props.$http}
					$routeParams={props.$routeParams}
					$timeout={props.$timeout}
					$location={props.$location}
					AuthServices={props.AuthServices}
					UtilCampaignsServices={props.UtilCampaignsServices}
				>
					<CampaignCreationContainer />
				</ContextProvider>
			</WysiwygEditorProvider>
		</I18nextProvider>
	);
}
angular
	.module('beyableSaasApp.CampaignCreation', ['beyableSaasApp.Services'])
	.component('campaigncreation', react2angular(CampaignCreation, [],
		['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'UtilCampaignsServices', 'AuthServices']));