import React from 'react';
import { serverSideCampaignsDashboardContext } from './context';
import { Panel, PanelHeader, PanelBody} from '../../Components/Panel/Panel';
import Link from '../../Components/Link';
import Fieldset from '../../Components/Fieldset';
import CodeSnippet from '../../Components/CodeSnippet';
import { Article } from '../../Components/Article/Article';
import SectionMessage from '../../Components/SectionMessage';
import InputCopyToClipBoard from '../../Components/InputCopyToClipBoard';

export default function ServerSideCampaignIntegrationPanel() {

	const {
		detailModalIsOpened,
		setDetailModalIsOpened,
		campaignSelected
	} = serverSideCampaignsDashboardContext();

	const CID = campaignSelected?.campaignId || null;
	const SID = campaignSelected?.slides && campaignSelected.slides.length > 0 ? campaignSelected.slides[0].slideId : null;
	const code = `
<beyable-campaign
	data-campaign-id="${CID}"
	data-slide-id="${SID}">
    Your HTML here
</beyable-campaign>
	`.trim();

	return (
		<Panel
			isOpen={detailModalIsOpened}
			width={740}
		>
			<PanelHeader
				title='Integration guide'
				onClose={() => setDetailModalIsOpened(false)}
			/>
			<PanelBody color='grey'>
				<SectionMessage
					text={
						<>
							<p>To track an element, it must be wrapped in a <code>&lt;beyable-campaign&gt;</code> tag, see the HTML code example below.</p>
							<p>Other integration methods and more complete examples are available in the documentation.</p>
						</>
					}
					appearance='background_outline'
					links={
						<Link
							href="https://docs.beyable.com/web-app-sdk/performance-tracking"
							target="_blank"
							rel="noreferrer"
							message='Documentation'
							icon="fas fa-external-link-square-alt"
							iconPosition='after'
						/>
					}
				/>
				<Article innerSize='l'>
					<InputCopyToClipBoard
						label='Campaign Id'
						value={CID}
						width={400}
					/>
					<InputCopyToClipBoard
						label='Slide Id'
						value={SID}
						width={400}
					/>
					<Fieldset label={'HTML code example'}>
						<CodeSnippet
							code={code}
							nbRows={5}
						/>
					</Fieldset>
				</Article>
			</PanelBody>
		</Panel>
	);
}