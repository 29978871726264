import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Confirm from '../../../../Components/Confirm';
import Btn from '../../../../Components/Btn';
import IconBtn from '../../../../Components/IconBtn';
import Modal from '../../../../Components/Modal';
import NumericUpDown from '../../../../Components/NumericUpDown';
import EmptyState from '../../../../Components/EmptyState';
import { List, ListItem } from '../../../../Components/List';
import { Article } from '../../../../Components/Article/Article';
import RuleComponent from './RuleComponent';
import styles from './RuleComposition.module.css';
import { ComposableGroupItem, CompositionGroup } from '../context/EntityTypes';
import { useRuleCompositionContext } from '../context/RuleCompositionContextProvider';
import { listRulesByType } from '../context/RuleType';

type RuleCompositionGroupComponentProps = {
	ruleCompositionGroup: CompositionGroup,
	nbCompositionGroups: number,
	groupIndex: number,
	isDragging?: boolean,
	displayMode?: string
}

export default function RuleCompositionGroup({
	ruleCompositionGroup,
	nbCompositionGroups,
	groupIndex
}: RuleCompositionGroupComponentProps) {
	const {
		handleAddRuleToGroup,
		loadPossibleRules,
		handleRulesOrderChanged,
		handleChangeGroupSize,
		handleDeleteGroup,

	} = useRuleCompositionContext();

	const animatedComponents = makeAnimated();

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(ruleCompositionGroup.rules);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		handleRulesOrderChanged(ruleCompositionGroup, items);
	};

	const [openModalToAddRule, setOpenModalToAddRule] = useState<boolean>(false);
	const [confirmDeleteGroup, setConfirmDeleteGroup] = useState<boolean>(false);

	const [listOfRules, setListOfRules] = useState<ComposableGroupItem[]>([]);
	const [ruleToAdd, setRuleToAdd] = useState<ComposableGroupItem | null>();
	const [ruleType, setRuleType] = useState<string | undefined>();
	const [loadingListOfRules, setLoadingListOfRules] = useState<boolean>(false);

	const handleProductCountChanged = (e) => {
		const val = e.target.value;
		handleChangeGroupSize(ruleCompositionGroup, val);
	};
	const handleChooseTypeRule = (type: string) => {
		const selectedRuleType = listRulesByType.find(r => r.value === type);
		if (selectedRuleType === undefined) return;
		if (selectedRuleType.isPersoRule) {
			setRuleType(undefined);
			setOpenModalToAddRule(false);
			const ruleN: ComposableGroupItem = {
				actualRuleId: null,
				internalId: uuidv4(),
				persoRuleType: selectedRuleType.PersoRuleType,
				name: selectedRuleType.label,
				ruleLimit: null,
				isFillUp: false,
				isPersoRule: true,
			};
			handleAddRuleToGroup(ruleCompositionGroup, ruleN);

		} else {
			setRuleType(selectedRuleType.value);
		}
	};
	useEffect(() => {
		if (!openModalToAddRule) return;

		setLoadingListOfRules(true);
		loadPossibleRules(x => {
			setListOfRules(x);
			setLoadingListOfRules(false);
		});

	}, [openModalToAddRule]);

	return (
		<>
			<div className={nbCompositionGroups > 1 ? styles.group_multiple : styles.group_simple}>
				{nbCompositionGroups > 1 &&
					<div className={styles.group_header}>
						<div className={styles.group_header_left}>
							<div className={styles.group_header_title}>Group {groupIndex + 1}</div>
						</div>
						<div className={styles.group_header_center}>
							...
						</div>
						<div className={styles.group_header_right}>
							<IconBtn
								tooltip="Delete group"
								onClick={() => setConfirmDeleteGroup(true)}
								icon="fas fa-trash"
								hoverColor="alert"
								size="s"
							/>
						</div>
					</div>
				}

				<div className={styles.group_body}>
					{ruleCompositionGroup.rules?.length === 0 &&
						<EmptyState
							title="Add a first rule"
							text="Combine multiple strategies within the same group"
							textSize="s"
							verticalSize="s"
							primaryAction={
								<Btn
									message="Add rule"
									onClick={() => setOpenModalToAddRule(true)}
									style="outline"
									icon="fas fa-plus-circle fa-sm"
								/>
							}
						/>
					}
					{ruleCompositionGroup.rules?.length > 0 &&
						<>
							<div className={styles.group_body_head}>
								<div className={styles.group_body_left}>
									Rules
								</div>
								<div className={styles.group_body_right}>
									Nb. of products
								</div>
							</div>
							<DragDropContext onDragEnd={handleOnDragEnd}>
								<Droppable droppableId="rule-id" direction="vertical">
									{(provided) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{ruleCompositionGroup.rules.map((rule, index) =>
												<Draggable key={rule.internalId} draggableId={rule.internalId.toString()} index={index}>
													{(provided, snapshot) => (
														<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															<RuleComponent
																isDragging={snapshot.isDragging}
																rule={rule}
																ruleCompositionGroup={ruleCompositionGroup}
																displayMode='drag'
															/>
														</div>
													)}
												</Draggable>
											)}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							{ruleCompositionGroup.hasFillUpRule() &&
								<div className={styles.group_body_total}>
									<div className="flex">
										<div className='flex_item_full'>
											<div className='fw_medium'>Total number of products</div>
										</div>
										<div className="flex_item_fix ml_20">
											<NumericUpDown
												value={ruleCompositionGroup.size || 0}
												onChange={handleProductCountChanged}
												canEdit={true}
												placeholder="2"
												min={0}
												isAnError={ruleCompositionGroup.error?.message}
											/>
										</div>
									</div>
								</div>
							}
							<div className={styles.group_body_add}>
								<Btn
									message="Add rule"
									onClick={() => setOpenModalToAddRule(true)}
									style="outline"
									icon="fas fa-plus-circle fa-sm"
								/>
							</div>
						</>
					}
				</div>
			</div>
			<Modal
				isOpen={openModalToAddRule}
				width="700"
				onClose={() => setOpenModalToAddRule(false)}
			>
				<div className="modal_header has_border">
					Add rule
				</div>
				<div className="modal_section">
					{loadingListOfRules
						&& <div className="mask">
							<span className="wheel"></span>
						</div>
					}

					{!ruleType &&
						<Article hasMargin={false}>
							<List removeVerticalMargin={true}>
								{!ruleType && listRulesByType.map((ruleType, i) =>
									<ListItem
										key={i}
										text={ruleType.label}
										description={ruleType.description}
										onClick={() => handleChooseTypeRule(ruleType.value)}
										hasArrow={true}
									/>
								)}
							</List>
						</Article>
					}

					{ruleType && ruleType === 'DynamicCollection' &&
						<>
							<label htmlFor="rule">Choose a rule</label>
							<Select
								closeMenuOnSelect={true}
								components={animatedComponents}
								defaultValue={{
									value: 'choose',
									label: 'Select an option',
								}}
								menuPortalTarget={document.body}
								styles={{ menuPortal: base => ({ ...base, zIndex: 20000 }) }}
								menuPosition={'fixed'}
								options={listOfRules && listOfRules.map(el => {
									return {
										value: el.actualRuleId,
										label: el.name,
										id: el.actualRuleId
									};
								})}
								placeholder={'Search a rule'}
								onChange={(e) => setRuleToAdd({
									actualRuleId: e.id,
									internalId: uuidv4(),
									name: e.label,
									ruleLimit: null,
									isFillUp: false,
									isPersoRule: false,
									persoRuleType: null
								})}
							/>
						</>
					}
				</div>
				{ruleType && ruleType === 'DynamicCollection' &&
					<div className="modal_footer al_right">
						<Btn
							onClickFunction={() => {
								setOpenModalToAddRule(false);
								setRuleToAdd(null);
								setRuleType(undefined);
							}}
							message="Cancel"
							color="secondary"
							size="l"
							style="ghost"
						/>
						<Btn
							onClickFunction={ruleToAdd !== null ? () => {
								setOpenModalToAddRule(false);
								setRuleType(undefined);
								handleAddRuleToGroup(ruleCompositionGroup, ruleToAdd!);
							} : null}
							message="Validate"
							disabled={ruleToAdd !== null ? false : true}
							size="l"
						/>
					</div>
				}
			</Modal>
			<Confirm
				isOpen={confirmDeleteGroup}
				onClose={() => setConfirmDeleteGroup(false)}
				title="Are you sure to delete this group?"
				text="This action is irreversible"
				cancelText="Cancel"
				confirmText="Delete this group"
				confirmCallback={() => handleDeleteGroup(ruleCompositionGroup)}
			/>
		</>
	);
}