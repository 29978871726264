
import { flow } from 'lodash';
import { EmailTemplateObject } from './EmailTemplateTypes';
import { DeviceType, Template } from '../../../WysiwygEditor/Types/BespokeTypes';
import { EditorState, EditorCreateOrUpdateMode, EditorStep } from './EditorStateTypes';
import { Style } from './StyleTypes';
import { propertiesProcessing } from '../../../WysiwygEditor/Utils/TemplateOperations';

const mergeEditablePropertiesIntoFormat = (
	mode: EditorCreateOrUpdateMode,
	f: Template,
	variation: EmailTemplateObject,
	initialCss: boolean
): Template => {
	return {
		...f,
		TemplateDescriptorDesktop: {
			...f.TemplateDescriptorDesktop,
			EditableCss:
				mode === EditorCreateOrUpdateMode.Create || initialCss
					? f.TemplateDescriptorDesktop.Css
					: variation.TemplateDescriptorDesktop.EditableCss,
			EditableTemplate:
				variation.TemplateDescriptorDesktop.EditableTemplate
		},
		TemplateDescriptorMobile: {
			...f.TemplateDescriptorMobile,
			EditableCss:
				mode === EditorCreateOrUpdateMode.Create || initialCss
					? f.TemplateDescriptorMobile.Css
					: variation.TemplateDescriptorMobile.EditableCss,
			EditableTemplate:
				variation.TemplateDescriptorMobile.EditableTemplate
		},
	};
};

export const initializeFromFormat = (device: DeviceType, mode: EditorCreateOrUpdateMode, format: Template, goToNext: boolean, cssToInitialize: boolean, editorState: EditorState, emailTemplate: EmailTemplateObject, formatIsSelected:boolean) => {
	const { updateEditorState: updateEditorStateFromFormat, updateVariation } = handleFormatChosenWith(mode, format, goToNext, cssToInitialize, editorState, emailTemplate);

	const initializeWysiwyg = (s: EditorState): EditorState => ({
		...s,
		formatIsSelected:formatIsSelected,
		currentWysiwyg:
			device === DeviceType.Desktop
				? format.WysiwygDesktop
				: format.WysiwygMobile,
	});

	const updateEditorState: ((s: EditorState) => EditorState) = flow([initializeWysiwyg, updateEditorStateFromFormat]);
	return {
		updateEditorState,
		updateVariation
	};
};

export const handleFormatChosenWith = (mode: EditorCreateOrUpdateMode, format: Template, goToNext: boolean, cssToInitialize: boolean, editorState: EditorState, emailTemplate: EmailTemplateObject) => {
	const {
		templatePropertyToStyleProperty,
	} = propertiesProcessing();

	const updateVariationFromFormat = (variation: EmailTemplateObject): EmailTemplateObject => {
		const mergedFormat = mergeEditablePropertiesIntoFormat(
			mode,
			format,
			emailTemplate,
			cssToInitialize
		);
		return {
			...variation,
			TemplateName: mergedFormat.TemplateName,
			TemplateDescriptorDesktop: mergedFormat.TemplateDescriptorDesktop,
			TemplateDescriptorMobile: mergedFormat.TemplateDescriptorMobile,
			Template: {
				...mergedFormat,
				Properties: mergedFormat.Properties,
			},
			Properties: mergedFormat.Properties.map(
				templatePropertyToStyleProperty
			),
		};
	};
	const formatHasNotChanged = emailTemplate.TemplateName === format.TemplateName;
	const shouldRestoreCurrentStyle = formatHasNotChanged && !!editorState.selectedStyle?.Id;

	const newEditorStateStyles = (s: EditorState): EditorState => {
		const newDefaultStyle: Style = {
			Id: 'default',
			Properties: format.DefaultStyle?.Properties ?? [],
		};
		return shouldRestoreCurrentStyle
			? s
			: {
				...s,
				defaultStyle: newDefaultStyle,
				selectedStyle: newDefaultStyle,
				currentStyle: null,
			};
	};
	const newStep = (s: EditorState): EditorState => (goToNext ? { ...s, sideBarStep: EditorStep.One } : s);
	const updateVariation = formatHasNotChanged
		? (s: EmailTemplateObject): EmailTemplateObject => s
		: updateVariationFromFormat;

	const updateEditorState: ((s: EditorState) => EditorState) = flow([newEditorStateStyles, newStep]);
	return {
		updateEditorState,
		updateVariation
	};
};




export type ValidationError = {
	name: string;
	error: string;
}
type ValidationResult = {
	isValid: boolean;
	errors: ValidationError[];
}

export function validate() {

	function validateEditorState(editorState: EditorState, validationError: ValidationError[]): ValidationResult {

		editorState; // ? check something

		const newValidationError = [...validationError];
		return { isValid: true, errors: newValidationError };
	}

	function validateFormatData(emailTemplate: EmailTemplateObject, validationError: ValidationError[]): ValidationResult {

		emailTemplate.Template; // ? check something

		const newValidationError = [...validationError];
		return { isValid: true, errors: newValidationError };
	}

	function validateInformationsData(emailTemplate: EmailTemplateObject, validationError: ValidationError[]): ValidationResult {
		console.log('🚀🐱 😻 --///** ~ file: EditorOperations.tsx:143 ~ validateInformationsData ~ emailTemplate:', emailTemplate)
		let isNameValid = true;

		const newValidationError = [...validationError];
		if (emailTemplate.Name.length < 2) {
			isNameValid = false;
			const isAlreadyDisplayed = newValidationError.findIndex(error => error.name === 'campaignName');
			if (isAlreadyDisplayed === -1) newValidationError.push({ name: 'campaignName', error: 'Campaign name is mandatory' });
		}
		else {
			const index = newValidationError.findIndex(error => error.name === 'campaignName');
			if (index !== -1) newValidationError.splice(index, 1);
		}


		return { isValid: (isNameValid === true), errors: newValidationError };
	}

	return {
		validateFormatData,
		validateEditorState,
		validateInformationsData
	};
}
