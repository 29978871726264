
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styles from './FormCustomPanel.module.css';
import surveyStyles from './Survey.module.css';
import controlTypes from './controlTypes';
import { reorderItems } from './FormCustomUtils';
import FormCustomPanelHook from './FormCustomPanelHook';
import { CampaignsContext } from '../../context/index';
import Btn from '../../../../Components/Btn';
import EmptyState from '../../../../Components/EmptyState';
import {useFormContext} from '../FormSurvey/ContextFormAndSurvey';
import { v4 as uuidv4 } from 'uuid';
import { PanelInitTypeSelection } from './FormCustomPanelSubComponents';
import { getCSS } from './css.js';
const isEmptyForm = function (formValue) {
	var hasNoSection = function (form) { return form.sections.length === 0; };
	var hasSingleEmptySection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 0; };
	var hasSingleNotSetSection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 1 && form.sections[0].panels[0].type === ''; };

	return (!formValue.sections) || hasNoSection(formValue) || hasSingleEmptySection(formValue) || hasSingleNotSetSection(formValue);
};

export default function FormSurveyCustomContainerHook({
	state,
	setState,
	nbQuestions,
	removePanel
}) {

	const [t] = useTranslation(['survey']);	
	const {
		variations,
		getCurrentVariation,
		setThankSectionValue,
		setFormSurveyStyles,
	} = CampaignsContext();

	const {
		updatePanel,
		setFormData,
		setpanelSelected,
		stepSurvey,
		setisLast
	} = useFormContext();

	const [data, setData] = useState();
	const [hasScroll, setHasScroll] = useState(false);
	const [addModalIsOpen, setAddModalIsOpen] = useState(false);
	const scrollRef = useRef(null);


	function getNextSectionId(sectionId) {
		const currentSectionIndex = state.sections.findIndex(section => section.id === sectionId);
		const nextSection = state.sections[currentSectionIndex + 1];

		return nextSection ? nextSection.id : null;
	}

	function removeSection(sectionId) {
		setState(state => {
			const sections = state.sections.filter(section => sectionId !== section.id);
			return { sections };
		});
	}

	function handleSelectCurrentPanel(p){
		setpanelSelected(p);
		setisLast(false);
	}

	function getDefaultQuestion(type) {
		if (type === 7) return 'Veuillez entrer votre adresse email';
		if (type === 9) return 'Veuillez entrer votre numéro de téléphone';
		if (type === 10) return 'Dans quelle mesure êtes-vous satisfait de nos produits ?';
		if (type === 11) return 'Dans quelle mesure êtes-vous satisfait de nos produits ?';
		return 'Comment se passe votre visite ?';
		// if (type === 7) return t('defaultQuestionEmail');
		// if (type === 9) return t('defaultQuestionTel');
		// return t('defaultQuestion');
	}

	function getDefaultPlaceholder(type) {
		if (type === 7) return 'john.doe@example.com';
		if (type === 9) return '0612345678';
		return 'Veuillez taper ici...';
		// if (type === 7) return t('defaultQuestionEmail');
		// if (type === 9) return t('defaultQuestionTel');
		// return t('defaultQuestion');
	}

	function getDefaultControls(type) {
		const controls = [];
		if (type === 10 || type === 11) {
			for (let i = 0; i < 5; i++) {
				controls.push({
					id: uuidv4(),
					text: i+1,
					editMode: true
				});
			}
		}
		return controls;
	}
	
	function addPanel(type) {
		const sectionId = state.sections[0].id;
		const newId = uuidv4();
		const newPanel =  {
			id: newId,
			name: '',
			type: type,
			question: getDefaultQuestion(type),
			placeholder: getDefaultPlaceholder(type),
			lowScoreLabel: 'Pas satisfait',
			highScoreLabel: 'Très satisfait',
			controls: getDefaultControls(type),
			required: true,
			showQuestion: true,
			controlTypes: [
				...controlTypes,
			],
			value: '',
			valueForTest : '',
			surveyInfos: {
				nextQuestion :'next',
				multipleQuestionsBasedOnAnswer : [],
				ignoreQuestion : 'next'
			}
		};
		setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const panels = [...section.panels, newPanel];
					section.panels = panels;
				}
				return section;
			});
			return { sections };
		});
		setpanelSelected(newPanel);
	}



	function duplicatePanel(id, sectionId) {
		setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const isDuplicatedPanel = panel => panel.id === id;
					const duplicatedPanel = section.panels.find(isDuplicatedPanel);
					const duplicatedPanelIndex = section.panels.findIndex(isDuplicatedPanel);
					let panels = section.panels;

					panels.splice(duplicatedPanelIndex + 1, 0, {
						id: uuidv4(),
						type: duplicatedPanel.type,
						question: duplicatedPanel.question,
						placeholder: duplicatedPanel.placeholder,
						controls: duplicatedPanel.controls,
						required: duplicatedPanel.required,
						showQuestion:duplicatedPanel.showQuestion,
						controlTypes: duplicatedPanel.controlTypes,
						buttonType: duplicatedPanel.buttonType,
						name: duplicatedPanel.name,
						value: duplicatedPanel.value,
						valueForTest: duplicatedPanel.valueForTest,
						surveyInfos: duplicatedPanel.surveyInfos 
					});

					section.panels = panels;
				}
				return section;
			});

			return { sections };
		});
	}

	function onDragEnd(result, sectionId) {
		if (!result.destination) {
			return;
		}

		const sections = state.sections.map(section => {
			if (section.id === sectionId) {
				const panels = reorderItems(
					section.panels,
					result.source.index,
					result.destination.index
				);
				section.panels = panels;
			}
			return section;
		});
		setState({ sections });
	}

	function onLoad(dataToCheck) {
		var parsePropertyValue = function (properties) {
			const form = properties.find(el => el.Name === 'FormSurveyCustom');
			if (properties && form) {
				return JSON.parse(form.Value);
			}
			else return {};
		};
		const emptyStyle = {
			colors : {
				global: {
					backgroundColor : '#FFFFFF',
					textColor : '#151E39',
					errorColor: '#df4141'
				},
				button : {
					backgroundColor : '#23B583',
					textColor : '#FFFFFF',
					border: false,
					borderColor: '#16563e'
				},
				skip : {
					backgroundColor : '#FFFFFF',
					textColor : '#5D606F',
					border: true,
					borderColor: '#CAD0DB'
				}
			},
			wording : {
				language : 'French',
				nextButton : 'Suivant',
				skipButton : 'Passer',
				errorEmail: 'Veuillez saisir une adresse email valide',
				errorTel: 'Veuillez saisir un numéro de téléphone valide',
				errorDefault: 'La valeur saisie n\'est pas valide'
			}
		};
		const emptyThankYouMessage = '<p style="text-align: center;">&nbsp;</p><p style="text-align: center;"><span style="color: #23b583;"><strong>Merci d\'avoir r&eacute;pondu &agrave; notre enqu&ecirc;te</strong></span><br /><span style="font-size: 15px;">Nous appr&eacute;cions beaucoup vos commentaires.</span></p><p style="text-align: center;">&nbsp;</p>';

		const slideFormValue = parsePropertyValue(dataToCheck.Properties);
		var formValue = isEmptyForm(slideFormValue)
			? { sections: [{ id: 1, panels: [], thanksSection: emptyThankYouMessage, styles: emptyStyle, css: getCSS(emptyStyle)}] }
			: {
				sections: slideFormValue.sections.map(function (section) {
					return {
						id: section.id,
						panels: section.panels.map(function (panel) {
							return {
								id: panel.id,
								name: panel.name,
								type: panel.type,
								question: panel.question,
								placeholder: panel.placeholder,
								lowScoreLabel: panel.lowScoreLabel,
								highScoreLabel: panel.highScoreLabel,
								controls: panel.controls,
								required: panel.required,
								showQuestion:panel.showQuestion,
								controlTypes: controlTypes,
								buttonType: panel.buttonType,
								value: panel.value,
								surveyInfos: panel.surveyInfos,
								valueForTest: panel?.valueForTest
							};
						}),
						thanksSection : section.thanksSection,
						styles : section.styles,
						css : section.css
					};
				})
			};

		const firstPanel = formValue.sections[0].panels;
		const elemenSection = formValue.sections[0].hasOwnProperty('thanksSection');
		const elemenStyles = formValue.sections[0].hasOwnProperty('styles');
		if(elemenSection){
			const elemenSectionValue = formValue.sections[0].thanksSection;
			setThankSectionValue(elemenSectionValue !== undefined ? elemenSectionValue : emptyThankYouMessage);
		}else{
			formValue.sections[0].thanksSection  = emptyThankYouMessage;
			setThankSectionValue(emptyThankYouMessage);
		}

		if(elemenStyles){
			const elemenSectionStyle= formValue.sections[0].styles ;
			setFormSurveyStyles(elemenSectionStyle !== undefined ? elemenSectionStyle : emptyStyle);
		}else{		
			setFormSurveyStyles(emptyStyle);
			formValue.sections[0].styles = emptyStyle;
		}

		if(firstPanel.length > 0){
			setpanelSelected(firstPanel[0]);
		}
					
		setState({ slide: dataToCheck });
		setState(formValue);
	}

	useEffect(()=>{
		const currentVariationSaved = getCurrentVariation();
		setData(currentVariationSaved);
	},[variations]);

	const __handleScroll = (ev) => {
		const scrollY = ev.target.scrollTop;
		setHasScroll(scrollY > 0);
	};

	useEffect(() => {
		if (scrollRef && scrollRef.current) {
			scrollRef.current.addEventListener('scroll', __handleScroll);
		}
		return () => {
			if (scrollRef && scrollRef.current) {
				scrollRef.current.removeEventListener('scroll', __handleScroll);
			}
		};
	}, [scrollRef.current]);

	useEffect(() => {
		if (scrollRef && scrollRef.current) {
			__handleScroll({target: scrollRef.current});
		}
	}, [stepSurvey]);
	
	function updateFormCustomData(){
		const sections = state.sections.map(section => {
			return {
				id: section.id,
				panels: section.panels.filter(function (p) { return p.type !== ''; }).map(function (panel) {
					return {
						highScoreLabel: panel.highScoreLabel,
						lowScoreLabel: panel.lowScoreLabel,
						id: panel.id,
						name: panel.name,
						type: panel.type,
						question: panel.question,
						placeholder: panel.placeholder,
						controls: panel.controls,
						required: panel.required,
						showQuestion:panel.showQuestion,
						buttonType: panel.buttonType,
						value: panel.value,
						surveyInfos: panel.surveyInfos,
						valueForTest: panel?.valueForTest
					};
				})
			};
		}).filter(function (s) { return s.panels.length > 0; });
		setFormData(JSON.stringify({ sections: sections })
		);		
	}

	useEffect(()=>{
		if(state){
			updateFormCustomData();
		}
	},[state]);

	useEffect(()=>{
		if(data){
			onLoad(data);
		}
	},[data]);
						
	if(stepSurvey !== 1) {
		return null;
	}

	if (!state || !state.sections) {
		return null;
	}

	return (
		<>
			{nbQuestions === 0 &&
				<>
					<EmptyState
						title={t('surveyEditor')}
						text={t('getFeedback')}
						imageUrl='/Assets/empty_survey.svg'
						verticalAlign={true}
						textSize='l'
						primaryAction={
							<Btn
								message={t('addFirstQuestion')}
								onClick={() => setAddModalIsOpen(true)}
							/>
						}
					/>
				</>
			}
			{nbQuestions > 0 &&
				<>
					<div className={[surveyStyles.editor_header, 'scroll_shadow', 'scroll_shadow_bottom', hasScroll ? 'scrolling' : ''].join(' ')}>
						<div className='flex'>
							<div className='flex_item_full h3'>
								{nbQuestions > 1 ?
									t('Nquestions', {n: nbQuestions})
									:
									t('Nquestion', {n: nbQuestions})
								}
							</div>
							<div className='flex_item_fix ml_20'>
								<Btn
									icon="fas fa-plus-circle"
									message={t('addQuestion')}
									onClick={() => setAddModalIsOpen(true)}
									style="outline"
								/>
							</div>
						</div>
					</div>
					{state.sections.map((section) => 
						<div
							key={section.id}
							className={styles.section + ' vscroll_custom ' + surveyStyles.editor_body}
							ref={scrollRef}>
							<DragDropContext onDragEnd={(result) => onDragEnd(result, section.id)}>
								<Droppable droppableId={`droppable-${section.id}`}>
									{
										(provided, snapshot) => (
											<div
												
												{...provided.droppableProps}
												ref={provided.innerRef}>
												{section.panels.map((panel, j) =>
													<FormCustomPanelHook
														getDefaultControls={getDefaultControls}
														onClickToPanel={()=>handleSelectCurrentPanel(panel)}
														{...panel}
														panel={panel}
														key={panel.id}
														index={j}
														sectionId={section.id}
														sections={{
															current: section.id,
															next: getNextSectionId(section.id)
														}}
														showQuestion={panel.showQuestion}
														displayRequired={panel.type !== 3}
														styles={styles}
														onSectionRemove={removeSection}
														onContentChange={updatePanel}
														onRemove={removePanel}
														onDuplicate={duplicatePanel}
													/>
												)}
												{provided.placeholder}
											</div>
										)}
								</Droppable>
							</DragDropContext>
						</div>
					)}
				</>
			}
			{addModalIsOpen &&
				<PanelInitTypeSelection
					styles={styles}
					controlTypes={controlTypes}
					onSelect={(type => {addPanel(type); setAddModalIsOpen(false);})}
					remove={() => setAddModalIsOpen(false)}
				/>
			}
		</>
	);
}

